/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import Dialog from '../../shared/Dialog';
import InlineSVG from '../../shared/InlineSVG';
import { isFirefox, isNotEmpty, isSafari } from '../../shared/Utilities';

import './PrintButton.scss';
import { helpContentMap } from './Shared';

const PrintButton = ( {
  keys,
  setPrintSelection,
  printLabel='',
} ) => {

  const [ showConfirm, setShowConfirm ] = React.useState( false );
  const [ selectionType, setSelectionType ] = React.useState( 'page' );

  const confirmPrint = e => {
    e.preventDefault();
    e.stopPropagation();

    // this print was initiated from a page, attempting to print one page
    if ( keys.pageKey ) {
      const { mainKey } = keys;
      const sectionKeys = [ keys.sectionKey ];
      const pageKeys = [ keys.pageKey ];
      setPrintSelection( { mainKey, sectionKeys, pageKeys } );
    // this print was from the menu, attempting to print a whole section
    } else if ( keys.sectionKey ) {
      const { mainKey } = keys;
      const sectionKeys = [ keys.sectionKey ];
      const pageKeys = Object.keys( helpContentMap[mainKey][keys.sectionKey].pages );
      setPrintSelection( { mainKey, sectionKeys, pageKeys } );
    // this print was from the menu, attempting to print a whole guide, either the user or reference
    } else if ( keys.mainKey ) {
      const { mainKey } = keys;
      const sectionKeys = Object.keys( helpContentMap[mainKey] );
      const pageKeys = [];
      Object.values( helpContentMap[mainKey] ).map( section => {
        Object.keys( section.pages ).map( pageKey => {
          pageKeys.push( pageKey );
        } );
      } );
      setPrintSelection( { mainKey, sectionKeys, pageKeys } );
    }
    setShowConfirm( true );
  };

  const cancelPrint = () => {
    setPrintSelection( { mainKey: null, sectionKeys: [], pageKeys: [] } );
  };

  React.useEffect( ( ) => {
    if ( isNotEmpty( keys.pageKey ) ) {
      setSelectionType( 'page' );
    } else if ( isNotEmpty( keys.sectionKey ) ) {
      setSelectionType( 'section' );
    } else {
      setSelectionType( 'guide' );
    }
  }, [ keys ] );

  return (
    <React.Fragment>
      <Dialog
        visible={ showConfirm }
        setVisible={ setShowConfirm }
        // eslint-disable-next-line max-len
        content={
          ( isSafari || isFirefox )
            // eslint-disable-next-line max-len
            ? 'Safari and Firefox have known issues dynamically printing lots of pages. Please use Chrome or Chromium Edge to print the documentation'
            : `Are you sure you want to print the following ${selectionType}: ${printLabel}?`
        }
        action={ ( isSafari || isFirefox ) ? null : () => window.print() }
        onCancel={ cancelPrint }
      />
      <button
        className="printButton"
        onClick={ confirmPrint }
      >
        <InlineSVG type="print" />
      </button>
    </React.Fragment>
  );
};

export default PrintButton;