/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import EditModal from '../../components/Remediation/RemediationPlans/EditModal/index.js';
import CredentialModal from '../../components/Scanning/ScanCredentials/CredentialModal';
import IntegrationModal from '../../components/Remediation/RemediationTicketing/IntegrationModal';
import ScanGroupModal from '../../components/Scanning/ScanGroups/ScanGroupModal';
import TagEditorV3 from '../../components/Setup/Tags/TagEditorV3';
import Form from '../Form';
import Modal from '../Modal';
import {
  capitalize,
  isNotEmpty,
  largeModals,
  removeFromURLHash,
  unFocusForOnboarding,
} from '../Utilities';

import './FormModal.scss';
import PolicyModal from '../../components/Setup/Settings/SensitiveAssets/PolicyModal.js';
import AcceptedRiskPlanModal from '../../components/Remediation/AcceptedRisk/AcceptedRiskPlanModal.js';
import { CurrentUserContext } from '../../Contexts/CurrentUser.js';

// most FormModals need an actions footer, but some of the more complicated ones already have their own
const hasOwnActions = [
  'remediation_plan',
  'tag',
  'scan_credential',
  'integration',
  'sensitive_asset_policy',
  'accepted_risk_plan',
];

const ModalBody = ( {
  recordType,
  selectedRecord,
  setSelectedRecord,
  setIsValid,
  fields,
  showModal,
  setShowModal,
  users,
  osLabels,
  setRecordFieldStates,
  formChangeCallBack,
  onSave,
  activeIntegrations,
  externalUsers,
  assetTagOptions,
  validateOnLoad,
  externalLoading,
  onRefresh,
} ) => {

  // const [ , , , demoMode ] = React.useContext( CurrentUserContext );

  const syncFieldStates = formState => {
    if ( isNotEmpty( formState ) && isNotEmpty( formState.fieldStates ) ) {
      setRecordFieldStates( formState.fieldStates );
      formChangeCallBack( formState );
    }
  };

  const contentForRecordType = type => {

    if ( type === 'sensitive_asset_policy' ) {
      return <PolicyModal
        selectedRecord={selectedRecord}
        setSelectedRecord={setSelectedRecord}
        showModal={showModal}
        setShowModal={setShowModal}
        onSave={onSave}
        assetTagOptions={assetTagOptions}
        externalLoading={externalLoading}
      />;
    }

    if ( type === 'integration' ) {
      return <IntegrationModal
        selectedRecord={selectedRecord}
        setSelectedRecord={setSelectedRecord}
        showModal={showModal}
        setShowModal={setShowModal}
        onSave={onSave}
        onRefresh={onRefresh}
      />;
    }

    if ( type === 'scan_credential' ) {
      return <CredentialModal
        selectedRecord={selectedRecord}
        showModal={showModal}
        setShowModal={setShowModal}
        onSave={onSave}
      />;
    }

    if ( type === 'tag' ) {
      return <TagEditorV3
        selectedRecord={selectedRecord}
        showModal={showModal}
        setShowModal={setShowModal}
        onSave={onSave}
        osLabels={osLabels}
        users={users}
      />;
    }

    if ( type === 'remediation_plan' ) {
      return  <EditModal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedRecord={selectedRecord}
        setSelectedRecord={setSelectedRecord}
        users={users}
        activeIntegrations={activeIntegrations}
        externalUsers={externalUsers}
      />;
    }

    if ( type === 'accepted_risk_plan' ) {
      return <AcceptedRiskPlanModal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedRecord={selectedRecord}
        setSelectedRecord={setSelectedRecord}
        onRefresh={onRefresh}
      />;
    }

    if ( type === 'scan_group' ) {
      return  <ScanGroupModal
        trackUpdates={ isNotEmpty( selectedRecord ) }
        fields={fields}
        setIsValid={setIsValid}
        existingRecord={selectedRecord}
        recordType={recordType}
        onChangeCallback={ syncFieldStates }
        showModal={showModal}
      />;
    }

    return  <Form
      trackUpdates={ isNotEmpty( selectedRecord ) }
      fields={fields}
      setIsValid={setIsValid}
      existingRecord={selectedRecord}
      recordType={recordType}
      onChangeCallback={ syncFieldStates }
      validateOnLoad={validateOnLoad}
    />;
  };

  return (
    <React.Fragment>
      { contentForRecordType( recordType ) }
    </React.Fragment>
  );
};

const ModalAction = ( {
  selectedRecord,
  isValid,
  onSave,
  setShowModal,
  recordType,
  recordFieldStates,
  allowEnterSubmit,
} ) => {

  const [ , , , demoMode ] = React.useContext( CurrentUserContext );

  const saveRecord = () => {
    if ( recordType === 'scan_credential'
      || recordType === 'scan_group'
      || recordType === 'user'
    ) {
      onSave( selectedRecord, isValid, recordFieldStates, () => setShowModal( false ) );
    } else {
      onSave( selectedRecord, isValid, recordFieldStates );
      setShowModal( false );
    }
    removeFromURLHash( 'selected_record' );
    const elementToFocus = document.getElementById( 'newItemButton' );
    unFocusForOnboarding( elementToFocus );
  };

  React.useEffect( () => {
    window.removeEventListener( 'keyup', submitOrCancel );
    window.addEventListener( 'keyup', submitOrCancel );
    return () => {
      window.removeEventListener( 'keyup', submitOrCancel );
    };
  }, [ selectedRecord, recordFieldStates, isValid ] );

  const submitOrCancel = e => {
    if ( allowEnterSubmit ) {
      if ( e.keyCode === 13 ) {
        e.preventDefault();
        if ( isValid ) {
          saveRecord();
        }
      }
    }
    if ( e.keyCode === 27 ) {
      setShowModal( false );
    }
  };

  return (
    <button
      disabled={ !isValid || demoMode }
      className={`${( !isValid || demoMode ) ? 'disabled' : ''} submitButton`}
      onClick={ saveRecord }
    >
      Save Changes
    </button>
  );
};

const FormModal = ( {
  recordType,
  showModal,
  setShowModal,
  isValid,
  setIsValid,
  selectedRecord,
  setSelectedRecord,
  onSave,
  modalClass,
  users,
  osLabels,
  fields,
  allowEnterSubmit=true,
  formChangeCallBack=() => {},
  activeIntegrations,
  externalUsers,
  assetTagOptions,
  validateOnLoad=false,
  externalLoading=false,
  onRefresh=() => {},
} ) => {
  // used on form callback whenever the state of the form changes
  const [ recordFieldStates, setRecordFieldStates ] = React.useState( null );

  return (
    <React.Fragment>
      <Modal
        visible={showModal}
        setVisible={setShowModal}
        header={`${isNotEmpty( selectedRecord ) ? 'Edit': 'New'} ${capitalize( recordType )}`}
        size={ largeModals.includes( recordType ) ? 'large' : 'small' }
        elementClass={ `${modalClass} reactSetupModal ${recordType}` }
        needsActions={ !hasOwnActions.includes( recordType ) }
        elementID="reactSetupModal"
        body={<ModalBody
          recordType={recordType}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          isValid={isValid}
          setIsValid={setIsValid}
          fields={fields}
          showModal={showModal}
          setShowModal={setShowModal}
          users={users}
          osLabels={osLabels}
          setRecordFieldStates={setRecordFieldStates}
          formChangeCallBack={formChangeCallBack}
          onSave={onSave}
          activeIntegrations={activeIntegrations}
          externalUsers={externalUsers}
          assetTagOptions={assetTagOptions}
          validateOnLoad={validateOnLoad}
          externalLoading={externalLoading}
          onRefresh={onRefresh}
        />
        }
        action={<ModalAction
          selectedRecord={selectedRecord}
          isValid={isValid}
          onSave={onSave}
          setShowModal={setShowModal}
          recordType={recordType}
          recordFieldStates={recordFieldStates}
          allowEnterSubmit={allowEnterSubmit}
        />}
      />
    </React.Fragment>
  );
};

export default FormModal;