/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty, shortenedVulnerabilityScannerNameMap } from '../../../shared/Utilities';
import ScanningStatusIndicator from '../ScanningStatusIndicator';

const ScanningStatus = ( { record, latestThirdParty } ) => {

  return (
    <React.Fragment>
      {
        isNotEmpty( record ) &&
        <div className="recordDetails__InformationPanel__Body_Section scanningStatus">
          <div className="bodySectionItem">
            <label>
              <span>Third Party Status</span>
              <span>
                {
                  isNotEmpty( latestThirdParty )
                    ? shortenedVulnerabilityScannerNameMap[latestThirdParty.scannerKey] || 'Third Party'
                    : 'N/A'
                }
              </span>
            </label>
            <ScanningStatusIndicator
              timestamp={ isNotEmpty( latestThirdParty ) ? latestThirdParty.timestamp : 0 }
              preformatted
              version="thirdParty"
            />
          </div>
          <div className="bodySectionItem">
            <label>DeepSurface Scanning Status</label>
            <ScanningStatusIndicator timestamp={ record?.last_scanned } />
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default ScanningStatus;