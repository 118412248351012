/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import { TasksContext } from '../../Contexts/Tasks';

import './style.scss';

import {
  getDimensionsAndOffset,
  isNotEmpty,
} from '../Utilities';

import InlineSVG from '../InlineSVG';
import { makeRequest } from '../../../legacy/io';
import { CurrentUserContext } from '../../Contexts/CurrentUser';

const TaskRunner = ( {
  record,
  taskType,
  notificationsCallback=() => {},
} ) => {

  const [ showAdditional, setShowAdditional ] = React.useState( false );
  const [ forceRescan, setForceRescan ] = React.useState( false );
  const [ runNextTask, setRunNextTask ] = React.useState( true );

  const [ currentTasks, setCurrentTasks ] = React.useContext( TasksContext );
  const [ , , , demoMode ] = React.useContext( CurrentUserContext );

  const [ isWorking, setIsWorking ] = React.useState( false );
  const [ isStopping, setIsStopping ] = React.useState( false );
  const [ status, setStatus ] = React.useState( '' );

  const [ buttonState, setButtonState ] = React.useState( '' );
  const [ warnings, setWarnings ] = React.useState( [] );

  const [ additionalOptionsStyle, setAdditionalOptionsStyle ] = React.useState( { top: '3em', right: '0em' } );

  const additionalOptionsRef = React.useRef( null );

  const titleMap = {
    import: 'Import Vulnerability Source Data',
    authenticated: 'Scan Now',
  };

  const labelMap = {
    import: 'Import',
    authenticated: 'Scan',
  };

  const buttonMap = {
    import: {
      start:  <button
        title={titleMap[taskType]}
        className={`${buttonState} ${taskType} ${demoMode ? 'disabled' : ''} taskRunnerButton`}
        disabled={demoMode}
        onClick={ () => setShowAdditional( true ) }
      >
        <InlineSVG type="play" elementClass="start"/>
        <InlineSVG type="queue" elementClass="queued"/>
        <span className="queuedLabel">Queued</span>
        <span className="taskStartLabel">{ labelMap[taskType] }</span>
      </button>,
      running:  <button
        disabled
        className={`${buttonState} ${demoMode ? 'disabled' : ''} ${taskType} taskRunnerButton`}
      >
        <InlineSVG type="cycle" elementClass="running" />
        Importing...
      </button>,
    },
    authenticated: {
      start:  <button
        title={titleMap[taskType]}
        className={`${demoMode ? 'disabled' : ''} ${buttonState} ${taskType} taskRunnerButton`}
        disabled={demoMode}
        onClick={ () => setShowAdditional( true ) }
      >
        <InlineSVG type="play" elementClass="start"/>
        <InlineSVG type="queue" elementClass="queued"/>
        <span className="queuedLabel">Queued</span>
        <span className="taskStartLabel">{ labelMap[taskType] }</span>
      </button>,
      running:  <button
        onClick={ () => stopTask() }
        title="Stop Task"
        className={`${demoMode ? 'disabled' : ''} ${buttonState} ${taskType} taskRunnerButton isStoppable`}
        disabled={demoMode}
      >
        <InlineSVG type="stop" elementClass="stop" />
        <InlineSVG type="cycle" elementClass="running" />
        Scanning...
      </button>,
    },
  };

  // 1) onload grab the latest tasks from the context
  React.useEffect( () => {
    if (
      isNotEmpty( currentTasks )
      && isNotEmpty( record )
      && isNotEmpty( taskType )
      && isNotEmpty( currentTasks[taskType] )
    ) {
      // set if this task is working
      if ( currentTasks[taskType].work_items.includes( record.id ) ) {
        setIsWorking( true );
      } else {
        setIsWorking( false );
      }

      // set if this task is working
      if ( currentTasks[taskType].work_items.includes( record.id ) && currentTasks[taskType].status === 'stopping' ) {
        setIsStopping( true );
      } else {
        setIsStopping( false );
      }


      setStatus( currentTasks[taskType].status );

      const _warnings = currentTasks[taskType].warnings || [];

      if ( isNotEmpty( currentTasks[taskType].exception ) ) {
        _warnings.push( currentTasks[taskType].exception );
      }

      setWarnings( _warnings );
    }
  }, [ currentTasks, record, taskType ] );

  // 2) the main engine that sets all the necessary state for the buttons etc.
  React.useEffect( () => {
    if ( isNotEmpty( status ) ) {
      if ( status === 'running' ) {
        if ( isWorking ) {
          setButtonState( 'running' );
        } else {
          setButtonState( '' );
        }
      }

      if ( status === 'completed' ) {
        setButtonState( '' );
      }

      if ( status === 'manually stopped' ) {
        setButtonState( '' );
      }

      if ( status === 'queued' && isWorking ) {
        setButtonState( 'queued' );
      }

      if ( status === 'stopping' && isStopping ) {
        setButtonState( 'stopping' );
      }
      if ( status === 'error' ) {
        setButtonState( '' );
      }
    }
  }, [ currentTasks, warnings, isWorking, isStopping, status ] );

  // if there are any warnings, also call the callback to the parent (if there is a callback)
  // currently only used for vuln scanners DMC 2022-07-21
  React.useEffect( ( ) => {
    if ( isNotEmpty( warnings ) ) {
      const _notifications = warnings.map( message => ( { type: 'alert', message } ) );
      notificationsCallback( _notifications );
    } else {
      notificationsCallback();
    }
  }, [ warnings ] );

  React.useEffect( ( ) => {
    document.addEventListener( 'keydown', escFunction );

    return () => {
      document.removeEventListener( 'keydown', escFunction );
    };
  }, [] );

  const escFunction = React.useCallback( ( e ) => {
    // esc key
    if ( e.keyCode === 27 ) {
      setShowAdditional( false );
    }
  }, [] );

  // position the additionalOptions when they need to be shown so that they are on the screen
  React.useEffect( () => {
    if ( showAdditional && isNotEmpty( additionalOptionsRef ) && isNotEmpty( additionalOptionsRef.current ) ) {
      const offset = getDimensionsAndOffset( additionalOptionsRef.current );
      const padding = 16;

      if ( ( offset.top + offset.height + padding ) > window.innerHeight ) {
        setAdditionalOptionsStyle( {
          top: 48 - 48 - 8 - offset.height,
          right: '0em',
        } );
      } else {
        setAdditionalOptionsStyle( {
          top: '3em',
          right: '0em',
        } );
      }
    } else {
      setAdditionalOptionsStyle( {
        top: '3em',
        right: '0em',
      } );
    }
  }, [ showAdditional, additionalOptionsRef ] );

  const fetchTaskStatus = () => {
    makeRequest( 'STATUS', '/task', { project: 'default' } ).then( response => {
      setCurrentTasks( response.results.current );
    } );
  };

  const handleAdditionalClick = () => {
    if ( buttonState !== 'disabled' ) {
      startTask();
      setShowAdditional( false );
    }
  };

  const startTask = () => {
    if ( buttonState !== 'disabled' ) {
      const params = {
        project: 'default',
        // eslint-disable-next-line camelcase
        work_items: [ record.id ],
        task: taskType,
        // eslint-disable-next-line camelcase
        run_next_task: runNextTask,
      };

      if ( taskType === 'authenticated' ) {
        // eslint-disable-next-line camelcase
        params.force_rescan = forceRescan;
      }

      makeRequest( 'START', '/task', params ).then( response => {
        if ( response && response.results ) {
          fetchTaskStatus();
        }
      } );
    }
  };

  const stopTask = async () => {
    const params = {
      project: 'default',
      task: taskType,
    };
    if ( confirm( 'Are you sure you want to stop this task?' ) ) {
      await makeRequest( 'STOP', '/task', params );

      fetchTaskStatus();
    }
  };

  return (
    <React.Fragment>
      {
        buttonState === 'running'
          ? <React.Fragment>
            { buttonMap[taskType].running }
          </React.Fragment>
          : <div className="startButtonWrapper">
            {
              showAdditional &&
              <React.Fragment>
                <div className="additionalShade" onClick={ () => setShowAdditional( false ) } />
                <div className="additionalContainer" ref={additionalOptionsRef} style={ additionalOptionsStyle }>
                  <h4>
                    Additional Options
                    <button
                      onClick={ () => setShowAdditional( false ) }
                      className="roundGlyphButton"
                    >
                      <InlineSVG type="close" />
                    </button>
                  </h4>
                  {
                    taskType === 'authenticated' &&
                    <label>
                      {
                        <div className={
                          `checkboxFieldWrapper ${forceRescan ? 'checked' : ''}`
                        }>
                          <input
                            type="checkbox"
                            onChange={ () => setForceRescan( !forceRescan ) }
                            checked={ forceRescan }
                          />
                        </div>
                      }
                      <span className="labelWrapper">Force re-scan of recently scanned hosts?</span>
                    </label>
                  }
                  <label>
                    {
                      <div className={
                        `checkboxFieldWrapper ${runNextTask ? 'checked' : ''}`
                      }>
                        <input
                          type="checkbox"
                          onChange={ () => setRunNextTask( !runNextTask ) }
                          checked={ runNextTask }
                        />
                      </div>
                    }
                    <span className="labelWrapper">Run next task in sequence when finished?</span>
                  </label>
                  <div className="actionsContainer">
                    <button
                      onClick={ () => setShowAdditional( false ) }
                      className="additionalCancelButton"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={ handleAdditionalClick }
                      className="additionalButton"
                    >
                      { taskType === 'import' ? 'Import' : 'Scan Now' }
                    </button>
                  </div>

                </div>
              </React.Fragment>

            }
            { buttonMap[taskType].start }
          </div>
      }

    </React.Fragment>
  );
};

export default TaskRunner;