/** *************************************************************
* Copyright (C) 2016-2022 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { HelpContext } from '../../Contexts/Help';
import InlineSVG from '../../shared/InlineSVG';
import Loading from '../../shared/Loading';
import {
  decodeURLHash,
  isNotEmpty,
  triggerHashRefresh,
  encodeURLHash,
  removeFromURLHash,
  isEmpty,
  capitalize,
  isSafari,
  isFirefox,
} from '../../shared/Utilities';
import MainGuideContent from './MainGuideContent';
import PrintButton from './PrintButton';
import { helpContentMap } from './Shared';

import './style.scss';
import Dialog from '../../shared/Dialog';

const HelpDocumentation = ( ) => {
  /* eslint-disable camelcase */

  const [ selectedParams, setSelectedParams ] = React.useState( null );
  const [ helpContent, setHelpContent ] = React.useState( { user_guide: null, reference_guide: null } );
  const [ toggledSections, setToggledSections ] = React.useState( {} );
  const [ loading, setLoading ] = React.useState( false );
  const [ printSelection, setPrintSelection ] = React.useState( { mainKey: null, sectionKeys: [], pageKeys: [] } );
  const [ showBrowserDialog, setShowBrowserDialog ] = React.useState( false );

  const [ , , , , , , , , loadHelpDocumentation, , ] = React.useContext( HelpContext );

  let previousScrollPosition = 0;
  let shouldScroll = true;

  const deepLinkRef = React.useRef( null );

  // helper toggle for contextual items collapse
  const toggleItemsFor = ( section, page ) => {
    if ( itemsAreCollapsed( section, page ) ) {
      // eslint-disable-next-line max-len
      if ( isEmpty( toggledSections[section] ) ) {
        setToggledSections( {
          ...toggledSections,
          [section]: [
            page,
          ],
        } );
      } else {
        setToggledSections( {
          ...toggledSections,
          [section]: [
            ...toggledSections[section],
            page,
          ],
        } );
      }

    } else {
      const _toggled = { ...toggledSections };
      _toggled[section] = _toggled[section].filter( i => i !== page );
      setToggledSections( _toggled );
    }
  };

  // helper function to know which help items are collapsed and which are not
  const itemsAreCollapsed = ( section, page ) => {
    if ( isEmpty( toggledSections ) ) {
      return true;
    }
    if ( isEmpty( toggledSections[section] ) ) {
      return true;
    }
    return !toggledSections[section].includes( page );
  };

  // triggered whenever the params change, the help section will scroll to the correct spot after a user is deep-linked
  // to a help section from the application, or when a user clicks on a menu item
  const scrollToHelpSection = () => {
    let domElement;

    // don't want to trigger the scroll event listener
    shouldScroll = false;

    const scrollingContainer = document.getElementById( 'helpDocumentationWrapper' );

    const params = decodeURLHash();

    if ( isNotEmpty( params.main ) ) {
      if ( isNotEmpty( params.section ) ) {
        if ( isNotEmpty( params.help_page ) ) {
          if ( isNotEmpty( params.topic ) ) {
            // eslint-disable-next-line max-len
            domElement = document.getElementById( `${params.main}-${params.section}-${params.help_page}-contextualHelpItem-${params.topic}` );
          } else {
            domElement = document.getElementById( `${params.main}-${params.section}-${params.help_page}` );
          }
        } else {
          domElement = document.getElementById( `${params.main}-${params.section}` );
        }
      } else {
        domElement = document.getElementById( `mainHelpSection-${params.main}` );
      }

      if ( isNotEmpty( domElement && isNotEmpty( scrollingContainer ) ) ) {
        const top = domElement.offsetTop;
        scrollingContainer.scrollTop = top;
      }
    }
  };

  // the opposite (in some ways) to the scrolltoHelp above. This fires on scroll of the main help container
  // and selects the currently viewed help section in the menu as you scroll through it
  const selectOnScroll = () => {
    const scrollInterval = 500;
    const scrollingContainer = document.getElementById( 'helpDocumentationWrapper' );
    const scrollPosition = scrollingContainer.scrollTop;
    const sectionElements = [ ...document.getElementsByClassName( 'helpSectionWrapper' ) ];

    let currentSection, currentPage;

    if ( shouldScroll ) {
      // throttling
      if ( Math.abs( previousScrollPosition - scrollPosition ) > scrollInterval ) {
        for ( let index = 0; index < sectionElements.length; index++ ) {
          const element = sectionElements[index];

          if ( element.offsetTop < scrollPosition ) {
            currentSection = element;
          } else {
            break;
          }
        }

        if ( isNotEmpty( currentSection ) ) {
          const pageElements = [ ...currentSection.childNodes ];
          for ( let index = 0; index < pageElements.length; index++ ) {
            const element = pageElements[index];

            if ( element.offsetTop < scrollPosition ) {
              currentPage = element;
            } else {
              break;
            }
          }
        }

        if ( isNotEmpty( currentPage ) ) {
          // not a typical page, need to set the section as the current
          if ( currentPage.classList.contains( 'mainSectionContent' ) ) {
            const [ main, section ] = currentSection.id.split( '-' );
            // console.log( mainKey, sectionKey, pageKey );

            if ( isNotEmpty( main ) && isNotEmpty( section )  ) {
              encodeURLHash( {
                main,
                section,
                help_page: null,
              } );
              parseAndSetSelectedParams();
            }
          } else {
            const [ main, section, help_page ] = currentPage.id.split( '-' );
            if ( isNotEmpty( main ) && isNotEmpty( section ) && isNotEmpty( help_page ) ) {
              encodeURLHash( {
                main,
                section,
                help_page,
              } );
              parseAndSetSelectedParams();
            }
          }
        }
        previousScrollPosition = scrollPosition;
      }
    } else {
      shouldScroll = true;
    }

  };

  const parseAndSetSelectedParams = () => {
    const helpParams = [
      'main',
      'section',
      'help_page',
    ];

    const hash = decodeURLHash();

    const selected = {};

    helpParams.map( key => {
      if ( isNotEmpty( hash[key] ) ) {
        selected[key] = hash[key];
      }
    } );

    setSelectedParams( selected );
  };

  // wires up hashchange listener, does not fire until the page is done loading
  // also wires up scroll listener that highlights the current section in the menu
  // as you scroll through the help
  React.useEffect( ( ) => {

    // on page load, triggered once, need to wait for the ref to be there before attempting to scroll
    if ( isNotEmpty( helpContent ) && finishedLoading() ) {

      const scrollingContainer = document.getElementById( 'helpDocumentationWrapper' );

      setLoading( true );
      parseAndSetSelectedParams();

      if ( isNotEmpty( deepLinkRef ) ) {

        // some of the content takes a while to load... setting a timeout to account for that until I can come
        // up with a better system
        window.setTimeout( () => {
          scrollToHelpSection();
          setLoading( false );
        }, 500 );
      } else {
        setLoading( false );
      }
      scrollingContainer.addEventListener( 'scroll', selectOnScroll );
      window.addEventListener( 'hashchange', scrollToHelpSection );
      return () => {
        scrollingContainer.removeEventListener( 'scroll', selectOnScroll );
        window.removeEventListener( 'hashchange', scrollToHelpSection );
      };
    }
  }, [ helpContent, deepLinkRef ] );

  // async function that handles all of the actual loading up of the md content.
  const loadHelpContent = async ( ) => {
    const user_guide = await ( loadHelpDocumentation( 'user_guide' ) );
    const reference_guide = await ( loadHelpDocumentation( 'reference_guide' ) ) ;
    const security_guide = await ( loadHelpDocumentation( 'security_guide' ) ) ;

    if ( isNotEmpty( user_guide ) && isNotEmpty( reference_guide ) && isNotEmpty( security_guide ) ) {
      setHelpContent( {
        user_guide,
        reference_guide,
        security_guide,
      } );
    }
  };

  const handleBeforePrint = () => {
    if ( isSafari || isFirefox ) {
      setShowBrowserDialog( true );
    } else if (
      isNotEmpty( printSelection )
      && isEmpty( printSelection.mainKey )
      && isEmpty( printSelection.sectionKeys )
      && isEmpty( printSelection.pageKeys )
    ) {

      const guideWrappers = [ ...document.getElementsByClassName( 'helpMainWrapper' ) ];

      guideWrappers.map( guideWrapper => {
        // adds printing class to the parents
        guideWrapper.classList.add( 'forPrinting' );

        [ ...guideWrapper.childNodes ].map( sectionWrapper => {
          // adds printing class to the sections
          sectionWrapper.classList.add( 'forPrinting' );

          [ ...sectionWrapper.childNodes ].map( pageWrapper => {
            // adds printing class to the pages
            pageWrapper.classList.add( 'forPrinting' );
          } );
        } );
      } );
    }
  };

  const handleAfterPrint = () => {
    setPrintSelection( { mainKey: null, sectionKeys: [], pageKeys: [] } );

    const guideWrappers = [ ...document.getElementsByClassName( 'helpMainWrapper' ) ];

    guideWrappers.map( guideWrapper => {
      // removes printing class from the parents
      guideWrapper.classList.remove( 'forPrinting' );

      [ ...guideWrapper.childNodes ].map( sectionWrapper => {
        // removes printing class from the sections
        sectionWrapper.classList.remove( 'forPrinting' );

        [ ...sectionWrapper.childNodes ].map( pageWrapper => {
          // removes printing class from the pages
          pageWrapper.classList.remove( 'forPrinting' );
        } );
      } );
    } );
  };

  const getPrintSectionsList = () => {
    const includedPrintContent = [];
    // Are we printing the whole thing?
    if (
      isNotEmpty( printSelection )
      && isEmpty( printSelection.mainKey )
      && isEmpty( printSelection.sectionKeys )
      && isEmpty( printSelection.pageKeys )
    ) {
      Object.entries( helpContentMap ).map( ( [ mainGuideKey, mainGuideContent ] ) => {
        const mainHeader = <h2>{ capitalize( mainGuideKey ) }</h2>;
        includedPrintContent.push( mainHeader );
        Object.values( mainGuideContent ).map( sectionContent => {
          const sectionHeader = <h3>{ sectionContent.label }</h3>;
          includedPrintContent.push( sectionHeader );
          Object.values( sectionContent.pages ).map( page => {
            let pageHeader;
            if ( mainGuideKey === 'reference_guide' || mainGuideKey === 'security_guide' ) {
              pageHeader = <h4>{ page }</h4>;
            } else {
              pageHeader = <h4>{ page.label }</h4>;
            }
            includedPrintContent.push( pageHeader );
          } );
        } );
      } );

    // we are only printing a specific guide/section/page
    } else {
      const mainHeader = <h2>{ capitalize( printSelection.mainKey ) }</h2>;
      includedPrintContent.push( mainHeader );
      if ( isNotEmpty( printSelection.sectionKeys ) ) {
        // we are printing multiple sections, need to map over them all and include all their pages,
        // in other words, we are printing an entire guide
        if ( printSelection.sectionKeys.length > 1 ) {
          printSelection.sectionKeys.map( sectionKey => {
            const sectionContent = helpContentMap[printSelection.mainKey][sectionKey];
            const sectionHeader = <h3>{ sectionContent.label }</h3>;
            includedPrintContent.push( sectionHeader );
            Object.values( sectionContent.pages ).map( page => {
              let pageHeader;
              if ( printSelection.mainKey === 'reference_guide' || printSelection.mainKey === 'security_guide' ) {
                pageHeader = <h4>{ page }</h4>;
              } else {
                pageHeader = <h4>{ page.label }</h4>;
              }
              includedPrintContent.push( pageHeader );
            } );
          } );
        // just printing one section, need to find out if is a whole section or just one page
        // printing all pages of a section, ie the whole section
        } else if ( printSelection.pageKeys?.length > 1 ) {
          const sectionContent = helpContentMap[printSelection.mainKey][printSelection.sectionKeys[0]];
          const sectionHeader = <h3>{ sectionContent.label }</h3>;
          includedPrintContent.push( sectionHeader );
          Object.values( sectionContent.pages ).map( page => {
            let pageHeader;
            if ( printSelection.mainKey === 'reference_guide' || printSelection.mainKey === 'security_guide' ) {
              pageHeader = <h4>{ page }</h4>;
            } else {
              pageHeader = <h4>{ page.label }</h4>;
            }
            includedPrintContent.push( pageHeader );
          } );
        // printing a single page
        } else if ( printSelection.pageKeys?.length === 1 ) {
          const sectionContent = helpContentMap[printSelection.mainKey][printSelection.sectionKeys[0]];
          const sectionHeader = <h3>{ sectionContent.label }</h3>;
          includedPrintContent.push( sectionHeader );
          // eslint-disable-next-line max-len
          const page = helpContentMap[printSelection.mainKey][printSelection.sectionKeys[0]].pages[printSelection.pageKeys[0]];
          let pageHeader;
          if ( printSelection.mainKey === 'reference_guide' || printSelection.mainKey === 'security_guide' ) {
            pageHeader = <h4>{ page }</h4>;
          } else {
            pageHeader = <h4>{ page.label }</h4>;
          }
          includedPrintContent.push( pageHeader );
        }
      }
    }
    return includedPrintContent;
  };

  // 1) on page load, loads all the markdown content
  React.useEffect( ( ) => {
    loadHelpContent();
  }, [] );

  // sets up event listeners for before and after print
  // before is important because it takes care of the situation where a user does not click one of the print
  // buttons, but instead uses the default browser print and actually intends to print the entire page
  React.useEffect( ( ) => {
    window.addEventListener( 'beforeprint', handleBeforePrint );
    window.addEventListener( 'afterprint', handleAfterPrint );
    return () => {
      window.removeEventListener( 'beforeprint', handleBeforePrint );
      window.removeEventListener( 'afterprint', handleAfterPrint );
    };
  }, [ printSelection ] );

  const finishedLoading = () => isNotEmpty( helpContent.user_guide )
    && isNotEmpty( helpContent.reference_guide )
    && !loading;

  // main function for toggling the params and currently selected help section from the helpMenu
  const goToHelpSection = (
    event,
    main,
    section=null,
    page=null,
    topic=null,
    shouldScroll=true,
  ) => {
    if ( event ) {
      event.stopPropagation();
    }

    const oldParams = decodeURLHash();

    removeFromURLHash( 'main' );
    removeFromURLHash( 'section' );
    removeFromURLHash( 'help_page' );
    removeFromURLHash( 'topic' );

    let newParams;

    // not actually wired up yet, but if there are contextual help items ever in the menu, this would be triggered
    // when the user clicks on one of those ( ie Risk Insight -> Hosts -> Keywords )
    if ( isNotEmpty( topic ) ) {
      console.log( 'placeholder functionality' );
    // the user has clicked on one of the subSection links in the menu ( ie Risk Insight -> Hosts )
    } else if ( isNotEmpty( page ) ) {
      newParams = { ...oldParams, main: main || oldParams.main || 'user_guide', help_page: page };
    // the user has clicked on one of the section links in the menu ( ie Risk Insight )
    } else if ( isNotEmpty( section ) ) {
      newParams = { main: main || oldParams.main || 'user_guide', section };
    // the user has clicked on one of the main links in the menu ( ie User Guide )
    } else if ( isNotEmpty( main ) ) {
      newParams = { main };
    // clear out everything
    } else {
      newParams = {};
    }
    encodeURLHash( newParams );
    parseAndSetSelectedParams();
    if ( shouldScroll ) {
      triggerHashRefresh();
    }
  };

  // helper function to know when to style an item in the help menu as selected or not
  const shouldSetSelected = keys => {
    if ( isNotEmpty( keys ) && isNotEmpty( selectedParams ) ) {
      // there is a main slug ( for now userGuide or installationGuide )
      if ( isNotEmpty( keys.mainKey ) ) {
        // there is a section slug ( the main sections of the app ie left nav )
        if ( isNotEmpty( keys.sectionKey ) ) {
          // there is a subSection slug, one of the pages within a section ( ie Risk Insight > Hosts )
          if ( isNotEmpty( keys.pageKey ) ) {
            // there is a contextual help item for a page within a section
            // ( ie Risk Insight > Hosts > Sensitive Assets help item)
            if ( isNotEmpty( keys.topicKey ) ) {
              return selectedParams.main === keys.mainKey
                && selectedParams.section === keys.sectionKey
                && selectedParams.help_page === keys.pageKey
                && selectedParams.topic === keys.topicKey;
            }
            return selectedParams.main === keys.mainKey
              && selectedParams.section === keys.sectionKey
              && selectedParams.help_page === keys.pageKey;
          }
          return selectedParams.main === keys.mainKey
            && selectedParams.section === keys.sectionKey;
        }
        return selectedParams.main === keys.mainKey;
      }
    }
  };
  /* eslint-enable camelcase */

  const getPrintLabel = ( mainKey, sectionKey, pageKey ) => {
    let sectionLabel, pageLabel;

    if ( isNotEmpty( sectionKey ) ) {
      sectionLabel = helpContentMap[mainKey][sectionKey].label;

      if ( isNotEmpty( pageKey ) ) {
        pageLabel = helpContentMap[mainKey][sectionKey].pages[pageKey].label;
      }
      return `${sectionLabel} ${ isNotEmpty( pageLabel ) ? `> ${pageLabel}`: ''}`;
    } else if ( isNotEmpty( mainKey ) ) {
      return capitalize( mainKey );
    }
    return '';
  };

  return (
    <div className="helpDocumentationContainer">
      { !finishedLoading() && <Loading /> }
      <Dialog
        visible={ showBrowserDialog }
        setVisible={ setShowBrowserDialog }
        // eslint-disable-next-line max-len
        content="Safari and Firefox have known issues dynamically printing lots of pages. Please use Chrome or Chromium Edge to print the documentation"
        action={ () => setShowBrowserDialog( false ) }
        noCancel
      />
      <div className="helpDocumentationWrapper" id="helpDocumentationWrapper" >
        <div className="printHeader">
          <InlineSVG type="primaryLogoBug" version="bug" size="logoBug" />
          <InlineSVG elementClass="logoText" type="primaryLogoText" version="text" size="logoText" />

          <h1>Included Documentation</h1>
          <div className="includedPrintSections">
            {
              getPrintSectionsList().map( ( section, index ) => {
                return <React.Fragment key={index}>
                  { section }
                </React.Fragment>;
              } )
            }
          </div>
          <div id="copyright">
            <span>Patent Pending</span>
            <span>Confidential and Proprietary</span>
            <span>Copyright &copy; 2023 DeepSurface Security, Inc. All Rights Reserved.</span>
            <span>DeepSurface is a registered trademark of DeepSurface Security, Inc.</span>
          </div>
        </div>
        {
          isNotEmpty( helpContent?.user_guide ) &&
          <MainGuideContent
            mainKey="user_guide"
            sections={ helpContent.user_guide }
            itemsAreCollapsed={ itemsAreCollapsed }
            toggleItemsFor={ toggleItemsFor }
            deepLinkRef={ deepLinkRef }
            selectedParams={ selectedParams }
            printSelection={printSelection}
            setPrintSelection={setPrintSelection}
            getPrintLabel={getPrintLabel}
          />
        }
        {
          isNotEmpty( helpContent?.reference_guide ) &&
          <MainGuideContent
            mainKey="reference_guide"
            sections={ helpContent.reference_guide }
            itemsAreCollapsed={ itemsAreCollapsed }
            toggleItemsFor={ toggleItemsFor }
            deepLinkRef={ deepLinkRef }
            selectedParams={ selectedParams }
            printSelection={printSelection}
            setPrintSelection={setPrintSelection}
            getPrintLabel={getPrintLabel}
          />
        }
        {
          isNotEmpty( helpContent?.security_guide ) &&
          <MainGuideContent
            mainKey="security_guide"
            sections={ helpContent.security_guide }
            itemsAreCollapsed={ itemsAreCollapsed }
            toggleItemsFor={ toggleItemsFor }
            deepLinkRef={ deepLinkRef }
            selectedParams={ selectedParams }
            printSelection={printSelection}
            setPrintSelection={setPrintSelection}
            getPrintLabel={getPrintLabel}
          />
        }
      </div>
      <div className="helpMenu" id="helpMenu" >
        {
          Object.keys( helpContentMap ).map( ( mainKey, mainIndex ) => {

            const sectionKeys = Object.keys( helpContentMap[mainKey] );

            // either user guide or reference guide
            return <div
              className={ `helpMenuMain ${shouldSetSelected( { mainKey } ) ? 'isSelected' : '' }` }
              key={mainIndex}
            >
              <h3
                onClick={ e => goToHelpSection( e, mainKey ) }
                className="mainLabel"
              >
                <PrintButton
                  keys={ { mainKey } }
                  setPrintSelection={setPrintSelection}
                  printLabel={ getPrintLabel( mainKey )}
                />
                { capitalize( mainKey ) }
              </h3>
              {
                sectionKeys.map( ( sectionKey, sectionIndex ) => {

                  const sectionData = helpContentMap[mainKey][sectionKey];

                  if ( isNotEmpty( sectionData.pages ) ) {
                    // the main section under the guide, ie Reporting, Risk Insight, etc...
                    return <div
                      // eslint-disable-next-line max-len
                      className={ `${shouldSetSelected( { mainKey, sectionKey } ) ? 'isSelected' : '' } helpMenuSection` }
                      key={ sectionIndex }
                    >
                      <div
                        onClick={ e => goToHelpSection( e, mainKey, sectionKey ) }
                        className="label section"
                      >
                        { sectionData.label }
                        {
                          shouldSetSelected( { mainKey, sectionKey } ) &&
                          <PrintButton
                            keys={ { sectionKey, mainKey } }
                            setPrintSelection={setPrintSelection}
                            printLabel={ getPrintLabel( sectionKey )}
                          />
                        }
                      </div>
                      {
                        Object.entries( sectionData.pages ).map( ( [ pageKey, pageData ], pageIndex ) => {
                          // the page under each section, ie Dashboard, Exporting, etc...
                          return <div
                            key={pageIndex}
                            // eslint-disable-next-line max-len
                            onClick={ e => goToHelpSection( e, mainKey, sectionKey, pageKey ) }
                            // eslint-disable-next-line max-len
                            className={ `${shouldSetSelected( { mainKey, sectionKey, pageKey } ) ? 'isSelected' : '' } label subSection` }
                          >
                            {
                              isNotEmpty( pageData.label )
                                ? pageData.label
                                : pageData
                            }
                          </div>;
                        } )
                      }
                    </div>;
                  }
                } )
              }
            </div>;
          } )
        }
      </div>
    </div>
  );
};

export default HelpDocumentation;