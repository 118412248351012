/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import './WeeklySchedule.scss';

import {
  isEmpty,
  isNotEmpty,
} from '../../../Utilities';

const WeeklySchedule = ( {
  field,
  formState,
  originalValue,
  onChange,
  existingRecord,
} ) => {

  const [ startTime, setStartTime ] = React.useState( '' );
  const [ endTime, setEndTime ] = React.useState( '' );
  const [ selectedDays, setSelectedDays ] = React.useState( [] );
  // const [ fieldErrors, setFieldErrors ] = React.useState( [] );

  const DAYS = {
    'mon': 'Monday',
    'tue': 'Tuesday',
    'wed': 'Wednesday',
    'thu': 'Thursday',
    'fri': 'Friday',
    'sat': 'Saturday',
    'sun': 'Sunday',
  };

  const isDisabled = () => field.disabled || formState.fieldStates[field.attribute].disabled;

  // called whenever any of the subvalues change
  const buildSchedule = () => {

    const blackouts = [];

    selectedDays.map( day => {
      blackouts.push( {
        day: day,
        // eslint-disable-next-line camelcase
        start_time: startTime,
        // eslint-disable-next-line camelcase
        end_time: endTime,
      } );
    } );

    const schedule = {
      // eslint-disable-next-line camelcase
      start_time: startTime,
      // eslint-disable-next-line camelcase
      end_time: endTime,
      blackouts: blackouts,
    };
    return schedule;
  };

  // if there is an existing schedule
  const parseAndSetSubValues = schedule => {

    setStartTime( schedule[0].start_time );
    setEndTime( schedule[0].end_time );

    const days = [];
    schedule.map( d => {
      days.push( d.day );
    } );
    setSelectedDays( days );
  };

  // setup existing values on init
  React.useEffect( () => {
    if ( isNotEmpty( originalValue ) ) {
      parseAndSetSubValues( originalValue );
    }
  }, [ existingRecord, field, originalValue ] );

  const handleSubValueChange = ( subField, value ) => {
    if ( subField === 'start_time' ) {
      setStartTime( value );
    }
    if ( subField === 'end_time' ) {
      setEndTime( value );
    }
    if ( subField === 'days' ) {
      // need to add the day

      if ( selectedDays.includes( value ) ) {
        setSelectedDays( selectedDays.filter( d => d !== value ) );
      } else {
        setSelectedDays( [
          ...selectedDays,
          value,
        ] );
      }
    }
  };

  React.useEffect( () => {
    const errors = [];
    if ( isEmpty( startTime ) ) {
      errors.push( 'Start time cannot be blank' );
    }
    if ( isEmpty( endTime ) ) {
      errors.push( 'End time cannot be blank' );
    }
    if ( isEmpty( selectedDays ) ) {
      errors.push( 'Must select at least one day' );
    }

    // setFieldErrors( errors );
    onChange( field, buildSchedule() );
  }, [ startTime, endTime, selectedDays ] );

  return (
    <React.Fragment>
      <div className="fieldsWrapper">
        <div className="timePickerWrapper">
          <label>
            <span className="labelWrapper">Start time</span>
            <input
              disabled={ isDisabled() }
              type="time"
              onChange={ e => handleSubValueChange( 'start_time', e.target.value ) }
              value={ startTime }
            />
          </label>
          <label>
            <span className="labelWrapper">End time</span>
            <input
              disabled={ isDisabled() }
              type="time"
              onChange={ e => handleSubValueChange( 'end_time', e.target.value ) }
              value={ endTime }
            />
          </label>
        </div>
        <div className="daysWrapper">
          {
            Object.keys( DAYS ).map( ( day, index ) => {
              return  <label
                key={index}
              >
                {/* eslint-disable-next-line max-len */}
                <div className={`checkboxFieldWrapper ${selectedDays.includes( day ) ? 'checked' : ''} ${ isDisabled()  ? 'disabled' : ''}`} >
                  <input
                    disabled={ isDisabled() }
                    type="checkbox"
                    checked={ selectedDays.includes( day ) }
                    onChange={ () => handleSubValueChange( 'days', day ) }
                  />
                </div>
                <span className="labelWrapper">{DAYS[day]}</span>
              </label>;
            } )
          }
        </div>
      </div>
      {/* {
        ( isNotEmpty( fieldErrors ) && !isDisabled() ) &&
        <ul className="fieldErrors">
          {
            fieldErrors.map( ( error, index ) => {
              return  <li key={index}>
                { error }
              </li>;
            } )
          }
        </ul>

      } */}
    </React.Fragment>
  );
};

export default WeeklySchedule;
