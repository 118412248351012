/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import './style.scss';

import { isValidPort, isEmailAddress } from '../../../../shared/Form/Validators';
import SetupForm from '../../../../shared/SetupComponents/SetupForm';
import Notification from '../../../../shared/Notification';
import Modal from '../../../../shared/Modal';
import { HelpTrigger } from '../../../HelpDocumentation/ContextualHelp/index.js';
import { getFieldValues } from '../../../../shared/Form/Shared';
import Form from '../../../../shared/Form';
import {
  decodeURLHash,
  getGlobalSettings,
  isEmpty,
  isNotEmpty,
  isTruthy,
  updateGlobalSettings,
} from '../../../../shared/Utilities';

import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../../../legacy/io';
import PageAlerts from '../../../../shared/PageAlerts';
import InlineSVG from '../../../../shared/InlineSVG';
import { CurrentUserContext } from '../../../../Contexts/CurrentUser.js';

// set up fields
const EMPTY_FIELDS = {
  global: {
    fields: [
      {
        type: 'text',
        label: 'Hostname',
        attribute: 'smtp_hostname',
        defautValue: '',
        help: <HelpTrigger helpKey="smtp_hostname" />,
      },
      {
        type: 'number',
        label: 'Port',
        attribute: 'smtp_port',
        required: true,
        defautValue: 465,
        help: <HelpTrigger helpKey="smtp_port" />,
        validators: [ isValidPort ],
      },
      {
        type: 'text',
        label: 'Sender email address',
        attribute: 'smtp_sender',
        required: true,
        defautValue: '',
        defaultValue: '',
        help: <HelpTrigger helpKey="smtp_sender" />,
        validators: [ isEmailAddress ],
      },
      {
        type: 'checkbox',
        label: 'Trust on First Use (TOFU)',
        attribute: 'smtp_tofu',
        defautValue: true,
        help: <HelpTrigger helpKey="smtp_tofu" />,
      },
      {
        type: 'checkbox',
        label: 'Allow Non-TLS SMTP',
        attribute: 'smtp_allow_insecure',
        defautValue: false,
      },
      {
        type: 'checkbox',
        label: 'Enable Authentication?',
        attribute: 'smtp_enable_authentication',
        defautValue: true,
        help: <HelpTrigger helpKey="smtp_enable_authentication" />,
      },
      {
        type: 'text',
        label: 'Username',
        attribute: 'credential_username',
        defaultValue: '',
        includeIf: { attribute: 'smtp_enable_authentication', value: true },
        requireIf: { attribute: 'smtp_enable_authentication', value: true },
      },
      {
        type: 'password',
        removeGenerate: true,
        label: 'Password',
        attribute: 'credential_secret',
        defaultValue: '',
        includeIf: { attribute: 'smtp_enable_authentication', value: true },
        requireIf: { attribute: 'smtp_enable_authentication', value: true },
      },
      {
        type: 'hidden',
        attribute: 'smtp_credential',
        defaultValue: '',
      },
    ],
  },
};

// the test form in the right column
const TEST_EMPTY_FIELDS = {
  test: {
    header: 'Test Settings',
    fields: [
      {
        type: 'text',
        label: 'Recipient Email Address',
        attribute: 'recipient',
        value: '',
        defaultValue: '',
        required: true,
        validators: [ isEmailAddress ],
        placeholder: 'jsmith@example.com',
        help: <HelpTrigger helpKey="test_smtp_settings" />,
      },
    ],
  },
};

const SmtpSettings = () => {
  // refresh control
  let isMounted = true;

  // contexts
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ , , , demoMode ] = React.useContext( CurrentUserContext );

  // state
  const [ fields, setFields ] = React.useState( null );
  const [ testFields, setTestFields ] = React.useState( null );
  const [ record, setRecord ] = React.useState( null );
  const [ isValid, setIsValid ] = React.useState( true );
  const [ isEmailValid, setIsEmailValid ] = React.useState( false );

  // this is set in the form onChange callback
  const [ updatedForm, setUpdatedForm ] = React.useState( null );
  const [ updatedTestForm, setUpdatedTestForm ] = React.useState( null );

  // for test email
  const [ notificationOptions, setNotificationOptions ] = React.useState( {} );
  const [ attemptingSMTPTest, setAttemptingSMTPTest ] = React.useState( false );

  const [ tlsWarningMessage, setTlsWarningMessage ] = React.useState( null );
  const [ missingAuthentication, setMissingAuthentication ] = React.useState( false );
  const [ showNotificationsModal, setShowNotificationsModal ] = React.useState( null );

  // one time init
  React.useEffect( ( ) => {
    onRefresh( );
    setFields( EMPTY_FIELDS );
    setTestFields( TEST_EMPTY_FIELDS );
  }, [ ] );

  // sets up onleave
  React.useEffect( () => {
    // important functionality that automatically
    // saves the form when navigating away from the page if the form is valid
    const parentEl = document.getElementById( 'pageContent' );
    parentEl.onleave = async () => {
      if ( isValid && isMounted && !demoMode ) {
        onSave( false );
        return true;
      }
    };

    return () => {
      isMounted = false;
      if ( parentEl ) {
        parentEl.onleave = null;
      }
    };
  }, [ isValid, updatedForm ] );

  // the user may have been redirected here from remediation, in that case, send them back there
  const handleRedirects = () => {
    const hash = decodeURLHash();
    if ( isNotEmpty( hash.redirect_url ) ) {
      window.location.href = decodeURIComponent( hash.redirect_url );
    }
  };

  // whenever the updatedForm changes, display a warning if the users chooses to not use TLS
  React.useEffect( ( ) => {
    if ( isNotEmpty( updatedForm ) && isNotEmpty( updatedForm.fieldStates ) ) {
      if ( updatedForm?.fieldStates?.smtp_allow_insecure?.updatedValue === true ) {
        // eslint-disable-next-line max-len
        setTlsWarningMessage( '"Allow Non-TLS SMTP" is set to true. This means that sensitive data will be sent unencrypted. DeepSurface does not recommend using this setting.' );
      } else {
        setTlsWarningMessage( null );
      }
    }
  }, [ updatedForm ] );

  // whenever the updatedForm changes, checking some special validation for sending a test email, need to
  // need to make sure creds are present if authentication is checked
  React.useEffect( ( ) => {
    if ( isNotEmpty( updatedForm ) && isNotEmpty( updatedForm.fieldStates ) ) {
      if ( updatedForm?.fieldStates?.smtp_enable_authentication?.updatedValue === true ) {

        if (
          isNotEmpty( updatedForm?.fieldStates?.credential_username?.updatedValue )
        ) {
          setMissingAuthentication( false );
        } else {
          setMissingAuthentication( true );
        }
      } else {
        setMissingAuthentication( false );
      }
    }
  }, [ updatedForm ] );

  // refreshes the form whenever one of the following happens:
  // 1. initial page load
  // 2. manual revert button is clicked in the footer
  // 3. a settings is changed and saved
  const onRefresh = async( ) => {

    const _global = await getGlobalSettings( 'global' );

    const _record = {
      /* eslint-disable camelcase */
      smtp_hostname: _global?.smtp_hostname || '',
      smtp_port: _global?.smtp_port,
      smtp_sender: _global?.smtp_sender,
      smtp_tofu: isTruthy( _global?.smtp_tofu ),
      smtp_enable_authentication: isTruthy( _global?.smtp_enable_authentication ),
      smtp_allow_insecure:  isTruthy( _global?.smtp_allow_insecure ),
      /* eslint-enable camelcase */
    };

    if ( isNotEmpty( _global.smtp_credential ) ) {
      const thisCred = await makeRequest( 'SEARCH', '/project/default/credential', {
        // eslint-disable-next-line camelcase
        extra_columns: [ 'username' ],
        // eslint-disable-next-line camelcase
        id_list: [ _global.smtp_credential ],
      } );

      if ( isNotEmpty( thisCred ) && isNotEmpty( thisCred.results ) ) {
        // eslint-disable-next-line camelcase
        _record.smtp_credential = thisCred.results[0].id;
        // eslint-disable-next-line camelcase
        _record.credential_username = thisCred.results[0].username;
      }
    }

    setRecord( _record );
  };

  // called when clicking save in the footer, or on page leave if the form is valid
  const onSave = async ( shouldRefresh=true ) => {

    const CREDENTIAL_ATTRS = [
      'credential_username',
      'credential_secret',
    ];

    // checks for validity and makes sure we have the latest values
    if ( isValid && isNotEmpty( updatedForm ) && isNotEmpty( updatedForm.fieldStates ) ) {
      const _values = getFieldValues( updatedForm.fieldStates, 'smtp_settings' );

      let updatedCredential;
      const _global = {};

      // clear out credential information if the user is not enabling authentication
      if ( _values.smtp_enable_authentication === false ) {
        if ( isNotEmpty( _values.smtp_credential ) ) {
          await makeRequest( 'DELETE', '/project/default/credential',  { id: _values.smtp_credential } );
        }
      } else {
        const credential = {
          username: _values.credential_username,
          secret: _values.credential_secret,
        };

        // update existing credential, rather than creating a new one.
        if ( isNotEmpty( _values.smtp_credential ) ) {
          credential.id = _values.smtp_credential;
          // we are updating, don't want to accidentally remove the secret.
          if ( isEmpty( _values.credential_secret ) ) {
            delete credential.secret;
          }
        }

        // eslint-disable-next-line max-len
        updatedCredential = await makeRequest( 'UPSERT', '/project/default/credential', { 'records': [ credential ] } );
      }

      // put the attrs and values into the correct spot
      Object.entries( _values ).map( ( [ attr, val ] ) => {
        // don't save the credential attrs to the global settings
        if ( !CREDENTIAL_ATTRS.includes( attr ) ) {
          if ( attr === 'smtp_credential' && isNotEmpty( updatedCredential ) ) {
            _global[attr] = updatedCredential?.results[0].id;
          } else {
            _global[attr] = val;
          }
        }
      } );

      // need to clear out any credential related info if we are no longer using a credential
      if ( _values.smtp_enable_authentication === false ) {
        // eslint-disable-next-line camelcase
        _global.smtp_credential = null;
      }

      const updatedGlobal = await updateGlobalSettings( 'global', _global );

      // success
      if ( isNotEmpty( updatedGlobal.results ) ) {
        const global = updatedGlobal.results;

        // only refresh if needed, ie, not when navigating away
        if (
          shouldRefresh
          && isMounted
        ) {
          // need to pass these through becuase the update of one was causing the other to get
          // wiped out, not ideal, need to think of a better approach at some point -DMC 2022-04-06
          onRefresh( global );
          addFlashMessage( {
            type: 'success',
            body: 'Successfully updated settings.',
          } );
          handleRedirects();
        }
      // formatted error
      } else if ( isNotEmpty( updatedGlobal.errors ) ) {
        const _errors = [];
        updatedGlobal?.errors.map( e => {
          _errors.push( e );
        } );
        if ( shouldRefresh && isMounted ) {
          _errors.map( e => {
            addFlashMessage( {
              type: 'alert',
              body: e,
            } );
          } );
        }
      // likely 500
      } else if ( shouldRefresh && isMounted ) {
        addFlashMessage( {
          type: 'alert',
          body: 'There was an error saving settings, please check your connection and try again.',
        } );
      }
    }
  };

  const onEmailTest = async ( ) => {
    if ( isNotEmpty( updatedForm ) && isNotEmpty( updatedTestForm ) ) {
      setAttemptingSMTPTest( true );
      setNotificationOptions( {} );

      const values = getFieldValues( updatedForm.fieldStates, 'smtp_settings' );
      const params = {
        /* eslint-disable camelcase */
        smtp_hostname: values?.smtp_hostname,
        smtp_port: values?.smtp_port,
        source_address: values?.smtp_sender,
        smtp_tofu: values?.smtp_tofu,
        recipient: updatedTestForm?.fieldStates?.recipient?.updatedValue,
        smtp_allow_insecure: values?.smtp_allow_insecure,
        /* eslint-disable camelcase */
      };

      if ( isNotEmpty( values.smtp_enable_authentication ) ) {
        if ( isNotEmpty( values?.credential_username ) && isNotEmpty( values?.credential_secret ) ) {
          params.username = values?.credential_username;
          params.password = values?.credential_secret;
        }
        if ( isNotEmpty( values.smtp_credential ) ) {
          // eslint-disable-next-line camelcase
          params.credential_id = values?.smtp_credential;
        }
      } else {
        params.credential_id = null;
      }

      makeRequest( 'GENERATE', '/test_email', params ).then( response => {
        setAttemptingSMTPTest( false );

        if ( response ) {
          if ( response.errors ) {
            setNotificationOptions( {
              type: 'alert',
              message: <React.Fragment>
                {
                  response.errors.map( ( e, i ) => {
                    return <p key={i}>{e}</p>;
                  } )
                }
              </React.Fragment>,
            } );
          } else if ( response.success ) {
            setNotificationOptions( {
              type: 'success',
              // eslint-disable-next-line max-len
              message: 'Your SMTP settings appear to be correct, please check your inbox to confirm the test email was received.',
            } );
          }
        } else {
          setNotificationOptions( {
            type: 'alert',
            message: 'There was an error with your settings',
          } );
        }
      } );
    }
  };

  return (
    <SetupForm elementClass="smtpContainer">
      {
        isNotEmpty( tlsWarningMessage ) &&
        <React.Fragment>
          <Modal
            visible={ showNotificationsModal }
            setVisible={ setShowNotificationsModal }
            elementClass="setupNotificationsModal"
            body={
              <Notification options={ { type: 'alert', message: tlsWarningMessage } } />
            }
          />
          <PageAlerts>
            <button className="showNotificationsButton" onClick={ () => setShowNotificationsModal( true ) }>
              <InlineSVG type="notifications_nav" />
              <span className="notificationsCount">1</span>
            </button>
          </PageAlerts>
        </React.Fragment>
      }
      {
        ( isNotEmpty( fields ) && isNotEmpty( record ) ) &&
        <Form
          fields={fields}
          onChangeCallback={setUpdatedForm}
          existingRecord={record}
          recordType={'smtp_settings'}
          setIsValid={setIsValid}
        />
      }
      {
        <div className="testFormWrapper">
          {
            missingAuthentication &&
            <Notification options={
              {
                type: 'warning',
                message: 'Authentication is enabled and you have not provided the necessary credentials',
              }
            } />
          }
          <Form
            fields={testFields}
            onChangeCallback={setUpdatedTestForm}
            recordType={'test_email'}
            setIsValid={setIsEmailValid}
          />
          <button
            className="test"
            disabled={demoMode || !isEmailValid || !isValid || attemptingSMTPTest || missingAuthentication }
            onClick={onEmailTest}
          >
            Send Test Email
          </button>
          {
            isNotEmpty( notificationOptions ) &&
            <Notification options={notificationOptions} />
          }
        </div>
      }
      <div className="formActionsContainer">
        <div className="formActions">
          <button
            disabled={demoMode}
            className={ `${demoMode ? 'disabled' : ''} revertButton` }
            onClick={() => onRefresh()}
          >
            Revert
          </button>
          <button
            disabled={!isValid || demoMode}
            className={demoMode ? 'disabled' : ''}
            onClick={onSave}
          >
            Save
          </button>
        </div>
      </div>
    </SetupForm>
  );
};

export default SmtpSettings;
