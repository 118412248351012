/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../shared/Utilities';
import ReactGA from 'react-ga4';

import './DemoModeContent.scss';
import InlineSVG from '../../shared/InlineSVG';

import RemediationPlans1GIF from '../../../../images/DemoGifs/remediation_plans_1.gif';
import ExploreModel1GIF from '../../../../images/DemoGifs/explore_model_1.gif';
import ActivityAlerts1 from '../../../../images/DemoGifs/activity_alerts_1.gif';
import ActivityLogs1 from '../../../../images/DemoGifs/activity_logs_1.gif';
import ScanningAgents1 from '../../../../images/DemoGifs/scanning_agents_1.gif';
import ScanningUserManaged1 from '../../../../images/DemoGifs/scanning_user_managed_1.gif';
import SetupManual1 from '../../../../images/DemoGifs/setup_manual_1.gif';
import SetupPolicies1 from '../../../../images/DemoGifs/setup_policies_1.gif';
import SetupTags1 from '../../../../images/DemoGifs/setup_tags_1.gif';
import SetupUsers1 from '../../../../images/DemoGifs/setup_users_1.gif';
// import ReportingExports1 from '../../../../images/DemoGifs/reporting_exports_1.gif';
// import AcceptedRisk1GIF from '../../../../images/DemoGifs/accepted_risk_1.gif';
import { pagesMap } from './Routing';

// completely removed from the demo version of the app because it just doesn't make sense
export const pagesRemovedInDemoMode = [
  'connectivity_settings',
];

// entire page content is replaced with a full-screen container for ease
export const pagesReplacedInDemoMode = [
  // reporting
  'exports',
  // remediation
  // 'remediation_plans',
  // explore
  'explore_model',
  // activity
  'configuration_alerts',
  'scan_logs',
  'notification_settings',
  // scanning
  'user_managed',
  'agents',
  // setup
  'sensitive_assets_policies',
  'sensitive_assets_manual',
  'tags',
  'users',
];

// pages that will have content added to it to explain what it would do outside of demo mode
export const pagesWithDemoModeContent = [
  // reporting
  'exports',
  // remediation
  'remediation_plans',
  'accepted_risk_plans',
  'remediation_settings',
  'remediation_ticketing',
  // explore
  'explore_model',
  // activity
  'tasks',
  'configuration_alerts',
  'scan_logs',
  'notification_settings',
  // scanning
  'user_managed',
  'agents',
  'agentless_settings',
  'credentials',
  'scan_groups',
  'scanning_general_settings',
  'vulnerability_scanners',
  'cloud_scanning',
  'subordinates',
  'connectivity_settings',
  // setup
  'sensitive_assets_policies',
  'sensitive_assets_manual',
  'tags',
  'providers',
  'users',
  'admin',
  'smtp',
  'certificates',
  'outbound_proxy',
];

const DemoModeContent = ( { currentLocation } ) => {

  const [ content, setContent ] = React.useState( null );

  const goToTrialForm = () => {
    ReactGA.event( {
      category: 'trial-request',
      action: 'clicked_trial_request_button_in_app',
    } );
    window.open( 'https://deepsurface.com/request-a-trial/' );
  };

  /* eslint-disable camelcase */
  const demoContentMap = {
    remediation_plans: {
      header: <React.Fragment>
        <strong>Remediation Plans</strong>
        <span>are not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      explanation: <p>
        With rememediation plans, users can choose the items that they want to fix and DeepSurface will provide
        the tasks needed to complete these remediation goals. Once the tasks are selected, DeepSurface will continue
        to scan and monitor your environment, automatically tracking remediation progress. Additionaly, tasks can be
        exported via email or other popular third party solutions such as Jira or Tanium.
      </p>,
      visual: RemediationPlans1GIF,
      items: [
        <span><strong>Unlimited</strong> remediation plans and tasks</span>,
        <span>Accepted risk plans</span>,
        <span>Simple <strong>remediation workflows</strong></span>,
        <span>Integrations with third party <strong>ticketing and patching solutions</strong></span>,
      ],
    },
    accepted_risk_plans: {
      header: <React.Fragment>
        <strong>Accepted Risk Plans</strong>
        <span>are not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      explanation: <p>
        Accepted risk plans allow users to choose hosts and/or vulnerabilities that should be accepted, therefore
        signalling to DeepSurface that they should not be considered, while evaluating risk. Users can choose different
        reasons for accepted risk and different amounts of time that an item is to be accepted.
      </p>,
      items: [
        <span><strong>Unlimited</strong> remediation plans and tasks</span>,
        <span>Accepted risk plans</span>,
        <span>Simple <strong>remediation workflows</strong></span>,
        <span>Integrations with third party <strong>ticketing and patching solutions</strong></span>,
      ],
    },
    remediation_settings: {
      header: <React.Fragment>
        <strong>Remediation Settings</strong>
        <span>are not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      items: [
        <span><strong>Unlimited</strong> remediation plans and tasks</span>,
        <span>Accepted risk plans</span>,
        <span>Simple <strong>remediation workflows</strong></span>,
        <span>Integrations with third party <strong>ticketing and patching solutions</strong></span>,
      ],
    },
    remediation_ticketing: {
      header: <React.Fragment>
        <strong>Remediation Integrations</strong>
        <span>are not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      explanation: <p>
        Deepsurface offers direct API integrations with Tanium for patch management and Jira for ticketing, with more
        being added all the time. We also offer the ability to integrate with any other third party tools via a simple
        email integration. Just configure what email you want tasks sent to, and DeepSurface will send them.
      </p>,
      items: [
        <span><strong>Unlimited</strong> remediation plans and tasks</span>,
        <span>Accepted risk plans</span>,
        <span>Simple <strong>remediation workflows</strong></span>,
        <span>Integrations with third party <strong>ticketing and patching solutions</strong></span>,
      ],
    },
    exports: {
      header: <React.Fragment>
        <strong>Reporting Exports</strong>
        <span>are not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      explanation: <p>
        Export many of the reports and dashboards in DeepSurface as XLSX spreadsheets and rich pdf visuals.
      </p>,
      items: [
        <span><strong>Unlimited</strong> report creation</span>,
        <span>Access to <strong>schedule</strong> recurring or one-off exports</span>,
        // eslint-disable-next-line max-len
        <span>Configure reports to be <strong>emailed to any number of recipients</strong> or downloaded directly</span>,
        <span>Export both <strong>PDF and XLSX</strong> versions</span>,
      ],
    },
    explore_model: {
      header: <React.Fragment>
        <span>The</span>
        <strong>Model Explorer</strong>
        <span>is not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      visual: ExploreModel1GIF,
      items: [
        <span>Access to the <strong>Model Explorer</strong></span>,
        <span>Ability to modify the underlying risk model</span>,
        <span>Access to deep and specific <strong>risk information and evidence</strong></span>,
      ],
    },
    tasks: {
      header: <React.Fragment>
        <strong>Tasks</strong>
        <span>are not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      items: [
        <span>Background task and job management</span>,
        <span>Scheduled tasks and data imports</span>,
        <span>Access to job history information</span>,
      ],
    },
    configuration_alerts: {
      header: <React.Fragment>
        <strong>Configuration Alerts</strong>
        <span>are not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      visual: ActivityAlerts1,
      items: [
        // eslint-disable-next-line max-len
        <span>Access to rich and detailed configuration alerts that keep you up to date on the health of your DeepSurface configuration</span>,
      ],
    },
    scan_logs: {
      header: <React.Fragment>
        <strong>DeepSurface Scan Logs</strong>
        <span>are not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      visual: ActivityLogs1,
      items: [
        // eslint-disable-next-line max-len
        <span>Direct access to system logs from DeepSurface Scans</span>,
      ],
    },
    notification_settings: {
      header: <React.Fragment>
        <strong>Notifications</strong>
        <span>are not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      items: [
        // eslint-disable-next-line max-len
        <span>Ability to subscribe to DeepSurface alerts and notifications</span>,
      ],
    },
    user_managed: {
      header: <React.Fragment>
        <strong>Scanning: User Managed Configuration</strong>
        <span>is not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      visual: ScanningUserManaged1,
      items: [
        <span>Ability to configure DeepSurface User Managed Scripts</span>,
        <span>Ability to run DeepSurface scanning scripts on devices in your network, any time you choose</span>,
      ],
    },
    agents: {
      header: <React.Fragment>
        <strong>Scanning: Agents Configuration</strong>
        <span>is not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      visual: ScanningAgents1,
      items: [
        <span>Ability to configure DeepSurface Agents</span>,
        <span>Ability to schedule agents to run whenever it is most convenient</span>,
        <span>Ability to configurue blackout schedules for when the agent phones home</span>,
      ],
    },
    agentless_settings: {
      header: <React.Fragment>
        <strong>Credentialed Scanning</strong>
        <span>is not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      items: [
        <span>Ability to configure DeepSurface Credential-Based Scanning (self-hosted edition only)</span>,
        <span>Ability to configure and prioritize credentials needed for scanning (self-hosted edition only)</span>,
        // eslint-disable-next-line max-len
        <span>Access Integrations with PAM providers such as <strong>CyberArk, Delinea, and Microsoft LAPS</strong> (self-hosted edition only)</span>,
        // eslint-disable-next-line max-len
        <span>configured IP-based credentialed scan groups to ensure that everything is covered (self-hosted edition only)</span>,
        // eslint-disable-next-line max-len
        <span>Access to scheduled credentialed scans so that nothing is forgotten or missed (self-hosted edition only)</span>,
      ],
    },
    credentials: {
      header: <React.Fragment>
        <strong>Credentialed Scanning</strong>
        <span>is not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      items: [
        <span>Ability to configure DeepSurface Credential-Based Scanning (self-hosted edition only)</span>,
        <span>Ability to configure and prioritize credentials needed for scanning (self-hosted edition only)</span>,
        // eslint-disable-next-line max-len
        <span>Access Integrations with PAM providers such as <strong>CyberArk, Delinea, and Microsoft LAPS</strong> (self-hosted edition only)</span>,
        // eslint-disable-next-line max-len
        <span>configured IP-based credentialed scan groups to ensure that everything is covered (self-hosted edition only)</span>,
        // eslint-disable-next-line max-len
        <span>Access to scheduled credentialed scans so that nothing is forgotten or missed (self-hosted edition only)</span>,
      ],
    },
    scan_groups: {
      header: <React.Fragment>
        <strong>Credentialed Scanning</strong>
        <span>is not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      items: [
        <span>Ability to configure DeepSurface Credential-Based Scanning (self-hosted edition only)</span>,
        <span>Ability to configure and prioritize credentials needed for scanning (self-hosted edition only)</span>,
        // eslint-disable-next-line max-len
        <span>Access Integrations with PAM providers such as <strong>CyberArk, Delinea, and Microsoft LAPS</strong> (self-hosted edition only)</span>,
        // eslint-disable-next-line max-len
        <span>configured IP-based credentialed scan groups to ensure that everything is covered (self-hosted edition only)</span>,
        // eslint-disable-next-line max-len
        <span>Access to scheduled credentialed scans so that nothing is forgotten or missed (self-hosted edition only)</span>,
      ],
    },
    scanning_general_settings: {
      header: <React.Fragment>
        <strong>Scanning: General Settings</strong>
        <span>are not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      items: [],
    },
    vulnerability_scanners: {
      header: <React.Fragment>
        <strong>Vulnerability Sources</strong>
        <span>are not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      items: [
        // eslint-disable-next-line max-len
        <span>Access to <strong>EDR/XDR Integrations</strong> such as <strong>CrowdStrike, SentinelOne, Carbon Black Cloud, Microsoft Defender for Endpoint, and Wazuh</strong></span>,
        // eslint-disable-next-line max-len
        <span>Access to <strong>Vulnerability Scanner Integrations</strong> such as <strong>Tenable, Rapid7, Qualys, and Nozomi Networks</strong></span>,
      ],
    },
    cloud_scanning: {
      header: <React.Fragment>
        <strong>Cloud Scanning</strong>
        <span>is not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      items: [
        // eslint-disable-next-line max-len
        <span>Access to cloud scanning integrations such as <strong>Amazon AWS</strong></span>,
      ],
    },
    subordinates: {
      header: <React.Fragment>
        <strong>Subordinates</strong>
        <span>are not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      items: [],
    },
    sensitive_assets_policies: {
      header: <React.Fragment>
        <strong>Sensitive Asset Policies</strong>
        <span>are not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      visual: SetupPolicies1,
      items: [
        // eslint-disable-next-line max-len
        <span>Ability to create policies that will automatically tag sensitive assets in your environment as scanning discovers them</span>,
      ],
    },
    sensitive_assets_manual: {
      header: <React.Fragment>
        <strong>Sensitive Asset Manual Assignment</strong>
        <span>is not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      visual: SetupManual1,
      items: [
        // eslint-disable-next-line max-len
        <span>Complete control over exactly which sensitive assets you want to identify as sensitve</span>,
      ],
    },
    tags: {
      header: <React.Fragment>
        <strong>Tags</strong>
        <span>are not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      visual: SetupTags1,
      items: [
        // eslint-disable-next-line max-len
        <span>Ability to create <strong>powerful and dynamic</strong> asset tags that automically grow with your environment</span>,
        // eslint-disable-next-line max-len
        <span>Ability to define groups of assets (devices/hosts) by <strong>IP Ranges, Host Lists, OS Version, and/or Wildcard Matchers</strong></span>,
      ],
    },
    providers: {
      header: <React.Fragment>
        <strong>Authentication Providers</strong>
        <span>are not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      items: [],
    },
    users: {
      header: <React.Fragment>
        <strong>Authentication Providers</strong>
        <span>are not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      visual: SetupUsers1,
      items: [
        // eslint-disable-next-line max-len
        <span>Ability to manage user information including <strong>API credentials, notification preferences, and integration preferences</strong></span>,
      ],
    },
    admin: {
      header: <React.Fragment>
        <strong>Admin Settings</strong>
        <span>are not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      items: [],
    },
    smtp: {
      header: <React.Fragment>
        <strong>SMTP Settings</strong>
        <span>are not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      items: [],
    },
    certificates: {
      header: <React.Fragment>
        <strong>Certificates</strong>
        <span>are not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      items: [],
    },
    outbound_proxy: {
      header: <React.Fragment>
        <strong>Outbound Proxy Settings</strong>
        <span>are not available in the</span>
        <strong>Demo Version</strong>
        <span>of DeepSurface.</span>
      </React.Fragment>,
      items: [],
    },
  };
  /* eslint-enable camelcase */

  const getContentForPage = location => {

    const { route, page, report } = location;

    let derivedPageName = '';

    if ( isNotEmpty( route ) && isNotEmpty( pagesMap[route] ) ) {
      const possiblePages = Object.values( pagesMap[route].items );

      let currentPage = possiblePages.find( p => p.slug === page );

      if ( isNotEmpty( report ) ) {
        currentPage = possiblePages.find( p => p.slug === report );
      }

      if ( isNotEmpty( currentPage ) ) {
        derivedPageName = currentPage.label;
      } else {
        derivedPageName = 'This Feature';
      }
    }

    const startTrial = <span className="startTrial">
      Start a <strong>15 Day Free Trial</strong> to unlock this feature
    </span>;

    const buttonContainer = <div className="trialButtonWrapper">
      <button className="startFreeTrialButton" onClick={ goToTrialForm }>
        <span>
          Start Free Trial
        </span>
        <span className="iconWrapper">
          <InlineSVG type="carretRight" />
        </span>
      </button>
      <span className="buttonSubText">
        *No credit card required
      </span>
    </div>;

    if ( isNotEmpty( page ) && isNotEmpty( demoContentMap[page] ) ) {

      return <div className="demoModeContentWrapper custom" >
        <div className="demoModeContentHeader">
          <div className="iconWrapper">
            <InlineSVG type="locked" />
          </div>
          <h2>
            {
              isNotEmpty( demoContentMap[page].header )
                ? demoContentMap[page].header
                : <React.Fragment>
                  <strong>{ derivedPageName }</strong> is not available in the demo version.
                </React.Fragment>
            }
          </h2>
        </div>
        {
          isNotEmpty( demoContentMap[page].explanation ) && demoContentMap[page].explanation
        }

        <div className="featuresWrapper">
          <div className="column">
            <span className="startTrial">
              Start a <strong>15 Day Free Trial</strong> to unlock
            </span>
            <ul>
              {
                demoContentMap[page].items.map( ( item, i ) => {
                  return <li key={i}>
                    <InlineSVG type="node_bullet" />
                    { item }
                  </li>;
                } )
              }
            </ul>
          </div>
          { buttonContainer }
        </div>
        {
          (
            isNotEmpty( demoContentMap[currentLocation.page] )
            && isNotEmpty( demoContentMap[currentLocation.page].visual )
            && pagesReplacedInDemoMode.includes( currentLocation.page )
          ) &&
          <img
            className="demoContentfeatureVisual"
            src={ demoContentMap[currentLocation.page].visual }
            alt="remediation plan item selection"
          />
        }
      </div>;
    }

    return <div className="demoModeContentWrapper default" >
      <div className="demoModeContentHeader">
        <div className="iconWrapper">
          <InlineSVG type="locked" />
        </div>
        <h2>
          <strong>{ derivedPageName }</strong>
          <span>is not available in the</span>
          <strong>demo version</strong>
          <span>of DeepSurface.</span>
        </h2>
      </div>
      <div className="featuresWrapper default">
        { startTrial }
        { buttonContainer }
      </div>
    </div>;
  };

  React.useEffect( () => {
    if ( isNotEmpty( currentLocation ) ) {
      setContent( getContentForPage( currentLocation ) );
    } else {
      setContent( null );
    }
  }, [ currentLocation ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( currentLocation ) && isNotEmpty( content ) ) &&
        content
      }

    </React.Fragment>
  );
};

export default DemoModeContent;