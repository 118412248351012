/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  formatNumber,
  formatTimeDuration,
  formatUnixTime,
  isEmpty,
  isNotEmpty,
  vulnScannerFullNameMap,
  vulnerabilityScannerLogo,
} from '../../../shared/Utilities';
import { makeRequest } from '../../../../legacy/io';
import { HelpTrigger } from '../../HelpDocumentation/ContextualHelp';
import TaskButton from './TaskButton';
import InlineSVG from '../../../shared/InlineSVG';
import DataTable from '../../../shared/DataTable';

import './TaskItem.scss';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';

const TaskItem = ( {
  task,
  warnings,
  serverTimeZone,
  scanners,
  scanGroups,
  taskItems,
  refreshTasks,
  currentTime,
} ) => {

  const [ showJobHistory, setShowJobHistory ] = React.useState( false );
  const [ jobHistoryData, setJobHistoryData ] = React.useState( null );
  const [ showHistoryItemWarnings, setShowHistoryItemWarnings ] = React.useState( false );
  const [ historyItemWarnings, setHistoryItemWarnings ] = React.useState( null );
  const [ warningsStyle, setWarningsStyle ] = React.useState( { } );
  const [ , , , demoMode ] = React.useContext( CurrentUserContext );

  const additionalOptionsFor = type => {
    if ( type === 'analysis' ) {
      return (
        { base: { label: 'Base Model' } }
      );
    }
    if ( type === 'authenticated' ) {
      return scanGroups;
    }
    if ( type === 'import' ) {
      return scanners;
    }
  };

  const getTitle = title => {
    if ( title === 'Authenticated Scan' ) {
      return 'Credentialed Scan';
    } else if ( title === 'External Vulnerability Scanner' ) {
      return 'Import Vulnerability Source Data';
    }
    return title;

  };

  const getHelpKey = taskIdentifier => {
    if ( taskIdentifier === 'authenticated' ) {
      return 'agentless_scan';
    } else if ( taskIdentifier === 'process' ) {
      return 'process_queue';
    }
    return taskIdentifier;
  };

  const handleWarningsClick = ( e, warnings, position='left' ) => {

    e.preventDefault();
    e.stopPropagation();

    if ( showHistoryItemWarnings ) {
      setShowHistoryItemWarnings( false );
      setHistoryItemWarnings( null );
    } else if ( isNotEmpty( warnings ) ) {
      setHistoryItemWarnings( warnings );
      setShowHistoryItemWarnings( true );

      const width = 16 * 24;

      let left = 'unset';
      let top = 'unset';

      const { pageY, pageX } = e;

      left = pageX;
      top = pageY;

      if ( position === 'right' ) {
        left = pageX - width;
      }

      setWarningsStyle( { top, left, width } );
    }
  };

  const taskStatusAndIcon = details => {
    if ( isNotEmpty( details ) ) {

      const { status, results } = details;

      if ( status === 'running' ) {
        return <div className="historyItemStatusWrapper running">
          <InlineSVG type="cycle" elementClass="running" />
          <span>Running...</span>
        </div>;
      }
      if ( status === 'queued' ) {
        return <div className="historyItemStatusWrapper queued">
          <InlineSVG type="queue" elementClass="queued" />
          <span>Queued</span>
        </div>;
      }
      let statusMessage;
      let statusIcon;
      let additionalClass = '';

      if ( isNotEmpty( status ) ) {
        if ( status === 'completed' ) {
          statusIcon = <InlineSVG type="checkmarkCircle" />;
          if ( isNotEmpty( results ) && isNotEmpty( results.warnings ) ) {
            additionalClass = 'withWarnings';
            statusMessage = <span>
              Completed with <button onClick={ ( e ) => handleWarningsClick( e, results.warnings ) }>errors</button>
            </span>;
          } else {
            statusMessage = <span>Completed without errors</span>;
          }

          return <div className={ `historyItemStatusWrapper completed ${additionalClass}`}>
            { isNotEmpty( statusIcon ) && statusIcon }
            { isNotEmpty( statusMessage ) && statusMessage }
          </div>;
        } else if ( isNotEmpty( results ) && isNotEmpty( results.warnings ) ) {
          statusIcon = <InlineSVG type="configurationAlert" />;
          statusMessage = <span>
            Incomplete with <button onClick={ ( e ) => handleWarningsClick( e, results.warnings ) }>errors</button>
          </span>;
          return <div className="historyItemStatusWrapper incomplete withWarnings">
            { isNotEmpty( statusIcon ) && statusIcon }
            { isNotEmpty( statusMessage ) && statusMessage }
          </div>;
        }

        statusIcon = <InlineSVG type="configurationAlert" />;
        statusMessage = <span>Incomplete</span>;

        return <div className="historyItemStatusWrapper incomplete">
          { isNotEmpty( statusIcon ) && statusIcon }
          { isNotEmpty( statusMessage ) && statusMessage }
        </div>;
      }
    }
    return 'N/A';
  };

  const taskHistoryItems = ( task, type ) => {
    if ( isNotEmpty( task ) && isNotEmpty( type ) ) {
      if ( type === 'import' && isNotEmpty( task.details?.results?.scanner_stats ) ) {
        const stats = [];
        Object.entries( task.details?.results.scanner_stats ).map( ( [ scannerKey, scannerInfo ] ) => {
          stats.push( {
            label: vulnScannerFullNameMap[scannerKey],
            icon: vulnerabilityScannerLogo( scannerKey ),
            // eslint-disable-next-line max-len
            details: `${ isNotEmpty( scannerInfo.scanned_hosts ) ? formatNumber( scannerInfo.scanned_hosts ) : 0 } Host(s) scanned`,
          } );
        } );
        if ( isNotEmpty( stats ) ) {
          return <ul>
            {
              stats.map( ( stat, i ) => {
                return <li key={i}>
                  { isNotEmpty( stat.icon ) && stat.icon }
                  <strong>{ stat.label }: </strong>
                  <span>{ stat.details }</span>
                </li>;
              } )
            }
          </ul>;
        }
        return 'N/A';
      }
      if ( type === 'authenticated' && isNotEmpty( task.work_items ) && isNotEmpty( scanGroups ) ) {
        const stats = [];
        task.work_items.map( sgID => {
          const scanGroup = scanGroups[sgID];

          if ( isNotEmpty( scanGroup ) ) {
            stats.push( <a href={ `#.=scanning&page=scan_groups&selected_record=${sgID}` }>{scanGroup.label}</a> );
          }
        } );

        if ( isNotEmpty( stats ) ) {
          return <ul>
            {
              stats.map( ( stat, i ) => {
                return <li key={i}>{ stat }</li>;
              } )
            }
          </ul>;
        }
        return 'N/A';
      }
      return 'N/A';
    }
    return 'N/A';
  };

  const transformRowData = row => {
    if ( isNotEmpty( row ) ) {
      const { task, details } = row;

      if ( isNotEmpty( task ) ) {
        switch ( task ) {
        case 'import':
          return {
            status: isNotEmpty( details ) ? taskStatusAndIcon( details ) : 'N/A',
            // eslint-disable-next-line camelcase
            scanner_statistics: taskHistoryItems( row, 'import' ),
            started: isNotEmpty( details ) ? `${formatUnixTime( row.created )} ${serverTimeZone}` : 'N/A',
            duration: isNotEmpty( details ) ? formatTimeDuration( details.duration ) : 'N/A',
            actions: isNotEmpty( details.results?.warnings )
              ? <button
                className="showWarningsButton"
                onClick={ ( e ) => handleWarningsClick( e, details.results?.warnings, 'right' )}
              >
                View error(s)
              </button>
              : <React.Fragment></React.Fragment>,
            id: row.id,
            originalRecord: row,
          };
        case 'authenticated':
          return {
            status: isNotEmpty( details ) ? taskStatusAndIcon( details ) : 'N/A',
            // eslint-disable-next-line camelcase
            included_scan_groups: taskHistoryItems( row, 'authenticated' ),
            started: isNotEmpty( details ) ? `${formatUnixTime( row.created )} ${serverTimeZone}` : 'N/A',
            duration: isNotEmpty( details ) ? formatTimeDuration( details.duration ) : 'N/A',
            actions: isNotEmpty( details.results?.warnings )
              ? <button
                className="showWarningsButton"
                onClick={ ( e ) => handleWarningsClick( e, details.results?.warnings, 'right' )}
              >
                View error(s)
              </button>
              : <React.Fragment></React.Fragment>,
            id: row.id,
            originalRecord: row,
          };

        default:
          break;
        }
      }
    }

  };

  const handleJobHistoryClick = async () => {
    if ( isEmpty( jobHistoryData ) ) {
      const data = await makeRequest( 'HISTORY', '/task', {
        // eslint-disable-next-line camelcase
        field_map: { task: task.id },
        // eslint-disable-next-line camelcase
        extra_columns: [ 'task', 'details', 'work_items', 'created' ],
        // eslint-disable-next-line camelcase
        order_by: [ [ 'created', 'DESC' ] ],
        rownums: [ 0, 50 ],
      } );

      if ( isNotEmpty( data ) && isNotEmpty( data.results ) ) {
        const _jobHistoryData = data.results.map( row => transformRowData( row ) );
        setJobHistoryData( _jobHistoryData );
      }
    }
    setShowJobHistory( !showJobHistory );
  };

  return (
    <React.Fragment>
      {
        (
          isNotEmpty( historyItemWarnings )
          && showHistoryItemWarnings
        ) &&
        <React.Fragment>
          <div className="itemHistoryWarningShade" onClick={ e => handleWarningsClick( e, null ) } />
          <div className="itemHistoryWarningsWrapper" style={ warningsStyle }>
            <div className="header">
              <div className="left">
                <InlineSVG type="configurationAlert" />
                Errors
              </div>
              <button className="roundGlyphButton light" onClick={ e => handleWarningsClick( e, null ) }>
                <InlineSVG type="close" />
              </button>
            </div>
            <ul>
              {
                historyItemWarnings.map( ( w, i ) => {
                  return <li key={i}>{ w }</li>;
                } )
              }
            </ul>
          </div>
        </React.Fragment>
      }
      {
        isNotEmpty( task ) &&
        <li
          // eslint-disable-next-line max-len
          className={`setupRecordItem task_item alternateLayout ${ task.focusForOnboarding ? 'onboardingWizardFocus' : '' }`}
          id={`${task.taskIdentifier}Task`}
        >
          <section className="mainDetailsSection">
            <h2>
              { getTitle( task.title ) }
              <HelpTrigger helpKey={ getHelpKey( task.taskIdentifier )} />
            </h2>
            <div className="actionAndDuration">
              <TaskButton
                task={task}
                taskType={ task.taskIdentifier }
                recordOptions={ additionalOptionsFor( task.taskIdentifier ) }
                tasks={taskItems}
                refreshTasks={refreshTasks}
                warnings={warnings}
                handleWarningsClick={handleWarningsClick}
              />
              <span>
                <strong>Duration:</strong>
                {
                  ( task.current.started )
                    ? <span>
                      {/* eslint-disable-next-line max-len */}
                      { formatTimeDuration( ( task.current.ended ? task.current.ended : currentTime ) - task.current.started ) }
                    </span>
                    : <span> -- </span>
                }
              </span>
            </div>
          </section>
          <section>
            <label className="sectionLabel">
              Current/Recent Job(s)
            </label>
            <span className="sectionDetails">
              <ul>
                <li className="taskStatus">
                  <strong>Status:</strong>
                  {
                    isEmpty( task.current )
                      ? <span> -- </span>
                      : <span> { taskStatusAndIcon( { warnings, results: task?.current, ...task?.current } ) }</span>
                  }
                </li>
                <li>
                  <strong>Completed:</strong>
                  {
                    ( isNotEmpty( task.current ) && isNotEmpty( task.current.ended ) )
                      ? <span>{ formatUnixTime( task.current.ended ) }</span>
                      : <span> -- </span>
                  }
                </li>
                {
                  (
                    task.current.started
                    && task.id === 'authenticated'
                    && isNotEmpty( scanGroups )
                    && isNotEmpty( task.current.work_items
                    && task.taskIdentifier !== 'process',
                    )
                  ) &&
                  <li>
                    <strong>Included Scan Groups:</strong>
                    <span>
                      {/* eslint-disable-next-line max-len */}
                      { task.current.work_items.map( item => scanGroups[item] ? scanGroups[item].label : '[Deleted Scan Group]' ).join( ', ' ) }
                    </span>
                  </li>
                }
              </ul>
            </span>
          </section>
          <section>
            <label className="sectionLabel">
              Scheduled Job(s)
            </label>
            <span className="sectionDetails">
              {
                ( isNotEmpty( task.scheduled ) && task.taskIdentifier !== 'process' )
                  ? <ul>
                    {
                      task.scheduled.map( ( task, index ) => {
                        return  <li
                          key={index}
                        >
                          {
                            // eslint-disable-next-line max-len
                            ( task.id === 'authenticated' && isNotEmpty( scanGroups ) && isNotEmpty( scanGroups[task.work_items[0]] ) ) &&
                            <strong>
                              { scanGroups[task.work_items[0]].label}:
                            </strong>
                          }
                          <span>{ `${formatUnixTime( task.next_run_time )} ${serverTimeZone}`}</span>
                        </li>;
                      } )
                    }
                  </ul>
                  : <span>N/A</span>
              }
            </span>
          </section>
          {
            ( task.taskIdentifier === 'authenticated' || task.taskIdentifier === 'import' ) &&
            <button disabled={demoMode} className="jobHistoryButton" onClick={ handleJobHistoryClick }>
              Job History
              <InlineSVG type={ showJobHistory ? 'carretUp' : 'carretDown' } />
            </button>
          }
          {
            ( showJobHistory && isNotEmpty( jobHistoryData ) ) &&
            <section className="jobHistorySection">
              <DataTable data={jobHistoryData}/>
            </section>
          }
        </li>
      }
    </React.Fragment>
  );
};

export default TaskItem;