/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import {
  isFalsey,
  isNotEmpty,
} from '../../shared/Utilities';

// all sections of the app that require license flags to visit,
// and the associated option for the license info
/* eslint-disable camelcase */
const licenseAccessRouteMap = {
  remediation: 'license_option_remediation',
};

const licenseAccessReportOrPageMap = {
  remediation_plans: 'license_option_remediation',
  remediation_settings: 'license_option_remediation',
};

const licenseAccessFeatureMap = {
  remediationWorkflow: 'license_option_remediation',
};
/* eslint-enable camelcase */

export const hasAccess = ( licenseInfo, currentLocation, restrictBy='route', featureKey='' ) => {
  if ( isNotEmpty( licenseInfo ) ) {
    switch ( restrictBy ) {
    case 'route':
      if ( isNotEmpty( currentLocation ) ) {
        const accessKey = licenseInfo[licenseAccessRouteMap[currentLocation.route]];
        // this accessKey does not exist, therefore it is not a restricted feature, allow access
        if ( accessKey === undefined ) {
          return true;
        }
        // this accessKey does exist and it is set to false, restrict access
        if ( isFalsey( accessKey ) ) {
          return false;
        }
        // this accessKey exists and is set to true
        return true;
      }
      return false;
    case 'reportOrPage':
      if ( isNotEmpty( currentLocation ) ) {
        const reportOrPage = currentLocation.page ? currentLocation.page : currentLocation.report;
        const accessKey = licenseInfo[licenseAccessReportOrPageMap[reportOrPage]];
        // this accessKey does not exist, therefore it is not a restricted feature, allow access
        if ( accessKey === undefined ) {
          return true;
        }
        // this accessKey does exist and it is set to false, restrict access
        if ( isFalsey( accessKey ) ) {
          return false;
        }
        // this accessKey exists and is set to true
        return true;
      }
      return false;
    case 'feature':
      if ( isNotEmpty( featureKey ) ) {
        const accessKey = licenseInfo[licenseAccessFeatureMap[featureKey]];
        // this accessKey does not exist, therefore it is not a restricted feature, allow access
        if ( accessKey === undefined ) {
          return true;
        }
        // this accessKey does exist and it is set to false, restrict access
        if ( isFalsey( accessKey ) ) {
          return false;
        }
        // this accessKey exists and is set to true
        return true;
      }
      return false;
    default:
      return false;
    }
  // they have no license
  }
  return false;
};