/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty, riskToRating } from '../../../shared/Utilities';
import { getArrowDirection } from './shared';
import './GraphEdge.scss';
import { getRiskFill } from '../../RecordDetails/shared';

const GraphEdge = ( {
  edge,
  nodes,
  focusEdges=[],
  setFocusEdges,
  focusNodes=[],
  setFocusNodes,
  externalHoverIDs,
  setSelectedItem,
  setSelectedItemType,
  setContextMenuItem,
  setContextMenuType,
  ignoreClick,
  setIgnoreClick,
} ) => {

  const [ pathVariables, setPathVariables ] = React.useState( null );
  const [ hovering, setHovering ] = React.useState( false );

  const handleEdgeRightClick = ( e, edge ) => {
    if ( isNotEmpty( e ) && e.button === 2  ) {
      e.stopPropagation();
      e.preventDefault();
      const _item = { ...edge, clickEvent: e };
      setContextMenuItem( _item );
      setContextMenuType( 'edge' );
      return false;
    }
  };

  const handleEdgeClick = ( e, edge ) => {

    if ( isNotEmpty( e ) && e.button === 0 ) {
      e.stopPropagation();
      e.preventDefault();
      const _item = { ...edge, clickEvent: e };

      // DO NOT REMOVE!!! used by devs to debug explore behavior -DMC 2023-07-27
      console.log( _item );

      const fromNode = nodes[_item.from_node];
      const toNode = nodes[_item.to_node];

      const allNodes = [ fromNode?.id, toNode?.id ];

      if ( ignoreClick ) {
        setIgnoreClick( false );
      } else if ( isNotEmpty( focusEdges ) && focusEdges.includes( _item.id ) ) {
        let _edges = [ ...focusEdges ];
        _edges = _edges.filter( e => e !== _item.id );
        setFocusEdges( _edges );
        if ( isNotEmpty( focusNodes ) ) {
          let _nodes = [ ...focusNodes ];
          _nodes = _nodes.filter( n => !allNodes.includes( n ) );
          setFocusNodes( _nodes );
        }
        setSelectedItem( null );
        setSelectedItemType( null );
      // not clicked yet, set it
      } else {
        setSelectedItem( _item );
        setSelectedItemType( 'edge' );
        setFocusEdges( [ _item.id ] );
        setFocusNodes( allNodes );
      }
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( edge ) ) {

      const { x1, x2, y1, y2 } = edge;

      const strokeWidth = 4;
      const arrowWidth = strokeWidth * 5;
      const arrowDirection = getArrowDirection( x1, y1, x2, y2 );

      const width = x2 - x1;
      const height = y2 - y1;
      const M = `${x1}, ${y1}`;
      const C = `${x1 + ( width * ( 3 / 4 ) )}, ${y1} ${x1 + ( width / 4 )},${y2} ${x2}, ${y2}`;

      setPathVariables( {
        arrowWidth,
        strokeWidth,
        x1,
        x2,
        y1,
        y2,
        width,
        height,
        M,
        C,
        arrowDirection,
      } );
    }
  }, [ edge ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( edge ) && isNotEmpty( pathVariables ) ) &&
        <g
          // eslint-disable-next-line max-len
          className={ `graphModelEdgeGroup risk-${riskToRating( edge.risk ) } ${ focusEdges?.includes( edge.id ) ? 'focused' : '' }` }
        >
          <path
            // eslint-disable-next-line max-len
            d={ `M ${pathVariables.M} C ${pathVariables.C}`}
            strokeWidth={ pathVariables.strokeWidth }
            stroke={ getRiskFill(
              edge,
              focusEdges.includes( edge.id ) || externalHoverIDs.includes( edge.id ),
              hovering,
              false,
            ) }
            strokeLinecap="round"
            fill="none"
            className="graphEdgePath"

          />

          {/* draw a little arrow */}
          <svg
            x={ pathVariables.x1 + ( pathVariables.width / 2 ) - ( pathVariables.arrowWidth / 2 ) }
            y={ pathVariables.y1 + ( pathVariables.height / 2 ) - ( pathVariables.arrowWidth / 2 ) }
            width={ pathVariables.arrowWidth }
            height={ pathVariables.arrowWidth }
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className={ `edgeArrow ${pathVariables.arrowDirection}` }
              // eslint-disable-next-line max-len
              d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7Z"
              fill={ getRiskFill(
                edge,
                focusEdges.includes( edge.id ) || externalHoverIDs.includes( edge.id ),
                hovering,
                false,
              ) }
            />
            <path
              className={ `edgeArrowDirection ${pathVariables.arrowDirection}` }
              // eslint-disable-next-line max-len
              d="M1.4 7C1.4 3.913 3.913 1.4 7 1.4C10.087 1.4 12.6 3.913 12.6 7C12.6 10.087 10.087 12.6 7 12.6C3.913 12.6 1.4 10.087 1.4 7ZM0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0C3.136 0 0 3.136 0 7ZM7 6.3H4.2V7.7H7V9.8L9.8 7L7 4.2V6.3Z"
              fill="#FFF"
            />
          </svg>
          <path
            // eslint-disable-next-line max-len
            d={ `M ${pathVariables.M} C ${pathVariables.C}`}
            strokeWidth={ pathVariables.arrowWidth * 1.125 }
            fill="none"
            opacity={ 1 }
            strokeLinecap="round"
            stroke={ getRiskFill(
              edge,
              focusEdges.includes( edge.id ) || externalHoverIDs.includes( edge.id ),
              hovering,
              false,
            ) }
            strokeOpacity={ 0.05 }
            className="edgeHoverPath"
            // need to prevent here before passed to the handler or it will still trigger the default right-click menu
            onContextMenu={ e => {
              e.preventDefault();
              e.stopPropagation();
              handleEdgeRightClick( e, edge );
              return false;
            } }
            onClick={ e  => {
              e.preventDefault();
              e.stopPropagation();
              handleEdgeClick( e, edge );
              return false;
            } }
            onMouseEnter={ () => setHovering( true ) }
            onMouseLeave={ () => setHovering( false ) }
          />
        </g>
      }
    </React.Fragment>
  );
};

export default GraphEdge;