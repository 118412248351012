/** **************************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ****************************************************************************/

import React from 'react';
import { helpContentMap } from '../components/HelpDocumentation/Shared';
import { helpIsLoaded, isEmpty, isNotEmpty } from '../shared/Utilities';

/* eslint-disable camelcase */
const EMPTY_PATH_MAP = {
  user_guide: {
    reporting: {},
    risk_insight: {},
    remediation: {},
    explore: {},
    activity: {},
    scanning: {},
    setup: {},
    about: {},
    help_documentation: {},
  },
  reference_guide: {
    deepsurface_api: {},
    other_reference_items: {},
    pam: {},
    providers: {},
    virtual_machines: {},
    vulnerability_scanners: {},
  },
  security_guide: {
    system_access: {},
    other_security_items: {},
  },
  global_items: [],
};

// using webpack context api to load all the file paths of all the md files
// eslint-disable-next-line no-undef
const mdContext = require.context( '../components/HelpDocumentation', true, /\.md$/ );

export const HelpContext = React.createContext();

export const HelpProvider = ( { children } ) => {

  // all the relative file paths,
  const mdKeys = mdContext.keys();

  // all of the actual modules
  const mdModules = mdKeys.map( filename => mdContext( filename ) );

  // will be filled with relative paths => corresponding module
  const keyedModules = {};

  mdKeys.map( ( key, index ) => {
    keyedModules[key] = mdModules[index];
  } );

  const [ helpItems, setHelpItems ] = React.useState( null );
  const [ selectedHelpItem, setSelectedHelpItem ] = React.useState( null );
  const [ showHelp, setShowHelp ] = React.useState( false );
  const [ filePathMap, setFilePathMap ] = React.useState( EMPTY_PATH_MAP );

  // helper function to call fetch and return the text of the md file
  const fetchMdFileText = async ( main, section, page, item ) => {
    let path;

    // help_documentation page itself, need to rewire
    if ( section === 'help_documentation' && isEmpty( page ) ) {
      path = filePathMap.global_items[item];
    // main content
    } else if ( isEmpty( item ) && isEmpty( page ) && isEmpty( section ) ) {
      path = filePathMap[main].content;
    // section content
    } else if ( isEmpty( item ) && isEmpty( page ) ) {
      path = filePathMap[main][section].content;
    // page content
    } else if ( isEmpty( item ) ) {
      if ( main === 'reference_guide' || main === 'security_guide' ) {
        path = filePathMap[main][section][page];
      } else if ( isNotEmpty( filePathMap[main][section][page] ) ) {
        path = filePathMap[main][section][page].content;
      }
    // item content
    } else if ( isNotEmpty( main ) && isNotEmpty( section ) && isNotEmpty( page ) && isNotEmpty( item ) ) {
      if ( item === 'risk_score' ) {
        path = filePathMap.global_items[item];
      } else {
        path = filePathMap[main][section][page].items[item];
      }
    }

    if ( isNotEmpty( path ) ) {
      const file = await fetch( path );
      return file.text();
    }
    return '';
  };

  // onload, fill the path map for future use
  React.useEffect( ( ) => {

    if ( !helpIsLoaded( filePathMap ) ) {
      const _filePathMap = { ...filePathMap };
      Object.entries( keyedModules ).map( ( [ relativePath, module ] ) => {

        const pathParts = relativePath.replace( './', '' ).split( '/' );

        const [ main ] = pathParts;

        // main content only, or a global help item
        if ( pathParts.length === 2 ) {
          // this is a global help item
          if ( main === 'global_items' ) {
            const [ main, filename ] = pathParts;
            _filePathMap[main] = { ..._filePathMap[main], [filename.replace( '.md', '' )]: module.default };
          // this is the main content for a guid
          } else {
            _filePathMap[main].content = module.default;
          }

        // either a reference item or a user guide section content
        } else if ( pathParts.length === 3 ) {
          const [ , section, filename ] = pathParts;
          // this is a reference file
          if ( main === 'reference_guide' || main === 'security_guide' ) {
            // eslint-disable-next-line max-len
            _filePathMap[main][section] = { ..._filePathMap[main][section], [filename.replace( '.md', '' )]: module.default };
          } else {
            _filePathMap[main][section] = { ..._filePathMap[main][section], content: module.default};
          }
        // a user guide page content
        } else if ( pathParts.length === 4 ) {
          const [ , section, page ] = pathParts;
          if ( _filePathMap[main][section][page] ) {
            _filePathMap[main][section][page] = { ..._filePathMap[main][section][page], content: module.default };
          } else {
            _filePathMap[main][section] = { ..._filePathMap[main][section], [page]: { content: module.default } };
          }
        // user guide contextual help item
        } else if ( pathParts.length === 5 ) {
          const [ , section, page, , filename ] = pathParts;
          // this page already has an entry in the object
          if ( _filePathMap[main][section][page] ) {
            // this page already has existing items
            if ( _filePathMap[main][section][page].items ) {
              // eslint-disable-next-line max-len
              _filePathMap[main][section][page].items = { ..._filePathMap[main][section][page].items, [filename.replace( '.md', '' )]: module.default };
            // this page does not have existing items
            } else {
              _filePathMap[main][section][page].items = { [filename.replace( '.md', '' )]: module.default };
            }
          // this page entry does not yet exist
          } else {
            // eslint-disable-next-line max-len
            _filePathMap[main][section] = { [page]: { items: [ { [filename.replace( '.md', '' )]: module.default } ] } };
          }
        }
      } );

      setFilePathMap( _filePathMap );
    }


  }, [] );

  // 1) highest level, gets all the sections for a given main, exported to help_documentation
  const loadHelpDocumentation = async ( mainKey ) => {

    // an object of all the sections for a given mainKey, for example { reporting: { ... }, risk_insight: { ... } }
    const sectionsObject = helpContentMap[mainKey];
    const contentPromises = [];
    const sectionPromises = [];
    const sectionKeys = Object.keys( sectionsObject );

    sectionKeys.map( sectionKey => {
      sectionPromises.push( getHelpPagesForSection( mainKey, sectionKey ) );
    } );

    const resolvedSections = await Promise.all( sectionPromises );

    const formattedSections = [];

    resolvedSections.map( async ( section, index ) => {
      contentPromises.push( fetchMdFileText( mainKey, sectionKeys[index] ) );
    } );

    const resolvedContent = await Promise.all( contentPromises );

    resolvedContent.map( ( content, index ) => {
      const pages = resolvedSections[index];
      const sectionKey = sectionKeys[index];
      formattedSections.push( { content, pages, sectionKey } );
    } );

    return formattedSections;
  };

  // 2) second highest level, gets all the pages for a given section
  const getHelpPagesForSection = async ( mainKey, sectionKey ) => {
    // an object for a give section, for example { reporting: { label: 'Reporting', ... } }
    const section = helpContentMap[mainKey][sectionKey];

    const pagePromises = [];
    Object.keys( section.pages ).map( pageKey => {
      pagePromises.push( getHelpPageContent( mainKey, sectionKey, pageKey ) );
    } );

    const resolvedPages = await Promise.all( pagePromises );

    const formattedPages = [];

    resolvedPages.map( async ( page, index ) => {
      const pageKey = Object.keys( section.pages )[ index ];

      formattedPages.push( { pageKey, page } );
    } );
    return formattedPages;
  };

  // 3) third highest level, gets all the content for a given page
  const getHelpPageContent = async ( mainKey, sectionKey, pageKey ) => {
    if ( isNotEmpty( mainKey ) && isNotEmpty( sectionKey ) && isNotEmpty( pageKey ) ) {
      let _markDownContent;
      // user guide structure is slightly different, folders, with content.md file,
      // reference guide has folders with md files named after the page
      if ( mainKey === 'user_guide' ) {

        _markDownContent = await fetchMdFileText( mainKey, sectionKey, pageKey );

        const items = await getContextualHelpItemsForPage( sectionKey, pageKey );

        return {
          content: _markDownContent,
          items,
        };
      } else if ( mainKey === 'reference_guide' || mainKey === 'security_guide' ) {
        _markDownContent = await fetchMdFileText( mainKey, sectionKey, pageKey );

        return {
          content: _markDownContent,
          items: [],
        };
      }
      return _markDownContent;
    }
  };

  // 4) fourth level, gets all the contextualHelp items for a specific page, also called from the rest
  // of the app
  const getContextualHelpItemsForPage = async ( sectionKey, pageKey, shouldLoadGlobal=false ) => {
    const itemsPromises = [];
    const formattedItems = {};
    if (
      isNotEmpty( sectionKey )
      && isNotEmpty( pageKey )
    ) {
      if ( sectionKey !== 'help_documentation' ) {
        const itemKeys = helpContentMap.user_guide[sectionKey]?.pages[pageKey]?.items;

        if ( isNotEmpty( itemKeys ) ) {
          itemKeys.map( itemKey => {
            itemsPromises.push( fetchMdFileText( 'user_guide', sectionKey, pageKey, itemKey ) );
          } );

          const items = await Promise.all( itemsPromises );

          items.map( ( item, index ) => {
            const itemKey = helpContentMap.user_guide[sectionKey].pages[pageKey].items[index];
            formattedItems[itemKey] = item;
          } );
        }

      }

    }
    if ( shouldLoadGlobal ) {
      formattedItems.risk_score = await fetchMdFileText( 'user_guide', sectionKey, pageKey, 'risk_score' );
    }

    // adds all global help to every page (ie: risk score)
    return formattedItems;
  };

  const showHelpFor = ( e, helpKey ) => {
    e.preventDefault();
    e.stopPropagation();

    if ( isNotEmpty( helpItems ) ) {
      if ( isNotEmpty( helpItems[helpKey] ) ) {
        setSelectedHelpItem( { [helpKey]: helpItems[helpKey] } );
        setShowHelp( true );
      }
      setShowHelp( true );
    }
  };

  const data = [
    helpItems,
    setHelpItems,
    selectedHelpItem,
    setSelectedHelpItem,
    showHelp,
    setShowHelp,
    showHelpFor,
    getContextualHelpItemsForPage,
    loadHelpDocumentation,
    filePathMap,
  ];

  return (
    <HelpContext.Provider value={ data }>
      { children }
    </HelpContext.Provider>
  );
};
