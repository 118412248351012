/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './NoThirdPartyEmptyState.scss';
import InlineSVG from '../../../shared/InlineSVG';

const NoThirdPartyEmptyState = ( ) => {
  return (
    <div className="noThirdPartyEmptyState">
      <InlineSVG type="notScanned" />
      {/* eslint-disable-next-line max-len */}
      <p>DeepSurface does not have any third party vulnerability scanner information for this item. Either a vulnerability scanner was never set up, or is no longer configured correctly and the last data that DeepSurface received was too old and has been expired. Please visit the <a href="#.=scanning&page=vulnerability_scanners">vulnerability scanners section</a> of the application to configure or fix a vulnerability scanner configuration.</p>
    </div>
  );
};

export default NoThirdPartyEmptyState;