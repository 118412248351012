/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  formatNumber,
  isEmpty,
  isNotEmpty,
  reportTypeDisplayName,
  shortenedVulnerabilityScannerNameMap,
  vulnerabilityScannerLogo,
  vulnerabilityScannerNameMap,
} from '../../../shared/Utilities';
// import InlineSVG from '../../../shared/InlineSVG';
import ScanningStatusIndicator from '../ScanningStatusIndicator';
import ScannerSignatureContent from './SignatureContent';
import InlineSVG from '../../../shared/InlineSVG';

import './ThirdPartyScanning.scss';
import NoThirdPartyEmptyState from './NoThirdPartyEmptyState';

const ThirdPartyScanning = ( {
  record,
  recordType,
  // riskType,
  // recordInstanceData,
  currentScannerSignature,
  // setCurrentScannerSignature,
  thirdPartyData,
  // setThirdPartyData,
  latestThirdParty,
  getScannerSignatureInfoAndSetAsCurrent,
} ) => {

  const [ scannerSignatureOptions, setScannerSignatureOptions ] = React.useState( null );
  const [ uniqueScanners, setUniqueScanners ] = React.useState( null );
  const [ showScannerSignatureOptions, setShowScannerSignatureOptions ] = React.useState( false );

  const handleScannerSignatureSelect = async ( thirdPartyID ) => {
    const selectedThirdParty = thirdPartyData[thirdPartyID];
    if ( isNotEmpty( selectedThirdParty ) ) {
      await getScannerSignatureInfoAndSetAsCurrent( selectedThirdParty );
      setShowScannerSignatureOptions( false );
    }
  };

  React.useEffect( () => {
    const keys = [];
    const options = {};
    if ( isNotEmpty( thirdPartyData ) ) {
      Object.entries( thirdPartyData ).map( ( [ signatureID, t ] ) => {
        options[signatureID] = {
          icon: vulnerabilityScannerLogo( t.scannerKey ),
          label: <span><strong>{ t.label }</strong> {t.signature}</span>,
          timestamp: t.timestamp,
        };

        if ( !keys.includes( t.scannerKey ) ) {
          keys.push( t.scannerKey );
        }
      } );
    }

    if ( isNotEmpty( options ) ) {
      setScannerSignatureOptions( options );
    }
    if ( isNotEmpty( keys ) ) {
      const _unique = [];

      keys.map( k => {
        _unique.push( {
          icon: vulnerabilityScannerLogo( k ),
          label: vulnerabilityScannerNameMap[k],
        } );
      } );

      if ( isNotEmpty( _unique ) ) {
        setUniqueScanners( _unique );
      }
    }
  }, [ thirdPartyData ] );

  return (
    <React.Fragment>
      <div className={ `recordDetails__MainPanel__Body_ThirdPartyScanning ${recordType}` }>
        <div className="recordDetails__MainPanel_BodyRow scanningStatusSummary">
          <h3 className="mainPanel__SectionHeader">Third Party Vulnerability Source Status</h3>
          {
            isEmpty( thirdPartyData ) &&
            <NoThirdPartyEmptyState />
          }
          {
            ( isNotEmpty( thirdPartyData ) && isNotEmpty( latestThirdParty ) && recordType === 'host' ) &&
            <div className="bodySectionItem">
              <label>
                <span>Latest status</span>
                <span>
                  { shortenedVulnerabilityScannerNameMap[latestThirdParty.scannerKey] || 'Third Party' }
                </span>
              </label>
              <ScanningStatusIndicator timestamp={ latestThirdParty.timestamp } version="thirdParty" preformatted />
            </div>
          }
          {
            isNotEmpty( uniqueScanners ) &&
            <div className="bodySectionItem">
              <label>
                <span>Coverage</span>
              </label>
              <span className="bodySectionDescription">
                {/* eslint-disable-next-line max-len*/}
                <strong>{ reportTypeDisplayName( record, recordType ) }</strong> is related to data imported from the following <strong>{ uniqueScanners.length } vulnerability source(s)</strong>.
              </span>
              <ul>
                {
                  uniqueScanners.map( ( s, i ) => {
                    return <li key={i} className="uniqueScanner">
                      { s.icon }
                      <span>{ s.label }</span>
                    </li>;
                  } )
                }
              </ul>
            </div>
          }
          {/* need a big number count or something of related signatures, don't want that to get lost */}

        </div>
        {/* we do not yet have the capability to link scanners to config alerts */}
        {/* <div className="recordDetails__MainPanel_BodyRow relatedConfigAlerts">
        </div> */}
        {
          isNotEmpty( currentScannerSignature ) &&
          <div className="recordDetails__MainPanel_BodyRow currentSignatureDetails">
            <h3 className="mainPanel__SectionHeader">
              <span>Vulnerability Source Output</span>
              {
                ( isNotEmpty( scannerSignatureOptions ) && isNotEmpty( currentScannerSignature ) ) &&
                <div className={ `scannerSignatureSelector ${ showScannerSignatureOptions ? 'open' : ''}` }>
                  <div
                    onClick={ () => setShowScannerSignatureOptions( !showScannerSignatureOptions ) }
                    className={ `scannerSignatureSelectorTrigger ${ showScannerSignatureOptions ? 'open' : ''}`}
                  >
                    {/* <span className="scannerSignatureLogoNameAndTimestamp">
                      <span className="label">
                        { vulnerabilityScannerLogo( currentScannerSignature.record?.scannerKey ) }
                        { currentScannerSignature.record?.label } {currentScannerSignature.record?.signature}
                      </span>
                      <span className="timestamp">{ currentScannerSignature.record?.timestamp }</span>
                    </span> */}
                    <span>
                      {/* eslint-disable-next-line max-len */}
                      View a different scan signature ({ formatNumber( Object.values( scannerSignatureOptions ).length ) })
                    </span>
                    <InlineSVG type="carretDown" />
                  </div>
                  {
                    showScannerSignatureOptions &&
                    <React.Fragment>
                      <div
                        onClick={ () => setShowScannerSignatureOptions( false ) }
                        className={ `scannerSignatureSelectorShade ${ showScannerSignatureOptions ? 'open' : ''}`}
                      />
                      <ul className="scannerSignatureSelectorOptions">
                        {
                          Object.entries( scannerSignatureOptions ).map( ( [ key, value ], index ) => {
                            return <li
                              className="scannerSignatureSelectorOption"
                              key={index}
                              onClick={ () => handleScannerSignatureSelect( key ) }
                            >
                              <span className="label">
                                { value.icon }
                                { value.label }
                              </span>
                              <span className="timestamp">{ value.timestamp }</span>
                            </li>;
                          } )
                        }
                      </ul>
                    </React.Fragment>
                  }
                </div>
              }
            </h3>
            <ScannerSignatureContent scannerSignature={currentScannerSignature } />
          </div>
        }
      </div>
    </React.Fragment>
  );
};

export default ThirdPartyScanning;