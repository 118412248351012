/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { TasksProvider } from '../../Contexts/Tasks';
import RecordItem from './RecordItem';
import {
  getGlobalSettings,
  isNotEmpty,
} from '../Utilities';
import InlineSVG from '../InlineSVG';

import './RecordList.scss';
import { pluralizedRecord } from './shared';
import EmptyLoading from '../EmptyLoading';

const RecordList = ( {
  records,
  onReorder,
  recordType,
  providers,
  credentials,
  setSelectedRecord,
  setShowModal,
  onRefresh,
  recordData,
  isDraggable,
  showRegistrationModal,
  asCard,
  alternateItemLayout,
  loading,
  users,
  globalSettings=null,
  setGlobalSettings=() => {},
  notificationsCallback,
  activeIntegrations,
  externalUsers,
  assetTagOptions={},
  setShowIPModal,
  setShowConnectionModal,
} ) => {

  const [ closedCollapsed, setClosedCollapsed ] = React.useState( true );

  React.useEffect( () => {
    // get the latest global settings
    getGlobalSettings( 'global' ).then( response => {
      setGlobalSettings( response );
    } );
  }, [] );

  const reorder = ( list, startIndex, endIndex ) => {

    const result = Array.from( list );
    const [ removed ] = result.splice( startIndex, 1 );
    result.splice( endIndex, 0, removed );

    onReorder( result );
  };

  // event listener to prevent the window from scrolling, hack to fix a bad architecture
  const fixScroll = yPos => {
    window.scrollTo( 0, yPos );
  };

  // event listener to prevent the window from scrolling, hack to fix a bad architecture
  const onBeforeDragStart = () => {
    const yPos = window.scrollY;
    window.addEventListener( 'scroll', () => fixScroll( yPos ) );
  };

  const onDragEnd = result => {
    // dropped outside the list
    if ( !result.destination ) {
      return;
    }

    reorder(
      records,
      result.source.index,
      result.destination.index,
    );
    window.removeEventListener( 'scroll', fixScroll );
  };

  return (
    <TasksProvider>
      <EmptyLoading
        payload={ records }
        loading={loading}
        loadingMessage={ `Loading ${pluralizedRecord( recordType )}` }
        emptyMessage={ `No ${pluralizedRecord( recordType )}` }
      />
      {
        isNotEmpty( records ) &&
        <React.Fragment>
          <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onBeforeDragStart} >
            <Droppable
              droppableId="droppable"
              isDropDisabled={ !isDraggable }
            >
              {
                ( provided, snapshot ) => (
                  // need to split the list into sections for remediation plans, otherwise, just a flat list
                  recordType === 'remediation_plan'
                    ? <React.Fragment>
                      {
                        Object.entries( records ).map( ( [ statusKey, plans ], index ) => {
                          return  <React.Fragment key={index}>
                            {
                              statusKey === 'closed' &&
                              <div className="listActions">
                                <button
                                  className="listCollapseToggle"
                                  onClick={ () => setClosedCollapsed( !closedCollapsed ) }
                                >
                                  { closedCollapsed ? 'View Closed Plans' : 'Hide Closed Plans' }
                                  {
                                    closedCollapsed
                                      ? <InlineSVG type="carretDown" version="primary" />
                                      : <InlineSVG type="carretUp" version="primary" />
                                  }
                                </button>
                              </div>
                            }
                            <ul
                              // eslint-disable-next-line max-len
                              className={ `setupRecordsList remediationPlans ${statusKey === 'closed' && closedCollapsed ? 'collapsed' : '' }` }
                            >
                              {
                                plans.map( ( record, _index ) => {
                                  return  <RecordItem
                                    key={_index}
                                    index={_index}
                                    record={record}
                                    records={records}
                                    recordType={recordType}
                                    providers={providers}
                                    credentials={credentials}
                                    setSelectedRecord={setSelectedRecord}
                                    setShowModal={setShowModal}
                                    onRefresh={onRefresh}
                                    recordData={recordData}
                                    showRegistrationModal={showRegistrationModal}
                                    asCard={asCard}
                                    alternateItemLayout={alternateItemLayout}
                                    users={users}
                                    globalSettings={globalSettings}
                                    setGlobalSettings={setGlobalSettings}
                                    notificationsCallback={notificationsCallback}
                                    activeIntegrations={activeIntegrations}
                                    externalUsers={externalUsers}
                                    assetTagOptions={assetTagOptions}
                                    isDraggable={isDraggable}
                                    setShowIPModal={setShowIPModal}
                                    setShowConnectionModal={setShowConnectionModal}
                                  />;
                                } )
                              }
                            </ul>
                          </React.Fragment>;
                        } )
                      }
                    </React.Fragment>
                    : <ul
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`setupRecordsList isDraggable ${snapshot.isDraggingOver ? 'isDragging' : ''}`}
                    >
                      {
                        records.map( ( record, _index ) => {
                          return  <RecordItem
                            key={_index}
                            index={_index}
                            record={record}
                            records={records}
                            recordType={recordType}
                            providers={providers}
                            credentials={credentials}
                            setSelectedRecord={setSelectedRecord}
                            setShowModal={setShowModal}
                            onRefresh={onRefresh}
                            recordData={recordData}
                            showRegistrationModal={showRegistrationModal}
                            asCard={asCard}
                            alternateItemLayout={alternateItemLayout}
                            users={users}
                            globalSettings={globalSettings}
                            setGlobalSettings={setGlobalSettings}
                            notificationsCallback={notificationsCallback}
                            activeIntegrations={activeIntegrations}
                            externalUsers={externalUsers}
                            assetTagOptions={assetTagOptions}
                            isDraggable={isDraggable}
                            setShowIPModal={setShowIPModal}
                            setShowConnectionModal={setShowConnectionModal}
                          />;
                        } )
                      }
                      { isDraggable && provided.placeholder }
                    </ul>
                ) }
            </Droppable>
          </DragDropContext>
        </React.Fragment>
      }
    </TasksProvider>
  );
};

export default RecordList;
