/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import Form from '../../../shared/Form';
import Accordion from '../../../shared/Accordion';
import InlineSVG from '../../../shared/InlineSVG';
import { formatNumber, formatUnixDate, isNotEmpty, userDisplayName } from '../../../shared/Utilities';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';
import { getFieldValues } from '../../../shared/Form/Shared';
import { makeRequest } from '../../../../legacy/io';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import Loading from '../../../shared/Loading';

import './AcceptedRiskPlanModal.scss';
import Notification from '../../../shared/Notification';

const AcceptedRiskPlanModal = ( { selectedRecord, showModal, setShowModal, onRefresh } ) => {

  const [ currentUser, , , demoMode ] = React.useContext( CurrentUserContext );
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const [ collapsed, setCollapsed ] = React.useState( false );
  const [ fields, setFields ] = React.useState( null );
  const [ isValid, setIsValid ] = React.useState( false );
  const [ updatedForm, setUpdatedForm ] = React.useState( null );

  const [ itemsHeader, setItemsHeader ] = React.useState( '' );
  const [ selectedTask, setSelectedTask ] = React.useState( null );
  const [ items, setItems ] = React.useState( [] );
  const [ itemsValues, setItemsValues ] = React.useState( {} );
  const [ loading, setLoading ] = React.useState( false );
  const [ shouldShowEmpty, setShouldShowEmpty ] = React.useState( false );

  /* eslint-disable camelcase */
  // Need to update PLAN_TYPES everytime this dict is changed.
  const REASON_OPTIONS = [
    {
      label: 'Not Exploitable',
      options: {
        false_positive: 'False positive',
        mitigated: 'Mitigated',
      },
    },
    {
      label: 'Accepting Risk',
      options: {
        decommissioning: 'Decommissioning',
        disrupts_functionality: 'Disrupts functionality',
      },
    },
  ];

  /* eslint-disable camelcase */
  // conditional reasons for nofix vulns, only allow accepting risk reasons for nofix vulns.
  const reasonOptionsTypeMap = {
    nofix: {
      decommissioning: 'Decommissioning',
      disrupts_functionality: 'Disrupts functionality',
    },
    all: [
      {
        label: 'Not Exploitable',
        options: {
          false_positive: 'False positive',
          mitigated: 'Mitigated',
        },
      },
      {
        label: 'Accepting Risk',
        options: {
          decommissioning: 'Decommissioning',
          disrupts_functionality: 'Disrupts functionality',
        },
      },
    ],
  };

  const PLAN_TYPES = {
    false_positive: 'not_exploitable',
    mitigated: 'not_exploitable',
    decommissioning: 'accepted_risk',
    disrupts_functionality: 'accepted_risk',
  };

  const EMPTY_FIELDS = {
    task: {
      fields: [
        {
          type: 'select',
          attribute: 'task_key',
          label: 'Vulnerability or host?',
          required: true,
          defaultValue: 'vulnerability',
          options: {
            vulnerability: 'Vulnerability',
            host: 'Host',
          },
        },
        {
          includeIf: { attribute: 'task_key', value: 'vulnerability' },
          requiredIf: { attribute: 'task_key', value:  'vulnerability' },
          type: 'searchResults',
          // additionalParams: { gt_map:  { risk_reduction: 0.01 } },
          selectCallback: ( result, field, onChange ) => onTaskSelectCallback( result, field, onChange ),
          label: 'Vulnerability',
          attribute: 'task_vulnerability',
          placeholder: 'Find vulnerability by name...',
          recordType: 'vulnerability',
        },
        {
          includeIf: { attribute: 'task_key', value: 'host' },
          requiredIf: { attribute: 'task_key', value:  'host' },
          type: 'searchResults',
          // additionalParams: { gt_map:  { risk_reduction: 0.01 } },
          selectCallback: ( result, field, onChange ) => onTaskSelectCallback( result, field, onChange ),
          label: 'Host',
          attribute: 'task_host',
          placeholder: 'Find host by name...',
          recordType: 'host',
        },

      ],
    },
    task_vulnerability_selected: {
      fields: [
        {
          includeIf: [
            { attribute: 'task_vulnerability', check: isNotEmpty },
            { attribute: 'task_key', value: 'vulnerability' },
          ],
          requiredIf: [
            { attribute: 'task_vulnerability', check: isNotEmpty },
            { attribute: 'task_key', value: 'vulnerability' },
          ],
          type: 'select',
          attribute: 'reason_vulnerability',
          label: 'Reason:',
          required: true,
          defaultValue: 'false_positive',
          options: {},
          conditionalOptions: { attribute: 'task_vulnerability', options: reasonOptionsTypeMap },
        },
        {
          includeIf: [
            { attribute: 'task_vulnerability', check: isNotEmpty },
            { attribute: 'task_key', value: 'vulnerability' },
          ],
          requiredIf: [
            { attribute: 'task_vulnerability', check: isNotEmpty },
            { attribute: 'task_key', value: 'vulnerability' },
          ],
          type: 'date',
          needsUnixTransform: true,
          label: 'Expiration:',
          attribute: 'expiration_vulnerability',
          required: true,
          // default date is 3 months out ( fun. expects unix timestamp, need to divide by 1000 to make it work )
          defaultValue: ( Date.now() + 7_884_000_000 ) / 1000,
          // cannot be before today or greater than 9 months out.
          // ( fun. expects unix timestamp, need to divide by 1000 to make it work )
          htmlProps: {
            min: formatUnixDate( Date.now() / 1000 ),
            max: formatUnixDate( ( Date.now() + ( 7_884_000_000 * 3 ) ) / 1000 ),
          },
        },
        {
          includeIf: [
            { attribute: 'task_vulnerability', check: isNotEmpty },
            { attribute: 'task_key', value: 'vulnerability' },
          ],
          requiredIf: [
            { attribute: 'task_vulnerability', check: isNotEmpty },
            { attribute: 'task_key', value: 'vulnerability' },
          ],
          type: 'text',
          label: 'Approved by:',
          attribute: 'owner_vulnerability',
          required: true,
          defaultValue: '',
        },
        {
          includeIf: [
            { attribute: 'task_vulnerability', check: isNotEmpty },
            { attribute: 'task_key', value: 'vulnerability' },
          ],
          requiredIf: [
            { attribute: 'task_vulnerability', check: isNotEmpty },
            { attribute: 'task_key', value: 'vulnerability' },
          ],
          type: 'textarea',
          label: 'Notes:',
          attribute: 'description_vulnerability',
          defaultValue: '',
        },
      ],
    },
    task_host_selected: {
      fields: [
        {
          includeIf: [
            { attribute: 'task_host', check: isNotEmpty },
            { attribute: 'task_key', value: 'host' },
          ],
          requiredIf: [
            { attribute: 'task_host', check: isNotEmpty },
            { attribute: 'task_key', value: 'host' },
          ],
          type: 'select',
          attribute: 'reason_host',
          label: 'Reason:',
          required: true,
          defaultValue: 'false_positive',
          options: REASON_OPTIONS,
        },
        {
          includeIf: [
            { attribute: 'task_host', check: isNotEmpty },
            { attribute: 'task_key', value: 'host' },
          ],
          requiredIf: [
            { attribute: 'task_host', check: isNotEmpty },
            { attribute: 'task_key', value: 'host' },
          ],
          type: 'date',
          needsUnixTransform: true,
          label: 'Expiration:',
          attribute: 'expiration_host',
          required: true,
          // default date is 3 months out ( fun. expects unix timestamp, need to divide by 1000 to make it work )
          defaultValue: ( Date.now() + 7_884_000_000 ) / 1000,
          // cannot be before today or greater than 9 months out.
          // ( fun. expects unix timestamp, need to divide by 1000 to make it work )
          htmlProps: {
            min: formatUnixDate( Date.now() / 1000 ),
            max: formatUnixDate( ( Date.now() + ( 7_884_000_000 * 3 ) ) / 1000 ),
          },
        },
        {
          includeIf: [
            { attribute: 'task_host', check: isNotEmpty },
            { attribute: 'task_key', value: 'host' },
          ],
          requiredIf: [
            { attribute: 'task_host', check: isNotEmpty },
            { attribute: 'task_key', value: 'host' },
          ],
          type: 'text',
          label: 'Approved by:',
          attribute: 'owner_host',
          required: true,
          defaultValue: '',
        },
        {
          includeIf: [
            { attribute: 'task_host', check: isNotEmpty },
            { attribute: 'task_key', value: 'host' },
          ],
          requiredIf: [
            { attribute: 'task_host', check: isNotEmpty },
            { attribute: 'task_key', value: 'host' },
          ],
          type: 'textarea',
          label: 'Notes:',
          attribute: 'description_host',
          defaultValue: '',
        },
      ],
    },
  };

  // callback when a vuln. or host is selected from the searchResult field
  const onTaskSelectCallback = ( result, field, onChange ) => {
    setSelectedTask( result );
    const _value = { id: result.id, nofix: result.nofix };
    onChange( field, _value );
  };

  // gets the items (either vulns. or hosts depending on the type)
  const fetchItemsFor = async ( type, taskID ) => {

    if ( isNotEmpty( taskID ) ) {
      const search_ids = [ taskID ];

      const itemsResponse = await makeRequest(
        'ITEMS',
        'model/base/accepted_risk',
        { type: type === 'host' ? 'vulnerabilities' : 'hosts', search_ids },
      );

      if ( isNotEmpty( itemsResponse ) && isNotEmpty( itemsResponse.results ) ) {
        const options = {};

        itemsResponse.results.map( result => {
          // eslint-disable-next-line max-len
          options[type === 'host' ? result.vulnerability_id : result.id] = type === 'host' ? result.identifier : result.local_name;
        } );
        setCollapsed( false );
        setItems( options );
      }
    }
  };

  // callback for checkbox of an additionalItem
  const handleItemValueCheck = item => {
    const _itemsValues = { ...itemsValues };

    const [ id ] = Object.keys( item );
    const [ value ] = Object.values( item );

    // already present, need to remove
    if ( isNotEmpty( _itemsValues[id] ) ) {
      delete _itemsValues[id];
    // not present, need to add
    } else {
      _itemsValues[id] = value;
    }
    setItemsValues( _itemsValues );
  };

  // checks to see if an item should be checked or toggled
  const itemValueIncluded = item => {
    const [ id ] = Object.keys( item );
    return isNotEmpty( itemsValues[id] );
  };

  const isFullyValid = () => isValid && isNotEmpty( itemsValues );

  const getPlanType = ( task_key, hasManyTasks ) => {
    if ( task_key === 'host' ) {
      if ( hasManyTasks ) {
        return 'vulnerability_hosts';
      }
      return 'host_vulnerabilities';
    }

    if ( task_key === 'vulnerability' ) {
      if ( hasManyTasks ) {
        return 'host_vulnerabilities';
      }
      return 'vulnerability_hosts';
    }
  };

  // handler for checking all of the items (only shows up for checkboxes, not radios)
  const handleSelectAllItems = () => {
    // already all selected, need to deselect all
    if ( allSelected() ) {
      setItemsValues( {} );
    // there is at least one not selected, select all
    } else {
      setItemsValues( items );
    }
  };

  // checker to see if all items are selected or not
  const allSelected = () => {
    const _itemsValues = { ...itemsValues };
    if ( isNotEmpty( _itemsValues ) && isNotEmpty( items ) ) {
      return Object.keys( _itemsValues ).length === Object.keys( items ).length;
    }
    return false;
  };

  // on init (or when the current user loads), need to setup the form with the current user name set as the default
  React.useEffect( () => {
    if ( isNotEmpty( currentUser ) && showModal ) {
      // set the default value of the owner field to the current user, this is not a select, unlike in other sections
      // this is a free-form text field, meaning it does not need to be a DS User
      const _fields = EMPTY_FIELDS;
      const vulnOwner = _fields.task_vulnerability_selected.fields.find( f => f.attribute === 'owner_vulnerability' );
      const hostOwner = _fields.task_host_selected.fields.find( f => f.attribute === 'owner_host' );
      vulnOwner.defaultValue = userDisplayName( currentUser );
      hostOwner.defaultValue = userDisplayName( currentUser );

      // sets the fields
      setFields( _fields );
    } else {
      setFields( null );
    }
  }, [ currentUser, showModal ] );

  // when the modal closes, clear it all out
  React.useEffect( () => {
    if ( !showModal ) {
      setFields( null );
      setUpdatedForm( null );
      setSelectedTask( null );
      setItems( [] );
      setItemsValues( {} );
    }
  }, [ showModal ] );

  // whenever the form changes ( really just looking at the host or vuln. fields ), need to fetch and populate the items
  React.useEffect( () => {
    if ( isNotEmpty( updatedForm ) && isNotEmpty( updatedForm.fieldStates ) ) {
      const values = getFieldValues( updatedForm.fieldStates, 'accepted_risk_plan' );
      setShouldShowEmpty( false );
      if ( isNotEmpty( values ) && isNotEmpty( values.task_key ) ) {
        // a single vuln. if one is selected, fill out the hosts as the items for that vuln.
        if ( values.task_key === 'vulnerability' ) {
          setItemsHeader( 'Include the following hosts' );
          if ( isNotEmpty( values.task_vulnerability ) ) {
            setShouldShowEmpty( true );
            fetchItemsFor( values.task_key, values.task_vulnerability?.id );
          } else {
            setShouldShowEmpty( false );
            setItems( null );
          }
        // a single host. if one is selected, fill out the vulns. as the items for that host
        } else if ( values.task_key === 'host' ) {
          setItemsHeader( 'Include the following vulnerabilities' );
          if ( isNotEmpty( values.task_host ) ) {
            setShouldShowEmpty( true );
            fetchItemsFor( values.task_key, values.task_host?.id );
          } else {
            setShouldShowEmpty( false );
            setItems( null );
          }
        }
      } else {
        setShouldShowEmpty( false );
        setItems( null );
      }
    } else {
      setItems( null );
      setShouldShowEmpty( false );
    }
  }, [ updatedForm ] );

  const onSave = async() => {

    const record = {};

    setLoading( true );

    if ( isNotEmpty( updatedForm ) && isNotEmpty( updatedForm.fieldStates ) ) {
      const values = getFieldValues( updatedForm.fieldStates, 'accepted_risk_plan' );
      let items = [];
      if ( isNotEmpty( itemsValues ) ) {
        items = Object.keys( itemsValues );
      }

      if ( isNotEmpty( values ) && isNotEmpty( items ) ) {
        // add in all the relavent form values
        Object.entries( values ).map( ( [ attr, val ] ) => {
          if ( attr === 'expiration_vulnerability' || attr === 'expiration_host' ) {
            record.expiration = Math.floor( new Date( val ).getTime() / 1000 );
          } else if ( attr === 'description_vulnerability' || attr === 'description_host' ) {
            record.description = val;
          } else if ( attr === 'owner_vulnerability' || attr === 'owner_host' ) {
            record.owner = val;
          } else if ( attr === 'reason_vulnerability' || attr === 'reason_host' ) {
            record.reason = val;
          }
        } );

        const reason = values.reason_vulnerability || values.reason_host;

        // the type, task, and tasks are all derived based on wether this is a single or multiple relationship
        // the naming conventions match the conventions of a remediation plan task.
        /* eslint-disable camelcase */
        record.type = getPlanType( values.task_key );
        record.object_id = selectedTask.id;
        // convenience label so another lookup is not needed to retrieve this for display later
        record.object_label = selectedTask.local_name || selectedTask.label || selectedTask.identifier;
        record.item_ids = items;
        record.plan_type = PLAN_TYPES[reason];
        /* eslint-enable camelcase */

        if ( isNotEmpty( record ) ) {

          const response = await makeRequest( 'UPSERT', 'model/base/accepted_risk', { records: [ record ] } );

          if ( isNotEmpty( response ) ) {
            // success
            if ( isNotEmpty( response.results ) ) {
              setLoading( false );
              addFlashMessage( {
                type: 'success',
                body: <React.Fragment>
                  Successfully created accepted risk plan. Some items in the plan may need to have a full analysis run
                  in order to properly display in the application. To run a full analysis go to
                  the <a target="_blank" href="#.=activity&page=tasks">{ 'Activity > Tasks' }</a> page and run
                  the "Risk Analysis and Prioritization" task.
                </React.Fragment>,
              } );
              setShowModal( false );
              onRefresh();
            // errors
            } else if ( isNotEmpty( response.errors ) ) {
              setLoading( false );
              response.errors.map( e => {
                addFlashMessage( {
                  type: 'alert',
                  body: e,
                } );
              } );
            // unkown
            } else {
              setLoading( false );
              addFlashMessage( {
                type: 'alert',
                body: 'There was an error creating the accepted risk plan.',
              } );
            }
          // likely 500
          } else {
            setLoading( false );
            addFlashMessage( {
              type: 'alert',
              body: 'There was an error creating the accepted risk plan.',
            } );
          }
        }
      }
    }
  };

  return (
    <React.Fragment>
      { loading && <Loading /> }
      {
        isNotEmpty( fields ) &&
        <React.Fragment>
          <Form
            fields={fields}
            trackUpdates={false}
            existingRecord={selectedRecord}
            onChangeCallback={ setUpdatedForm }
            recordType="accepted_risk_plan"
            setIsValid={setIsValid}
          />
          {
            isNotEmpty( items )
              ? <div className="itemsContainer">
                <Accordion>
                  <div
                    className={`${collapsed ? 'collapsed' : ''} accordionWrapper alternate`}
                  >
                    <div
                      className="accordionHeader"
                      onClick={ () => setCollapsed( !collapsed ) }
                    >
                      <h3>
                        <span>{ itemsHeader }</span>
                        <span className="itemsCount">
                          ({ formatNumber( Object.values( itemsValues ).length ) } Selected)
                        </span>
                      </h3>
                      <button
                        onClick={ () => setCollapsed( !collapsed ) }
                      >
                        <InlineSVG type="carretUp" />
                      </button>
                    </div>
                    <div className="accordionBody">
                      {
                        isNotEmpty( items ) &&
                        <React.Fragment>
                          <button
                            className="selectAllButton"
                            onClick={ handleSelectAllItems }
                          >
                            { allSelected() ? <InlineSVG type="remove" /> : <InlineSVG type="checkbox" /> }
                            Select all
                          </button>
                          {
                            Object.entries( items ).map( ( [ id, label ], index ) => {
                              return <label key={index} >
                                <div
                                  // eslint-disable-next-line max-len
                                  className={ `checkboxFieldWrapper ${ itemValueIncluded( { [id]: label } ) ? 'checked' : ''}` }
                                >
                                  <input
                                    type="checkbox"
                                    onChange={ () => handleItemValueCheck( { [id]: label } ) }
                                    checked={ itemValueIncluded( { [id]: label } ) }
                                  />
                                </div>
                                <span className="labelWrapper">{ label }</span>
                              </label>;
                            } )
                          }
                        </React.Fragment>
                      }
                    </div>
                  </div>
                </Accordion>
              </div>
              : <React.Fragment>
                {
                  shouldShowEmpty
                  && <Notification
                    options={ {
                      type: 'alert',
                      message: 'This plan cannot be created because there are no items available to accept',
                    } }
                  />
                }
              </React.Fragment>
          }
          <div className="modalActions">
            <button
              className={ `${ ( !isFullyValid() || demoMode ) ? 'disabled' : '' } submitButton` }
              disabled={ !isFullyValid() || demoMode  }
              onClick={ onSave }
            >
              Save plan
            </button>
            <button className="cancelButton" onClick={ () => setShowModal( false ) }>
              <span>Cancel</span>
            </button>
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default AcceptedRiskPlanModal;