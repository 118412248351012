/* eslint-disable camelcase */
// VERY IMPORTANT!
// the canonical source for the entire help content structure is below
// top level keys correspond to the main sections, as of now, User Guide, and Reference Guid
// each top level main section has an object that represents each section of within
// each section has a key that corresponds ( in the case of the user guide ), to the underscored url name in the app
// within a section you have:
// [section name]: {
//   label: 'Some Label',
//   icon: 'some_icon_key',
//   pages: {
//     page_key: {
//       label: 'Some Page Label',
//       items: [
//         'contextual_help_item_one'
//         ...
//       ]
//     },
//     ...
//   }
// }
//
// TO ADD A NEW PAGE TO A SECTION:
//
// STEP 1: First add a new page_key to the pages object with a label and any contextual help items in an items array
// STEP 2: Create a directory within the parent section (ie 'setup') with the same name as the page_key
// STEP 3: Create a content.md file within that new directory, does not need any content.
// STEP 4: (User Guide Only) Create a new directory within the page directory called items and make an .md file
// with the same name as each of the keys supplied in the items array.
//
// TO ADD A NEW CONTEXTUAL HELP ITEM TO AN EXISTING PAGE:
//
// STEP 1: Find the existing page within this object and add a new key to the items array that will be the name
// of the .md file for the contextual help item
// STEP 2: Find the existing directory for the page and add a new .md file with the same name as the key added
// to the items array and add content there. Refer to existing files for formatting.
//
export const helpContentMap = {
  user_guide: {
    reporting: {
      label: 'Reporting',
      icon: 'reporting',
      pages: {
        reporting_dashboard: {
          label: 'Dashboard',
          items: [
            'total_instances',
            'exploit_status',
            // 'include_missing_hosts',

            // report creator
            'name',
            'owner',
            'format',
            'schedule',
            'expires',
            'email_recipients',
            'include_all_results',

            // categories
            'deprioritized',
            'dos_only',
            'not_exploitable',
            'overridden',

            'for_review',
            'unrecognized',
            'missing_host',
            'unsupported',
            'cannot_model',
            'insufficient_information',
            'missing_capability',

            'prioritized',
            'unreachable',
            'carries_risk',
          ],
        },
        exports: {
          label: 'Exports',
          items: [],
        },
      },
    },
    risk_insight: {
      label: 'Risk Insight',
      icon: 'insight',
      pages: {
        hosts: {
          label: 'Hosts',
          items: [
            // unique to hosts
            'sensitive_assets',

            // shared by all reports
            'exploit_status',
            'keywords',
            'tag',
            'item_count',
            'include_accepted_risk_items',
            'specific_host',
            'specific_patch',
            'specific_vulnerability',
            'specific_scanner_signature',
            'category',
            'host_wildcard',
            'patch_wildcard',
            'vulnerability_wildcard',
            'vulnerability_scanners',
            'host_os_name',
            'host_os_type',
            'host_os_architecture',
            'host_os_vendor',

            // instance categories
            'deprioritized',
            'for_review',
            'carries_risk',

            // report creator
            'name',
            'owner',
            'format',
            'schedule',
            'expires',
            'email_recipients',
            'include_all_results',
          ],
        },
        patches: {
          label: 'Patches',
          items: [
            // unique to patches
            'risk_type',
            'published_date',
            'superseded',

            // shared by all reports
            'exploit_status',
            'keywords',
            'tag',
            'item_count',
            'include_accepted_risk_items',
            'specific_host',
            'specific_patch',
            'specific_vulnerability',
            'specific_scanner_signature',
            'category',
            'host_wildcard',
            'patch_wildcard',
            'vulnerability_wildcard',
            'vulnerability_scanners',
            'host_os_name',
            'host_os_type',
            'host_os_architecture',
            'host_os_vendor',

            // instance categories
            'deprioritized',
            'for_review',
            'carries_risk',

            // report creator
            'name',
            'owner',
            'format',
            'schedule',
            'expires',
            'email_recipients',
            'include_all_results',
          ],
        },
        vulnerabilities: {
          label: 'Vulnerabilities',
          items: [
            // unique to vulns
            'published_date',
            'patchable',

            // shared by all reports
            'exploit_status',
            'keywords',
            'tag',
            'item_count',
            'specific_host',
            'specific_patch',
            'specific_vulnerability',
            'specific_scanner_signature',
            'category',
            'host_wildcard',
            'patch_wildcard',
            'vulnerability_wildcard',
            'vulnerability_scanners',
            'include_accepted_risk_items',
            'host_os_name',
            'host_os_type',
            'host_os_architecture',
            'host_os_vendor',

            // instance categories
            'deprioritized',
            'for_review',
            'carries_risk',

            // report creator
            'name',
            'owner',
            'format',
            'schedule',
            'expires',
            'email_recipients',
            'include_all_results',
          ],
        },
        instances: {
          label: 'Vulnerability Instances',
          items: [
            // filters
            'specific_host',
            'specific_patch',
            'specific_vulnerability',
            'specific_scanner_signature',
            'category',
            'tag',
            'host_wildcard',
            'patch_wildcard',
            'vulnerability_wildcard',
            'exploit_status',
            'vulnerability_scanners',
            'include_accepted_risk_items',
            'host_os_name',
            'host_os_type',
            'host_os_architecture',
            'host_os_vendor',

            // report creator
            'name',
            'owner',
            'format',
            'schedule',
            'expires',
            'email_recipients',
            'include_all_results',

            // categories
            'deprioritized',
            'dos_only',
            'not_exploitable',
            'overridden',

            'for_review',
            'unrecognized',
            'missing_host',
            'unsupported',
            'cannot_model',
            'insufficient_information',
            'missing_capability',

            'prioritized',
            'unreachable',
            'carries_risk',
          ],
        },
        users: {
          label: 'Users',
          items: [

            'exploit_status',

            // report creator
            'name',
            'owner',
            'format',
            'schedule',
            'expires',
            'email_recipients',
            'include_all_results',
          ],
        },
      },
    },
    remediation: {
      label: 'Remediation',
      icon: 'remediation',
      pages: {
        remediation_plans: { label: 'Plans', items: [] },
        remediation_settings: {
          label: 'Settings',
          items: [
            'automatic_task_closing',
          ],
        },
        remediation_ticketing: {
          label: 'Integrations',
          items: [
            'tool',

            'label',

            'jira_domain',
            'jira_username',
            'jira_api_token',
            'jira_project',

            'destination_email_address',
            'subject_prefix',

            'tanium_server',
            'tanium_token',
          ],
        },
        remediation_workflow: { label: 'Workflow', items: [] },
        remediation_exporting: { label: 'Exporting', items: [] },
        accepted_risk_plans: { label: 'Accepted Risk Plans', items: [] },
        accepted_risk_workflow: { label: 'Accepted Risk Workflow', items: [] },
      },
    },
    explore: {
      label: 'Explore',
      icon: 'explore',
      pages: {
        explore_model: {
          label: 'Model',
          items: [
            'node_label',
            'node_scope',
            'node_type',
            'node_description',
            'node_impact',
            'scope_label',
            'parent_scope',
            'risk_level',
            'scope_type',
            'vulnerability_fields',
            'vulnerability_description',
            'vulnerability_references',
            'remediation_effort',
            'likelihood_metrics',
            'user_interaction',
            'disruption',
            'exploit_development',
            'identification',
            'certainty',
            'completeness',
          ],
        },
        paths: {
          label: 'Paths',
          items: [
            'exploit_status',

            // instance categories
            'deprioritized',
            'for_review',
            'carries_risk',
          ],
        },
      },
    },
    activity: {
      label: 'Activity',
      icon: 'activity',
      pages: {
        tasks: {
          label: 'Tasks',
          items: [
            'feed',
            'agentless_scan',
            'process_queue',
            'import',
            'analysis',
            'upgrade',
          ],
        },
        configuration_alerts: {
          label: 'Configuration Alerts',
          items: [
            'configuration_alerts',
          ],
        },
        scan_logs: { label: 'Scan Logs', items: [] },
        notification_settings: {
          label: 'Notification Settings',
          items: [],
        },
      },
    },
    scanning: {
      label: 'Scanning',
      icon: 'scanning',
      pages: {
        scanning_dashboard: {
          label: 'Status',
          items: [
            'scanning_mode',
            'host_wildcard',
            'agent_version',
            'enter_ip_manually',
            'ip_address',
            'scan_groups',
            'tag',
          ],
        },
        agents: { label: 'Agents', items: [] },
        user_managed: { label: 'User Managed', items: [] },
        agentless_settings: {
          label: 'Credentialed Scanning Settings',
          items: [
            'scan_group_default_settings',
            'avoid_rescan_period',
            'maximum_duration',
            'scan_workers',
            'scan_domains',
            'mssql_grant_privileges',
            'winrm_allow_http',

            'scan_max_workers',
            'scan_hard_stop_time',
          ],
        },
        credentials: {
          label: 'Credentials',
          items: [
            'pam_type',

            'username',
            'password',
            'ports',
            'domain',

            'laps_username',
            'laps_password',
            'laps_local_user',

            'delinea_api_username',
            'delinea_api_password',
            'url',

            'target_domain',
            'target_username',
            'secret_name',
            'tofu',

            'cyberark_url',
            'cyberark_safe_name',
            'cyberark_target_username',
            'cyberark_host_username',
            'cyberark_target_domain',
            'cyberark_api_username',
            'cyberark_api_password',

            'pam_client_cert',
            'pam_client_key',
            'cyberark_require_ip_match',
          ],
        },
        scan_groups: {
          label: 'Scan Groups',
          items: [
            'scan_groups',
            'label',
            'ip_address',
            'enter_ip_manually',
            'subordinate_scanner',
            'included_ranges',
            'excluded_ranges',
            'schedule',
            'credentials',
            'scan_default_overrides',
            'winrm_allow_http',
            'scan_domains',
            'mssql_grant_privileges',
            'scan_workers',
            'maximum_duration',
            'avoid_rescan_period',
          ],
        },
        scanning_general_settings: {
          label: 'General Settings',
          items: [
            'windows_domains',
            'third_party_ignore_age',
          ],
        },
        cloud_scanning: {
          label: 'Cloud Scanning',
          items: [ 'use_outbound_proxy' ],
        },
        connectivity_settings: {
          label: 'Network Connectivity',
          items: [
            'connectivity_max_workers',
            'connectivity_port_delay',
            'connectivity_port_timeout',
            'connectivity_ports_per_client',
          ],
        },
        subordinates: { label: 'Subordinates', items: [] },
        vulnerability_scanners: {
          label: 'Vulnerability Scanners',
          items: [
            'vulnerability_scanners',
            'qualys_api',
            'nessus_pro',
            'tenable_io',
            'tenable_sc',
            'rapid7_insight_vm',
            'microsoft_defender_for_endpoint',
            'use_outbound_proxy',
            'trust_on_first_use',
            'include_wazuh_host',
          ],
        },
      },
    },
    setup: {
      label: 'Setup',
      icon: 'setup',
      pages: {
        sensitive_assets_policies: {
          label: 'Sensitive Assets: Polices',
          items: [],
        },
        sensitive_assets_manual: {
          label: 'Sensitive Assets: Manual Assignment',
          items: [
            'sensitive_assets',
            'type',
          ],
        },
        admin: {
          label: 'Admin Settings',
          items: [
            'analysis_max_workers',
            'analysis_path_tail_ratio',
            'analysis_target_paths',
            'risk_target',
            'admin_session_active_timeout',
            'admin_session_inactive_timeout',
            'send_telemetry',
            'update_feed_time',
            'speculative_hijacking',
          ],
        },
        smtp: {
          label: 'SMTP Settings',
          items: [
            'smtp_hostname',
            'smtp_port',
            'smtp_sender',
            'smtp_tofu',
            'smtp_enable_authentication',
            'test_smtp_settings',
          ],
        },
        certificates: {
          label: 'Certificates',
          items: [
            'identity',
            'purpose',
            'certificate',
          ],
        },
        outbound_proxy: {
          label: 'Outbound Proxy',
          items: [
            'enable_outbound_proxy',
            'protocol',
            'proxy_server',
            'port',
            'proxy_username',
            'proxy_password',
          ],
        },
        providers: {
          label: 'Authentication Providers',
          items: [
            'autoprovision_authentication_provider',
            'provider_type',
            'name',
            'servers',
            'encryption_protocol',
            'acs_url',
            'metadata_xml',
            'port_number',
            'windows_domain',
            'trust_on_first_use',
          ],
        },
        users: { label: 'Users', items: [ 'api_credentials' ] },
        tags: {
          label: 'Tags',
          items: [
            'filter_options',
            'name',
            'owner',
            'specific_hosts',
            'excluded_specific_hosts',
            'ip_ranges',
            'excluded_ip_ranges',
            'host_wildcards',
            'excluded_host_wildcards',
          ],
        },
      },
    },
  },
  reference_guide: {
    providers: {
      label: 'Authentication Providers',
      icon: 'authentication_providers',
      pages: {
        ldap_auth: 'LDAP (Active Directory)',
        saml_azure: 'SAML (Azure Active Directory)',
        saml_google: 'SAML (Google)',
        saml_okta: 'SAML (Okta)',
      },
    },
    pam: {
      label: 'PAM',
      icon: 'pam',
      pages: {
        cyberark: 'CyberArk',
        delinea: 'Delinea (Thycotic)',
        laps: 'Microsoft LAPS',
      },
    },
    vulnerability_scanners: {
      label: 'Vulnerability Sources',
      icon: 'vulnerability_scanners',
      pages: {
        cbc: 'Carbon Black Cloud',
        msde: 'Microsoft Defender for Endpoint',
        nessus: 'Nessus',
        qualys: 'Qualys',
        rapid7: 'Rapid7 InsightVM',
        tenableio: 'Tenable.io',
        tenablesc: 'Tenable.sc',
        crowdstrike: 'CrowdStrike Spotlight',
        lansweeper: 'Lansweeper Cloud',
        sentinelone: 'SentinelOne',
        tanium: 'Tanium',
      },

    },
    virtual_machines: {
      label: 'Virtual Machines',
      icon: 'virtual_machines',
      pages: {
        configuration: 'Configuration',
        vm_ware: 'VMWare',
        aws_ec2_byol: 'AWS EC2 (BYOL)',
        aws_ec2_usage_based: 'AWS EC2 (Usage Based)',
        virtual_box: 'Virtual Box',
        vbox_guest_additions: 'VirtualBox Guest Additions',
        ova_first_login: 'OVA First Login',
      },
    },
    deployment_options: {
      label: 'Deployment',
      icon: 'deployments',
      pages: {
        user_managed: 'User Managed Scan Deployments',
        agentless: 'Credentialed Scanning Deployments',
        agent: 'Agent-Based Deployments',
        mixed: 'Mixed Environment',
        requirements: 'Installation and Configuration Requirements',
        package_installation: 'Package Installation',
        mecm: 'Microsoft Endpoint Configuration Manager',
      },
    },
    other_reference_items: {
      label: 'Other Items',
      icon: 'other_reference_items',
      pages: {
        reference_certificates: 'Certificates',
        ldap: 'LDAP',
        tofu: 'TOFU',
        clock_sync: 'Clock Sync',
        ds_commands: 'DeepSurface Commands',
      },
    },
  },
  security_guide: {
    firewall_config: {
      label: 'Firewall Configuration',
      icon: 'pam',
      pages: {
        vm_network_requirements: 'Base Network Requirements',
        agent_network_requirements: 'Agent Network Requirements',
        agentless_network_requirements: 'Credentialed Scanning Network Requirements',
        api_network_requirements: 'API Network Requirements',
      },
    },
    how_scans_work: {
      label: 'How DeepSurface Scans Work',
      icon: 'pam',
      pages: {
        domain_scans: 'Domain (LDAP) Scanning',
        host_scans: 'Host Scanning Routine',
        access_reasons: 'Reasons for the Administrative Access Requirement',
        endpoint_protection: 'Endpoint Protection Considerations',
      },
    },
    other_security_items: {
      label: 'Other Items',
      icon: 'pam',
      pages: {
        data_retention: 'Scope of Data Storage and Retention',
        ips: 'IPS/IDS Considerations',
        logging: 'Logging',
      },
    },
  },
};
