/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import InlineSVG from '../../../../shared/InlineSVG';

import {
  isNotEmpty,
} from '../../../../shared/Utilities';
import { CurrentUserContext } from '../../../../Contexts/CurrentUser';

const DownloadCertificate = ( {
  certificate,
} ) => {

  const [ downloadOptions, setDownloadOptions ] = React.useState( {} );

  const [ , , , demoMode ] = React.useContext( CurrentUserContext );

  React.useEffect( () => {
    const filename = 'cert_' + certificate['id'] + ( {
      'x509' : '.crt',
      'ssh' : '.pub',
    }[certificate['type']] || '.txt' );

    const mime = {
      'x509' : 'application/x-x509-user-cert',
      'ssh' : 'text/plain',
    }[certificate['type']] || 'text/plain';

    const blob = new Blob( [ certificate.key ], {type: mime} );

    const href = window.URL.createObjectURL( blob );

    setDownloadOptions( {
      filename: filename,
      href: href,
    } );

  }, [] );

  return (
    <React.Fragment>
      {
        isNotEmpty( downloadOptions ) &&
        <a
          href={downloadOptions.href}
          download={downloadOptions.filename}
          className={ `recordItemAction ${demoMode ? 'disabled' : ''}` }
          disabled={ demoMode }
          title="Download Certificate"
        >
          <InlineSVG type="download" />
        </a>
      }
    </React.Fragment>
  );
};

export default DownloadCertificate;
