/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../shared/Utilities';
import NotScannedEmptyState from '../Host/NotScannedEmptyState';
import ScanningStatusIndicator from '../ScanningStatusIndicator';

import './DeepSurfaceScanning.scss';
import ScanLogViewer from '../../Activity/ScanLogs/ScanLogViewer';

const DeepSurfaceScanning = ( { record } ) => {
  return (
    <React.Fragment>
      <div className="recordDetails__MainPanel__Body_DeepSurfaceScanning">
        <div className="recordDetails__MainPanel_BodyRow scanningStatusSummary">
          <h3 className="mainPanel__SectionHeader">DeepSurface Scanner Status</h3>
          <div className="bodySectionItem">
            <label>
              <span>Latest scan status</span>
            </label>
            <ScanningStatusIndicator timestamp={ record?.last_scanned } />
          </div>
        </div>
        {
          ( isNotEmpty( record?.last_scanned ) && isNotEmpty( record?.last_scanned_address ) )
            ? <div className="recordDetails__MainPanel_BodyRow latestScanLog">
              <h3 className="mainPanel__SectionHeader">
                <span>Latest Scan Log</span>
              </h3>
              <ScanLogViewer logIdentifier={ record?.last_scanned_address } />
            </div>
            : <NotScannedEmptyState />
        }
      </div>
    </React.Fragment>
  );
};

export default DeepSurfaceScanning;