/** *************************************************************
* Copyright (C) 2016-2022 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../shared/Utilities';
import { Remarkable } from 'remarkable';
import SectionContent from './SectionContent';

const MainGuideContent = ( {
  mainKey,
  sections,
  toggleItemsFor,
  itemsAreCollapsed,
  deepLinkRef,
  selectedParams,
  printSelection,
  setPrintSelection,
  getPrintLabel,
} ) => {

  const [ deepLinkLevel, setDeepLinkLevel ] = React.useState( null );

  React.useEffect( ( ) => {
    // we have been deeplinked here and it is for this main section
    if (
      isNotEmpty( selectedParams )
      && isNotEmpty( selectedParams.main )
      && selectedParams.main === mainKey
    ) {
      // we need to go to a specific page
      if ( isNotEmpty( selectedParams.help_page ) && isNotEmpty( selectedParams.section ) ) {
        setDeepLinkLevel( 'help_page' );
      // we need to go to a specific section
      } else if ( isNotEmpty( selectedParams.section ) ) {
        setDeepLinkLevel( 'section' );
      } else {
        setDeepLinkLevel( 'main' );
      }
    }
  }, [ selectedParams, mainKey ] );

  const md = new Remarkable( {
    html: true,
  } );

  const getRef = ( context, contextKey ) => {
    if (
      context === deepLinkLevel
      && selectedParams[context] === contextKey
    ) {
      return deepLinkRef;
    }
  };

  return (
    // eslint-disable-next-line max-len
    <div id={ `mainHelpSection-${mainKey}` } className={ `helpMainWrapper ${ ( isNotEmpty( printSelection ) && printSelection.mainKey === mainKey ) ? 'forPrinting' : ''}`}>
      {
        isNotEmpty( sections ) &&
        sections.map( ( section, index ) => {

          // corresponds to each top-level section of the appliance ui (left nav items)
          return <SectionContent
            section={section}
            mainKey={mainKey}
            md={md}
            getRef={getRef}
            toggleItemsFor={toggleItemsFor}
            itemsAreCollapsed={itemsAreCollapsed}
            key={index}
            setPrintSelection={setPrintSelection}
            printSelection={printSelection}
            getPrintLabel={getPrintLabel}
          />;
        } )
      }
    </div>
  );
};

export default MainGuideContent;