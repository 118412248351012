/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';

import { v4 as uuidv4 } from 'uuid';

export const FlashMessageQueueContext = React.createContext();

export const FlashMessageQueueProvider = ( { children } ) => {

  const [ currentFlashMessages, setCurrentFlashMessages ] = React.useState( new Map() );

  // called from any child to add an item to the flashMessage queue
  const addFlashMessage = ( { type, body, duration=window.DEFAULT_FLASH_MESSAGE_DURATION } ) => {

    const copy = new Map( window.flashMessageQueue );

    copy.set( uuidv4(), { type, body, duration } );

    if ( copy.size >= window.FLASH_MESSAGE_CAPACITY ) {
      const [ firstKey ] = copy.keys();
      copy.delete( firstKey );
    }

    window.flashMessageQueue = new Map( copy );
    setCurrentFlashMessages( copy );
  };

  // called from a flashMessage to remove itself from the queue early
  const removeFlashMessage = nKey => {

    const copy = new Map( window.flashMessageQueue );

    copy.delete( nKey );

    window.flashMessageQueue = new Map( copy );
    setCurrentFlashMessages( copy );
  };

  const data = [
    addFlashMessage,
    removeFlashMessage,
    currentFlashMessages,
  ];

  return (
    <FlashMessageQueueContext.Provider value={ data }>
      { children }
    </FlashMessageQueueContext.Provider>
  );
};
