/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import SetupForm from '../../../shared/SetupComponents/SetupForm';
import Form from '../../../shared/Form';
import { getGlobalSettings, isNotEmpty, updateGlobalSettings } from '../../../shared/Utilities';
import { getFieldValues } from '../../../shared/Form/Shared';
import { HelpTrigger } from '../../../components/HelpDocumentation/ContextualHelp/index.js';

import './style.scss';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import { CurrentUserContext } from '../../../Contexts/CurrentUser.js';

const RemediationSettings = ( ) => {

  let isMounted = true;

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ , , , demoMode ] = React.useContext( CurrentUserContext );

  const [ fields, setFields ] = React.useState( null );
  const [ record, setRecord ] = React.useState( null );
  const [ isValid, setIsValid ] = React.useState( true );
  const [ updatedForm, setUpdatedForm ] = React.useState( null );

  const [ hasSetFields, setHasSetFields ] = React.useState( false );

  const EMPTY_FIELDS = {
    // eslint-disable-next-line max-len, camelcase
    task_closing: {
      header: 'Automatic Task Closing',
      help: <HelpTrigger helpKey="automatic_task_closing" />,
      fields: [
        {
          type: 'number',
          attribute: 'remediation_task_instance_threshold',
          label: 'Instance Threshold',
          defaultValue: 100,
          htmlProps: { max: 100, min: 1, step: 1 },
          required: true,
        },
        {
          type: 'number',
          attribute: 'remediation_task_risk_threshold',
          label: 'Risk Threshold',
          defaultValue: 95,
          htmlProps: { max: 100, min: 1, step: 1 },
          required: true,
        },
      ],
    },
  };

  React.useEffect( () => {
    const parentEl = document.getElementById( 'pageContent' );

    parentEl.onleave = async () => {
      if ( isValid && isMounted && !demoMode ) {
        onSave( false );
        return true;
      }
    };

    setHasSetFields( true );

    // one time init
    if ( !hasSetFields ) {
      onRefresh( );
      setFields( EMPTY_FIELDS );
    }

    return () => {
      isMounted = false;
      if ( parentEl ) {
        parentEl.onleave = null;
      }
    };
  }, [ ] );

  const onRefresh = async( ) => {

    const _record = await getGlobalSettings( 'global' );

    setRecord( {
      /* eslint-disable camelcase */
      remediation_task_instance_threshold: _record.remediation_task_instance_threshold,
      remediation_task_risk_threshold: _record.remediation_task_risk_threshold,
      /* eslint-enable camelcase */
    } );
  };

  const onRevert = () => {
    onRefresh();
  };

  const onSave = async ( shouldRefresh=true ) => {

    if ( isValid && isNotEmpty( updatedForm ) ) {
      const includedValues = getFieldValues( updatedForm.fieldStates, 'remediation_settings' );

      const globalRecord = {};

      if ( isNotEmpty( includedValues ) ) {
        Object.keys( includedValues ).map( attr => {
          globalRecord[attr] = includedValues[attr];
        } );
      }
      const response = await updateGlobalSettings( 'global', globalRecord );

      if ( response && isNotEmpty( response.results ) ) {

        if ( shouldRefresh ) {
          addFlashMessage( {
            type: 'success',
            body: 'Successfully saved settings.',
          } );

          onRefresh( );
        }

      } else if ( shouldRefresh ) {
        addFlashMessage( {
          type: 'alert',
          body: 'Error saving settings.',
        } );
      }
    }
  };

  return (
    <div className="react-remediationSettings-container">
      <SetupForm>
        {
          ( isNotEmpty( fields ) && isNotEmpty( record ) ) &&
          <Form
            fields={ fields }
            existingRecord={ record }
            recordType={ 'remediation_settings' }
            setIsValid={ setIsValid }
            onChangeCallback={setUpdatedForm}
          />
        }
        <div className="formActionsContainer">
          <div className="formActions">
            <button
              className="revertButton"
              onClick={ onRevert }
            >
              Revert
            </button>
            <button
              disabled={ !isValid || demoMode }
              onClick={ onSave }
            >
              Save
            </button>
          </div>
        </div>
      </SetupForm>
    </div>
  );
};

export default RemediationSettings;