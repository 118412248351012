/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import Accordion from '../../shared/Accordion';
import InlineSVG from '../../shared/InlineSVG';
import { isNotEmpty } from '../../shared/Utilities';
import { helpContentMap } from './Shared';

import React from 'react';
import PrintButton from './PrintButton';
import ItemContent from './ItemContent';

const PageContent = ( {
  mainKey,
  page,
  section,
  md,
  getRef,
  toggleItemsFor,
  itemsAreCollapsed,
  setPrintSelection,
  printSelection,
  getPrintLabel,
} ) => {

  const getHelpPageLabel = () => {
    if ( mainKey === 'user_guide' ) {
      return helpContentMap[mainKey][section.sectionKey].pages[page.pageKey].label;
    }
    return helpContentMap[mainKey][section.sectionKey].pages[page.pageKey];
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( page ) &&
        <div
          id={ `${mainKey}-${section.sectionKey}-${page.pageKey}` }
          // eslint-disable-next-line max-len
          className={ `${ ( isNotEmpty( printSelection ) && printSelection.pageKeys?.includes( page.pageKey ) ) ? 'forPrinting' : ''} helpPageWrapper` }
          ref={ getRef( 'help_page', page.pageKey ) }
        >
          <span className="mainPageLabel">
            {
              (
                isNotEmpty( helpContentMap[mainKey] )
                && isNotEmpty( helpContentMap[mainKey][section.sectionKey] )
              ) &&
              <React.Fragment>
                <InlineSVG type={ helpContentMap[mainKey][section.sectionKey].icon } />
                { helpContentMap[mainKey][section.sectionKey].label }
              </React.Fragment>
            }
          </span>
          <div className="pageHeaderWrapper">
            <PrintButton
              keys={ { mainKey, sectionKey: section.sectionKey, pageKey: page.pageKey } }
              setPrintSelection={setPrintSelection}
              printLabel={ getPrintLabel( mainKey, section.sectionKey, page.pageKey )}
            />
            {
              ( isNotEmpty( mainKey )
              && isNotEmpty( section )
              && isNotEmpty( section.sectionKey )
              && isNotEmpty( page )
              && isNotEmpty( page.pageKey ) ) &&
              <h3>{ getHelpPageLabel() }</h3>
            }
          </div>
          <div
            className="markdownContent pageContent"
            dangerouslySetInnerHTML={
              { __html: md.render( page.page.content ) }
            }
          />
          {
            isNotEmpty( page.page?.items ) &&
            <Accordion>
              <div
                // eslint-disable-next-line max-len
                className={ `${mainKey}_${section.sectionKey}_${page.pageKey}_contextualHelpItems  accordionWrapper ${ itemsAreCollapsed( section.sectionKey, page.pageKey ) ? 'collapsed' : '' }` }
              >
                <div
                  className="accordionHeader"
                  onClick={ () => toggleItemsFor( section.sectionKey, page.pageKey ) }
                >
                  <h3>
                    <InlineSVG type="help" />
                    Contextual Help Items
                  </h3>
                  <button
                    onClick={ () => toggleItemsFor( section.sectionKey, page.pageKey ) }
                  >
                    {
                      itemsAreCollapsed( section.sectionKey, page.pageKey )
                        ? 'view'
                        : 'hide'
                    }
                    <InlineSVG type="carretUp" version="primary" />
                  </button>

                </div>
                <div className="accordionBody">
                  {
                    Object.entries( page.page.items ).map( ( [ key, item ], _index ) => {
                      return <ItemContent
                        key={_index}
                        item={item}
                        sectionKey={section.sectionKey}
                        pageKey={page.pageKey}
                        itemKey={key}
                        md={md}
                      />;
                    } )
                  }
                </div>
              </div>
            </Accordion>
          }
        </div>
      }
    </React.Fragment>
  );
};

export default PageContent;