/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './Paths.scss';
import PathsGraph from '../../../../RecordDetails/Sections/PathsGraph';
import { isNotEmpty } from '../../../../../shared/Utilities';

const Paths = ( { data } ) => {

  return (
    <React.Fragment>
      {
        isNotEmpty( data ) &&
        <div className="pathsWidgetWrapper">
          <PathsGraph
            relatedPaths={ data }
          />
        </div>
      }
    </React.Fragment>
  );
};

export default Paths;