/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { makeRequest } from '../../../../legacy/io';
import EmptyState from '../../../shared/EmptyState';
import InlineSVG from '../../../shared/InlineSVG';
import Loading from '../../../shared/Loading';
import { getRecords } from '../../../shared/RecordCache';
import {
  formatNumber,
  formatUnixDate,
  isNotEmpty,
  recordSorter,
  reportTypeDisplayName,
  riskToRating,
} from '../../../shared/Utilities';
import TaskItem from './EditModal/RemediationSteps/TaskItem';

import './RemediationPlanItem.scss';
import { completionClass, nameForPlan, percentComplete, typeForTask } from './Shared';
import RatingBadge from '../../../shared/RatingBadge';
import RiskReduction from '../../../shared/RiskReduction';

// gets all necessary records for the tasks and formats it correctly
export const fetchAndFormatTaskRecordData = async ( _tasks, users ) => {

  const _formatted = [];

  const hostIDs = [];
  const patchIDs = [];
  const vulnerabilityIDs = [];

  let fetchedHosts, fetchedPatches, fetchedVulnerabilities;

  _tasks.map( c => {
    if ( typeForTask( c ) === 'host' ) {
      hostIDs.push( c.task );
    }
    if ( typeForTask( c ) === 'patch' ) {
      patchIDs.push( c.task );
    }
    if ( typeForTask( c ) === 'vulnerability' ) {
      vulnerabilityIDs.push( c.task );
    }
  } );

  if ( isNotEmpty( hostIDs ) ) {
    // eslint-disable-next-line camelcase
    fetchedHosts = await getRecords( 'scope', { type: 'host', id_list: hostIDs, extra_columns: [ 'label' ] } );
  }
  if ( isNotEmpty( patchIDs ) ) {
    // eslint-disable-next-line max-len, camelcase
    fetchedPatches = await getRecords( 'patch', { id_list: patchIDs, extra_columns: [ 'vendor', 'identifier', 'patch_analysis.risk' ] } );
  }
  if ( isNotEmpty( vulnerabilityIDs ) ) {
    // eslint-disable-next-line max-len, camelcase
    fetchedVulnerabilities = await getRecords( 'vulnerability', { id_list: vulnerabilityIDs, extra_columns: [ 'identifier' ] } );
  }

  _tasks.map( c => {

    const _type = typeForTask( c );

    let _record;

    if ( _type === 'host' ) {
      _record = fetchedHosts.find( r => r.id === c.task );
    }
    if ( _type === 'vulnerability' ) {
      _record = fetchedVulnerabilities.find( r => r.id === c.task );
    }
    if ( _type === 'patch' ) {
      _record = fetchedPatches.find( r => r.id === c.task );
    }
    const _formattedTask = {
      original: c,
      risk: c.risk,
      id: c.id,
      normalizedType: _type,
      owner: isNotEmpty( c.owner ) ? users[c.owner] : 'N/A',
      label: isNotEmpty( _record ) ? reportTypeDisplayName( _record, _type ) : 'N/A',
    };

    _formatted.push( _formattedTask );
  } );

  return _formatted;
};

const RemediationPlanItem = ( { plan, users, activeIntegrations } ) => {

  const [ loadingTasks, setLoadingTasks ] = React.useState( false );
  const [ showTasks, setShowTasks ] = React.useState( false );
  const [ tasks, setTasks ] = React.useState( [] );
  const [ hasFetchedTasks, setHasFetchedTasks ] = React.useState( false );

  const fetchAndFormatTasks = async () => {
    setLoadingTasks( true );

    let _tasks;
    const taskFetch = await makeRequest( 'FETCH', '/project/default/model/base/remediation_task', {
      // eslint-disable-next-line camelcase
      remediation_plan_id: plan.id,
    } );

    if ( isNotEmpty( taskFetch ) && isNotEmpty( taskFetch.results ) ) {
      _tasks = taskFetch.results;
    } else {
      _tasks = [];
    }

    if ( isNotEmpty( _tasks ) ) {
      const _formatted = await fetchAndFormatTaskRecordData( _tasks, users );
      _formatted.sort( ( a, b ) => recordSorter( 'risk', false, a, b ) );
      setTasks( _formatted );
      setHasFetchedTasks( true );
      setLoadingTasks( false );
    } else {
      setHasFetchedTasks( true );
      setLoadingTasks( false );
    }
  };

  React.useEffect( ( ) => {
    if ( showTasks && !hasFetchedTasks && isNotEmpty( plan ) ) {
      fetchAndFormatTasks();
    }
  }, [ showTasks ] );

  return (
    <React.Fragment>
      <div className={ `remediationPlanInformation risk--${riskToRating( plan.risk )}`} >
        <h2>
          <span>
            <div className="recordIconWrapper">
              <InlineSVG type="remediation_nav" />
            </div>
            { nameForPlan( plan ) }
          </span>
          <span>
            <RiskReduction item={ plan } riskType="risk" />
            <RatingBadge rating={ riskToRating( plan.risk ) } />
          </span>
        </h2>
        <div className="planStatisticsWrapper">
          <div className="planStatistic status">
            <label>Status:</label>
            <RatingBadge rating={plan.status} elementClass={plan.status} />
          </div>
          <div className="planStatistic created">
            <label>{ plan.status === 'closed' ? 'Closed: ' : 'Created: ' }</label>
            <span>
              {
                plan.status === 'closed'
                  ? formatUnixDate( plan.modified )
                  : formatUnixDate( plan.created )
              }
            </span>
          </div>
          <div className="planStatistic owner">
            <label>Owner:</label>
            <span>{ ( isNotEmpty( plan.owner ) && isNotEmpty( users ) ) ? users[plan.owner] : 'No Owner' }</span>
          </div>
        </div>
      </div>
      <div className={ `remediationPlanProgress risk--${riskToRating( plan.risk )}`} >
        <div className="sectionLabel">Overall Plan Completion</div >
        <div className="completionWrapper risk">
          <div className="completionLabel">
            <strong className="percentage">{ percentComplete( plan.original_risk, plan.risk ) }%</strong>
            <span>Risk </span>
            {/* eslint-disable-next-line max-len */}
            <strong className="count">({ formatNumber( Math.ceil( ( plan.original_risk - plan.risk ) < 0 ? 0 : plan.original_risk - plan.risk ) ) }/{ formatNumber( Math.ceil( plan.original_risk ) ) })</strong>
          </div>
          <div className={`${completionClass( plan.original_risk, plan.risk )} completionBarWrapper risk`}>
            <div
              className={ 'completionBar risk' }
              style={{
                width: `${percentComplete( plan.original_risk, plan.risk )}%`,
              }}
            />
          </div>

        </div>
        <div className="completionWrapper instances">
          <div className="completionLabel">
            <div className="completionLabel">
              {/* eslint-disable-next-line max-len */}
              <strong className="percentage">{ percentComplete( plan.original_num_instances, plan.num_instances ) }%</strong>
              <span>Instances </span>
              {/* eslint-disable-next-line max-len */}
              <strong className="count">({ formatNumber( Math.ceil( ( plan.original_num_instances - plan.num_instances < 0 ? 0 : plan.original_num_instances - plan.num_instances ) ) ) }/{ formatNumber( plan.original_num_instances ) })</strong>
            </div>
          </div>
          {/* eslint-disable-next-line max-len */}
          <div className={ `${completionClass( plan.original_num_instances, plan.num_instances )} completionBarWrapper instances` }>
            <div
              className={ 'completionBar instances' }
              style={{
                width: `${percentComplete( plan.original_num_instances, plan.num_instances )}%`,
              }}
            />
          </div>
        </div>
      </div>
      <div className={ `expandingTasksContainer ${ showTasks ? 'expanded' : '' }` }>
        <button
          className="showTasksButton"
          onClick={ () => setShowTasks( !showTasks )}
        >
          {
            showTasks
              ? 'Hide Tasks'
              : 'Show Tasks'
          }
          <InlineSVG type="carretDown" />
        </button>

        {
          showTasks &&
          <section className="remediationTaskListSectionWrapper">
            <h2>{ `Tasks (${tasks.length})` }</h2>
            {
              loadingTasks
                ? <Loading />
                : <React.Fragment>
                  {
                    isNotEmpty( tasks )
                      ? <ul className="taskList">
                        {
                          tasks.map( ( task, index ) => {
                            return  <TaskItem
                              task={task}
                              key={index}
                              setLoadingStep={setLoadingTasks}
                              users={users}
                              isSelected
                              isActive
                              readOnly
                              activeIntegrations={activeIntegrations}
                            />;
                          } )
                        }
                      </ul>
                      : <EmptyState message="This plan has no tasks" />
                  }
                </React.Fragment>
            }
          </section>
        }
      </div>
    </React.Fragment>
  );
};

export default RemediationPlanItem;