/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import {
  isNotEmpty,
  recordSorter,
  reportTypeDisplayName,
  uniqueArray,
  isEmpty,
  removeFromURLHash,
} from '../../../../shared/Utilities';

import Step1 from './RemediationSteps/Step1';
import Step2 from './RemediationSteps/Step2';
import Step3 from './RemediationSteps/Step3';
import Step4 from './RemediationSteps/Step4';

import InlineSVG from '../../../../shared/InlineSVG';
import { getFieldValues } from '../../../../shared/Form/Shared';

import './style.scss';
import { typeForTask } from '../Shared';
import { RemediationContext } from '../../../../Contexts/Remediation';
import RemediationExportMenu from '../RemediationExportMenu';
import Loading from '../../../../shared/Loading';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../../../legacy/io';
import { getRecords } from '../../../../shared/RecordCache';
import { CurrentUserContext } from '../../../../Contexts/CurrentUser';

// used for className purposes
const stepNameMap = {
  1: 'details',
  2: 'select',
  3: 'draft',
  4: 'review',
};

const EMPTY_PLAN_STATE = {
  items: {
    host: [],
    patch: [],
    vulnerability: [],
  },
  tasks: {
    selected: [],
    candidates: [],
  },
};

const RemediationModal = ( {
  activeIntegrations,
  setShowModal,
  showModal,
  selectedRecord,
  users,
} ) => {
  const [
    workingDraftPlan,
    setWorkingDraftPlan,
    ,
    ,
    refreshAllDrafts,
  ] = React.useContext( RemediationContext );

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const [ loadingStep, setLoadingStep ] = React.useState( false );
  const [ currentStep, setCurrentStep ] = React.useState( 0 );

  const [ loadingExisting, setLoadingExisting ] = React.useState( false );
  const [ isSaving, setIsSaving ] = React.useState( false );
  const [ updatedForm, setUpdatedForm ]= React.useState( null );

  // source of truth for current state of the plan we are interacting with, needed something a bit more
  // flexible to track changes across all 4 tabs
  const [ planState, setPlanState ] = React.useState( EMPTY_PLAN_STATE );

  const [ itemsStale, setItemsStale ] = React.useState( false );
  const [ planStale, setPlanStale ] = React.useState( true );

  const [ isActive, setIsActive ] = React.useState( false );
  const [ , , , demoMode ] = React.useContext( CurrentUserContext );

  // 1) First thing to happen when opening the modal, if there is a plan already, it will be editing,
  // otherwise it will be a blank slate
  React.useEffect( ( ) => {
    const setupExistingRecord = async ( plan ) => {
      setLoadingExisting( true );
      const items = await getItems( plan );
      setItemsStale( false );
      const tasks = await getTasks( plan );
      const _plan = { ...plan };
      setLoadingExisting( false );

      setUpdatedForm( {
        label: plan.label || '',
        description: plan.description || '',
        status: plan.status || 'draft',
        owner: plan.owner || null,
      } );

      const _planState = {
        ..._plan,
        tasks,
        items,
      };
      setPlanState( _planState );
      setTimeout( () => {
        setPlanStale( false );
      }, 100 );

      if ( _plan.status === 'active' ) {
        startStep( 4, _planState, true );
      } else {
        startStep( 1, _planState, true );
      }
    };

    if ( showModal === false ) {
      setPlanState( EMPTY_PLAN_STATE );
      setCurrentStep( 0 );
      removeFromURLHash( 'create_from_saved' );
    } else if ( isNotEmpty( users ) && isNotEmpty( selectedRecord ) ) {
      setupExistingRecord( selectedRecord );
    } else {
      setPlanState( EMPTY_PLAN_STATE );
      startStep( 1, EMPTY_PLAN_STATE );
    }
  }, [ selectedRecord, users, showModal ] );

  // 2) always called when switching steps, needs to make sure the planState is ready for that step, and if
  // it is not, it gets what it needs
  const startStep = async ( step=1, plan=planState, skipCheck=false ) => {
    setLoadingStep( true );
    if ( !skipCheck ) {
      const needsPromises = [];
      const readyState = stepReadyState( step, plan );
      let _plan = { ...plan };
      let _items = { ...plan.items };
      let _tasks = { ...plan.tasks };

      const getNeeded = async ( need, plan ) => {
        switch ( need ) {
        case 'plan':
          if ( planStale ) {
            _plan = await updatePlan( plan );
          }

          break;
        case 'items':
          setLoadingExisting( true );
          _items = await getItems( plan );
          setLoadingExisting( false );
          setItemsStale( false );
          break;
        case 'tasks':
          _tasks = await getTasks( plan );
          break;
        default:
          break;
        }
      };

      const notReady = isNotEmpty( readyState.errors );

      if ( currentStep === 1 && ( planStale || itemsStale ) ) {
        _plan = await updatePlan( plan );
      }
      if ( itemsStale ) {
        _plan = await updatePlan( plan );
        needsPromises.push( getNeeded( 'tasks', plan ) );
      }
      if ( notReady ) {
        readyState.errors.map( e => {
          if ( currentStep === 1 ) {
            if ( e !== 'plan' ) {
              needsPromises.push( getNeeded( e, plan ) );
            }
          } else {
            needsPromises.push( getNeeded( e, plan ) );
          }
        } );
      }

      await Promise.all( needsPromises );

      setItemsStale( false );

      const _planState = {
        ..._plan,
        items: _items,
        tasks: _tasks,
      };
      setPlanState( _planState );
    }
    setCurrentStep( step );
    setLoadingStep( false );
  };

  // 3) called whenever a users selects 'save draft'
  const saveProgress = async ( planState ) => {
    if ( !demoMode  ) {
      setIsSaving( true );

      const toUpdate = { ...planState };
      // need to remove any empty fields so that the endpoint can optimize
      if ( isEmpty( toUpdate.patch_ids ) || !itemsStale ) {
        delete toUpdate.patch_ids;
      }
      if ( isEmpty( toUpdate.host_ids ) || !itemsStale ) {
        delete toUpdate.host_ids;
      }
      if ( isEmpty( toUpdate.vulnerability_ids ) || !itemsStale ) {
        delete toUpdate.vulnerability_ids;
      }

      const plan = await updatePlan( toUpdate );

      // success
      if ( plan?.id ) {

        // sync with current draft if you are modifying it
        if ( plan.id === workingDraftPlan?.id ) {
          setWorkingDraftPlan( plan );
        }
        refreshAllDrafts();

        setPlanState( { ...planState, ...plan } );
        addFlashMessage( {
          type: 'success',
          body: 'Successfully updated remediation plan',
        } );
        setShowModal( false );
        removeFromURLHash( 'selected_record' );
      } else if ( isNotEmpty( plan?.errors ) ) {
        plan.errors.map( e => {
          addFlashMessage( {
            type: 'alert',
            body: e,
          } );
        } );
      } else {
        setShowModal( false );
        removeFromURLHash( 'selected_record' );
      }

      setIsSaving( false );
    }
  };

  // 4) called on the last step to finalize the plan, sets status to 'active'
  const finish = ( plan ) => {
    if ( !demoMode ) {
      // eslint-disable-next-line max-len, camelcase
      if ( confirm( 'Are you sure you want to commit to this remediation plan? Once committed, you can no longer change the underlying risk items (step 2) for this plan. You will still be able to update computed tasks.' ) ) {
        setIsSaving( true );

        const planParams = {
          id: plan.id,
          status: 'active',
        };
        // eslint-disable-next-line max-len, camelcase
        makeRequest( 'UPSERT', '/project/default/model/base/remediation_plan', {
          records: [ planParams ],
        } ).then( response => {
          if ( response && isNotEmpty( response.results ) ) {
            const _planState = { ...planState, ...response.results[0] };
            setPlanState( _planState );
            setIsSaving( false );
            startStep( 4, _planState );
            setShowModal( false );
            addFlashMessage( {
              type: 'success',
              body: 'Successfully saved and activated remediation plan',
            } );
            setShowModal( false );
            removeFromURLHash( 'selected_record' );
            if ( response.results[0].id === workingDraftPlan?.id ) {
              setWorkingDraftPlan( null );
            }
            refreshAllDrafts();
          } else {
            setIsSaving( false );
            addFlashMessage( {
              type: 'alert',
              body: 'error saving and activating remediation plan',
            } );
          }
        } );
      }
    }

  };

  // only ever called when re-opening an existing plan
  const getItems = async ( plan ) => {

    let host = [];
    let patch = [];
    let vulnerability = [];

    if ( isNotEmpty( plan.host_ids ) ) {
      const params = {
        rownums: [ 0, plan.host_ids.length + 1 ],
        model: 'base',
        project: 'default',
        // eslint-disable-next-line camelcase
        group_type: 'host',
        filters: {
          // eslint-disable-next-line camelcase
          num_sensitive_nodes: 'any',
          'ii.has_host': true,
          // eslint-disable-next-line camelcase
          sort_by: 'risk',
          // eslint-disable-next-line camelcase
          sort_direction: 'DESC',
          // eslint-disable-next-line camelcase
          accepted_risk: 'false',
          // eslint-disable-next-line camelcase
          risk_type: 'risk',
          // eslint-disable-next-line camelcase
          host_ids: plan.host_ids,
        },
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'filtered_risk', 'DESC' ],
          [ 'local_name', 'ASC' ],
        ],
      };

      host = await getRecords( 'HOST', params, true );
      host = uniqueArray( host );
    }
    if ( isNotEmpty( plan.patch_ids ) ) {

      const params = {
        rownums: [ 0, plan.patch_ids.length + 1 ],
        model: 'base',
        project: 'default',
        // eslint-disable-next-line camelcase
        group_type: 'patch_cumulative',
        filters: {
          superseded: 'unsuperseded',
          // eslint-disable-next-line camelcase
          risk_type: 'risk',
          // eslint-disable-next-line camelcase
          sort_by: 'risk',
          // eslint-disable-next-line camelcase
          sort_direction: 'DESC',
          // eslint-disable-next-line camelcase
          accepted_risk: 'false',
          // eslint-disable-next-line camelcase
          patch_ids: plan.patch_ids,
        },
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'risk', 'DESC' ],
          [ 'vendor', 'ASC' ],
          [ 'identifier', 'DESC' ],
        ],
      };

      patch = await getRecords( 'PATCH', params, true );
      patch = uniqueArray( patch );
    }
    if ( isNotEmpty( plan.vulnerability_ids ) ) {
      const params = {
        rownums: [ 0, plan.vulnerability_ids.length + 1 ],
        model: 'base',
        project: 'default',
        // eslint-disable-next-line camelcase
        group_type: 'vulnerability',
        filters: {
          patchable: 'any',
          // eslint-disable-next-line camelcase
          sort_by: 'risk',
          // eslint-disable-next-line camelcase
          sort_direction: 'DESC',
          // eslint-disable-next-line camelcase
          accepted_risk: 'false',
          // eslint-disable-next-line camelcase
          exploit_status: 'null',
          // eslint-disable-next-line camelcase
          risk_type: 'risk',
          // eslint-disable-next-line camelcase
          vulnerability_ids: plan.vulnerability_ids,
        },
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'filtered_risk', 'DESC' ],
          [ 'identifier', 'ASC' ],
        ],
      };

      vulnerability = await getRecords( 'VULNERABILITY', params, true );
      vulnerability = uniqueArray( vulnerability );
    }
    return { host, patch, vulnerability };
  };

  // called whenever the items change
  // eslint-disable-next-line camelcase
  const getTasks = async ( plan, additionalFilters={ exclude_no_risk: true, tanium: false } ) => {
    setLoadingExisting( true );
    let selected, candidates;

    const taskCandidatesResponse = await makeRequest( 'COMPUTE', '/project/default/model/base/remediation_plan', {
      // eslint-disable-next-line camelcase
      remediation_plan_id: plan.id,
      ...additionalFilters,
      rownums: [ 0, 100 ],
    } );

    if ( isNotEmpty( taskCandidatesResponse ) && isNotEmpty( taskCandidatesResponse.results ) ) {
      candidates = taskCandidatesResponse.results;
    } else {
      candidates = [];
    }

    const selectedTasksResponse = await makeRequest( 'FETCH', '/project/default/model/base/remediation_task', {
      // eslint-disable-next-line camelcase
      remediation_plan_id: plan.id,
    } );

    if ( isNotEmpty( selectedTasksResponse ) && isNotEmpty( selectedTasksResponse.results ) ) {
      selected = selectedTasksResponse.results;
    } else {
      selected = [];
    }

    if ( isNotEmpty( candidates ) ) {
      candidates = await formatTaskItems( candidates );
    }
    if ( isNotEmpty( selected ) ) {
      selected = await formatTaskItems( selected );
    }
    const _planTasks = { selected, candidates };
    setLoadingExisting( false );
    return _planTasks;
  };

  // knowing if a step is ready to show what it needs, if not, it takes care of that
  const stepReadyState = ( step, plan ) => {
    const readyState = {
      errors: [],
      warnings: [],
    };

    switch ( step ) {
    case 1:
      return readyState;
    case 2:
      if ( isEmpty( plan.id ) ) {
        readyState.errors.push( 'plan' );
      }
      return readyState;
    case 3:
      if ( isEmpty( plan.id ) ) {
        readyState.errors.push( 'plan' );
      }
      if ( isEmpty( plan.items ) ) {
        readyState.warnings.push( 'items' );
      }
      return readyState;
    case 4:
      if ( isEmpty( plan.id ) ) {
        readyState.errors.push( 'plan' );
      }
      if ( isEmpty( plan.items ) ) {
        readyState.warnings.push( 'items' );
      }
      if ( isEmpty( plan.tasks ) ) {
        readyState.warnings.push( 'tasks' );
      }
      return readyState;
    default:
      return readyState;
    }
  };

  // helper function to get the next step action in the lower right
  const nextStepAction = ( currentStep, plan ) => {
    switch ( currentStep ) {
    case 1:
      return startStep( 2, plan );
    case 2:
      return startStep( 3, plan );
    case 3:
      return startStep( 4, plan );
    case 4:
      return finish( plan );
    default:
      return saveProgress( plan );
    }
  };

  // helper function to get the text for the next step in the lower right
  const nextStepText = currentStep => {
    switch ( currentStep ) {
    case 1:
      return <React.Fragment>
        <span>NEXT STEP: Select Risk Items</span>
      </React.Fragment>;
    case 2:
      return <React.Fragment>
        <span>NEXT STEP: Select Tasks</span>
        <InlineSVG type="carretRight" version="light" />
      </React.Fragment>;
    case 3:
      return <React.Fragment>
        <span>NEXT STEP: Review / Approve</span>
        <InlineSVG type="carretRight" version="light" />
      </React.Fragment>;
    case 4:
      return <React.Fragment>
        <span>Activate</span>
      </React.Fragment>;
    default:
      return <React.Fragment>
        <span>Activate</span>
      </React.Fragment>;
    }
  };

  // // helper function to get the loading text depending on the step transition
  const loadingStepText = currentStep => {
    switch ( currentStep ) {
    case 1:
      return 'Saving plan details...';
    case 2:
      return 'Saving risk items and creating plan...';
    case 3:
      return 'calculating remaining tasks and risk...';
    case 4:
      return 'Saving plan...';
    default:
      return 'Saving plan...';
    }
  };

  // called whenever the tasks are recomputed, needs to go fetch additional things and format all the data correctly
  const formatTaskItems = async ( tasks ) => {

    const _formatted = await fetchAndFormatTaskRecordData( tasks );
    _formatted.sort( ( a, b ) => recordSorter( 'risk', false, a, b ) );

    return _formatted;
  };

  // gets all necessary records for the tasks and formats it correctly
  const fetchAndFormatTaskRecordData = async ( _tasks ) => {

    const _formatted = [];

    const hostIDs = [];
    const patchIDs = [];
    const vulnerabilityIDs = [];

    let fetchedHosts, fetchedPatches, fetchedVulnerabilities;

    _tasks.map( c => {
      if ( typeForTask( c ) === 'host' ) {
        hostIDs.push( c.task );
      }
      if ( typeForTask( c ) === 'patch' ) {
        patchIDs.push( c.task );
      }
      if ( typeForTask( c ) === 'vulnerability' ) {
        vulnerabilityIDs.push( c.task );
      }
    } );

    if ( isNotEmpty( hostIDs ) ) {
      const params = {
        rownums: [ 0, hostIDs.length + 1 ],
        model: 'base',
        project: 'default',
        // eslint-disable-next-line camelcase
        group_type: 'host',
        filters: {
          // eslint-disable-next-line camelcase
          num_sensitive_nodes: 'any',
          // eslint-disable-next-line camelcase
          sort_by: 'risk',
          // eslint-disable-next-line camelcase
          sort_direction: 'DESC',
          // eslint-disable-next-line camelcase
          accepted_risk: 'false',
          // eslint-disable-next-line camelcase
          risk_type: 'risk',
          // eslint-disable-next-line camelcase
          host_ids: hostIDs,
        },
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'filtered_risk', 'DESC' ],
          [ 'local_name', 'ASC' ],
        ],
      };
      // eslint-disable-next-line camelcase
      fetchedHosts = await getRecords( 'HOST', params, true );
    }
    if ( isNotEmpty( patchIDs ) ) {
      // We only need vendor and identifier for patches, we can get that from patches table
      const params = {
        model: 'base',
        project: 'default',
        // eslint-disable-next-line camelcase
        extra_columns: [ 'vendor', 'identifier' ],
        // eslint-disable-next-line camelcase
        id_list: patchIDs,
      };
      // eslint-disable-next-line max-len, camelcase
      fetchedPatches = await getRecords( 'patch', params );
    }
    if ( isNotEmpty( vulnerabilityIDs ) ) {
      const params = {
        rownums: [ 0, vulnerabilityIDs.length + 1 ],
        model: 'base',
        project: 'default',
        // eslint-disable-next-line camelcase
        group_type: 'vulnerability',
        filters: {
          patchable: 'any',
          // eslint-disable-next-line camelcase
          sort_by: 'risk',
          // eslint-disable-next-line camelcase
          sort_direction: 'DESC',
          // eslint-disable-next-line camelcase
          accepted_risk: 'false',
          // eslint-disable-next-line camelcase
          exploit_status: 'null',
          // eslint-disable-next-line camelcase
          risk_type: 'risk',
          // eslint-disable-next-line camelcase
          vulnerability_ids: vulnerabilityIDs,
        },
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'filtered_risk', 'DESC' ],
          [ 'identifier', 'ASC' ],
        ],
      };
      // eslint-disable-next-line max-len, camelcase
      fetchedVulnerabilities = await getRecords( 'VULNERABILITY', params, true );
    }

    _tasks.map( c => {

      const _type = typeForTask( c );

      let _record;

      if ( _type === 'host' ) {
        _record = fetchedHosts.find( r => r.id === c.task );
      }
      if ( _type === 'vulnerability' ) {
        _record = fetchedVulnerabilities.find( r => r.id === c.task );
      }
      if ( _type === 'patch' ) {
        _record = fetchedPatches.find( r => r.id === c.task );
      }
      const _formattedTask = {
        original: c,
        risk: c.risk,
        id: c.id,
        normalizedType: _type,
        owner: isNotEmpty( c.owner ) ? users[c.owner] : 'N/A',
        label: isNotEmpty( _record ) ? reportTypeDisplayName( _record, _type ) : 'N/A',
      };

      _formatted.push( _formattedTask );
    } );

    return _formatted;
  };

  // called whenever the plan needs to be updated, returns the latest plan from the server
  const updatePlan = async ( planState ) => {
    if ( !demoMode ) {
      const planValues = getFieldValues( updatedForm?.fieldStates, 'remediation_plan' );
      if ( isNotEmpty( planValues ) ) {
        const planParams = {
          // these are synced to the planState whenever an item is added or removed
          /* eslint-disable camelcase */
          host_ids: planState.host_ids || [],
          patch_ids : planState.patch_ids || [],
          vulnerability_ids: planState.vulnerability_ids || [],
          // /* eslint-enable camelcase */
          // these come from the form on tab 1
          owner: planValues?.owner || null,
          label: planValues?.label || '',
          description: planValues?.description || '',
          status: planValues?.status || 'draft',
        };


        //  map over any ids from the items obj. that have not been mapped over yet.
        if ( isNotEmpty( planState.items ) ) {
          Object.entries( planState.items ).map( ( [ type, items ] ) => {
            if ( isNotEmpty( items ) ) {
              items.map( item => {
                if ( planParams[`${type}_ids`] && !planParams[`${type}_ids`].includes( item.id ) ) {
                  planParams[`${type}_ids`].push( item.id );
                }
              } );
            }
          } );
        }

        if ( planParams.owner === 'null' ) {
          planParams.owner = null;
        }

        if ( planState.id ) {
          planParams.id = planState.id;
        }

        // eslint-disable-next-line max-len, camelcase
        const _planRequest = await makeRequest( 'UPSERT', '/project/default/model/base/remediation_plan', {
          records: [ planParams ],
        } );

        if ( isNotEmpty( _planRequest ) && isNotEmpty( _planRequest.results ) ) {
          const _planState = { ...planState, ..._planRequest.results[0] };
          // setPlanState( _planState );
          return _planState;
        }
        setPlanStale( false );
        return _planRequest;
      }
    }
  };

  // syncs the form values to the planState, whenever they change
  React.useEffect( ( ) => {
    setPlanStale( true );
  }, [ updatedForm ] );

  // convenience checker to know whether to show the cancel button or not
  const shouldCancel = () => {
    if ( isActive ) {
      return true;
    }
    if ( isEmpty( planState.id ) ) {
      return true;
    }
    return false;
  };

  // convenience checker to make sure users cannot get to step 3 without any items
  const hasNoItems = plan => {
    const itemStatus = [];

    if ( isNotEmpty( plan.items ) ) {
      Object.values( plan.items ).map( itemGroup => {
        itemStatus.push( isEmpty( itemGroup ) );
      } );
    }
    return itemStatus.every( s => s === true );
  };

  // convenience checker to make sure users cannot get to step 4 without any tasks
  const hasNoTasks = plan => {
    return isEmpty( plan.tasks ) || isEmpty( plan.tasks?.selected );
  };

  // used on the lower right button, and checks the previous ^^^ two methods for specific disabling logic for
  // steps 3 and 4
  const shouldDisable = ( step, plan ) => {
    if ( step === 0 || step === 1 ) {
      return false;
    }
    if ( step === 2 ) {
      return hasNoItems( plan );
    }
    if ( step === 3 ) {
      return hasNoItems( plan ) || hasNoTasks( plan );
    }
    return false;
  };

  // just a small tweak when opening this modal, make sure to remove an unneeded hash param
  React.useEffect( ( ) => {
    if ( showModal === true ) {
      removeFromURLHash( 'export_plan_id' );
    }
  }, [ showModal ] );

  React.useEffect( () => {
    if ( isNotEmpty( planState ) && planState.status === 'active' ) {
      setIsActive( true );
    } else {
      setIsActive( false );
    }
  }, [ planState ] );
  // showing the correct loading message was getting complicated, this checks all the conditions and makes
  // sure it only shows one loading message
  const getLoadingMessage = () => {
    if ( loadingStep || isSaving || loadingExisting ) {
      let text = 'Loading...';

      if ( loadingExisting ) {
        text = 'Loading Remediation Plan...';
      } else if ( loadingStep ) {
        text = loadingStepText( currentStep );
      } else {
        text = 'Saving Remediation Plan...';
      }
      return <Loading text={text} />;
    }
  };

  return (
    <React.Fragment>
      { getLoadingMessage() }
      <div
        // eslint-disable-next-line max-len
        className={ `setupTabsContainer tabCount--4 ${ isNotEmpty( currentStep ) ? `selectedTabIndex--${ currentStep - 1 }`: 'noSelectedTab' }`}
      >
        <div
          className={`setupTab ${currentStep === 1 ? 'isCurrent' : ''}`}
          onClick={ () => startStep( 1, planState )}
        >
          <div className="setupTabContentWrapper">
            <span className="stepAndLabelWrapper">
              <InlineSVG type="progress_circle_1" />
              <label>Details</label>
            </span>
          </div>
        </div>
        <div
          className={`setupTab ${currentStep === 2 ? 'isCurrent' : ''}`}
          onClick={ () => startStep( 2, planState )}
        >
          {
            isActive
              ? <div className="setupTabContentWrapper">
                <span className="stepAndLabelWrapper">
                  <InlineSVG type="progress_circle_2" />
                  <label>View Risk Items</label>
                </span>
              </div>
              : <div className="setupTabContentWrapper">
                <span className="stepAndLabelWrapper">
                  <InlineSVG type="progress_circle_2" />
                  <label>Select Risk Items</label>
                </span>
              </div>
          }
        </div>
        <div
          className={`setupTab ${currentStep === 3 ? 'isCurrent' : ''} ${ hasNoItems( planState ) ? 'isDisabled' : ''}`}
          onClick={ () => startStep( 3, planState ) }
        >
          {
            isActive
              ? <div className="setupTabContentWrapper">
                <span className="stepAndLabelWrapper">
                  <InlineSVG type="progress_circle_3" />
                  <label>Edit Selected Tasks</label>
                </span>
              </div>
              : <div className="setupTabContentWrapper">
                <span className="stepAndLabelWrapper">
                  <InlineSVG type="progress_circle_3" />
                  <label>Select Tasks</label>
                </span>
              </div>
          }
        </div>
        <div
          // eslint-disable-next-line max-len
          className={`setupTab ${currentStep === 4 ? 'isCurrent' : ''} ${ ( hasNoItems( planState ) || hasNoTasks( planState ) ) ? 'isDisabled' : ''}`}
          onClick={ () => startStep( 4, planState ) }
        >
          {
            isActive
              ? <div className="setupTabContentWrapper">
                <span className="stepAndLabelWrapper">
                  <InlineSVG type="progress_circle_4" />
                  <label>Tasks Summary / Progress</label>
                </span>
              </div>
              : <div className="setupTabContentWrapper">
                <span className="stepAndLabelWrapper">
                  <InlineSVG type="progress_circle_4" />
                  <label>Review / Approve</label>
                </span>
              </div>
          }
        </div>
      </div>

      <div className={`tabWrapper ${stepNameMap[currentStep]} ${isActive ? 'isActive' : ''}`}>
        {
          currentStep === 1 &&
          <React.Fragment>
            <h2>Remediation Plan: Details</h2>
            <div className="stepDirections">
              <span>
                <strong>Step 1:</strong> Details of the remediation plan.
              </span>
            </div>
          </React.Fragment>

        }
        {
          currentStep === 2 &&
          <React.Fragment>
            <h2>Remediation Plan: Select Risk Items</h2>
            <div className="stepDirections">
              {
                isActive
                  ? <span>
                    <strong>Step 2:</strong> The underlying risk items included in this plan
                  </span>
                  : <span>
                    <strong>Step 2:</strong> Filter down and select the risk
                    items (hosts, patches, and/or vulnerabilities) that you wish to have included in the remediation
                    plan.
                  </span>
              }
            </div>
          </React.Fragment>

        }
        {
          currentStep === 3 &&
          <React.Fragment>
            <h2>Remediation Plan: Select Tasks</h2>
            <div className="stepDirections">
              <span>
                <strong>Step 3:</strong> Review the available tasks and add the ones you would like included in this
                remediation plan. <strong>NOTE: </strong> Remediation plans are automatically saved when changes are
                made because tasks need to be computed on the fly whenever you add or remove tasks for a plan.
              </span>
            </div>
          </React.Fragment>
        }
        {
          currentStep === 4 &&
          <React.Fragment>
            <h2>
              {
                isActive
                  ? 'Remediation Plan'
                  : 'Remeidation Plan: Review / Approve'
              }
            </h2>
            <div className="stepDirections">
              {
                isActive
                  ? <React.Fragment>
                    <span>
                      <strong>STEP 4:</strong> Your Remediation Plan.
                    </span>
                  </React.Fragment>
                  : <React.Fragment>
                    <span>
                      <strong>STEP 4:</strong> If you approve of these tasks, click "Activate" to commit to this
                      remediation plan.
                    </span>
                  </React.Fragment>
              }
              <div className="activePlanActions">
                <button onClick={ () => startStep( 3, planState ) } className="stepChangeButton">
                  Edit tasks
                </button>
                {
                  isActive &&
                  <RemediationExportMenu fullVersion={true} plan={planState} activeIntegrations={activeIntegrations} />
                }
              </div>
            </div>
          </React.Fragment>
        }
        {
          currentStep === 1 &&
          <Step1
            selectedRecord={selectedRecord}
            planState={planState}
            users={users}
            setUpdatedForm={setUpdatedForm}
          />
        }
        {
          currentStep === 2 &&
          <Step2
            planState={planState}
            setPlanState={setPlanState}
            isActive={ isActive }
            setItemsStale={setItemsStale}
          />
        }
        {
          currentStep === 3 &&
          <Step3
            setLoadingStep={setLoadingStep}
            planState={planState}
            setPlanState={setPlanState}
            getTasks={getTasks}
            isActive={ isActive }
            users={users}
            activeIntegrations={activeIntegrations}
          />
        }
        {
          currentStep === 4 &&
          <Step4
            currentStep={currentStep}
            setLoadingStep={setLoadingStep}
            startStep={startStep}
            getTasks={getTasks}
            isActive={ isActive }
            planState={planState}
            setPlanState={setPlanState}
            users={users}
            activeIntegrations={activeIntegrations}
          />
        }
      </div>
      <div className="modalActions">
        {
          isActive
            ? <button
              disabled={ demoMode }
              className={ `${ demoMode ? 'disabled' : ''} nextFinishButton` }
              onClick={ () => saveProgress( planState ) }
            >
              Save and close
            </button>
            : <button
              disabled={ demoMode }
              className={ `${shouldDisable( currentStep, planState ) ? 'disabled' : '' } nextFinishButton` }
              onClick={ () => nextStepAction( currentStep, planState ) }
            >
              { nextStepText( currentStep ) }
            </button>
        }

        <div
          className="stepText"
        >
          { `Step ${currentStep} of 4` }
        </div>
        {
          !shouldCancel() &&
          <button
            disabled={demoMode}
            onClick={ () => saveProgress( planState ) }
            className={`${demoMode ? 'disabled' : ''} cancelButton`}
          >
            <span>Save draft and close</span>
          </button>
        }
      </div>
    </React.Fragment>
  );
};

export default RemediationModal;