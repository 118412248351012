/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';

import DataTable from '../../../shared/DataTable';

import InlineSVG from '../../../shared/InlineSVG';

import {
  isNotEmpty,
  isEmpty,
  reportTypeDisplayName,
  riskToRating,
  formatUnixTime,
  formatNumber,
  decodeURLHash,
  paramsToFilters,
  removeFromURLHash,
  encodeURLHash,
  triggerHashRefresh,
  vulnScannerNameMap,
  vulnerabilityScannerLogo,
  isTruthy,
  cvssScoreToRating,
} from '../../../shared/Utilities';

import './style.scss';
import Loading from '../../../shared/Loading';
import ExploitStatus from '../../../shared/ExploitStatus';
import { hasAccess } from '../../App/AccessControl';
import { remediationItemsKey } from './shared';
import { RiskContext } from '../../../Contexts/Risk';
import RatingBadge from '../../../shared/RatingBadge';
import TagList from '../Tags/List';
import { TagsContext } from '../../../Contexts/Tags';
import RiskReduction from '../../../shared/RiskReduction';
import NotScannedBadge from '../../../shared/NotScannedBadge';
import RecordCard from '../../RecordDetails/RecordCard';
import ScanningStatusIndicator from '../../RecordDetails/ScanningStatusIndicator';
import { keyToAttrMap, multipleOrderByOptions } from '../../Reporting/Dashboards/shared';

const InsightTable = ( {
  records,
  reportType,
  loading=false,
  selectRecord,
  hoverRecord,
  setHoverRecord,
  onRefresh,
  visualCollapsed,
  riskType='risk',
  setSelectedSignature,
  remediationItems,
  setRemediationItems,
} ) => {

  const [ adjustedData, setAdjustedData ] = React.useState( [] );
  const [ primaryOrderBy, setPrimaryOrderBy ] = React.useState( 'filtered_risk' );
  const [ orderByClass, setOrderByClass ] = React.useState( 'primary_order_by--filtered_risk' );
  const [ includeRiskClass, setIncludeRiskClass ] = React.useState( 'include_risk--true' );

  const [ , , licenseInfo, demoMode ] = React.useContext( CurrentUserContext );
  const [ , targetRisk ] = React.useContext( RiskContext );

  // for remediation: { [groupType]: [ ...IDs ] }


  const [ tags ] = React.useContext( TagsContext );

  // for recordCard
  const [ recordCardRecord, setRecordCardRecord ] = React.useState( null );
  const [ recordCardType, setRecordCardType ] = React.useState( null );
  const [ showRecordCard, setShowRecordCard ] = React.useState( null );

  const viewRecord = ( e, record, type ) => {
    setRecordCardRecord( { ...record, clickEvent: e } );
    setRecordCardType( type );
    setShowRecordCard( true );
  };

  React.useEffect( () => {
    if ( showRecordCard === false ) {
      setRecordCardRecord( null );
      setRecordCardType( null );
    }
  }, [ showRecordCard ] );

  // source of truth for which columns are sortable by report type (used for all except instances)
  const reportTypeSortableColumns = {
    /* eslint-disable camelcase */
    host: {
      // risk: 'risk',
      // name: 'label',
      // 'Last DeepSurface Scan': 'last_scanned',
    },
    vulnerability: {
      // risk: 'risk',
      // name: 'identifier',
      // exploit_status: 'exploit_status',
      // 'CVSS': 'cvss_base_score',
    },
    path: {
      // risk: 'risk',
    },
    patch: {
      // risk: 'risk',
      // name: 'label',
    },
    user: {
      // risk: 'risk',
      // name: 'name',
    },
    /* eslint-enable camelcase */
  };

  // source of truth for which columns are sortable by group type (used for instances)
  const instanceGroupingSortableColumns = {
    /* eslint-disable camelcase */
    host: {
      // risk: 'filtered_risk',
      // name: 'local_name',
      // product_name: 'product_name',
      // vulns: 'num_vulnerabilities',
      // 'Last DeepSurface Scan': 'last_scanned',
    },
    patch: {
      // risk: 'filtered_risk',
      // name: [ 'vendor', 'identifier' ],
      // hosts: 'num_hosts',
      // vulns: 'num_vulnerabilities',
    },
    vulnerability: {
      // risk: 'filtered_risk',
      // name: 'identifier',
      // hosts: 'num_hosts',
      // CVSS: 'cvss_base_score',
      // exploit_status: 'exploit_status',
    },
    signature: {
      // scanner: [ 'scanner', 'signature' ],
      // risk: 'filtered_risk',
      // hosts: 'num_hosts',
      // vulns: 'num_vulnerabilities',
    },
    /* eslint-enable camelcase */
  };

  const formatIpAddresses = addresses => {
    const formatted = [];
    if ( addresses ) {
      addresses.map( address => {
        return formatted.push( address.split( '/' )[0] );
      } );
    }
    return formatted.join( ', ' );
  };

  const insightGoToRecord = ( row, _groupType ) => {
    let reportType;
    if ( _groupType === 'host' ) {
      reportType = 'hosts';
    } else if ( _groupType === 'patch' || _groupType === 'patch_cumulative' ) {
      reportType = 'patches';
    } else {
      reportType = 'vulnerabilities';
    }

    const hash = decodeURLHash();

    if ( hash.report === 'instances' && isNotEmpty( _groupType ) ) {
      // eslint-disable-next-line max-len
      window.location.href = `#.=risk_insight&report=${reportType}&item_count=100&order_by=filtered_risk&order_direction=DESC&current_page=1&item=${row.id}${ reportType === 'patches' ? '&risk_type=risk' : ''}`;
    } else if ( isNotEmpty( selectRecord ) ) {
      selectRecord( row.id );
    }
  };

  const reportTypeNameLink = ( row, _groupType ) => {
    if (
      isEmpty( row.id )
    ) {
      return <span className="notLink">
        {
          (
            ( ( reportType === 'host' ) && !row.has_host )
            || ( reportType === 'instance' && _groupType === 'host' && !row.has_host )
          ) &&
          <div className="iconWrapper notScanned">
            <InlineSVG type="notScanned" />
          </div>
        }
        { reportTypeDisplayName( row, _groupType ) }
      </span>;
    }
    return <div
      className="nameLink"
      onClick={ () => insightGoToRecord( row, _groupType ) }
    >
      {
        (
          ( ( reportType === 'host' ) && !row.has_host )
          || ( reportType === 'instance' && _groupType === 'host' && !row.has_host )
        ) &&
        <div className="iconWrapper notScanned">
          <InlineSVG type="notScanned" />
        </div>
      }
      { reportTypeDisplayName( row, _groupType || reportType ) }
    </div>;
  };

  const formatLastScanned = ( row, _groupType ) => {
    if ( isEmpty( _groupType ) ) {
      if ( isNotEmpty( row.last_scanned ) && row.last_scanned !== 0 ) {
        return formatUnixTime( row.last_scanned );
      }
    } else if ( _groupType === 'host' && !row.has_host ) {
      return <NotScannedBadge />;
    }
    return formatUnixTime( row.last_scanned );
  };

  // remediation selection functions
  const onRemediationSelect = id => {
    if ( includeRemediationSelect( licenseInfo ) ) {
      const rKey = remediationItemsKey( reportType );
      const selectedIDs = Object.keys( remediationItems[rKey] );
      if ( selectedIDs.includes( id ) ) {

        delete remediationItems[rKey][id];
        setRemediationItems( {
          ...remediationItems,
          [rKey]: remediationItems[rKey],
        } );
      } else {
        const newItem = records.find( r => r.id === id );
        setRemediationItems( {
          ...remediationItems,
          [rKey]: { ...remediationItems[rKey], [id]: newItem },
        } );
      }
    } else {
      return false;
    }
  };

  const setRemediationItemsForType = ids => {
    const rKey = remediationItemsKey( reportType );
    const _items = {};

    if ( isNotEmpty( ids ) ) {
      ids.map( id => {
        _items[id] = records.find( r => r.id === id );
      } );
    }
    setRemediationItems( { ...remediationItems, [rKey]: _items } );
  };

  // The add/remove button for each row in the table (instances)
  const AddRemoveFilterAction = ( { row } ) => {

    const hash = decodeURLHash();

    let _groupType = hash.group_type || 'host';

    if ( _groupType === 'patch_cumulative' ) {
      _groupType = 'patch';
    }

    const existingFilter = hash[`${_groupType}_ids`] && hash[`${_groupType}_ids`].includes( row.id );

    const addOrRemoveFilter = () => {
      if ( existingFilter ) {
        removeFromURLHash( `${_groupType}_ids` );
      } else {
        encodeURLHash( { [`${_groupType}_ids`]: JSON.stringify( [ row.id ] ) } );
      }
      triggerHashRefresh();
      onRefresh();
    };

    return (
      <button
        title={ existingFilter ? 'remove filter' : 'add filter' }
        className={`${ existingFilter ? 'removeFilterButton' : 'addFilterButton'}`}
        onClick={ () => addOrRemoveFilter() }
      >
        {
          existingFilter
            ? <InlineSVG type="removeFilter" elementClass="filterIcon" />
            : <InlineSVG type="addFilter" elementClass="filterIcon" />
        }
      </button>
    );
  };

  // formatted name of signature (instances)
  const ScannerSignature = ( { row, link=true } ) => {
    let scanner;

    if ( row.scanner in vulnScannerNameMap ) {
      scanner = vulnScannerNameMap[row.scanner];
    }

    if ( !link ) {
      return <p>{`${ scanner ?? row.scanner } - ${ row.signature }`}</p>;
    }

    return (
      <button
        title={`${ scanner } - ${ row.signature }`}
        className={ `relatedSignatureButton ${riskToRating( row?.risk ) }`}
        onClick={ ( e ) => setSelectedSignature( { ...row, clickEvent: e } ) }
      >
        { vulnerabilityScannerLogo( row?.scanner ) }
        <span className="name">{`${ scanner ?? row.scanner } - ${ row.signature }`}</span>
      </button>
    );
  };

  // The data returned from the server needs to be massaged and adjusted for display
  // the table will now adjust the row order depending on what we are ordering by so that
  // the flow of information makes a bit more sense, ie: sorting by num_vulnerabilities, will put
  // those in the second column... etc.
  // RULE of thumb:
  // 1. remediation check (if included)
  // 2. risk (if included)
  // 3. label
  // 4. primary sort (if other than risk)
  // 5. secondary sort
  const transformRowData = ( row, orderBy='filtered_risk', includeRisk=true ) => {
    const riskRating = riskToRating(
      row[( reportType === 'host' || reportType === 'vulnerability' ) ? 'filtered_risk' : riskType], targetRisk,
    );
    // only used for instances
    const _groupType = paramsToFilters()['group_type'] || 'host';
    const _tags = {};

    if (
      isNotEmpty( tags )
      && reportType === 'host'
      && isNotEmpty( row )
      && isNotEmpty( row.asset_tags )
    ) {
      row.asset_tags.map( id => _tags[id] = tags[id] );
    }

    /* eslint-disable camelcase */
    switch ( reportType ) {
    case 'host':
      switch ( orderBy ) {
      case 'filtered_risk':
        if ( includeRisk ) {
          return {
            risk: <div className={ `rowRiskWrapper ${riskRating}` }>
              <RatingBadge
                item={ row }
                itemType={ reportType }
                altVersion
                rating={ riskToRating( row.filtered_risk ) }
              />
              <RiskReduction item={ row } itemType={ reportType } riskType="filtered_risk" />
            </div>,
            name: <React.Fragment>
              { reportTypeNameLink( row, reportType ) }
              <TagList tags={_tags} truncation={ 2 } />
            </React.Fragment>,
            sensitive_assets: <span
              className={ `sensitiveAssetsCount ${ row.num_sensitive_nodes !== 0 ? 'hasAssets' : ''}`}
            >
              { formatNumber( row.num_sensitive_nodes || 0 ) }
            </span>,
            all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
            unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
            vulnerabilities: <span>{ formatNumber( row?.num_vulnerabilities || 0 ) }</span>,
            'DeepSurface Scanning Status': <ScanningStatusIndicator hideText timestamp={ row.last_scanned } />,
            actions: <React.Fragment>
              {
                isNotEmpty( row.id ) &&
                <React.Fragment>
                  <button
                    title="Quick View Host"
                    className="roundGlyphButton light viewRecordButton"
                    onClick={ ( e ) => viewRecord( e, row, 'host' ) }
                  >
                    <InlineSVG type="moreHorizontal" />
                  </button>
                  <button
                    title="View Full Host Details"
                    className="roundGlyphButton light selectRecordButton"
                    onClick={ () => selectRecord( row.id ) }
                  >
                    <InlineSVG type="carretRight" />
                  </button>
                </React.Fragment>
              }
            </React.Fragment>,
            id: row.id,
            originalRecord: row,
          };
        }
        return {
          name: <React.Fragment>
            { reportTypeNameLink( row, reportType ) }
            <TagList tags={_tags} truncation={ 2 } />
          </React.Fragment>,
          sensitive_assets: <span
            className={ `sensitiveAssetsCount ${ row.num_sensitive_nodes !== 0 ? 'hasAssets' : ''}`}
          >
            { formatNumber( row.num_sensitive_nodes || 0 ) }
          </span>,
          all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
          unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
          vulnerabilities: <span>{ formatNumber( row?.num_vulnerabilities || 0 ) }</span>,
          'DeepSurface Scanning Status': <ScanningStatusIndicator hideText timestamp={ row.last_scanned } />,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <button
                  title="Quick View Host"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'host' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Host Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => selectRecord( row.id ) }
                >
                  <InlineSVG type="carretRight" />
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
          id: row.id,
          originalRecord: row,
        };
      case 'num_vulnerabilities':
        if ( includeRisk ) {
          return {
            risk: <div className={ `rowRiskWrapper ${riskRating}` }>
              <RatingBadge
                altVersion
                item={ row }
                itemType={ reportType }
                rating={ riskToRating( row.filtered_risk ) }
              />
              <RiskReduction item={ row } itemType={ reportType } riskType="filtered_risk" />
            </div>,
            name: <React.Fragment>
              { reportTypeNameLink( row, reportType ) }
              <TagList tags={_tags} truncation={ 2 } />
            </React.Fragment>,
            vulnerabilities: <span>{ formatNumber( row?.num_vulnerabilities || 0 ) }</span>,
            sensitive_assets: <span
              className={ `sensitiveAssetsCount ${ row.num_sensitive_nodes !== 0 ? 'hasAssets' : ''}`}
            >
              { formatNumber( row.num_sensitive_nodes || 0 ) }
            </span>,
            all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
            unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
            'DeepSurface Scanning Status': <ScanningStatusIndicator hideText timestamp={ row.last_scanned } />,
            actions: <React.Fragment>
              {
                isNotEmpty( row.id ) &&
                <React.Fragment>
                  <button
                    title="Quick View Host"
                    className="roundGlyphButton light viewRecordButton"
                    onClick={ ( e ) => viewRecord( e, row, 'host' ) }
                  >
                    <InlineSVG type="moreHorizontal" />
                  </button>
                  <button
                    title="View Full Host Details"
                    className="roundGlyphButton light selectRecordButton"
                    onClick={ () => selectRecord( row.id ) }
                  >
                    <InlineSVG type="carretRight" />
                  </button>
                </React.Fragment>
              }
            </React.Fragment>,
            id: row.id,
            originalRecord: row,
          };
        }
        return {
          name: <React.Fragment>
            { reportTypeNameLink( row, reportType ) }
            <TagList tags={_tags} truncation={ 2 } />
          </React.Fragment>,
          vulnerabilities: <span>{ formatNumber( row?.num_vulnerabilities || 0 ) }</span>,
          sensitive_assets: <span
            className={ `sensitiveAssetsCount ${ row.num_sensitive_nodes !== 0 ? 'hasAssets' : ''}`}
          >
            { formatNumber( row.num_sensitive_nodes || 0 ) }
          </span>,
          all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
          unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
          'DeepSurface Scanning Status': <ScanningStatusIndicator hideText timestamp={ row.last_scanned } />,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <button
                  title="Quick View Host"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'host' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Host Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => selectRecord( row.id ) }
                >
                  <InlineSVG type="carretRight" />
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
          id: row.id,
          originalRecord: row,
        };
      default:
        return {
          name: <React.Fragment>
            { reportTypeNameLink( row, reportType ) }
            <TagList tags={_tags} truncation={ 2 } />
          </React.Fragment>,
          risk: <div className={ `rowRiskWrapper ${riskRating}` }>
            <RatingBadge item={ row } itemType={ reportType } altVersion rating={ riskToRating( row.filtered_risk ) } />
            <RiskReduction item={ row } itemType={ reportType } riskType="filtered_risk" />
          </div>,
          sensitive_assets: <span
            className={ `sensitiveAssetsCount ${ row.num_sensitive_nodes !== 0 ? 'hasAssets' : ''}`}
          >
            { formatNumber( row.num_sensitive_nodes || 0 ) }
          </span>,
          all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
          unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
          vulnerabilities: <span>{ formatNumber( row?.num_vulnerabilities || 0 ) }</span>,
          'DeepSurface Scanning Status': <ScanningStatusIndicator hideText timestamp={ row.last_scanned } />,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <button
                  title="Quick View Host"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'host' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Host Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => selectRecord( row.id ) }
                >
                  <InlineSVG type="carretRight" />
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
          id: row.id,
          originalRecord: row,
        };
      }
    case 'vulnerability':
      switch ( orderBy ) {
      case 'filtered_risk':
        if ( includeRisk ) {
          return {
            risk: <div className={ `rowRiskWrapper ${riskRating}` }>
              <RatingBadge
                item={ row }
                itemType={ reportType }
                altVersion
                rating={ riskToRating( row.filtered_risk ) }
              />
              <RiskReduction item={ row } itemType={ reportType } riskType="filtered_risk" />
            </div>,
            name: reportTypeNameLink( row, reportType ),
            exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
            'CVSS': <span>{ row.cvss_base_score }</span>,
            affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
            all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
            unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
            actions: <React.Fragment>
              {
                isNotEmpty( row.id ) &&
                <React.Fragment>
                  <button
                    title="Quick View Vulnerability"
                    className="roundGlyphButton light viewRecordButton"
                    onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                  >
                    <InlineSVG type="moreHorizontal" />
                  </button>
                  <button
                    title="View Full Vulnerability Details"
                    className="roundGlyphButton light selectRecordButton"
                    onClick={ () => selectRecord( row.id ) }
                  >
                    <InlineSVG type="carretRight" />
                  </button>
                </React.Fragment>
              }
            </React.Fragment>,
            id: row.id,
            originalRecord: row,
          };
        }
        return {
          name: reportTypeNameLink( row, reportType ),
          exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
          'CVSS': <RatingBadge
            rating={ cvssScoreToRating( row.cvss_base_score ) }
            // eslint-disable-next-line max-len
            alternateText={ `CVSS Score: ${row.cvss_base_score}`}
            elementClass="cvssRatingBadge"
          />,
          affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
          all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
          unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <button
                  title="Quick View Vulnerability"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Vulnerability Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => selectRecord( row.id ) }
                >
                  <InlineSVG type="carretRight" />
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
          id: row.id,
          originalRecord: row,
        };
      case 'cvss_base_score':
        if ( includeRisk ) {
          return {
            risk: <div className={ `rowRiskWrapper ${riskRating}` }>
              <RatingBadge
                item={ row }
                itemType={ reportType }
                altVersion
                rating={ riskToRating( row.filtered_risk ) }
              />
              <RiskReduction item={ row } itemType={ reportType } riskType="filtered_risk" />
            </div>,
            name: reportTypeNameLink( row, reportType ),
            'CVSS': <RatingBadge
              rating={ cvssScoreToRating( row.cvss_base_score ) }
              // eslint-disable-next-line max-len
              alternateText={ `CVSS Score: ${row.cvss_base_score}`}
              elementClass="cvssRatingBadge"
            />,
            exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
            affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
            all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
            unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
            actions: <React.Fragment>
              {
                isNotEmpty( row.id ) &&
                <React.Fragment>
                  <button
                    title="Quick View Vulnerability"
                    className="roundGlyphButton light viewRecordButton"
                    onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                  >
                    <InlineSVG type="moreHorizontal" />
                  </button>
                  <button
                    title="View Full Vulnerability Details"
                    className="roundGlyphButton light selectRecordButton"
                    onClick={ () => selectRecord( row.id ) }
                  >
                    <InlineSVG type="carretRight" />
                  </button>
                </React.Fragment>
              }
            </React.Fragment>,
            id: row.id,
            originalRecord: row,
          };
        }
        return {
          name: reportTypeNameLink( row, reportType ),
          'CVSS': <RatingBadge
            rating={ cvssScoreToRating( row.cvss_base_score ) }
            // eslint-disable-next-line max-len
            alternateText={ `CVSS Score: ${row.cvss_base_score}`}
            elementClass="cvssRatingBadge"
          />,
          exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
          affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
          all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
          unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <button
                  title="Quick View Vulnerability"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Vulnerability Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => selectRecord( row.id ) }
                >
                  <InlineSVG type="carretRight" />
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
          id: row.id,
          originalRecord: row,
        };
      case 'exploit_status':
        if ( includeRisk ) {
          return {
            risk: <div className={ `rowRiskWrapper ${riskRating}` }>
              <RatingBadge
                item={ row }
                itemType={ reportType }
                altVersion
                rating={ riskToRating( row.filtered_risk ) }
              />
              <RiskReduction item={ row } itemType={ reportType } riskType="filtered_risk" />
            </div>,
            name: reportTypeNameLink( row, reportType ),
            exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
            'CVSS': <span>{ row.cvss_base_score }</span>,
            affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
            all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
            unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
            actions: <React.Fragment>
              {
                isNotEmpty( row.id ) &&
                <React.Fragment>
                  <button
                    title="Quick View Vulnerability"
                    className="roundGlyphButton light viewRecordButton"
                    onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                  >
                    <InlineSVG type="moreHorizontal" />
                  </button>
                  <button
                    title="View Full Vulnerability Details"
                    className="roundGlyphButton light selectRecordButton"
                    onClick={ () => selectRecord( row.id ) }
                  >
                    <InlineSVG type="carretRight" />
                  </button>
                </React.Fragment>
              }
            </React.Fragment>,
            id: row.id,
            originalRecord: row,
          };
        }
        return {
          name: reportTypeNameLink( row, reportType ),
          exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
          'CVSS': <RatingBadge
            rating={ cvssScoreToRating( row.cvss_base_score ) }
            // eslint-disable-next-line max-len
            alternateText={ `CVSS Score: ${row.cvss_base_score}`}
            elementClass="cvssRatingBadge"
          />,
          affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
          all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
          unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <button
                  title="Quick View Vulnerability"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Vulnerability Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => selectRecord( row.id ) }
                >
                  <InlineSVG type="carretRight" />
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
          id: row.id,
          originalRecord: row,
        };
      case 'num_hosts':
        if ( includeRisk ) {
          return {
            risk: <div className={ `rowRiskWrapper ${riskRating}` }>
              <RatingBadge
                item={ row }
                itemType={ reportType }
                altVersion
                rating={ riskToRating( row.filtered_risk ) }
              />
              <RiskReduction item={ row } itemType={ reportType } riskType="filtered_risk" />
            </div>,
            name: reportTypeNameLink( row, reportType ),
            affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
            exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
            'CVSS': <span>{ row.cvss_base_score }</span>,
            all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
            unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
            actions: <React.Fragment>
              {
                isNotEmpty( row.id ) &&
                <React.Fragment>
                  <button
                    title="Quick View Vulnerability"
                    className="roundGlyphButton light viewRecordButton"
                    onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                  >
                    <InlineSVG type="moreHorizontal" />
                  </button>
                  <button
                    title="View Full Vulnerability Details"
                    className="roundGlyphButton light selectRecordButton"
                    onClick={ () => selectRecord( row.id ) }
                  >
                    <InlineSVG type="carretRight" />
                  </button>
                </React.Fragment>
              }
            </React.Fragment>,
            id: row.id,
            originalRecord: row,
          };
        }
        return {
          name: reportTypeNameLink( row, reportType ),
          affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
          exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
          'CVSS': <RatingBadge
            rating={ cvssScoreToRating( row.cvss_base_score ) }
            // eslint-disable-next-line max-len
            alternateText={ `CVSS Score: ${row.cvss_base_score}`}
            elementClass="cvssRatingBadge"
          />,
          all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
          unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <button
                  title="Quick View Vulnerability"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Vulnerability Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => selectRecord( row.id ) }
                >
                  <InlineSVG type="carretRight" />
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
          id: row.id,
          originalRecord: row,
        };
      case 'cvss_exploit':
        if ( includeRisk ) {
          return {
            risk: <div className={ `rowRiskWrapper ${riskRating}` }>
              <RatingBadge
                item={ row }
                itemType={ reportType }
                altVersion
                rating={ riskToRating( row.filtered_risk ) }
              />
              <RiskReduction item={ row } itemType={ reportType } riskType="filtered_risk" />
            </div>,
            name: reportTypeNameLink( row, reportType ),
            'CVSS': <RatingBadge
              rating={ cvssScoreToRating( row.cvss_base_score ) }
              // eslint-disable-next-line max-len
              alternateText={ `CVSS Score: ${row.cvss_base_score}`}
              elementClass="cvssRatingBadge"
            />,
            exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
            affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
            all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
            unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
            actions: <React.Fragment>
              {
                isNotEmpty( row.id ) &&
                <React.Fragment>
                  <button
                    title="Quick View Vulnerability"
                    className="roundGlyphButton light viewRecordButton"
                    onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                  >
                    <InlineSVG type="moreHorizontal" />
                  </button>
                  <button
                    title="View Full Vulnerability Details"
                    className="roundGlyphButton light selectRecordButton"
                    onClick={ () => selectRecord( row.id ) }
                  >
                    <InlineSVG type="carretRight" />
                  </button>
                </React.Fragment>
              }
            </React.Fragment>,
            id: row.id,
            originalRecord: row,
          };
        }
        return {
          name: reportTypeNameLink( row, reportType ),
          'CVSS': <RatingBadge
            rating={ cvssScoreToRating( row.cvss_base_score ) }
            // eslint-disable-next-line max-len
            alternateText={ `CVSS Score: ${row.cvss_base_score}`}
            elementClass="cvssRatingBadge"
          />,
          exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
          affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
          all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
          unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <button
                  title="Quick View Vulnerability"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Vulnerability Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => selectRecord( row.id ) }
                >
                  <InlineSVG type="carretRight" />
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
          id: row.id,
          originalRecord: row,
        };
      case 'cvss_hosts':
        if ( includeRisk ) {
          return {
            risk: <div className={ `rowRiskWrapper ${riskRating}` }>
              <RatingBadge
                item={ row }
                itemType={ reportType }
                altVersion
                rating={ riskToRating( row.filtered_risk ) }
              />
              <RiskReduction item={ row } itemType={ reportType } riskType="filtered_risk" />
            </div>,
            name: reportTypeNameLink( row, reportType ),
            'CVSS': <RatingBadge
              rating={ cvssScoreToRating( row.cvss_base_score ) }
              // eslint-disable-next-line max-len
              alternateText={ `CVSS Score: ${row.cvss_base_score}`}
              elementClass="cvssRatingBadge"
            />,
            affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
            exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
            all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
            unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
            actions: <React.Fragment>
              {
                isNotEmpty( row.id ) &&
                <React.Fragment>
                  <button
                    title="Quick View Vulnerability"
                    className="roundGlyphButton light viewRecordButton"
                    onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                  >
                    <InlineSVG type="moreHorizontal" />
                  </button>
                  <button
                    title="View Full Vulnerability Details"
                    className="roundGlyphButton light selectRecordButton"
                    onClick={ () => selectRecord( row.id ) }
                  >
                    <InlineSVG type="carretRight" />
                  </button>
                </React.Fragment>
              }
            </React.Fragment>,
            id: row.id,
            originalRecord: row,
          };
        }
        return {
          name: reportTypeNameLink( row, reportType ),
          'CVSS': <RatingBadge
            rating={ cvssScoreToRating( row.cvss_base_score ) }
            // eslint-disable-next-line max-len
            alternateText={ `CVSS Score: ${row.cvss_base_score}`}
            elementClass="cvssRatingBadge"
          />,
          affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
          exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
          all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
          unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <button
                  title="Quick View Vulnerability"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Vulnerability Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => selectRecord( row.id ) }
                >
                  <InlineSVG type="carretRight" />
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
          id: row.id,
          originalRecord: row,
        };
      case 'exploit_cvss':
        if ( includeRisk ) {
          return {
            risk: <div className={ `rowRiskWrapper ${riskRating}` }>
              <RatingBadge
                item={ row }
                itemType={ reportType }
                altVersion
                rating={ riskToRating( row.filtered_risk ) }
              />
              <RiskReduction item={ row } itemType={ reportType } riskType="filtered_risk" />
            </div>,
            name: reportTypeNameLink( row, reportType ),
            exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
            'CVSS': <span>{ row.cvss_base_score }</span>,
            affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
            all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
            unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
            actions: <React.Fragment>
              {
                isNotEmpty( row.id ) &&
                <React.Fragment>
                  <button
                    title="Quick View Vulnerability"
                    className="roundGlyphButton light viewRecordButton"
                    onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                  >
                    <InlineSVG type="moreHorizontal" />
                  </button>
                  <button
                    title="View Full Vulnerability Details"
                    className="roundGlyphButton light selectRecordButton"
                    onClick={ () => selectRecord( row.id ) }
                  >
                    <InlineSVG type="carretRight" />
                  </button>
                </React.Fragment>
              }
            </React.Fragment>,
            id: row.id,
            originalRecord: row,
          };
        }
        return {
          name: reportTypeNameLink( row, reportType ),
          exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
          'CVSS': <RatingBadge
            rating={ cvssScoreToRating( row.cvss_base_score ) }
            // eslint-disable-next-line max-len
            alternateText={ `CVSS Score: ${row.cvss_base_score}`}
            elementClass="cvssRatingBadge"
          />,
          affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
          all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
          unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <button
                  title="Quick View Vulnerability"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Vulnerability Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => selectRecord( row.id ) }
                >
                  <InlineSVG type="carretRight" />
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
          id: row.id,
          originalRecord: row,
        };
      case 'exploit_hosts':
        if ( includeRisk ) {
          return {
            risk: <div className={ `rowRiskWrapper ${riskRating}` }>
              <RatingBadge
                item={ row }
                itemType={ reportType }
                altVersion
                rating={ riskToRating( row.filtered_risk ) }
              />
              <RiskReduction item={ row } itemType={ reportType } riskType="filtered_risk" />
            </div>,
            name: reportTypeNameLink( row, reportType ),
            exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
            affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
            'CVSS': <span>{ row.cvss_base_score }</span>,
            all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
            unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
            actions: <React.Fragment>
              {
                isNotEmpty( row.id ) &&
                <React.Fragment>
                  <button
                    title="Quick View Vulnerability"
                    className="roundGlyphButton light viewRecordButton"
                    onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                  >
                    <InlineSVG type="moreHorizontal" />
                  </button>
                  <button
                    title="View Full Vulnerability Details"
                    className="roundGlyphButton light selectRecordButton"
                    onClick={ () => selectRecord( row.id ) }
                  >
                    <InlineSVG type="carretRight" />
                  </button>
                </React.Fragment>
              }
            </React.Fragment>,
            id: row.id,
            originalRecord: row,
          };
        }
        return {
          name: reportTypeNameLink( row, reportType ),
          exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
          affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
          'CVSS': <RatingBadge
            rating={ cvssScoreToRating( row.cvss_base_score ) }
            // eslint-disable-next-line max-len
            alternateText={ `CVSS Score: ${row.cvss_base_score}`}
            elementClass="cvssRatingBadge"
          />,
          all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
          unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <button
                  title="Quick View Vulnerability"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Vulnerability Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => selectRecord( row.id ) }
                >
                  <InlineSVG type="carretRight" />
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
          id: row.id,
          originalRecord: row,
        };
      case 'hosts_cvss':
        if ( includeRisk ) {
          return {
            risk: <div className={ `rowRiskWrapper ${riskRating}` }>
              <RatingBadge
                item={ row }
                itemType={ reportType }
                altVersion
                rating={ riskToRating( row.filtered_risk ) }
              />
              <RiskReduction item={ row } itemType={ reportType } riskType="filtered_risk" />
            </div>,
            name: reportTypeNameLink( row, reportType ),
            affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
            'CVSS': <span>{ row.cvss_base_score }</span>,
            exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
            all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
            unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
            actions: <React.Fragment>
              {
                isNotEmpty( row.id ) &&
                <React.Fragment>
                  <button
                    title="Quick View Vulnerability"
                    className="roundGlyphButton light viewRecordButton"
                    onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                  >
                    <InlineSVG type="moreHorizontal" />
                  </button>
                  <button
                    title="View Full Vulnerability Details"
                    className="roundGlyphButton light selectRecordButton"
                    onClick={ () => selectRecord( row.id ) }
                  >
                    <InlineSVG type="carretRight" />
                  </button>
                </React.Fragment>
              }
            </React.Fragment>,
            id: row.id,
            originalRecord: row,
          };
        }
        return {
          name: reportTypeNameLink( row, reportType ),
          affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
          'CVSS': <RatingBadge
            rating={ cvssScoreToRating( row.cvss_base_score ) }
            // eslint-disable-next-line max-len
            alternateText={ `CVSS Score: ${row.cvss_base_score}`}
            elementClass="cvssRatingBadge"
          />,
          exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
          all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
          unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <button
                  title="Quick View Vulnerability"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Vulnerability Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => selectRecord( row.id ) }
                >
                  <InlineSVG type="carretRight" />
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
          id: row.id,
          originalRecord: row,
        };
      case 'hosts_exploit':
        if ( includeRisk ) {
          return {
            risk: <div className={ `rowRiskWrapper ${riskRating}` }>
              <RatingBadge
                item={ row }
                itemType={ reportType }
                altVersion
                rating={ riskToRating( row.filtered_risk ) }
              />
              <RiskReduction item={ row } itemType={ reportType } riskType="filtered_risk" />
            </div>,
            name: reportTypeNameLink( row, reportType ),
            affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
            exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
            'CVSS': <span>{ row.cvss_base_score }</span>,
            all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
            unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
            actions: <React.Fragment>
              {
                isNotEmpty( row.id ) &&
                <React.Fragment>
                  <button
                    title="Quick View Vulnerability"
                    className="roundGlyphButton light viewRecordButton"
                    onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                  >
                    <InlineSVG type="moreHorizontal" />
                  </button>
                  <button
                    title="View Full Vulnerability Details"
                    className="roundGlyphButton light selectRecordButton"
                    onClick={ () => selectRecord( row.id ) }
                  >
                    <InlineSVG type="carretRight" />
                  </button>
                </React.Fragment>
              }
            </React.Fragment>,
            id: row.id,
            originalRecord: row,
          };
        }
        return {
          name: reportTypeNameLink( row, reportType ),
          affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
          exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
          'CVSS': <RatingBadge
            rating={ cvssScoreToRating( row.cvss_base_score ) }
            // eslint-disable-next-line max-len
            alternateText={ `CVSS Score: ${row.cvss_base_score}`}
            elementClass="cvssRatingBadge"
          />,
          all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
          unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <button
                  title="Quick View Vulnerability"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Vulnerability Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => selectRecord( row.id ) }
                >
                  <InlineSVG type="carretRight" />
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
          id: row.id,
          originalRecord: row,
        };
      default:
        return {
          risk: <div className={ `rowRiskWrapper ${riskRating}` }>
            <RatingBadge item={ row } itemType={ reportType } altVersion rating={ riskToRating( row.filtered_risk ) } />
            <RiskReduction item={ row } itemType={ reportType } riskType="filtered_risk" />
          </div>,
          name: reportTypeNameLink( row, reportType ),
          exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
          'CVSS': <span>{ row.cvss_base_score }</span>,
          affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
          all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
          unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <button
                  title="Quick View Vulnerability"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Vulnerability Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => selectRecord( row.id ) }
                >
                  <InlineSVG type="carretRight" />
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
          id: row.id,
          originalRecord: row,
        };
      }
    case 'patch':
      switch ( orderBy ) {
      case 'filtered_risk':
        if ( includeRisk ) {
          return {
            risk: <div className={ `rowRiskWrapper ${riskRating}` }>
              <RatingBadge item={ row } itemType={ reportType } altVersion rating={ riskToRating( row[riskType] ) } />
              <RiskReduction item={ row } itemType={ reportType } riskType={riskType} />
            </div>,
            name: reportTypeNameLink( row, reportType ),
            superseded_patches: <span>{ formatNumber( row?.num_supersedes || 0 ) }</span>,
            affected_hosts: <span>{ formatNumber( row?.num_hosts || 0 ) }</span>,
            vulnerabilities: <span>{ formatNumber( row?.num_vulnerabilities || 0 ) }</span>,
            actions: <React.Fragment>
              {
                isNotEmpty( row.id ) &&
                <React.Fragment>
                  <button
                    title="Quick View Patch"
                    className="roundGlyphButton light viewRecordButton"
                    onClick={ ( e ) => viewRecord( e, row, 'patch' ) }
                  >
                    <InlineSVG type="moreHorizontal" />
                  </button>
                  <button
                    title="View Full Patch Details"
                    className="roundGlyphButton light selectRecordButton"
                    onClick={ () => selectRecord( row.id ) }
                  >
                    <InlineSVG type="carretRight" />
                  </button>
                </React.Fragment>
              }
            </React.Fragment>,
            id: row.id,
            originalRecord: row,
          };
        }
        return {
          name: reportTypeNameLink( row, reportType ),
          superseded_patches: <span>{ formatNumber( row?.num_supersedes || 0 ) }</span>,
          affected_hosts: <span>{ formatNumber( row?.num_hosts || 0 ) }</span>,
          vulnerabilities: <span>{ formatNumber( row?.num_vulnerabilities || 0 ) }</span>,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <button
                  title="Quick View Patch"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'patch' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Patch Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => selectRecord( row.id ) }
                >
                  <InlineSVG type="carretRight" />
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
          id: row.id,
          originalRecord: row,
        };
      case 'num_hosts':
        if ( includeRisk ) {
          return {
            risk: <div className={ `rowRiskWrapper ${riskRating}` }>
              <RatingBadge item={ row } itemType={ reportType } altVersion rating={ riskToRating( row[riskType] ) } />
              <RiskReduction item={ row } itemType={ reportType } riskType={riskType} />
            </div>,
            name: reportTypeNameLink( row, reportType ),
            affected_hosts: <span>{ formatNumber( row?.num_hosts || 0 ) }</span>,
            superseded_patches: <span>{ formatNumber( row?.num_supersedes || 0 ) }</span>,
            vulnerabilities: <span>{ formatNumber( row?.num_vulnerabilities || 0 ) }</span>,
            actions: <React.Fragment>
              {
                isNotEmpty( row.id ) &&
                <React.Fragment>
                  <button
                    title="Quick View Patch"
                    className="roundGlyphButton light viewRecordButton"
                    onClick={ ( e ) => viewRecord( e, row, 'patch' ) }
                  >
                    <InlineSVG type="moreHorizontal" />
                  </button>
                  <button
                    title="View Full Patch Details"
                    className="roundGlyphButton light selectRecordButton"
                    onClick={ () => selectRecord( row.id ) }
                  >
                    <InlineSVG type="carretRight" />
                  </button>
                </React.Fragment>
              }
            </React.Fragment>,
            id: row.id,
            originalRecord: row,
          };
        }
        return {
          name: reportTypeNameLink( row, reportType ),
          affected_hosts: <span>{ formatNumber( row?.num_hosts || 0 ) }</span>,
          superseded_patches: <span>{ formatNumber( row?.num_supersedes || 0 ) }</span>,
          vulnerabilities: <span>{ formatNumber( row?.num_vulnerabilities || 0 ) }</span>,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <button
                  title="Quick View Patch"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'patch' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Patch Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => selectRecord( row.id ) }
                >
                  <InlineSVG type="carretRight" />
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
          id: row.id,
          originalRecord: row,
        };
      case 'num_vulnerabilities':
        if ( includeRisk ) {
          return {
            risk: <div className={ `rowRiskWrapper ${riskRating}` }>
              <RatingBadge item={ row } itemType={ reportType } altVersion rating={ riskToRating( row[riskType] ) } />
              <RiskReduction item={ row } itemType={ reportType } riskType={riskType} />
            </div>,
            name: reportTypeNameLink( row, reportType ),
            vulnerabilities: <span>{ formatNumber( row?.num_vulnerabilities || 0 ) }</span>,
            superseded_patches: <span>{ formatNumber( row?.num_supersedes || 0 ) }</span>,
            affected_hosts: <span>{ formatNumber( row?.num_hosts || 0 ) }</span>,
            actions: <React.Fragment>
              {
                isNotEmpty( row.id ) &&
                <React.Fragment>
                  <button
                    title="Quick View Patch"
                    className="roundGlyphButton light viewRecordButton"
                    onClick={ ( e ) => viewRecord( e, row, 'patch' ) }
                  >
                    <InlineSVG type="moreHorizontal" />
                  </button>
                  <button
                    title="View Full Patch Details"
                    className="roundGlyphButton light selectRecordButton"
                    onClick={ () => selectRecord( row.id ) }
                  >
                    <InlineSVG type="carretRight" />
                  </button>
                </React.Fragment>
              }
            </React.Fragment>,
            id: row.id,
            originalRecord: row,
          };
        }
        return {
          name: reportTypeNameLink( row, reportType ),
          vulnerabilities: <span>{ formatNumber( row?.num_vulnerabilities || 0 ) }</span>,
          superseded_patches: <span>{ formatNumber( row?.num_supersedes || 0 ) }</span>,
          affected_hosts: <span>{ formatNumber( row?.num_hosts || 0 ) }</span>,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <button
                  title="Quick View Patch"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'patch' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Patch Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => selectRecord( row.id ) }
                >
                  <InlineSVG type="carretRight" />
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
          id: row.id,
          originalRecord: row,
        };
      default:
        return {
          risk: <div className={ `rowRiskWrapper ${riskRating}` }>
            <RatingBadge item={ row } itemType={ reportType } altVersion rating={ riskToRating( row[riskType] ) } />
            <RiskReduction item={ row } itemType={ reportType } riskType={riskType} />
          </div>,
          name: reportTypeNameLink( row, reportType ),
          superseded_patches: <span>{ formatNumber( row?.num_supersedes || 0 ) }</span>,
          affected_hosts: <span>{ formatNumber( row?.num_hosts || 0 ) }</span>,
          vulnerabilities: <span>{ formatNumber( row?.num_vulnerabilities || 0 ) }</span>,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <button
                  title="Quick View Patch"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'patch' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Patch Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => selectRecord( row.id ) }
                >
                  <InlineSVG type="carretRight" />
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
          id: row.id,
          originalRecord: row,
        };
      }
    case 'path':
      if ( includeRisk ) {
        return {
          risk: <div className={ `rowRiskWrapper ${riskRating}` }>
            <RatingBadge item={ row } itemType={ reportType } altVersion rating={ riskToRating( row[riskType] ) } />
            <RiskReduction item={ row } itemType={ reportType } riskType={riskType} />
          </div>,
          name: reportTypeNameLink( row, reportType ),
          steps: <span>{ row.edges.length }</span>,
          // eslint-disable-next-line max-len
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <button
                className="roundGlyphButton light selectRecordButton"
                onClick={ () => selectRecord( row.id ) }
              >
                <InlineSVG type="carretRight" version="primary"/>
              </button>
            }
          </React.Fragment>,
          id: row.id,
          originalRecord: row,
        };
      }
      return {
        name: reportTypeNameLink( row, reportType ),
        steps: <span>{ row.edges.length }</span>,
        // eslint-disable-next-line max-len
        actions: <React.Fragment>
          {
            isNotEmpty( row.id ) &&
            <button
              className="roundGlyphButton light selectRecordButton"
              onClick={ () => selectRecord( row.id ) }
            >
              <InlineSVG type="carretRight" version="primary"/>
            </button>
          }
        </React.Fragment>,
        id: row.id,
        originalRecord: row,
      };
    case 'user':
      if ( includeRisk ) {
        return {
          risk: <div className={ `rowRiskWrapper ${riskRating}` }>
            <RatingBadge item={ row } itemType={ reportType } altVersion rating={ riskToRating( row[riskType] ) } />
            <RiskReduction item={ row } itemType={ reportType } riskType={riskType} />
          </div>,
          name: reportTypeNameLink( row, reportType ),
          // eslint-disable-next-line max-len
          recently_accessed_hosts: <span>{ formatNumber( row.active_hosts?.length || 0 ) }</span>,
          domain_groups: <span>{ formatNumber( row.domain_groups?.length || 0 ) }</span>,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <button
                  title="View Full User Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => selectRecord( row.id ) }
                >
                  <InlineSVG type="carretRight" />
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
          id: row.id,
          originalRecord: row,
        };
      }
      return {
        name: reportTypeNameLink( row, reportType ),
        // eslint-disable-next-line max-len
        recently_accessed_hosts: <span>{ formatNumber( row.active_hosts?.length || 0 ) }</span>,
        domain_groups: <span>{ formatNumber( row.domain_groups?.length || 0 ) }</span>,
        actions: <React.Fragment>
          {
            isNotEmpty( row.id ) &&
            <React.Fragment>
              <button
                title="View Full User Details"
                className="roundGlyphButton light selectRecordButton"
                onClick={ () => selectRecord( row.id ) }
              >
                <InlineSVG type="carretRight" />
              </button>
            </React.Fragment>
          }
        </React.Fragment>,
        id: row.id,
        originalRecord: row,
      };
    case 'instance':
      switch ( _groupType ) {
      case 'host':
        if ( includeRisk ) {
          return {
            risk: <div className={ `rowRiskWrapper ${riskRating}` }>
              <RatingBadge
                item={ row }
                itemType={ _groupType }
                altVersion
                rating={ riskToRating( row.filtered_risk ) }
              />
              <RiskReduction item={ row } itemType={ _groupType } riskType="filtered_risk" />
            </div>,
            // eslint-disable-next-line max-len
            name: reportTypeNameLink( row, _groupType ),
            vulns: formatNumber( row.num_vulnerabilities ),
            // eslint-disable-next-line camelcase
            product_name: row.product_name,
            addresses: isNotEmpty( row?.ip_addresses )
              ? formatIpAddresses( row?.ip_addresses )
              : <span></span>,
            // eslint-disable-next-line camelcase
            'Last DeepSurface Scan': formatLastScanned( row, _groupType ),
            id: row.id,
            originalRecord: row,
            actions: <React.Fragment>
              {
                isNotEmpty( row.id ) &&
                <React.Fragment>
                  <AddRemoveFilterAction row={ row } />
                  <button
                    title="Quick View Host"
                    className="roundGlyphButton light viewRecordButton"
                    onClick={ ( e ) => viewRecord( e, row, 'host' ) }
                  >
                    <InlineSVG type="moreHorizontal" />
                  </button>
                  <button
                    title="View Full Host Details"
                    className="roundGlyphButton light selectRecordButton"
                    onClick={ () => insightGoToRecord( row, _groupType ) }
                  >
                    <InlineSVG type="carretRight" version="primary"/>
                  </button>
                </React.Fragment>
              }
            </React.Fragment>,
          };
        }
        return {
          // eslint-disable-next-line max-len
          name: reportTypeNameLink( row, _groupType ),
          vulns: formatNumber( row.num_vulnerabilities ),
          // eslint-disable-next-line camelcase
          product_name: row.product_name,
          addresses: isNotEmpty( row?.ip_addresses )
            ? formatIpAddresses( row?.ip_addresses )
            : <span></span>,
          // eslint-disable-next-line camelcase
          'Last DeepSurface Scan': formatLastScanned( row, _groupType ),
          id: row.id,
          originalRecord: row,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <AddRemoveFilterAction row={ row } />
                <button
                  title="Quick View Host"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'host' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Host Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => insightGoToRecord( row, _groupType ) }
                >
                  <InlineSVG type="carretRight" version="primary"/>
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
        };
      case 'patch':
        if ( includeRisk ) {
          return {
            risk: <div className={ `rowRiskWrapper ${riskRating}` }>
              <RatingBadge
                item={ row }
                itemType={ _groupType }
                altVersion
                rating={ riskToRating( row.filtered_risk ) }
              />
              <RiskReduction item={ row } itemType={ _groupType } riskType="filtered_risk" />
            </div>,
            name: reportTypeNameLink( row, _groupType ),
            hosts: formatNumber( row.num_hosts ),
            vulns: formatNumber( row.num_vulnerabilities ),
            description: isNotEmpty( row.description )
              ? <pre>{ row.description }</pre>
              : <span></span>,
            id: row.id,
            originalRecord: row,
            actions: <React.Fragment>
              {
                isNotEmpty( row.id ) &&
                <React.Fragment>
                  <AddRemoveFilterAction row={ row } />
                  <button
                    title="Quick View Patch"
                    className="roundGlyphButton light viewRecordButton"
                    onClick={ ( e ) => viewRecord( e, row, 'patch' ) }
                  >
                    <InlineSVG type="moreHorizontal" />
                  </button>
                  <button
                    title="View Full Patch Details"
                    className="roundGlyphButton light selectRecordButton"
                    onClick={ () => insightGoToRecord( row, _groupType ) }
                  >
                    <InlineSVG type="carretRight" version="primary"/>
                  </button>
                </React.Fragment>
              }
            </React.Fragment>,
          };
        }
        return {
          name: reportTypeNameLink( row, _groupType ),
          hosts: formatNumber( row.num_hosts ),
          vulns: formatNumber( row.num_vulnerabilities ),
          description: isNotEmpty( row.description )
            ? <pre>{ row.description }</pre>
            : <span></span>,
          id: row.id,
          originalRecord: row,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <AddRemoveFilterAction row={ row } />
                <button
                  title="Quick View Patch"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'patch' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Patch Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => insightGoToRecord( row, _groupType ) }
                >
                  <InlineSVG type="carretRight" version="primary"/>
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
        };
      case 'patch_cumulative':
        if ( includeRisk ) {
          return {
            risk: <div className={ `rowRiskWrapper ${riskRating}` }>
              <RatingBadge item={ row } itemType={ 'patch' } altVersion rating={ riskToRating( row.filtered_risk ) } />
              <RiskReduction item={ row } itemType={ 'patch' } riskType="filtered_risk" />
            </div>,
            name: reportTypeNameLink( row, _groupType ),
            hosts: formatNumber( row.num_hosts ),
            vulns: formatNumber( row.num_vulnerabilities ),
            description: isNotEmpty( row?.description )
              ? <pre>{ row.description }</pre>
              : <span></span>,
            id: row.id,
            originalRecord: row,
            actions: <React.Fragment>
              {
                isNotEmpty( row.id ) &&
                <React.Fragment>
                  <AddRemoveFilterAction row={ row } />
                  <button
                    title="Quick View Patch"
                    className="roundGlyphButton light viewRecordButton"
                    onClick={ ( e ) => viewRecord( e, row, 'patch' ) }
                  >
                    <InlineSVG type="moreHorizontal" />
                  </button>
                  <button
                    title="View Full Patch Details"
                    className="roundGlyphButton light selectRecordButton"
                    onClick={ () => insightGoToRecord( row, _groupType ) }
                  >
                    <InlineSVG type="carretRight" version="primary"/>
                  </button>
                </React.Fragment>
              }
            </React.Fragment>,
          };
        }
        return {

          name: reportTypeNameLink( row, _groupType ),
          hosts: formatNumber( row.num_hosts ),
          vulns: formatNumber( row.num_vulnerabilities ),
          description: isNotEmpty( row?.description )
            ? <pre>{ row.description }</pre>
            : <span></span>,
          id: row.id,
          originalRecord: row,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <AddRemoveFilterAction row={ row } />
                <button
                  title="Quick View Patch"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'patch' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Patch Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => insightGoToRecord( row, _groupType ) }
                >
                  <InlineSVG type="carretRight" version="primary"/>
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
        };
      case  'vulnerability':
        if ( includeRisk ) {
          return {
            risk: <div className={ `rowRiskWrapper ${riskRating}` }>
              <RatingBadge
                item={ row }
                itemType={ _groupType }
                altVersion
                rating={ riskToRating( row?.filtered_risk ) }
              />
              <RiskReduction item={ row } itemType={ _groupType } riskType="filtered_risk" />
            </div>,
            name: reportTypeNameLink( row, _groupType ),
            exploit_status: <ExploitStatus status={row?.exploit_status} fullVersion={false} />,
            CVSS: row?.cvss_base_score,
            hosts: formatNumber( row?.num_hosts ),
            description: isNotEmpty( row.description )
              ? <pre>{ row.description }</pre>
              : <span></span>,
            id: row.id,
            originalRecord: row,
            actions: <React.Fragment>
              {
                isNotEmpty( row.id ) &&
                <React.Fragment>
                  <AddRemoveFilterAction row={ row } />
                  <button
                    title="Quick View Vulnerability"
                    className="roundGlyphButton light viewRecordButton"
                    onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                  >
                    <InlineSVG type="moreHorizontal" />
                  </button>
                  <button
                    title="View Full Vulnerability Details"
                    className="roundGlyphButton light selectRecordButton"
                    onClick={ () => insightGoToRecord( row, _groupType ) }
                  >
                    <InlineSVG type="carretRight" version="primary"/>
                  </button>
                </React.Fragment>
              }
            </React.Fragment>,
          };
        }
        return {
          name: reportTypeNameLink( row, _groupType ),
          exploit_status: <ExploitStatus status={row?.exploit_status} fullVersion={false} />,
          CVSS: <RatingBadge
            rating={ cvssScoreToRating( row?.cvss_base_score ) }
            // eslint-disable-next-line max-len
            alternateText={ `CVSS Score: ${row?.cvss_base_score}`}
            elementClass="cvssRatingBadge"
          />,
          hosts: formatNumber( row?.num_hosts ),
          description: isNotEmpty( row.description )
            ? <pre>{ row.description }</pre>
            : <span></span>,
          id: row.id,
          originalRecord: row,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <AddRemoveFilterAction row={ row } />
                <button
                  title="Quick View Vulnerability"
                  className="roundGlyphButton light viewRecordButton"
                  onClick={ ( e ) => viewRecord( e, row, 'vulnerability' ) }
                >
                  <InlineSVG type="moreHorizontal" />
                </button>
                <button
                  title="View Full Vulnerability Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ () => insightGoToRecord( row, _groupType ) }
                >
                  <InlineSVG type="carretRight" version="primary"/>
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
        };
      case 'signature':
        if ( includeRisk ) {
          return {
            risk: <div className={ `rowRiskWrapper ${riskRating}` }>
              <RatingBadge
                item={ row }
                itemType={ _groupType }
                altVersion
                rating={ riskToRating( row?.filtered_risk ) }
              />
              <RiskReduction item={ row } itemType={ _groupType } riskType="filtered_risk" />
            </div>,
            scanner_signature: <ScannerSignature row={ row } />,
            name: <p title={ row.title }>{ row.title }</p>,
            hosts: formatNumber( row.num_hosts ),
            vulns: formatNumber( row.num_vulnerabilities ),
            id: row.id,
            originalRecord: row,
            actions: <React.Fragment>
              {
                isNotEmpty( row.id ) &&
                <React.Fragment>
                  <AddRemoveFilterAction row={ row } />
                  <button
                    title="View Full Signature Details"
                    className="roundGlyphButton light selectRecordButton"
                    onClick={ ( e ) => setSelectedSignature( { ...row, clickEvent: e } ) }
                  >
                    <InlineSVG type="carretRight" version="primary"/>
                  </button>
                </React.Fragment>
              }
            </React.Fragment>,
          };
        }
        return {
          scanner_signature: <ScannerSignature row={ row } />,
          name: <p title={ row.title }>{ row.title }</p>,
          hosts: formatNumber( row.num_hosts ),
          vulns: formatNumber( row.num_vulnerabilities ),
          id: row.id,
          originalRecord: row,
          actions: <React.Fragment>
            {
              isNotEmpty( row.id ) &&
              <React.Fragment>
                <AddRemoveFilterAction row={ row } />
                <button
                  title="View Full Signature Details"
                  className="roundGlyphButton light selectRecordButton"
                  onClick={ ( e ) => setSelectedSignature( { ...row, clickEvent: e } ) }
                >
                  <InlineSVG type="carretRight" version="primary"/>
                </button>
              </React.Fragment>
            }
          </React.Fragment>,
        };
      }
    }
  /* eslint-enable camelcase */
  };

  // whenever the table data changes (ie filters, etc.)
  // this sets the adjusted data that the table will render
  // also needs to figure out the order by class and risk class and set that in order to render the correct information
  React.useEffect( () => {
    if ( isNotEmpty( records ) ) {
      const params = decodeURLHash( );
      let _orderByClass = '';

      const orderPriorityMap = [
        'primary',
        'secondary',
        'tertiary',
      ];
      // eslint-disable-next-line camelcase
      const { order_by, include_risk } = params;

      if ( multipleOrderByOptions.includes( order_by ) ) {
        const orderByStringParts = [];
        // eslint-disable-next-line camelcase
        order_by.split( '_' ).map( ( orderBySectionKey, index ) => {
          if ( index === 0 ) {
            setPrimaryOrderBy( keyToAttrMap[orderBySectionKey] );
          }
          orderByStringParts.push( `${orderPriorityMap[index]}_order_by--${keyToAttrMap[orderBySectionKey]}` );
        } );

        _orderByClass = orderByStringParts.join( ' ' );
      } else {
        setPrimaryOrderBy( order_by );
        // eslint-disable-next-line camelcase
        _orderByClass = `primary_order_by--${order_by}`;
      }

      setOrderByClass( _orderByClass );
      // eslint-disable-next-line camelcase
      setIncludeRiskClass( `include_risk--${isTruthy( include_risk )}` );

      const newData = records.map( row => {
        const rowData = transformRowData( row, order_by, isTruthy( include_risk ) );
        return { ...rowData };
      } );
      setAdjustedData( newData );
    } else {
      setAdjustedData( [] );
    }
  }, [ records, tags ] );

  // only some reports and group types support remediation workflow, this dictates which
  const includeRemediationSelect = licenseInfo => {
    if ( isNotEmpty( licenseInfo ) ) {
      if ( hasAccess( licenseInfo, {}, 'feature', 'remediationWorkflow' ) ) {
        if ( reportType === 'instance' ) {
          return paramsToFilters()['group_type'] !== 'signature';
        }
        if ( reportType === 'path' || reportType === 'user' || reportType === 'vulnerability_data' ) {
          return false;
        }
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <React.Fragment>
      {
        <RecordCard
          record={ recordCardRecord }
          type={ recordCardType }
          context="risk_insight_table"
          show={ showRecordCard }
          setShow={ setShowRecordCard }
          fetchRecordDetails
          options={ {
            isDismissable: true,
            isDraggable: true,
            // eslint-disable-next-line camelcase
            include_shade: true,
            // eslint-disable-next-line camelcase
            include_risk: true,
            // eslint-disable-next-line camelcase
            include_vulnerability_instances: true,
            // eslint-disable-next-line camelcase
            include_cvss_breakdown: true,
            // eslint-disable-next-line camelcase
            include_details_link: true,
          } }
        />
      }
      {
        isNotEmpty( licenseInfo ) &&
        <div
          // eslint-disable-next-line max-len
          className={ `${visualCollapsed ? 'visualCollapsed' : ''} riskInsightTableWrapper` }
        >
          { loading && <Loading /> }
          { !loading &&
            <DataTable
              sortableColumns={
                ( reportType === 'instance' || reportType === 'vulnerability_data' )
                  ? instanceGroupingSortableColumns[paramsToFilters()['group_type'] || 'host']
                  : reportTypeSortableColumns[reportType]
              }
              sortBy= { primaryOrderBy }
              data={ adjustedData }
              // eslint-disable-next-line max-len
              elementClass={ `${includeRiskClass} ${orderByClass} ${ ( includeRemediationSelect( licenseInfo ) && !demoMode ) ? 'hasRemediation' : '' }  ${reportType}_riskInsightDataTable ${paramsToFilters()['group_type'] || 'host'}_groupBy riskInsightDataTable` }
              externalHoverRecord={hoverRecord}
              setExternalHoverRecord={setHoverRecord}
              withSelect={ includeRemediationSelect( licenseInfo ) && !demoMode }
              onSelect={ onRemediationSelect }
              // eslint-disable-next-line max-len
              selectedIDs={ includeRemediationSelect( licenseInfo ) ? Object.keys( remediationItems[remediationItemsKey( reportType )] ) : [] }
              setSelectedIDs={ setRemediationItemsForType }
              allowHover
            />
          }
        </div>
      }
    </React.Fragment>
  );
};

export default InsightTable;