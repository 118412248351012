/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { CurrentUserContext } from '../../Contexts/CurrentUser';
import { OnboardingWizardContext } from '../../Contexts/OnboardingWizard';
import InlineSVG from '../../shared/InlineSVG';

import Notification from '../../shared/Notification';

const helpStepLinkMap = {
  // eslint-disable-next-line camelcase
  user_managed: {
    1: '#.=help_documentation&main=user_guide&section=scanning&help_page=scanning_general_settings',
    2: '#.=help_documentation&main=user_guide&section=scanning&help_page=user_managed',
    3: '#.=help_documentation&main=user_guide&section=scanning&help_page=vulnerability_scanners',
    4: '#.=help_documentation&main=user_guide&section=activity&help_page=tasks',
    5: '#.=help_documentation&main=user_guide&section=setup&help_page=sensitive_assets_policies',
  },
  agentless: {
    1: '#.=help_documentation&main=user_guide&section=scanning&help_page=scanning_general_settings',
    2: '#.=help_documentation&main=user_guide&section=scanning&help_page=credentials',
    3: '#.=help_documentation&main=user_guide&section=scanning&help_page=scan_groups',
    4: '#.=help_documentation&main=user_guide&section=scanning&help_page=vulnerability_scanners',
    5: '#.=help_documentation&main=user_guide&section=activity&help_page=tasks',
    6: '#.=help_documentation&main=user_guide&section=setup&help_page=sensitive_assets_policies',
  },
  agent: {
    1: '#.=help_documentation&main=user_guide&section=scanning&help_page=scanning_general_settings',
    2: '#.=help_documentation&main=user_guide&section=scanning&help_page=agents',
    3: '#.=help_documentation&main=user_guide&section=scanning&help_page=vulnerability_scanners',
    4: '#.=help_documentation&main=user_guide&section=activity&help_page=tasks',
    5: '#.=help_documentation&main=user_guide&section=setup&help_page=sensitive_assets_policies',
  },
};

import {
  isNotEmpty,
} from '../../shared/Utilities';
import RatingBadge from '../../shared/RatingBadge';

const WizardModal = ( { disableOnboarding } ) => {

  const [
    wizardStatus,
    ,
    ,
    showOnboarding,
    setShowOnboarding,
    currentWizardStep,
    setCurrentWizardStep,
    scanningMode,
    setScanningMode,
  ] = React.useContext( OnboardingWizardContext );

  const [ currentUser, , , demoMode ] = React.useContext( CurrentUserContext );

  const [ expandedStep, setExpandedStep ] = React.useState( null );
  const [ shouldWelcome, setShouldWelcome ] = React.useState( true );
  const [ helpURI, setHelpURI ] = React.useState( '' );

  React.useEffect( () => {
    document.addEventListener( 'keydown', escFunction );

    if ( demoMode ) {
      setShowOnboarding( false );
    } else if ( isNotEmpty( currentUser ) ) {
      if ( currentUser.setup_wizard_disabled ) {
        setShowOnboarding( false );
      } else {
        setShowOnboarding( true );
      }
    }

    return () => {
      document.removeEventListener( 'keydown', escFunction );
    };
  }, [ currentUser, demoMode ] );

  React.useEffect( () => {
    if (
      isNotEmpty( wizardStatus )
      && wizardStatus.agent.every( s => s.status === 'unconfigured' )
      && wizardStatus.agentless.every( s => s.status === 'unconfigured' )
      && wizardStatus.user_managed.every( s => s.status === 'unconfigured' )
    ) {
      setShouldWelcome( true );
    } else {
      setShouldWelcome( false );
    }

  }, [ wizardStatus ] );

  React.useEffect( () => {
    // eslint-disable-next-line max-len
    if ( isNotEmpty( currentWizardStep ) && isNotEmpty( scanningMode ) && isNotEmpty( currentWizardStep[scanningMode] ) ) {
      if ( currentWizardStep[scanningMode].stepNumber === expandedStep ) {
        setExpandedStep( null );
      } else {
        setExpandedStep( currentWizardStep[scanningMode].stepNumber );
      }

      let stepNumber = 1;

      if ( scanningMode === 'agent' ) {
        ( { stepNumber } = currentWizardStep.agent );
      }
      if ( scanningMode === 'agentless' ) {
        ( { stepNumber } = currentWizardStep.agentless );
      }
      if ( scanningMode === 'user_managed' ) {
        ( { stepNumber } = currentWizardStep.user_managed );
      }
      setHelpURI( helpStepLinkMap[scanningMode][stepNumber] );
    }
  }, [ currentWizardStep, scanningMode ] );

  const escFunction = React.useCallback( ( e ) => {
    // esc key
    if ( e.keyCode === 27 ) {
      disableOnboarding();
    }
  }, [] );

  const toggleExpand = stepNumber => {
    const currentStep = wizardStatus[scanningMode].find( step => step.stepNumber === stepNumber );
    setCurrentWizardStep( { ...currentWizardStep, [scanningMode]: currentStep } );
  };

  const changeScanningMode = mode => {
    setScanningMode( mode );
  };

  const exploreSampleData = () => {
    disableOnboarding();
    // eslint-disable-next-line max-len
    window.location = '#.=risk_insight&report=hosts&item_count=100&order_by=filtered_risk&order_direction=DESC&sensitive_assets=any&current_page=1';
  };

  const beginConfiguration = () => {
    setScanningMode( 'agent' );
    setShouldWelcome( false );
  };

  return (
    <div className={`${ showOnboarding ? 'visible' : ''} onboardingWizardContainer`}>
      <div
        className="exitOnboardingButton"
        onClick={ disableOnboarding }
      >
        <InlineSVG type="close" version="light" />
      </div>
      <div className="onboardingShade" onClick={ disableOnboarding } />
      {
        shouldWelcome &&
        <div className="welcomeMessage">
          <h2>Welcome to</h2>
          <InlineSVG type="primaryLogoAlt" size="logoAlt" version="special" />
        </div>
      }
      <span className="collapsingStepsWrapper">
        {
          !shouldWelcome &&
          <React.Fragment>
            <h2 className="scanningModeHeader">How will DeepSurface Scan your environment?</h2>
            <div className={ `${scanningMode} toggleWrapper optionCount--3` }>
              <button
                onClick={ () => changeScanningMode( 'user_managed' )}
                className={ `${scanningMode === 'user_managed' ? 'toggled' : ''} toggleButton`}
              >
                <RatingBadge rating="new" />
                User Managed Configuration
              </button>
              <button
                onClick={ () => changeScanningMode( 'agentless' )}
                className={ `${scanningMode === 'agentless' ? 'toggled' : ''} toggleButton`}
              >
                Credentialed Configuration
              </button>
              <button
                onClick={ () => changeScanningMode( 'agent' )}
                className={ `${scanningMode === 'agent' ? 'toggled' : ''} toggleButton`}
              >
                Agent Configuration
              </button>
            </div>
            {
              scanningMode === 'user_managed' &&
              <p className="scanningModeDescription">
                User Managed Scans are controlled by your script manager of choice. Configure the DeepSurface console
                and download the script to get started. Follow the steps below:
              </p>
            }
            {
              scanningMode === 'agentless' &&
              <p className="scanningModeDescription">
                Credentialed scans are a more traditional credential-based scanning method. To configure credentialed
                scans follow the steps below to upload credentials, configure scan groups, and schedule your scans:
              </p>
            }
            {
              scanningMode === 'agent' &&
              <p className="scanningModeDescription">
                Agent based scans allow DeepSurface users to install an agent on each machine that will be scanned. Our
                light-weight agent can be configured by following the steps below:
              </p>
            }
          </React.Fragment>

        }
        {
          // eslint-disable-next-line max-len
          ( isNotEmpty( wizardStatus ) && isNotEmpty( scanningMode ) && isNotEmpty( currentWizardStep ) && isNotEmpty( currentWizardStep[scanningMode] ) ) &&
          <React.Fragment>
            {
              shouldWelcome
                ? <div className="welcomeWrapper">
                  <p>
                    The application has been seeded with sample data to help get you acquainted
                    with how DeepSurface works.
                  </p>
                  <p>
                    At this point you can choose to explore the sample data or begin configuring
                    the application with your own data.
                  </p>
                  <div className="welcomeChoiceContainer">
                    <div className="imgColumn">
                      <button
                        onClick={ exploreSampleData }
                      >
                        Explore Sample Data
                      </button>
                    </div>
                    <div className="imgColumn">
                      <button
                        onClick={ beginConfiguration }
                      >
                        Begin Configuring your system
                      </button>
                    </div>
                  </div>
                </div>
                : <React.Fragment>
                  {
                    wizardStatus[scanningMode].map( ( step, index ) => {
                      return  <div
                        key={index}
                        onClick={ () => toggleExpand( step.stepNumber ) }
                        // eslint-disable-next-line max-len
                        className={ `collapsingStep ${expandedStep === step.stepNumber ? 'expanded' : ''} ${step.status} ${step.stepName === currentWizardStep[scanningMode].stepName ? 'isCurrent' : ''} ` }
                      >
                        <span className={`stepBadge ${step.status}`}>
                          <span>{step.stepNumber}</span>
                          <InlineSVG type="checkmark" version="light" elementClass="check" />
                          <InlineSVG type="warningAlt" version="light" elementClass="alertImg" />
                        </span>
                        <div className="stepDescription">
                          <strong>{step.helperText}</strong>
                          {step.helperTextDetails}
                          {
                            ( step?.stepName === 'one-auth-scan' && step?.status !== 'configured' ) &&
                            <Notification options={{
                              type: 'warning',
                              message: 'Warning: starting this task will wipe all sample data from the application. ',
                            }}
                            />
                          }
                          <a
                            className="wizardHelpLink"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={ helpURI }
                          >
                            Learn more
                            <InlineSVG type="link" version="primary" />
                          </a>
                          {
                            ( step.status === 'incomplete' && isNotEmpty( step.warning ) ) &&
                              <Notification options={ { type: 'alert', message: step.warning } } />
                          }
                        </div>
                        <div className="linkContainer">
                          <a
                            href={step.link}
                            onClick={ disableOnboarding }
                            className={`stepStatusLink ${step.status}`}
                          >
                            <span className="configure" >Configure</span>
                            <span className="revisit" >Revisit</span>
                            <span className="fix" >Fix</span>
                            <InlineSVG type="carretRight" elementClass="go" />
                            <InlineSVG type="carretRight" elementClass="goLight" version="light" />
                          </a>
                        </div>

                      </div>;
                    } )
                  }
                </React.Fragment>
            }

          </React.Fragment>
        }
      </span>
      {
        // eslint-disable-next-line max-len
        ( isNotEmpty( wizardStatus[scanningMode] ) && isNotEmpty( currentWizardStep[scanningMode] ) && !shouldWelcome ) &&
        <div className="onboardingModalFooter">
          <button
            disabled={currentWizardStep[scanningMode].stepNumber === 1}
            // eslint-disable-next-line max-len
            onClick={ () => setCurrentWizardStep( { ...currentWizardStep, [scanningMode]: wizardStatus[scanningMode].find( step => step.stepNumber === currentWizardStep[scanningMode].stepNumber - 1 )} ) }
          >
            <InlineSVG type="carretLeft" version="light" />
            Back
          </button>
          <div>
            { `Step ${currentWizardStep[scanningMode].stepNumber} of ${wizardStatus[scanningMode].length}` }
          </div>
          <button
            disabled={currentWizardStep[scanningMode].stepNumber === wizardStatus[scanningMode].length}
            // eslint-disable-next-line max-len
            onClick={ () => setCurrentWizardStep( { ...currentWizardStep, [scanningMode]: wizardStatus[scanningMode].find( step => step.stepNumber === currentWizardStep[scanningMode].stepNumber + 1 )} ) }
          >
            Next
            <InlineSVG type="carretRight" version="light" />
          </button>
        </div>
      }
    </div>
  );
};

export default WizardModal;
