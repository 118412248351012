/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../shared/Utilities';
import PageContent from './PageContent';
import { helpContentMap } from './Shared';

const SectionContent = ( {
  mainKey,
  section,
  md,
  getRef,
  toggleItemsFor,
  itemsAreCollapsed,
  printSelection,
  setPrintSelection,
  getPrintLabel,
} ) => {

  return (
    <React.Fragment>
      <div
        id={ `${mainKey}-${section.sectionKey}` }
        // eslint-disable-next-line max-len
        className={ `helpSectionWrapper ${ ( isNotEmpty( printSelection ) && printSelection.sectionKeys?.includes( section.sectionKey ) ) ? 'forPrinting' : ''}` }
        ref={ getRef( 'section', section.sectionKey ) }
      >
        {
          ( isNotEmpty( section.content ) && isNotEmpty( mainKey ) && isNotEmpty( section.sectionKey ) ) &&
          <React.Fragment>
            <h2>{ helpContentMap[mainKey][section.sectionKey].label }</h2>
            <div
              className="markdownContent mainSectionContent"
              dangerouslySetInnerHTML={
                { __html: md.render( section.content ) }
              }
            />
          </React.Fragment>
        }
        {
          Object.values( section.pages ).map( ( page, index ) => {
            return <PageContent
              key={index}
              mainKey={mainKey}
              md={md}
              getRef={getRef}
              toggleItemsFor={toggleItemsFor}
              itemsAreCollapsed={itemsAreCollapsed}
              page={page}
              section={section}
              printSelection={printSelection}
              setPrintSelection={setPrintSelection}
              getPrintLabel={getPrintLabel}
            />;
          } )
        }
      </div>
    </React.Fragment>
  );
};

export default SectionContent;