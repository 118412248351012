/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isEmpty, isNotEmpty } from '../../shared/Utilities';
import InlineSVG from '../../shared/InlineSVG';
import { formatUnixTime } from '../../../../../login/javascript/LegacyFunctions';

import './ScanningStatusIndicator.scss';

const ScanningStatusIndicator = ( {
  hideText=false,
  timestamp,
  address=null,
  version='deepSurface',
  preformatted=false,
} ) => {

  const [ statusClass, setStatusClass ] = React.useState( 'default' );
  const [ timeago, setTimeago ] = React.useState( '' );
  const [ iconKey, setIconKey ] = React.useState( 'scanning_nav' );

  React.useEffect( () => {
    if ( isNotEmpty( timestamp ) && timestamp !== 0 ) {

      let _timestamp;
      if ( preformatted ) {
        _timestamp = new Date( timestamp ).getTime();
      } else {
        _timestamp = timestamp * 1_000;
      }
      let _timeago, _class;
      const now = new Date().getTime();

      const delta = now - ( _timestamp );

      const daysAgo = Math.floor( delta / ( 24 * 60 * 60 * 1_000 ) );

      if ( daysAgo <= 30 ) {
        if ( daysAgo <= 7 ) {
          if ( daysAgo <= 1 ) {
            _timeago = 'Within 24 hours';
            _class = 'blue';
          } else {
            _timeago = 'Within 7 days';
            _class = 'green';
          }
        } else {
          _timeago = 'Within 30 days';
          _class = 'yellow';
        }
      } else {
        _timeago = 'More than 30 days';
        _class = 'orange';
      }

      setTimeago( _timeago );
      setStatusClass( _class );
      setIconKey( 'scanning_nav' );

    } else {
      setTimeago( 'Never scanned' );
      setStatusClass( 'red' );
      setIconKey( 'notScanned' );
    }
  }, [ timestamp, address, version, preformatted ] );

  return (
    <div className={ `scanningStatusIndicator status--${statusClass}`}>
      <div className="labelWrapper">
        <InlineSVG type={iconKey} />
        <strong>Last Scanned: </strong>
        {
          isEmpty( timestamp )
            ? <span>
              {
                version === 'deepSurface'
                  ? 'Not Scanned by DeepSurface'
                  : 'Not Scanned by Third Party'
              }
            </span>
            : <span>{ preformatted ? timestamp : formatUnixTime( timestamp ) }</span>
        }
      </div>
      {
        !hideText &&
        <span className="timeago">{ timeago }</span>
      }
    </div>
  );
};

export default ScanningStatusIndicator;