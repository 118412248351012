/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../../shared/Utilities';

import './TopRecordDetails.scss';
import RecordCard from '../../../../RecordDetails/RecordCard';

const TopRecordDetails = ( { data, settings } ) => {
  const reportTypeToCardMap = {
    hosts: 'scope',
    patches: 'patch',
    vulnerabilities: 'vulnerability',
  };

  const [ records, setRecords ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( data ) ) {
      setRecords( data );
    }
  }, [ settings, data ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( records ) &&
        <div
          // eslint-disable-next-line max-len
          className={ `topRecordDetailsWrapper itemCount--${settings?.item_count} ${ settings?.orientation === 'horizontal' ? 'horizontal' : 'vertical'}`}
        >
          {
            records.map( ( record, index ) => {
              let _record = { ...record };
              if ( settings?.report_type === 'hosts' ) {
                _record = { ..._record, type: 'host' };
              }
              return <RecordCard
                key={index}
                record={_record}
                type={ reportTypeToCardMap[settings?.report_type] }
                options={ {
                  // eslint-disable-next-line camelcase
                  include_risk:  settings?.include_risk,
                  // eslint-disable-next-line camelcase
                  include_vulnerability_instances: settings?.include_instances,
                  // eslint-disable-next-line camelcase
                  include_cvss_breakdown: settings?.include_cvss,
                } }
                show={true}
                context="dashboard"
              />;
            } )
          }
        </div>

      }
    </React.Fragment>
  );
};

export default TopRecordDetails;