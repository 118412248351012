/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import InlineSVG from '../InlineSVG';
import {
  categoryLabelMap,
  encodeURLHash,
  exploitStatusLabelMap,
  globalColors,
  hexToRGB,
  isEmpty,
  isNotEmpty,
  itemIsArray,
  itemIsFunction,
  itemIsString,
  paramsToFilters,
  removeFromURLHash,
  reportTypeDisplayName,
  reportTypeIcon,
  riskToRating,
  triggerHashRefresh,
} from '../Utilities';
import './AppliedFilter.scss';
import {
  appliedFiltersTransformMap,
  defaultFilterValues,
  deprioritizedCategoryClasses,
  excludedTagFilterAttributes,
  forReviewCategoryClasses,
  includedTagFilterAttributes,
  needsRecordLabel,
  omitLabels,
  prioritizedCategoryClasses,
  separateValues,
  tagFilterAttributes,
} from './shared';
import { TagsContext } from '../../Contexts/Tags';

const AppliedFilter = ( {
  label,
  value,
  attribute,
  onRefresh,
  reportType,
  onRemoveCallback=null,
} ) => {

  const [ transformedValue, setTransformedValue ] = React.useState( null );
  const [ tags ] = React.useContext( TagsContext );

  const removeFilter = ( subValue ) => {

    // if there was a specific remove function passed in, do that instead, this is used for the tag Editor version
    // otherwise use the default behavior below
    if ( isNotEmpty( onRemoveCallback ) && itemIsFunction( onRemoveCallback ) ) {
      onRemoveCallback( attribute, subValue );
    // if this is one of the defaultValues that we are keeping track of, replace the param with the
    // default value, otherwise it should be cleared
    } else if (
      isNotEmpty( defaultFilterValues )
      && isNotEmpty( defaultFilterValues[reportType][attribute] )
    ) {
      encodeURLHash( { [attribute]: defaultFilterValues[reportType][attribute] } );
    } else if ( separateValues.includes( attribute ) && isNotEmpty( subValue ) ) {
      const params = paramsToFilters();
      const attributeParams = params[attribute];
      let attributeValues;
      if ( attribute === 'exploit_status' || attribute === 'exploit_statuses' ) {
        if ( isNotEmpty( attributeParams ) && itemIsArray( attributeParams ) ) {
          attributeValues = attributeParams.filter( v => v !== subValue );
        }
      }
      if ( attribute === 'asset_tag_ids' ) {
        if ( isNotEmpty( attributeParams ) && itemIsArray( attributeParams ) ) {
          attributeValues = attributeParams.filter( v => v !== subValue?.id );
        }
      }
      if ( attribute === 'third_party_setting_ids' ) {
        if ( isNotEmpty( attributeParams ) && itemIsArray( attributeParams ) ) {
          attributeValues = attributeParams.filter( v => v !== subValue?.record?.id );
        }
      }
      encodeURLHash( { [attribute]: attributeValues } );
      // need to only remove this specific part of the value, not the entire value
      // removeFromURLHash( attribute );
    } else {
      removeFromURLHash( attribute );
    }
    triggerHashRefresh();
    onRefresh();
  };

  // transform value into user friendly value (as opposed to uuid or something like that)
  React.useEffect( ( ) => {
    let _value;

    if ( isNotEmpty( value ) ) {
      // need to do something special for asset tags, all other transforms happen in the map
      if ( attribute === 'asset_tag_ids' && isNotEmpty( tags ) ) {
        _value = [];
        value.map( id => {
          const _tag = tags[id];
          if ( isNotEmpty( _tag ) ) {
            _value.push( _tag );
          }
        } );
        setTransformedValue( _value );
      // this value needs to be transformed to something user friendly
      } else if ( Object.keys( appliedFiltersTransformMap ).includes( attribute ) ) {
        const transformer = appliedFiltersTransformMap[attribute];

        if ( isNotEmpty( transformer ) ) {
          // some transformers are promises and need to be resolved
          if ( transformer.isPromise ) {
            transformer.formatter( value ).then( response => {
              if ( needsRecordLabel.includes( attribute ) ) {
                _value = {
                  label: reportTypeDisplayName( response, attribute.split( '_' )[0] ),
                  icon: reportTypeIcon[attribute.split( '_' )[0]],
                  type: attribute.split( '_' )[0],
                  record: response,
                };
              } else if ( isEmpty( response ) ) {
                _value = 'N/A';
              } else {
                _value = response;
              }
              setTransformedValue( _value );
            } );

          // not a promise
          } else {
            _value = transformer.formatter( value );
            setTransformedValue( _value );
          }
        } else {
          setTransformedValue( value );
        }
      } else {
        setTransformedValue( value );
      }
    }
  }, [ value, attribute, tags ] );

  const getAttributeValueClass = ( attribute, value ) => {

    if ( isNotEmpty( attribute ) && isNotEmpty( value ) ) {
      // for tag included and excluded, need to make it status--green or status--red
      if ( includedTagFilterAttributes.includes( attribute ) ) {
        return 'included';
      }
      if ( excludedTagFilterAttributes.includes( attribute ) ) {
        return 'excluded';
      }

      // for exploit status, return the class based on the level
      if ( attribute === 'exploit_status' || attribute === 'exploit_statuses' ) {
        if ( value === 'null' || value === 'private' ) {
          return 'divider';
        }
        if ( value === 'published_details' ) {
          return 'low';
        }
        if ( value === 'poc' ) {
          return 'moderate';
        }
        if ( value === 'weaponized' ) {
          return 'high';
        }
        return 'default';
      }
      // for cvss, return the cvss rating class
      if ( attribute === 'cvss_base_score' ) {
        const _value = value;

        if ( isNotEmpty( _value ) && itemIsString( _value ) ) {
          const gtlt = _value.substr( 0, 1 );
          const number = parseFloat( _value.substr( 1 ) );
          // less than amounts
          if ( gtlt === '<' ) {
            if ( number <= 3 ) {
              return 'minimal';
            }
            if ( number <= 6 ) {
              return 'low';
            }
            if ( number <= 7.5 ) {
              return 'moderate';
            }
            if ( number <= 9 ) {
              return 'high';
            }
            return 'critical';
          // more than amounts
          } else if ( gtlt === '>' ) {
            if ( number > 9 ) {
              return 'critical';
            }
            if ( number > 7.5 ) {
              return 'high';
            }
            if ( number > 6 ) {
              return 'moderate';
            }
            if ( number > 3 ) {
              return 'low';
            }
            return 'minimal';
          }
          return 'default';
        }
        return 'default';
      }
      if ( needsRecordLabel.includes( attribute ) ) {
        return riskToRating( value?.record?.filtered_risk || 'default' );
      }
      if ( attribute === 'category' ) {
        if ( deprioritizedCategoryClasses.includes( value ) ) {
          return 'green';
        }
        if ( forReviewCategoryClasses.includes( value ) ) {
          return 'red50';
        }
        if ( prioritizedCategoryClasses.includes( value ) ) {
          return 'red';
        }
        return 'default';
      }
      if ( attribute === 'asset_tag_ids' ) {
        return 'asset_tag';
      }
      return 'default';
    }
    return 'default';
  };

  const getTagStyle = ( attribute, tag, section='main' ) => {
    // tag color styling
    if ( isNotEmpty( attribute ) && attribute === 'asset_tag_ids' && isNotEmpty( tag ) ) {
      let rgb = {};
      if ( isNotEmpty( tag.color ) ) {
        rgb = hexToRGB( tag.color );
      } else {
        rgb = hexToRGB( globalColors.grey );
      }

      if ( isNotEmpty( rgb ) ) {
        const { r, g, b } = rgb;

        const style = {
          color: isNotEmpty( tag.color ) ? tag.color : globalColors['grey'],
        };

        if ( section === 'main' ) {
          style.background = isNotEmpty( tag.color ) ? `rgba(${r},${g},${b}, 0.1)` : globalColors['grey--divider'];
        }
        if ( section === 'button' ) {
          style.background = isNotEmpty( tag.color ) ? tag.color : globalColors['grey'];
        }
        return style;
      }
    }
    return {};
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( transformedValue ) && isNotEmpty( attribute ) ) &&
        <React.Fragment>
          {
            ( separateValues.includes( attribute ) && itemIsArray( transformedValue ) )
              ? transformedValue.map( ( v, i ) => {
                return <div
                  key={i}
                  className={ `appliedFilterButton appliedFilterClass--${getAttributeValueClass( attribute, v )}` }
                  onClick={ () => removeFilter( v ) }
                  style={ getTagStyle( attribute, v ) }
                >
                  {
                    !omitLabels.includes( attribute ) &&
                    <strong className="filterLabel">{ `${label}: ` }</strong>
                  }
                  { isNotEmpty( v.icon ) && v.icon }
                  {
                    ( attribute === 'asset_tag_ids' || attribute === 'third_party_setting_ids' ) &&
                    <span
                      className={ `filterValue ${omitLabels.includes( attribute ) ? 'strong' : ''}`}
                    >
                      { ( isNotEmpty( v ) && isNotEmpty( v.label ) && itemIsString( v.label ) ) && v.label }
                    </span>
                  }
                  {
                    ( attribute === 'exploit_status' || attribute === 'exploit_statuses' ) &&
                    <span
                      className={ `filterValue ${omitLabels.includes( attribute ) ? 'strong' : ''}`}
                    >
                      {
                        ( isNotEmpty( exploitStatusLabelMap[v] ) && itemIsString( exploitStatusLabelMap[v] ) )
                        && exploitStatusLabelMap[v]
                      }
                    </span>
                  }
                  {
                    ( tagFilterAttributes.includes( attribute ) && isNotEmpty( v ) ) &&
                    <React.Fragment>
                      {
                        ( attribute === 'included_host_ids' || attribute === 'excluded_host_ids' )
                          ? v.label
                          : itemIsString( v ) && v
                      }
                    </React.Fragment>
                  }
                  <button
                    style={ getTagStyle( attribute, v, 'button' ) }
                  >
                    <InlineSVG type="remove" />
                  </button>
                </div>;
              } )
              : <div
                // eslint-disable-next-line max-len
                className={ `appliedFilterButton appliedFilterClass--${getAttributeValueClass( attribute, transformedValue )}` }
                onClick={ removeFilter }
              >
                {
                  !omitLabels.includes( attribute ) &&
                  <strong className="filterLabel">{ `${label}: ` }</strong>
                }
                { isNotEmpty( transformedValue.icon ) && transformedValue.icon }
                {
                  isNotEmpty( transformedValue ) &&
                  <React.Fragment>
                    {
                      needsRecordLabel.includes( attribute )
                        ? <span
                          className={ `filterValue ${omitLabels.includes( attribute ) ? 'strong' : ''}`}
                        >
                          {
                            (
                              isNotEmpty( transformedValue )
                              && isNotEmpty( transformedValue.label )
                              && itemIsString( transformedValue.label )
                            ) &&
                            transformedValue.label
                          }
                        </span>
                        : <React.Fragment>
                          {
                            attribute === 'category'
                              ? <span
                                className={ `filterValue ${omitLabels.includes( attribute ) ? 'strong' : ''}`}
                              >
                                {
                                  (
                                    isNotEmpty( categoryLabelMap[transformedValue] )
                                    && itemIsString( categoryLabelMap[transformedValue] )
                                  ) &&
                                  categoryLabelMap[transformedValue]
                                }
                              </span>
                              : <span
                                className={ `filterValue ${omitLabels.includes( attribute ) ? 'strong' : ''}`}
                              >
                                { itemIsString( transformedValue ) && transformedValue }
                                {
                                  (
                                    itemIsArray( transformedValue )
                                    && itemIsString( transformedValue[0] )
                                  ) &&
                                  transformedValue[0]
                                }
                                {
                                  attribute === 'signature_ids' &&
                                  `${transformedValue.scanner} ${transformedValue.signature}`
                                }
                              </span>
                          }
                        </React.Fragment>

                    }
                  </React.Fragment>
                }
                <button>
                  <InlineSVG type="remove" />
                </button>
              </div>
          }
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default AppliedFilter;