/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../shared/Utilities';

const ItemContent = ( {
  item,
  sectionKey,
  pageKey,
  itemKey,
  md,
} ) => {
  return (
    <React.Fragment>
      {
        ( isNotEmpty( item )
        && isNotEmpty( sectionKey )
        && isNotEmpty( pageKey )
        && isNotEmpty( itemKey ) ) &&
        <div
          // eslint-disable-next-line max-len
          id={ `user_guide-${sectionKey}-${pageKey}-contextualHelpItem-${itemKey}` }
          className="helpDocumentationContextualHelpItem"
          dangerouslySetInnerHTML={
            { __html: md.render( item ) }
          }
        />
      }
    </React.Fragment>
  );
};

export default ItemContent;