import {
  removeFromURLHash,
  decodeURLHash,
  encodeURLHash,
  isNotEmpty,
} from '../../../shared/Utilities';

export const typeOptions = [
  'scope',
  'path',
  'patch',
  'vulnerability',
  'node',
];

export const selectExploreItems =  async ( type, items ) => {
  const hash = decodeURLHash();

  const existingSelected = hash[`explore_${type}`] || [];

  const selectedIDs = new Set( existingSelected );

  console.log( selectedIDs );

  const newIDs = items.map( i => i.id );

  newIDs.map( id => {
    selectedIDs.add( id );
  } );

  console.log( selectedIDs );
  encodeURLHash( { [`explore_${type}`]: Array.from( selectedIDs ) } );
};

export const deselectExploreItems = async ( type, items ) => {
  const hash = decodeURLHash();

  const existingSelected = hash[`explore_${type}`];
  let _existingIDs = [];
  const _newIDs = [];

  if ( isNotEmpty( existingSelected ) ) {
    _existingIDs = existingSelected;

    const removedIDs = items.map( i => i.id );

    _existingIDs.map( id => {
      if ( !removedIDs.includes( id ) ) {
        _newIDs.push( id );
      }
    } );
  }

  if ( isNotEmpty( _newIDs ) ) {
    encodeURLHash( { [`explore_${type}`]: _newIDs } );
  } else {
    removeFromURLHash( `explore_${type}` );
  }
};
