/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty, isEmpty, riskToRating, globalColors } from '../../../shared/Utilities';
import { GLOBAL_SCOPE, MENU_WIDTH, SCOPE_PADDING } from './shared';

import './GraphScope.scss';
import { nodeIconMap } from '../../RecordDetails/shared';

const GraphScope = ( {
  scope,
  handleItemMouseUp,
  handleItemMouseDown,
  externalHoverIDs,
  selectedItem,
  setSelectedItem,
  setSelectedItemType,
  ignoreClick,
  setIgnoreClick,
  setSVGScale,
  setSVGPanShift,
  svgDimensions,
  collapsedGraphMenu,
  setIsZoomed,
  setContextMenuItem,
  setContextMenuType,
} ) => {

  const [ labelTruncation, setLabelTruncation ] = React.useState( 24 );

  const handleScopeRightClick = ( e, scope ) => {
    if ( isNotEmpty( e ) && e.button === 2 ) {
      e.stopPropagation();
      e.preventDefault();
      const _item = { ...scope, clickEvent: e };
      setContextMenuItem( _item );
      setContextMenuType( 'scope' );
      return false;
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( scope ) && scope.children?.length > 2  ) {
      setLabelTruncation( 29 );
    }
  }, [ scope ] );

  // when double-clicking a node, we want to zoom into the node
  const handleScopeDoubleClick = ( e, scope ) => {
    if ( isNotEmpty( e ) && e.button === 0 ) {
      e.stopPropagation();
      e.preventDefault();

      const additionalX = collapsedGraphMenu ? SCOPE_PADDING : ( MENU_WIDTH + SCOPE_PADDING );
      const additionalY = collapsedGraphMenu ? 6 * 16 : 2 * 16;

      // find the optimal scale, then back it off a bit so that there is a bit of padding around the item
      // eslint-disable-next-line max-len
      const scale = Math.min( ( svgDimensions.w - additionalX ) / scope?.rectangle?.w, ( svgDimensions.h - additionalY ) / scope?.rectangle?.h ) * 0.95;

      const x = ( scope?.rectangle?.x * scale * -1 ) + additionalX;
      const y = scope?.rectangle?.y * scale * -1 + additionalY;

      setSVGScale( scale );
      setSVGPanShift( { x, y } );
      setIsZoomed( true );
    }

  };

  const handleScopeClick = ( e, scope ) => {
    if ( isNotEmpty( e ) && e.button === 0 ) {
      e.stopPropagation();
      e.preventDefault();

      const _item = { ...scope, clickEvent: e };

      // DO NOT REMOVE!!! used by devs to debug explore behavior -DMC 2023-07-27
      console.log( _item );

      if ( ignoreClick ) {
        setIgnoreClick( false );
      } else if ( isEmpty( selectedItem ) ) {
        setSelectedItem( _item );
        setSelectedItemType( 'scope' );
      } else if ( selectedItem.id === _item.id ) {
        setSelectedItem( null );
        setSelectedItemType( null );
      } else {
        setSelectedItem( _item );
        setSelectedItemType( 'scope' );
      }
    }
  };

  return (
    <React.Fragment>
      <g
        // eslint-disable-next-line max-len
        className={ `${ selectedItem?.id === scope.id ? 'selected' : '' }  ${ externalHoverIDs.includes( scope.id ) ? 'externallyHovered' : ''} graphModelScopeGroup risk-${riskToRating( scope.risk )}` }
        onMouseDown={ handleItemMouseDown }
        onMouseUp={ handleItemMouseUp }
      >
        {
          scope.id !== GLOBAL_SCOPE.id &&
          <React.Fragment>
            <rect
              className="graphModelScopeRectangle"
              x={ scope.rectangle.x }
              y={ scope.rectangle.y }
              width={ scope.rectangle.w }
              height={ scope.rectangle.h }
              fill={ scope.childType ? globalColors['grey--background--light'] : '#FFF' }
              stroke={  globalColors['grey--icon'] }
              rx={ scope.childType ? 8 : 14 }
            />
            <svg
              width={ 22 }
              height={ 22 }
              x={ scope.rectangle.x + ( SCOPE_PADDING * 0.5 ) }
              y={ scope.rectangle.y + ( SCOPE_PADDING * 0.6 ) }
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="scopeIcon"
            >
              { nodeIconMap.scope }
            </svg>
            <text
              x={ scope.rectangle.x + ( SCOPE_PADDING * 1.625 ) }
              y={ scope.rectangle.y + ( SCOPE_PADDING * 0.6 ) }
              fill={ globalColors['darkBlue'] }
              fontSize={ 22 }
              fontWeight="bold"
              dominantBaseline="hanging"
            >
              {
                scope.label?.length > labelTruncation
                  ? `${scope.label?.slice( 0, labelTruncation - 1 )}...`
                  : scope.label
              }
            </text>
            <svg
              width={ 24 }
              height={ 24 }
              x={ scope.rectangle.x + scope.rectangle.w - ( SCOPE_PADDING * 1.5 ) }
              y={ scope.rectangle.y + SCOPE_PADDING * 0.5 }
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="draggableIcon"
            >
              { nodeIconMap.draggable }
            </svg>
            {/* <rect
              fill="blue"
              fillOpacity={ 0.1 }
              x={scope.x}
              y={scope.y}
              width={scope.w}
              height={scope.h}
            /> */}
            <rect
              className="draggingMask"
              id={ scope.id }
              x={ scope.rectangle.x }
              y={ scope.rectangle.y }
              width={ scope.rectangle.w }
              height={ scope.rectangle.h }
              fill="#FFF"
              fillOpacity={ 0 }
              rx={ scope.childType ? 6 : 8 }
              onClick={ e => {
                e.preventDefault();
                e.stopPropagation();
                handleScopeClick( e, scope );
                return false;
              } }
              onDoubleClick={ e => {
                e.preventDefault();
                e.stopPropagation();
                handleScopeDoubleClick( e, scope );
                return false;
              } }
              // need to prevent here before passed to the handler or it will still trigger the default right-click menu
              onContextMenu={ e => {
                e.preventDefault();
                e.stopPropagation();
                handleScopeRightClick( e, scope );
                return false;
              } }
            />
          </React.Fragment>
        }
      </g>
    </React.Fragment>
  );
};

export default GraphScope;