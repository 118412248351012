/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import {
  focusForOnboarding,
  getGlobalSettings,
  isNotEmpty,
  itemIsString,
  updateGlobalSettings,
} from '../../../shared/Utilities';
import { HelpTrigger } from '../../../components/HelpDocumentation/ContextualHelp/index.js';
import { getFieldValues } from '../../../shared/Form/Shared';
import { isListOfPorts } from '../../../shared/Form/Validators';

import SetupForm from '../../../shared/SetupComponents/SetupForm';
import Form from '../../../shared/Form';

import { OnboardingWizardContext } from '../../../Contexts/OnboardingWizard';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';

import './style.scss';
import { getConfigurationAlertsFor } from '../../../shared/ConfigurationAlert';
import Modal from '../../../shared/Modal';
import InlineSVG from '../../../shared/InlineSVG';
import Notification from '../../../shared/Notification';
import PageAlerts from '../../../shared/PageAlerts';
import { CurrentUserContext } from '../../../Contexts/CurrentUser.js';

const ScanningGeneralSettings = ( ) => {
  // controls when refresh is needed
  let isMounted = true;

  // contextual getters and setters
  const [ , , refreshWizard, , , , , , , ] = React.useContext( OnboardingWizardContext );
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ , , , demoMode ] = React.useContext( CurrentUserContext );
  const [ hasNotifications, setHasNotifications ] = React.useState( null );
  const [ showNotificationsModal, setShowNotificationsModal ] = React.useState( null );

  // set up form state handlers
  const [ fields, setFields ] = React.useState( null );
  const [ record, setRecord ] = React.useState( null );
  const [ isValid, setIsValid ] = React.useState( true );

  // handle callback and fields
  const [ updatedForm, setUpdatedForm ] = React.useState( null );

  // fields object that represents what the Form component will turn into inputs,
  // with all of the necessary validation and help requirements
  const EMPTY_FIELDS = {
    windowsDomainsGroup: {
      header: 'Windows Domains',
      fields: [
        {
          type: 'collection',
          label: 'Windows Domains',
          attribute: 'windows_domains',
          value: '',
          required: true,
          needsFQDN: true,
          help: <HelpTrigger helpKey="windows_domains" />,
        },
      ],
    },
    settings: {
      header: 'General Settings',
      fields: [
        {
          type: 'duration',
          label: 'Ignore Vulnerability scanner data older than',
          expectedUnit: 's',
          attribute: 'third_party_ignore_age',
          value: '',
          required: true,
          htmlProps: { min: 0, step: 1 },
          help: <HelpTrigger helpKey="third_party_ignore_age" />,
        },
        {
          type: 'text',
          label: 'Recon TCP Ports',
          needsSplit: true,
          attribute: 'recon_tcp_ports',
          defaultValue: [ 445, 80, 443, 3389, 5985, 5986, 22, 23, 21, 636 ],
          validators: [ isListOfPorts ],
          help: <HelpTrigger helpKey="recon_tcp_ports" />,
        },
      ],
    },
  };

  // the init of the form, called on page load, once the global context has loaded
  React.useEffect( () => {
    setFields( EMPTY_FIELDS );
    onRefresh( );

    setTimeout( () => {
      const elementToFocus = document.getElementById( 'windows_domains_collection_fieldWrapper' );
      focusForOnboarding( elementToFocus );
    }, 500 );
  }, [ ] );

  // sets up onleave
  React.useEffect( ( ) => {
    // important functionality that automatically saves the form when
    // navigating away from the page if the form is valid
    const parentEl = document.getElementById( 'pageContent' );
    parentEl.onleave = async () => {
      if ( isValid && isMounted && !demoMode ) {
        onSave( false );
        return true;
      }
    };
    return () => {
      isMounted = false;
      if ( parentEl ) {
        parentEl.onleave = null;
      }
    };
  }, [ isValid, updatedForm, demoMode ] );

  // refreshes the form whenever one of the following happens:
  // 1. initial page load
  // 2. manual revert button is clicked in the footer
  // 3. a settings is changed and saved
  const onRefresh = async ( ) => {

    const projectSettings = await getGlobalSettings( 'project' );
    const globalSettings = await getGlobalSettings( 'global' );

    setRecord( {
      // eslint-disable-next-line camelcase
      windows_domains: projectSettings?.windows_domains,
      // eslint-disable-next-line camelcase
      third_party_ignore_age: projectSettings?.settings?.third_party_ignore_age,
      // eslint-disable-next-line camelcase
      recon_tcp_ports: globalSettings?.recon_tcp_ports,
    } );
  };

  React.useEffect( ( ) => {
    getConfigurationAlertsFor( [ 'scan-domain', 'domain-discovery' ] ).then( response => {
      if ( isNotEmpty( response ) ) {
        setHasNotifications( true );
      }
    } );
  }, [] );



  // handles save cases
  const onSave = async ( shouldRefresh=true ) => {
    if ( isValid && isNotEmpty( updatedForm ) && isNotEmpty( updatedForm.fieldStates ) ) {

      const _values = getFieldValues( updatedForm.fieldStates, 'general_settings' );

      const isProjectSettings = [ 'third_party_ignore_age' ];
      const isGlobalSettings = [ 'recon_tcp_ports' ];

      // eslint-disable-next-line camelcase
      const _project = { settings: {}, windows_domains: [] };
      const _global = { };

      // put the attrs and values into the correct spot
      Object.entries( _values ).map( ( [ attr, val ] ) => {
        if ( isProjectSettings.includes( attr ) ) {
          _project.settings[attr] = val;
        } else if ( isGlobalSettings.includes( attr ) ) {
          if ( itemIsString( val ) ) {
            // check because global recon_tcp_ports could be a comma
            // separated number string from 2.16 hotfix
            const _array = val.split( ',' ).map( item =>  Number( item ) );
            _global[attr] = _array;
          } else {
            // enforce number array for edge cases
            const _stringArray = val.map( item => Number( item ) );
            _global[attr] = _stringArray;
          }
        } else {
          // eslint-disable-next-line camelcase
          _project.windows_domains = val ? [ ...val ] : [];
        }
      } );

      const updatedProject = await updateGlobalSettings( 'project', _project );
      const updatedGlobal = await updateGlobalSettings( 'global', _global );

      // success
      if ( isNotEmpty( updatedProject.results ) && isNotEmpty( updatedGlobal ) ) {

        // only refresh if needed, ie, not when navigating away
        if (
          shouldRefresh
          && isMounted
        ) {
          onRefresh( );
          refreshWizard();
          addFlashMessage( {
            type: 'success',
            body: 'Successfully updated settings.',
          } );
        }
        // display error - check for both global and proj
      } else if ( isNotEmpty( updatedProject.errors ) || isNotEmpty( updatedGlobal.errors )  ) {
        const _errors = [];
        refreshWizard();
        updatedProject?.errors?.map( e => {
          _errors.push( e );
        } );
        updatedGlobal?.errors?.map( e => {
          _errors.push( e );
        } );
        if ( shouldRefresh && isMounted ) {
          _errors.map( e => {
            addFlashMessage( {
              type: 'alert',
              body: e,
            } );
          } );
        }
        // likely 500 (empty errors)
      } else if ( shouldRefresh && isMounted ) {
        refreshWizard();
        addFlashMessage( {
          type: 'alert',
          body: 'There was an error saving settings, please check your connection and try again.',
        } );
      }
    }
  };

  return (
    <React.Fragment>
      <SetupForm elementClass="scanningGeneralSettings">
        {
          hasNotifications &&
          <React.Fragment>
            <Modal
              visible={ showNotificationsModal }
              setVisible={ setShowNotificationsModal }
              elementClass="setupNotificationsModal"
              body={
                <Notification
                  options={
                    {
                      type: 'alert',
                      // eslint-disable-next-line max-len
                      message: <span>Your windows domains have been misconfigured, for further information please refer to the relevant configuration alerts concerning <a target="_blank" rel="noopener noreferrer" href="#.=activity&page=configuration_alerts&source=scan-domain">Windows Domain Scan</a> and/or <a target="_blank" rel="noopener noreferrer" href="#.=activity&page=configuration_alerts&source=domain-discovery">Windows Domain Discovery</a>.</span>,
                    }
                  }
                />
              }
            />
            <PageAlerts>
              <button className="showNotificationsButton" onClick={ () => setShowNotificationsModal( true ) }>
                <InlineSVG type="notifications_nav" />
                <span className="notificationsCount">1</span>
              </button>
            </PageAlerts>
          </React.Fragment>
        }
        {
          ( isNotEmpty( fields ) && isNotEmpty( record ) ) &&
          <Form
            fields={fields}
            onChangeCallback={setUpdatedForm}
            existingRecord={record}
            recordType={'general_settings'}
            setIsValid={setIsValid}
            validateOnLoad={true}
          />
        }
        <div className="formActionsContainer">
          <div className="formActions">
            <button
              className={ `revertButton ${demoMode ? 'disabled' : ''}`}
              onClick={( ) => onRefresh( )}
              disabled={demoMode}
            >
              Revert
            </button>
            <button
              className={demoMode ? 'disabled' : ''}
              disabled={!isValid || demoMode}
              onClick={onSave}
            >
              Save
            </button>
          </div>
        </div>

      </SetupForm>
    </React.Fragment>
  );
};

export default ScanningGeneralSettings;
