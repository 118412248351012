/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  formatNumber,
  isNotEmpty,
  pluralizeType,
  riskToRating,
} from '../../../../../shared/Utilities';
import InlineSVG from '../../../../../shared/InlineSVG';

import './TaskItem.scss';
import { completionClass, percentComplete } from '../../Shared';
import { makeRequest } from '../../../../../../legacy/io';
import RiskReduction from '../../../../../shared/RiskReduction';
import RatingBadge from '../../../../../shared/RatingBadge';
import { CurrentUserContext } from '../../../../../Contexts/CurrentUser';

// eslint-disable-next-line max-len
export const taskIcon = task => <InlineSVG type={ `${pluralizeType( task.normalizedType )}Alt` } elementClass="taskIcon" />;

const ExternalTicketLink = ( { task, flattenedIntegrations } ) => {

  const [ href, setHref ] = React.useState( null );
  const [ integration, setIntegration ] = React.useState( null );

  React.useEffect( ( ) => {
    if (
      isNotEmpty( task )
      && isNotEmpty( task.original )
      && isNotEmpty( task.original.third_party_setting_id )
      && isNotEmpty( task.original.external_ticket_id )
      && isNotEmpty( flattenedIntegrations )
    ) {
      const _integration = flattenedIntegrations[task.original.third_party_setting_id];
      setIntegration( _integration );
      if ( isNotEmpty( _integration ) && isNotEmpty( _integration.domain ) ) {
        setHref( `${_integration.domain}/browse/${task.original.external_ticket_id}` );
      } else {
        setHref( null );
      }
    }
  }, [ task, flattenedIntegrations ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( integration )
          ? <React.Fragment>
            {
              isNotEmpty( href )
                ? <a
                  className="externalTicketLink"
                  href={ href }
                  target="_blank"
                  rel="nofollow noopener"
                >
                  <InlineSVG type={ `${integration.tool}Logo` } version="special" elementClass="integrationLogo" />
                  <div className="text">
                    <div className="row id">
                      <strong>
                        { task.original.external_ticket_id }
                      </strong>
                      <InlineSVG type="link" elementClass="externalLinkIcon" />
                    </div>
                    <div className="row status">
                      <label>Status: </label>
                      <span>{ task.original?.external_ticket_status || 'N/A' }</span>
                    </div>
                  </div>
                </a>
                : <div
                  className="externalTicketLink notLink"
                >
                  <InlineSVG type={ `${integration.tool}Logo` } version="special" elementClass="integrationLogo" />
                  <div className="text">
                    <div className="row id">
                      <strong>
                        { task.original.external_ticket_id }
                      </strong>
                    </div>
                    <div className="row status">
                      <label>Status: </label>
                      <span>{ task.original?.external_ticket_status || 'N/A' }</span>
                    </div>
                  </div>
                </div>
            }
          </React.Fragment>
          : <div className="externalLinkPlaceholder"></div>
      }
    </React.Fragment>
  );
};

const TaskItem = ( {
  task,
  getTasks,
  planState,
  setPlanState,
  setLoadingStep,
  isSelected=false,
  isExported=false,
  users,
  currentStep=null,
  isActive,
  readOnly=false,
  hideProgress=false,
  activeIntegrations={},
  needsIntegration=false,
  elementClass='',
  excludeNoRisk=true,
  tanium=false,
} ) => {

  const [ flattenedIntegrations, setFlattenedIntegrations ] = React.useState( {} );

  const [ , , , demoMode ] = React.useContext( CurrentUserContext );

  // on init, happens only once, sets up the options for the select
  React.useEffect( ( ) => {
    const flattened= {};
    if ( isNotEmpty( activeIntegrations ) ) {
      Object.values( activeIntegrations ).map( intGroup => {
        intGroup.map( int => {
          flattened[int.id] = int;
        } );
      } );
    }
    setFlattenedIntegrations( flattened );
  }, [ activeIntegrations ] );

  const taskAction = task => {
    let _text = '';
    if ( task.original ) {
      if ( task.original.type === 'host_patches' ) {
        _text = <span>Apply <strong>{task.original.num_items} {task.original.type.split( '_' )[1]}</strong></span>;
      }
      if ( task.original.type === 'patch_hosts' ) {
        _text = <span>Apply to <strong>{task.original.num_items} {task.original.type.split( '_' )[1]}</strong></span>;
      }
      if ( task.original.type === 'host_vulnerabilities' ) {
        _text = <span>Fix <strong>{task.original.num_items} {task.original.type.split( '_' )[1]}</strong></span>;
      }
      if ( task.original.type === 'vulnerability_hosts' ) {
        _text = <span>Fix on <strong>{task.original.num_items} {task.original.type.split( '_' )[1]}</strong></span>;
      }
    }

    return _text;
  };


  const selectTask = async ( task ) => {
    if ( !demoMode ) {
      setLoadingStep( true );

      const { id } = planState;

      await makeRequest( 'ADD', '/project/default/model/base/remediation_task', {
        // eslint-disable-next-line camelcase
        remediation_plan_id: id,
        additions: [ task ],
      } );

      // eslint-disable-next-line camelcase
      const tasks = await getTasks( planState, { exclude_no_risk: excludeNoRisk, tanium: tanium } );

      setPlanState( { ...planState, tasks } );
      setLoadingStep( false );
    }

  };

  const removeTask = async ( task ) => {
    if ( !demoMode ) {
      setLoadingStep( true );

      const { id } = planState;

      await makeRequest( 'DELETE', '/project/default/model/base/remediation_task', {
        // eslint-disable-next-line camelcase
        remediation_plan_id: id,
        ids: [ task.id ],
      } );

      // eslint-disable-next-line camelcase
      const tasks = await getTasks( planState, { exclude_no_risk: excludeNoRisk, tanium: tanium } );

      setPlanState( { ...planState, tasks } );
      setLoadingStep( false );
    }

  };

  const updateOwner = async ( task, ownerID ) => {
    if ( !demoMode ) {
      const { id } = planState;

      await makeRequest( 'UPDATE', '/project/default/model/base/remediation_task', {
        updates: [ { id: task.original.id, owner: ownerID === '' ? null : ownerID } ],
      } );

      await makeRequest( 'FETCH', '/project/default/model/base/remediation_task', {
        // eslint-disable-next-line camelcase
        remediation_plan_id: id,
      } );

      // eslint-disable-next-line camelcase
      const tasks = await getTasks( planState, { exclude_no_risk: excludeNoRisk, tanium: tanium } );

      setPlanState( { ...planState, tasks } );
    }

  };

  const hasExternalTicket = () => {
    return isNotEmpty( task.original.external_ticket_id )
    && isNotEmpty( task.original.third_party_setting_id )
    && isNotEmpty( flattenedIntegrations );
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( task ) &&
        // eslint-disable-next-line max-len
        <li className={`riskClass--${ riskToRating( task.risk )} ${hasExternalTicket() ? 'hasExternalTicket' : ''} ${isExported ? 'isExported' : ''} ${elementClass} ${needsIntegration ? 'needsIntegration' : ''} taskItem remediationTaskItem ${readOnly ? 'readOnly' : '' } ${isSelected ? 'selected' : ''} ${isActive ? 'active' : ''} ${hideProgress ? 'hideProgress' : ''} ${isNotEmpty( task.externalUser ) ? 'hasExternalUser' : '' }`}>
          <div className={ `remediationPlanInformation risk--${riskToRating( task.risk )}` }>
            <h3>
              <span>
                <div className="recordIconWrapper">
                  { taskIcon( task ) }
                </div>
                { task.label }
                {
                  task.original?.tanium_compatible &&
                  <div className="taniumBadge">
                    <InlineSVG type="taniumLogo" version="special" />
                    <strong>Tanium</strong>
                  </div>
                }
              </span>
              <span>
                <RiskReduction item={task} riskType="risk" />
                <RatingBadge rating={ riskToRating( task.risk ) } />
              </span>

            </h3>
            <div className="actions">
              <span className="actionDescription">
                { taskAction( task ) }
              </span>
              {
                readOnly
                  ? <div className="lowerStat">
                    <label>Assigned to: </label>
                    <span>{ users[task.original.owner] || 'Unassigned' }</span>
                  </div>
                  : <React.Fragment>
                    {
                      isNotEmpty( users )
                        ? <React.Fragment>
                          {
                            isSelected
                              ? <div className="selectFieldWrapper">
                                <select
                                  value={ task.original.owner || '' }
                                  onChange={ e => updateOwner( task, e.target.value ) }
                                >
                                  <option value="">Unassigned</option>
                                  {
                                    Object.entries( users ).map( ( [ uuid, name ], index ) => {
                                      return  <option key={index} value={uuid}>{ name }</option>;
                                    } )
                                  }
                                </select>
                              </div>
                              : <div className="lowerStat">
                                <label>Assigned to: </label>
                                <span>{ users[task.original.owner] || 'Unassigned' }</span>
                              </div>
                          }
                        </React.Fragment>
                        : <span> Unassigned </span>
                    }
                  </React.Fragment>
              }
            </div>

          </div>
          {
            ( isActive && !hideProgress ) &&
            <div className={ `remediationPlanProgress risk--${riskToRating( task.risk )}` } >
              <div className="completionWrapper risk">
                <div className="completionLabel">
                  {/* eslint-disable-next-line max-len */}
                  <strong className="percentage">{ percentComplete( task.original.original_risk, task.original.risk ) }%</strong>
                  <span>Risk </span>
                  {/* eslint-disable-next-line max-len */}
                  <strong className="count">({ formatNumber( Math.ceil( ( task.original.original_risk - task.original.risk ) < 0 ? 0 : task.original.original_risk - task.original.risk ) ) }/{ formatNumber( Math.ceil( task.original.original_risk ) ) })</strong>
                </div>
                <div className={
                  `${completionClass( task.original.original_risk, task.original.risk )} completionBarWrapper risk`
                }>
                  <div
                    className={ 'completionBar risk' }
                    style={{
                      width: `${percentComplete( task.original.original_risk, task.original.risk )}%`,
                    }}
                  />
                </div>

              </div>
              <div className="completionWrapper instances">
                <div className="completionLabel">
                  {/* eslint-disable-next-line max-len */}
                  <strong className="percentage">{ percentComplete( task.original.original_num_instances, task.original.num_instances ) }%</strong>
                  <span>Instances </span>
                  {/* eslint-disable-next-line max-len */}
                  <strong className="count">({ formatNumber( Math.ceil( ( task.original.original_num_instances - task.original.num_instances < 0 ? 0 : task.original.original_num_instances - task.original.num_instances ) ) ) }/{ formatNumber( task.original.original_num_instances ) })</strong>
                </div>
                {/* eslint-disable-next-line max-len */}
                <div className={ `${completionClass( task.original.original_num_instances, task.original.num_instances )} completionBarWrapper instances` }>
                  <div
                    className={ 'completionBar instances' }
                    style={{
                      width: `${percentComplete( task.original.original_num_instances, task.original.num_instances )}%`,
                    }}
                  />
                </div>
              </div>
            </div>
          }
          {
            ( hasExternalTicket() && isActive ) &&
            <ExternalTicketLink task={task} flattenedIntegrations={flattenedIntegrations} />
          }
          {
            ( currentStep !== 4 && !readOnly ) &&
            <div className="itemActions">
              {
                isSelected
                  ? <button
                    disabled={demoMode}
                    className={ `${ demoMode ? 'disabled' : '' } addItemButton` }
                    onClick={ () => removeTask( task.original ) }
                  >
                    <InlineSVG type="remove" />
                  </button>
                  : <button
                    className={ `${ demoMode ? 'disabled' : '' } addItemButton` }
                    onClick={ () => selectTask( task.original ) }
                  >
                    <InlineSVG type="add" />
                  </button>
              }
            </div>
          }
        </li>
      }
    </React.Fragment>
  );
};

export default TaskItem;