/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { makeRequest } from '../../../../legacy/io';
import {
  decodeURLHash,
  getDimensionsAndOffset,
  isEmpty,
  isNotEmpty,
  promiseAllSequential,
  useLocalStorage,
} from '../../../shared/Utilities';
import { fetchForCacheKey, getLatestWidgetVersion, getWidgetCacheKey } from './shared';
import { Responsive } from 'react-grid-layout';
import ReportCreator, { openReportCreator } from '../../../shared/ReportCreator';
import { NavigationContext } from '../../../Contexts/Navigation';

import './style.scss';
import WidgetWrapperV2 from './Widgets/WidgetWrapperV2';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import Loading from '../../../shared/Loading';
import WidgetEditorModal from './WidgetEditorModal';
import PageHeader from '../../../shared/PageHeader';
import DashboardEditorV2 from './DashboardEditorV2';
import InlineSVG from '../../../shared/InlineSVG';
import PageCreateButton from '../../../shared/PageCreateButton';
import DashboardSelector from './DashboardSelector';
import { PrintingContext } from '../../../Contexts/Printing';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';
import { TagsContext } from '../../../Contexts/Tags';

// /* eslint-disable camelcase */
// const builtInDashboards = [
//   {
//     id: '00000000-0000-0000-0000-000000000000',
//     label: 'Summary',
//     widgets: [
//       {
//         'h': 18,
//         'i': 'd1fe682e-471a-455a-b6cb-2d1bc32c60ec',
//         'key': 'hosts_priority',
//         'maxH': 48,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 6,
//         'x': 0,
//         'y': 0,
//         'cacheKey': 'hosts_priority|order_undefined|tags_',
//         'fullLabel': 'Hosts: Highest Priority (100)',
//         'label': 'Highest Priority Hosts',
//         'settings': {
//           'version': 'table',
//           'item_count': 25,
//           'report_type': 'hosts',
//           'include_rating': true,
//           'include_user': true,
//           'include_os_type': true,
//           'include_description': false,
//           'include_risk': true,
//         },
//         'version': 2,
//       },
//       {
//         'h': 17,
//         'i': 'a82c85a1-0cb9-4218-b705-6b4354d8b9fa',
//         'key': 'patches_priority',
//         'maxH': 48,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 6,
//         'x': 0,
//         'y': 18,
//         'cacheKey': 'patches_priority|order_undefined|tags_',
//         'fullLabel': 'Patches: Highest Priority (100)',
//         'label': 'Highest Priority Patches',
//         'settings': {
//           'version': 'table',
//           'item_count': 25,
//           'report_type': 'patches',
//           'include_rating': true,
//           'include_description': false,
//           'include_risk': true,
//         },
//         'version': 2,
//       },
//       {
//         'h': 17,
//         'i': '6d36be4a-5517-4b92-8ecb-2f9fff9d2543',
//         'key': 'vulnerabilities_priority',
//         'maxH': 48,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 6,
//         'x': 0,
//         'y': 35,
//         'cacheKey': 'vulnerabilities_priority|order_undefined|tags_',
//         'fullLabel': 'Vulnerabilities: Highest Priority (100)',
//         'label': 'Highest Priority Vulnerabilities',
//         'settings': {
//           'version': 'table',
//           'item_count': 25,
//           'report_type': 'vulnerabilities',
//           'include_rating': true,
//           'include_description': false,
//           'include_risk': true,
//           'include_cvss': true,
//         },
//         'version': 2,
//       },
//       {
//         'h': 7,
//         'i': '382e90c9-37f1-4ce2-86cf-2dac966b76cc',
//         'key': 'risk_over_time',
//         'maxH': 12,
//         'maxW': 6,
//         'minH': 6,
//         'minW': 6,
//         'resizeHandles': [
//           's',
//         ],
//         'w': 6,
//         'x': 0,
//         'y': 52,
//         'cacheKey': 'risk_over_time|duration_90',
//         'fullLabel': 'Risk: Over Time',
//         'label': 'Risk Over Time',
//         'settings': {
//           'include_description': false,
//           'include_escalations': true,
//           'include_hosts': true,
//           'patch_tuesday': true,
//           'duration': 90,
//           'include_legend': true,
//         },
//         'version': 2,
//       },
//       {
//         'h': 6,
//         'i': '08616ac1-2ca7-4432-8a37-d3707ebef7ca',
//         'key': 'risk_peer_percentile_over_time',
//         'maxH': 12,
//         'maxW': 6,
//         'minH': 6,
//         'minW': 6,
//         'resizeHandles': [
//           's',
//         ],
//         'w': 6,
//         'x': 0,
//         'y': 65,
//         'cacheKey': 'risk_peer_percentile',
//         'fullLabel': 'Risk: Peer Percentile Over Time',
//         'label': 'Peer Percentile Over Time',
//         'settings': {
//           'include_description': false,
//         },
//         'version': 2,
//       },
//       {
//         'h': 6,
//         'i': '4c3a0446-35c6-4685-9404-48e6c9649476',
//         'key': 'vulnerability_instances_global',
//         'maxH': 12,
//         'maxW': 6,
//         'minH': 6,
//         'minW': 6,
//         'resizeHandles': [
//           's',
//         ],
//         'w': 6,
//         'x': 0,
//         'y': 59,
//         'cacheKey': 'vulnerability_instances_category',
//         'fullLabel': '',
//         'label': 'Vulnerability Instances',
//         'settings': {
//           'version': 'full',
//           'include_description': false,
//         },
//         'version': 2,
//       },
//     ],
//   },
//   {
//     id: '00000000-0000-0000-0000-000000000001',
//     label: 'Risk Report',
//     widgets: [
//       {
//         'h': 6,
//         'i': 'abf6e79b-4622-42ec-a6e8-332fa3f560a6',
//         'key': 'patches_priority',
//         'maxH': 48,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 4,
//         'y': 71,
//         'cacheKey': 'patches_priority|order_undefined|tags_',
//         'fullLabel': 'Patches: Highest Priority (100)',
//         'label': 'Highest Priority Patches',
//         'settings': {
//           'include_description': false,
//           'version': 'list',
//           'item_count': '10',
//           'report_type': 'patches',
//         },
//         'version': 2,
//       },
//       {
//         'h': 6,
//         'i': '97d53bce-d955-4f70-b196-52dcfc9b61ba',
//         'key': 'vulnerabilities_priority',
//         'maxH': 48,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 4,
//         'y': 35,
//         'cacheKey': 'vulnerabilities_priority|order_undefined|tags_',
//         'fullLabel': 'Vulnerabilities: Highest Priority (100)',
//         'label': 'Highest Priority Vulnerabilities',
//         'settings': {
//           'include_description': false,
//           'version': 'list',
//           'include_risk': true,
//           'include_cvss': true,
//           'item_count': '10',
//           'report_type': 'vulnerabilities',
//         },
//         'version': 2,
//       },
//       {
//         'h': 6,
//         'i': 'e788e232-147b-4d01-94e5-b7505ebdfd29',
//         'key': 'vulnerability_instances_exploit_status_breakdown',
//         'maxH': 8,
//         'maxW': 3,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 4,
//         'y': 10,
//         'cacheKey': 'vulnerability_instances_exploit_status',
//         'fullLabel': 'Vulnerability Instances: Exploit Status Breakdown',
//         'label': 'Exploit Status Breakdown',
//         'settings': {
//           'include_description': false,
//         },
//         'version': 2,
//       },
//       {
//         'h': 5,
//         'i': 'b2a5f945-994d-404d-b6fa-fe63b0a41af0',
//         'key': 'vulnerability_instances_cvss_breakdown',
//         'maxH': 8,
//         'maxW': 4,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 4,
//         'y': 0,
//         'cacheKey': 'vulnerability_instances_cvss',
//         'fullLabel': 'Vulnerability Instances: CVSS Breakdown',
//         'label': 'CVSS Breakdown',
//         'settings': {
//           'include_description': false,
//           'version': 'barchart',
//         },
//         'version': 2,
//       },
//       {
//         'h': 7,
//         'i': '7735d84a-644c-458e-9b9d-22a6511cae83',
//         'key': 'risk_over_time',
//         'maxH': 12,
//         'maxW': 6,
//         'minH': 6,
//         'minW': 6,
//         'resizeHandles': [
//           's',
//         ],
//         'w': 6,
//         'x': 0,
//         'y': 28,
//         'cacheKey': 'risk_over_time|duration_90',
//         'fullLabel': 'Risk: Over Time',
//         'label': 'Risk Over Time',
//         'settings': {
//           'include_description': false,
//           'include_escalations': true,
//           'include_hosts': true,
//           'patch_tuesday': true,
//           'duration': 90,
//           'include_legend': true,
//         },
//         'version': 2,
//       },
//       {
//         'h': 6,
//         'i': '18628600-b65e-4779-b75f-9b44afe9b767',
//         'key': 'vulnerability_instances_global',
//         'maxH': 12,
//         'maxW': 6,
//         'minH': 6,
//         'minW': 6,
//         'resizeHandles': [
//           's',
//         ],
//         'w': 6,
//         'x': 0,
//         'y': 16,
//         'cacheKey': 'vulnerability_instances_category',
//         'fullLabel': '',
//         'label': 'Vulnerability Instances',
//         'settings': {
//           'version': 'full',
//           'include_description': false,
//         },
//         'version': 2,
//       },
//       {
//         'h': 11,
//         'i': '344f30d6-4701-4846-bbfc-dfe7514b248e',
//         'key': 'paths_global',
//         'maxH': 12,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 4,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 4,
//         'x': 0,
//         'y': 5,
//         'cacheKey': 'paths_global|count_5',
//         'fullLabel': 'Top 5 Critical Paths',
//         'label': 'Top Paths (system wide)',
//         'settings': {
//           'item_count': 5,
//           'include_description': true,
//         },
//         'version': 2,
//       },
//       {
//         'h': 6,
//         'i': 'e3423fc4-e5b1-42a1-a334-8acd0fcbc4f0',
//         'key': 'hosts_global',
//         'maxH': 12,
//         'maxW': 4,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 0,
//         'y': 55,
//         'cacheKey': 'risk_breakdown',
//         'fullLabel': 'Hosts: Risk Breakdown',
//         'label': 'Host Risk Breakdown',
//         'settings': {
//           'report_type': 'hosts',
//           'include_description': false,
//         },
//         'version': 2,
//       },
//       {
//         'h': 6,
//         'i': '0657603b-68f9-412e-ab78-ed28c058a72a',
//         'key': 'patches_global',
//         'maxH': 12,
//         'maxW': 4,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 0,
//         'y': 71,
//         'cacheKey': 'risk_breakdown',
//         'fullLabel': 'Patches: Risk Breakdown',
//         'label': 'Patch Risk Breakdown',
//         'settings': {
//           'report_type': 'patches',
//           'include_description': false,
//         },
//         'version': 2,
//       },
//       {
//         'h': 6,
//         'i': 'eb52039e-77c2-4c2f-9ad6-280a08534209',
//         'key': 'vulnerabilities_global',
//         'maxH': 12,
//         'maxW': 4,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 0,
//         'y': 35,
//         'cacheKey': 'risk_breakdown',
//         'fullLabel': 'Vulnerabilities: Risk Breakdown',
//         'label': 'Vulnerability Risk Breakdown',
//         'settings': {
//           'report_type': 'vulnerabilities',
//           'include_description': false,
//         },
//         'version': 2,
//       },
//       {
//         'h': 5,
//         'i': '2342bbd8-7e38-417e-b5a4-91f05c00a67a',
//         'key': 'risk_peer_percentile',
//         'maxH': 12,
//         'maxW': 4,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 2,
//         'y': 0,
//         'cacheKey': 'risk_peer_percentile',
//         'fullLabel': '',
//         'label': 'Peer Percentile',
//         'settings': {},
//         'version': 2,
//       },
//       {
//         'h': 6,
//         'i': '935a1fb4-6e41-446f-a33e-710657cba21e',
//         'key': 'hosts_total',
//         'maxH': 12,
//         'maxW': 4,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 2,
//         'y': 55,
//         'cacheKey': 'risk_breakdown',
//         'fullLabel': '',
//         'label': 'Total',
//         'settings': {
//           'report_type': 'hosts',
//         },
//         'version': 2,
//       },
//       {
//         'h': 6,
//         'i': 'cec2e767-b026-4f31-b2f2-2171ca156781',
//         'key': 'patches_total',
//         'maxH': 12,
//         'maxW': 4,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 2,
//         'y': 71,
//         'cacheKey': 'risk_breakdown',
//         'fullLabel': '',
//         'label': 'Total',
//         'settings': {
//           'report_type': 'patches',
//         },
//         'version': 2,
//       },
//       {
//         'h': 6,
//         'i': '7c2e1bc2-b966-4640-bb11-fec8f3319901',
//         'key': 'vulnerabilities_total',
//         'maxH': 12,
//         'maxW': 4,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 2,
//         'y': 35,
//         'cacheKey': 'risk_breakdown',
//         'fullLabel': '',
//         'label': 'Total',
//         'settings': {
//           'report_type': 'vulnerabilities',
//         },
//         'version': 2,
//       },
//       {
//         'h': 14,
//         'i': '560e7d8f-d3c6-4313-8a7d-c6ebd773d836',
//         'key': 'vulnerabilities_top_details',
//         'maxH': 48,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 6,
//         'x': 0,
//         'y': 41,
//         'cacheKey': 'vulnerabilities_top_details|count_3|order_filtered_risk',
//         'fullLabel': '',
//         'label': 'Top Vulnerabilities w/ Details',
//         'settings': {
//           'order_by': 'filtered_risk',
//           'item_count': 3,
//           'orientation': 'horizontal',
//           'include_risk': true,
//           'include_instances': true,
//           'include_cvss': true,
//           'report_type': 'vulnerabilities',
//         },
//         'version': 2,
//       },
//       {
//         'h': 6,
//         'i': '8b4393f5-2be7-425c-baec-af9058ab86e8',
//         'key': 'vulnerability_instances_vulnerability_age_breakdown',
//         'maxH': 8,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 4,
//         'x': 2,
//         'y': 22,
//         'cacheKey': 'vulnerability_instances_vulnerability_age',
//         'fullLabel': 'Vulnerability Instances: Vulnerability Age Breakdown',
//         'label': 'Vulnerability Age Breakdown',
//         'settings': {
//           'include_description': true,
//         },
//         'version': 2,
//       },
//       {
//         'h': 5,
//         'i': 'bc3febc8-0fd8-4d40-b7f5-b9f071afedd7',
//         'key': 'vulnerability_instances_total',
//         'maxH': 12,
//         'maxW': 4,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 4,
//         'y': 5,
//         'cacheKey': 'vulnerability_instances_category',
//         'fullLabel': '',
//         'label': 'Total',
//         'settings': {
//           'include_description': false,
//           'version': 'full',
//         },
//         'version': 2,
//       },
//       {
//         'h': 6,
//         'i': '51590b8a-56a0-4285-bf3a-8e4cab4dd7dd',
//         'key': 'scanning_total',
//         'maxH': 12,
//         'maxW': 4,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 0,
//         'y': 22,
//         'cacheKey': 'scanning|type_both',
//         'fullLabel': '',
//         'label': 'Total',
//         'settings': {
//           'scanning_type': 'both',
//         },
//         'version': 2,
//       },
//       {
//         'h': 10,
//         'i': '4f49b689-4483-4d0f-b1d0-7c87df3799bc',
//         'key': 'hosts_top_details',
//         'maxH': 48,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 6,
//         'x': 0,
//         'y': 61,
//         'cacheKey': 'hosts_top_details|count_3|order_filtered_risk',
//         'fullLabel': '',
//         'label': 'Top Hosts w/ Details',
//         'settings': {
//           'order_by': 'filtered_risk',
//           'item_count': 3,
//           'orientation': 'horizontal',
//           'include_risk': true,
//           'include_instances': true,
//           'include_cvss': true,
//           'report_type': 'hosts',
//         },
//         'version': 2,
//       },
//       {
//         'h': 11,
//         'i': 'd984ff5b-bbd0-4b84-9079-6a3708597a8c',
//         'key': 'patches_top_details',
//         'maxH': 48,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 6,
//         'x': 0,
//         'y': 77,
//         'cacheKey': 'patches_top_details|count_3|order_filtered_risk',
//         'fullLabel': '',
//         'label': 'Top Patches w/ Details',
//         'settings': {
//           'order_by': 'filtered_risk',
//           'item_count': 3,
//           'orientation': 'horizontal',
//           'include_risk': true,
//           'include_instances': true,
//           'include_cvss': true,
//           'report_type': 'patches',
//         },
//         'version': 2,
//       },
//       {
//         'h': 6,
//         'i': 'eb005c2f-4884-469e-a907-13bee22f3e37',
//         'key': 'hosts_priority',
//         'maxH': 48,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 4,
//         'y': 55,
//         'cacheKey': 'hosts_priority|order_filtered_risk|tags_',
//         'fullLabel': 'Hosts: Highest Priority (100)',
//         'label': 'Highest Priority Hosts',
//         'settings': {
//           'include_description': false,
//           'version': 'list',
//           'order_by': 'filtered_risk',
//           'item_count': '10',
//           'report_type': 'hosts',
//         },
//         'version': 2,
//       },
//       {
//         'h': 5,
//         'i': 'cbade6b7-e2aa-4151-ac7d-1e4483f5d591',
//         'key': 'risk_score',
//         'maxH': 12,
//         'maxW': 4,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 0,
//         'y': 0,
//         'cacheKey': 'risk_score',
//         'fullLabel': '',
//         'label': 'Risk Score',
//         'settings': {},
//         'version': 2,
//       },
//     ],
//   },
//   {
//     label: 'Executive Summary',
//     id: '00000000-0000-0000-0000-000000000004',
//     // eslint-disable-next-line max-len
//     widgets: [
//       {
//         'h': 6,
//         'i': '8b4393f5-2be7-425c-baec-af9058ab86e8',
//         'key': 'vulnerability_instances_vulnerability_age_breakdown',
//         'maxH': 8,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 3,
//         'x': 3,
//         'y': 30,
//         'cacheKey': 'vulnerability_instances_vulnerability_age',
//         'fullLabel': 'Vulnerability Instances: Vulnerability Age Breakdown',
//         'label': 'Vulnerability Age Breakdown',
//         'settings': {
//           'include_description': true,
//         },
//         'version': 2,
//       },
//       {
//         'h': 7,
//         'i': 'eb005c2f-4884-469e-a907-13bee22f3e37',
//         'key': 'hosts_priority',
//         'maxH': 48,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 0,
//         'y': 36,
//         'cacheKey': 'hosts_priority|order_filtered_risk|tags_',
//         'fullLabel': 'Hosts: Highest Priority (100)',
//         'label': 'Highest Priority Hosts',
//         'settings': {
//           'include_description': false,
//           'version': 'list',
//           'order_by': 'filtered_risk',
//           'item_count': '10',
//           'report_type': 'hosts',
//         },
//         'version': 2,
//       },
//       {
//         'h': 6,
//         'i': '7d9ced7b-aa3d-44b7-b955-d3bdbcd9303b',
//         'key': 'vulnerability_instances_over_time',
//         'maxH': 12,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 6,
//         'x': 0,
//         'y': 18,
//         'cacheKey': 'analysis_tag_history_over_time|comparison_year|breakdown_specific_categories',
//         'fullLabel': 'Vulnerability Instances: Count Over Time',
//         'label': 'Over Time',
//         'settings': {
//           'include_description': true,
//           'duration': 'year',
//           'category_version': 'specific_categories',
//           'included_categories': [
//             'prioritized',
//             'deprioritized',
//             'for_review',
//           ],
//           'include_legend': true,
//           'report_type': 'vulnerability_instances',
//         },
//         'version': 2,
//       },
//       {
//         'h': 6,
//         'i': 'c470096e-1ab2-4a2c-b20e-583464383531',
//         'key': 'vulnerability_instances_tag_breakdown',
//         'maxH': 8,
//         'maxW': 3,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 0,
//         'y': 24,
//         'cacheKey': 'vulnerability_instances_asset_tag',
//         'fullLabel': 'Vulnerability Instances: Tag Breakdown',
//         'label': 'Tag Breakdown',
//         'settings': {
//           'include_description': true,
//         },
//         'version': 2,
//       },
//       {
//         'h': 7,
//         'i': '71d6b4c7-b0bf-4e02-9785-97f2056682c4',
//         'key': 'users_priority',
//         'maxH': 48,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 2,
//         'y': 36,
//         'cacheKey': 'users_priority|order_undefined|tags_',
//         'fullLabel': 'Users: Highest Priority',
//         'label': 'Highest Priority Users',
//         'settings': {
//           'include_description': false,
//           'version': 'list',
//           'item_count': '10',
//           'report_type': 'users',
//         },
//         'version': 2,
//       },
//       {
//         'h': 7,
//         'i': '31c29abc-9663-4d95-860d-fb8eaa39394b',
//         'key': 'patches_priority',
//         'maxH': 48,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 4,
//         'y': 36,
//         'cacheKey': 'patches_priority|order_undefined|tags_',
//         'fullLabel': 'Patches: Highest Priority (100)',
//         'label': 'Highest Priority Patches',
//         'settings': {
//           'include_description': false,
//           'version': 'list',
//           'item_count': '10',
//           'report_type': 'patches',
//         },
//         'version': 2,
//       },
//       {
//         'h': 5,
//         'i': 'caecea09-a767-4073-bd1d-bf7fb4719e90',
//         'key': 'risk_grade',
//         'maxH': 12,
//         'maxW': 4,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 0,
//         'y': 0,
//         'cacheKey': 'risk_peer_percentile',
//         'fullLabel': '',
//         'label': 'Risk Grade',
//         'settings': {
//           'comparison_version': 'number',
//           'comparison_date': 'quarter',
//           'report_type': 'vulnerability_instances',
//         },
//         'version': 2,
//       },
//       {
//         'h': 5,
//         'i': 'bffa11f8-ba8f-41a7-877e-27ed4f4ad9f6',
//         'key': 'risk_peer_percentile',
//         'maxH': 12,
//         'maxW': 4,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 4,
//         'y': 0,
//         'cacheKey': 'risk_peer_percentile',
//         'fullLabel': '',
//         'label': 'Peer Percentile',
//         'settings': {
//           'include_description': false,
//           'version': 'full',
//         },
//         'version': 2,
//       },
//       {
//         'h': 6,
//         'i': 'b09520dd-5999-4f3d-b9d3-8f1b9209f46d',
//         'key': 'risk_simplified_over_time',
//         'maxH': 12,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 4,
//         'x': 2,
//         'y': 24,
//         'cacheKey': 'analysis_tag_history_over_time|comparison_year|breakdown_tag',
//         'fullLabel': 'Risk: Over Time',
//         'label': 'Simplified Over Time',
//         'settings': {
//           'include_description': true,
//           'duration': 'year',
//           'include_tag_breakdown': true,
//           'asset_tag_ids': [
//             'd318b5b0-7658-40d9-b065-b30548056af6',
//             '6255c8be-60c6-4ae4-bfc4-1c33014b06f0',
//             'c45e1964-da83-41c7-9d78-d250e24c8bc0',
//             'fe0d646c-214e-4f40-ad2c-e9c49602f301',
//             'd3aa49d3-124b-4e36-bba7-e16fca48474c',
//             'a12f9585-98fa-4080-9b5c-7e79d1007816',
//           ],
//           'include_legend': true,
//           'report_type': 'risk',
//         },
//         'version': 2,
//       },
//       {
//         'h': 5,
//         'i': '555b102f-d671-43bf-a31f-417ddf34eb4e',
//         'key': 'vulnerability_instances_comparison',
//         'maxH': 12,
//         'maxW': 6,
//         'minH': 4,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 2,
//         'y': 0,
//         'cacheKey': 'analysis_tag_history_comparison|comparison_quarter|breakdown_undefined',
//         'fullLabel': 'Vulnerability Instances: Comparison',
//         'label': 'Comparison',
//         'settings': {
//           'comparison_version': 'number',
//           'comparison_date': 'quarter',
//           'report_type': 'vulnerability_instances',
//         },
//         'version': 2,
//       },
//       {
//         'h': 6,
//         'i': '6004b422-4066-4bf2-8808-de4985976b19',
//         'key': 'vulnerability_instances_comparison',
//         'maxH': 12,
//         'maxW': 6,
//         'minH': 4,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 3,
//         'x': 0,
//         'y': 30,
//         'cacheKey': 'analysis_tag_history_comparison|comparison_quarter|breakdown_tag',
//         'fullLabel': 'Vulnerability Instances: Comparison',
//         'label': 'Comparison',
//         'settings': {
//           'include_description': true,
//           'comparison_version': 'barchart',
//           'category_version': 'tag',
//           'asset_tag_ids': [
//             'a12f9585-98fa-4080-9b5c-7e79d1007816',
//             '6255c8be-60c6-4ae4-bfc4-1c33014b06f0',
//             'c45e1964-da83-41c7-9d78-d250e24c8bc0',
//             'd318b5b0-7658-40d9-b065-b30548056af6',
//             'fe0d646c-214e-4f40-ad2c-e9c49602f301',
//             'd3aa49d3-124b-4e36-bba7-e16fca48474c',
//           ],
//           'comparison_date': 'quarter',
//           'report_type': 'vulnerability_instances',
//         },
//         'version': 2,
//       },
//       {
//         'h': 6,
//         'i': '1902d65b-e9a2-4662-b4ff-6e827d71c7e8',
//         'key': 'risk_peer_percentile_over_time',
//         'maxH': 12,
//         'maxW': 6,
//         'minH': 6,
//         'minW': 3,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 6,
//         'x': 0,
//         'y': 12,
//         'cacheKey': 'risk_peer_percentile',
//         'fullLabel': 'Risk: Peer Percentile Over Time',
//         'label': 'Peer Percentile Over Time',
//         'settings': {
//           'include_description': true,
//         },
//         'version': 2,
//       },
//       {
//         'h': 7,
//         'i': 'ca7b4a69-d804-4475-b6bb-6206224223bc',
//         'key': 'risk_over_time',
//         'maxH': 12,
//         'maxW': 6,
//         'minH': 6,
//         'minW': 6,
//         'resizeHandles': [
//           's',
//         ],
//         'w': 6,
//         'x': 0,
//         'y': 5,
//         'cacheKey': 'risk_over_time|duration_90',
//         'fullLabel': 'Risk: Over Time',
//         'label': 'Risk Over Time',
//         'settings': {
//           'include_description': true,
//           'include_escalations': true,
//           'include_hosts': true,
//           'patch_tuesday': true,
//           'duration': 90,
//           'include_legend': true,
//         },
//         'version': 2,
//       },
//     ],
//   },
//   {
//     id: '00000000-0000-0000-0000-000000000002',
//     label: 'Scanning and Vulnerabilities',
//     widgets: [
//       {
//         'version': 2,
//         'label': 'OS Family Breakdown',
//         'fullLabel': 'Vulnerability Instances: OS Family Breakdown',
//         'key': 'vulnerability_instances_os_family_breakdown',
//         'settings': {
//           'include_description': true,
//         },
//         'minH': 4,
//         'maxH': 8,
//         'minW': 2,
//         'maxW': 4,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'h': 8,
//         'w': 2,
//         'i': '56db2cf5-616c-4043-b713-fc67ba0c3e2e',
//         'x': 2,
//         'y': 8,
//       },
//       {
//         'version': 2,
//         'label': 'Tag Breakdown',
//         'fullLabel': 'Vulnerability Instances: Tag Breakdown',
//         'key': 'vulnerability_instances_tag_breakdown',
//         'settings': {
//           'include_description': true,
//         },
//         'minH': 4,
//         'maxH': 8,
//         'minW': 2,
//         'maxW': 4,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'h': 8,
//         'w': 2,
//         'i': '78f67f29-0082-4fec-b002-c7e6b70cb024',
//         'x': 0,
//         'y': 8,
//       },
//       {
//         'version': 2,
//         'label': 'Vulnerability Age Breakdown',
//         'fullLabel': 'Vulnerability Instances: Vulnerability Age Breakdown',
//         'key': 'vulnerability_instances_vulnerability_age_breakdown',
//         'settings': {
//           'include_description': true,
//         },
//         'minH': 4,
//         'maxH': 8,
//         'minW': 2,
//         'maxW': 6,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'h': 6,
//         'w': 4,
//         'i': '2c489ad7-ef48-43d6-9054-3f386738f8c7',
//         'x': 2,
//         'y': 16,
//       },
//       {
//         'version': 2,
//         'label': 'Exploit Status Breakdown',
//         'fullLabel': 'Vulnerability Instances: Exploit Status Breakdown',
//         'key': 'vulnerability_instances_exploit_status_breakdown',
//         'settings': {
//           'include_description': true,
//         },
//         'minH': 4,
//         'maxH': 8,
//         'minW': 2,
//         'maxW': 3,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'h': 8,
//         'w': 2,
//         'i': '660908b0-48c9-4c09-be7c-1b41c3dd2aa7',
//         'x': 4,
//         'y': 0,
//       },
//       {
//         'version': 2,
//         'label': 'CVSS Breakdown',
//         'fullLabel': 'Vulnerability Instances: CVSS Breakdown',
//         'key': 'vulnerability_instances_cvss_breakdown',
//         'settings': {
//           'include_description': false,
//           'version': 'barchart',
//         },
//         'minH': 4,
//         'maxH': 8,
//         'minW': 2,
//         'maxW': 4,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'h': 8,
//         'w': 2,
//         'i': '5acc0dae-d59e-4cc3-b317-d3376ea7b0d2',
//         'x': 0,
//         'y': 0,
//       },
//       {
//         'version': 2,
//         'label': 'Total',
//         'fullLabel': '',
//         'key': 'scanning_total',
//         'minH': 4,
//         'maxH': 12,
//         'minW': 2,
//         'maxW': 4,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'h': 4,
//         'w': 2,
//         'i': 'e8487196-fa2a-4850-afde-12d3fb4ef7b3',
//         'x': 2,
//         'y': 0,
//         'settings': {
//           'scanning_type': 'both',
//         },
//       },
//       {
//         'version': 2,
//         'label': 'Agent Version Breakdown',
//         'fullLabel': 'Scanning: Agent Version Breakdown',
//         'key': 'scanning_agent_version_breakdown',
//         'settings': {
//           'include_description': true,
//         },
//         'minH': 4,
//         'maxH': 8,
//         'minW': 2,
//         'maxW': 4,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'h': 8,
//         'w': 2,
//         'i': 'da6cb7ec-9e55-4de8-88c7-ac243d1ee491',
//         'x': 4,
//         'y': 8,
//       },
//       {
//         'version': 2,
//         'label': 'Highest Priority Vulnerabilities',
//         'fullLabel': 'Vulnerabilities: Highest Priority (100)',
//         'key': 'vulnerabilities_priority',
//         'minH': 4,
//         'maxH': 48,
//         'minW': 2,
//         'maxW': 6,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'h': 33,
//         'w': 4,
//         'settings': {
//           'include_description': false,
//           'version': 'table',
//           'include_risk': false,
//           'include_cvss': true,
//           'order_by': 'exploit_cvss',
//           'item_count': '50',
//           'report_type': 'vulnerabilities',
//         },
//         'i': '7f948544-04a7-4c1e-912c-6094c5d84548',
//         'x': 2,
//         'y': 28,
//       },
//       {
//         'version': 2,
//         'label': 'Highest Priority Hosts',
//         'fullLabel': 'Hosts: Highest Priority (100)',
//         'key': 'hosts_priority',
//         'minH': 4,
//         'maxH': 48,
//         'minW': 2,
//         'maxW': 6,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'h': 32,
//         'w': 4,
//         'settings': {
//           'include_description': false,
//           'version': 'table',
//           'include_risk': false,
//           'include_user': false,
//           'include_os_type': true,
//           'order_by': 'num_vulnerabilities',
//           'item_count': '50',
//           'report_type': 'hosts',
//         },
//         'i': 'e7f666bf-c6aa-4877-bbdd-d19031a7fff2',
//         'x': 2,
//         'y': 61,
//       },
//       {
//         'version': 2,
//         'label': 'Total',
//         'fullLabel': '',
//         'key': 'vulnerability_instances_total',
//         'minH': 4,
//         'maxH': 12,
//         'minW': 2,
//         'maxW': 4,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'h': 4,
//         'w': 2,
//         'i': 'a9076b31-a640-4786-8034-1ed47d6ab060',
//         'x': 2,
//         'y': 4,
//         'settings': {
//           'include_description': false,
//           'version': 'full',
//         },
//       },
//       {
//         'version': 2,
//         'label': 'Top Vulnerabilities w/ Details',
//         'fullLabel': '',
//         'key': 'vulnerabilities_top_details',
//         'minH': 4,
//         'maxH': 48,
//         'minW': 2,
//         'maxW': 6,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'h': 33,
//         'w': 2,
//         'settings': {
//           'order_by': 'exploit_cvss',
//           'item_count': 3,
//           'orientation': 'vertical',
//           'include_risk': false,
//           'include_instances': true,
//           'include_cvss': true,
//           'report_type': 'vulnerabilities',
//         },
//         'i': 'bea79062-c457-4e55-9af2-4d62a03ba7d4',
//         'x': 0,
//         'y': 28,
//       },
//       {
//         'version': 2,
//         'label': 'Top Hosts w/ Details',
//         'fullLabel': '',
//         'key': 'hosts_top_details',
//         'minH': 4,
//         'maxH': 48,
//         'minW': 2,
//         'maxW': 6,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'h': 32,
//         'w': 2,
//         'settings': {
//           'order_by': 'num_vulnerabilities',
//           'item_count': 3,
//           'orientation': 'vertical',
//           'include_risk': false,
//           'include_instances': true,
//           'include_cvss': true,
//           'report_type': 'hosts',
//         },
//         'i': '6b17c869-3fc1-4e4d-9094-79c0917a3221',
//         'x': 0,
//         'y': 61,
//       },
//       {
//         'version': 2,
//         'label': 'Categories',
//         'fullLabel': '',
//         'key': 'vulnerability_instances_global',
//         'minH': 6,
//         'maxH': 12,
//         'minW': 6,
//         'maxW': 6,
//         'resizeHandles': [
//           's',
//         ],
//         'h': 6,
//         'w': 6,
//         'settings': {
//           'version': 'full',
//           'include_description': false,
//         },
//         'i': '7f7c1819-5b1f-44de-9348-06f600602353',
//         'x': 0,
//         'y': 22,
//       },
//       {
//         'version': 2,
//         'label': 'Total',
//         'fullLabel': '',
//         'key': 'vulnerabilities_total',
//         'minH': 4,
//         'maxH': 12,
//         'minW': 2,
//         'maxW': 4,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'h': 6,
//         'w': 2,
//         'settings': {
//           'report_type': 'vulnerabilities',
//         },
//         'i': '552f703d-fd65-4609-b655-eeeae8706830',
//         'x': 0,
//         'y': 16,
//       },
//     ],
//   },
//   {
//     id: '00000000-0000-0000-0000-000000000003',
//     label: 'Vulnerabilities and Instances',
//     widgets: [
//       {
//         'h': 8,
//         'i': '56db2cf5-616c-4043-b713-fc67ba0c3e2e',
//         'key': 'vulnerability_instances_os_family_breakdown',
//         'maxH': 8,
//         'maxW': 4,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 4,
//         'y': 8,
//         'cacheKey': 'vulnerability_instances_os_family',
//         'fullLabel': 'Vulnerability Instances: OS Family Breakdown',
//         'label': 'OS Family Breakdown',
//         'settings': {
//           'include_description': true,
//         },
//         'version': 2,
//       },
//       {
//         'h': 8,
//         'i': '78f67f29-0082-4fec-b002-c7e6b70cb024',
//         'key': 'vulnerability_instances_tag_breakdown',
//         'maxH': 8,
//         'maxW': 4,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 0,
//         'y': 8,
//         'cacheKey': 'vulnerability_instances_asset_tag',
//         'fullLabel': 'Vulnerability Instances: Tag Breakdown',
//         'label': 'Tag Breakdown',
//         'settings': {
//           'include_description': true,
//         },
//         'version': 2,
//       },
//       {
//         'h': 7,
//         'i': '2c489ad7-ef48-43d6-9054-3f386738f8c7',
//         'key': 'vulnerability_instances_vulnerability_age_breakdown',
//         'maxH': 8,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 6,
//         'x': 0,
//         'y': 16,
//         'cacheKey': 'vulnerability_instances_vulnerability_age',
//         'fullLabel': 'Vulnerability Instances: Vulnerability Age Breakdown',
//         'label': 'Vulnerability Age Breakdown',
//         'settings': {
//           'include_description': true,
//         },
//         'version': 2,
//       },
//       {
//         'h': 8,
//         'i': '660908b0-48c9-4c09-be7c-1b41c3dd2aa7',
//         'key': 'vulnerability_instances_exploit_status_breakdown',
//         'maxH': 8,
//         'maxW': 3,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 4,
//         'y': 0,
//         'cacheKey': 'vulnerability_instances_exploit_status',
//         'fullLabel': 'Vulnerability Instances: Exploit Status Breakdown',
//         'label': 'Exploit Status Breakdown',
//         'settings': {
//           'include_description': true,
//         },
//         'version': 2,
//       },
//       {
//         'h': 8,
//         'i': '5acc0dae-d59e-4cc3-b317-d3376ea7b0d2',
//         'key': 'vulnerability_instances_cvss_breakdown',
//         'maxH': 8,
//         'maxW': 4,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 0,
//         'y': 0,
//         'cacheKey': 'vulnerability_instances_cvss',
//         'fullLabel': 'Vulnerability Instances: CVSS Breakdown',
//         'label': 'CVSS Breakdown',
//         'settings': {
//           'include_description': true,
//           'version': 'barchart',
//         },
//         'version': 2,
//       },
//       {
//         'h': 17,
//         'i': '7f948544-04a7-4c1e-912c-6094c5d84548',
//         'key': 'vulnerabilities_priority',
//         'maxH': 48,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 4,
//         'x': 0,
//         'y': 35,
//         'cacheKey': 'vulnerabilities_priority|order_exploit_cvss|tags_',
//         'fullLabel': 'Vulnerabilities: Highest Priority (100)',
//         'label': 'Highest Priority Vulnerabilities',
//         'settings': {
//           'include_description': false,
//           'version': 'table',
//           'include_risk': false,
//           'include_cvss': true,
//           'order_by': 'exploit_cvss',
//           'item_count': '25',
//           'report_type': 'vulnerabilities',
//         },
//         'version': 2,
//       },
//       {
//         'h': 17,
//         'i': 'e7f666bf-c6aa-4877-bbdd-d19031a7fff2',
//         'key': 'hosts_priority',
//         'maxH': 48,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 4,
//         'x': 0,
//         'y': 62,
//         'cacheKey': 'hosts_priority|order_num_vulnerabilities|tags_',
//         'fullLabel': 'Hosts: Highest Priority (100)',
//         'label': 'Highest Priority Hosts',
//         'settings': {
//           'include_description': false,
//           'version': 'table',
//           'include_risk': false,
//           'include_user': false,
//           'include_os_type': true,
//           'order_by': 'num_vulnerabilities',
//           'item_count': '25',
//           'report_type': 'hosts',
//         },
//         'version': 2,
//       },
//       {
//         'h': 6,
//         'i': 'a9076b31-a640-4786-8034-1ed47d6ab060',
//         'key': 'vulnerability_instances_total',
//         'maxH': 12,
//         'maxW': 4,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 2,
//         'y': 10,
//         'cacheKey': 'vulnerability_instances_category',
//         'fullLabel': '',
//         'label': 'Total',
//         'settings': {
//           'include_description': false,
//           'version': 'full',
//         },
//         'version': 2,
//       },
//       {
//         'h': 12,
//         'i': 'bea79062-c457-4e55-9af2-4d62a03ba7d4',
//         'key': 'vulnerabilities_top_details',
//         'maxH': 48,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 6,
//         'x': 0,
//         'y': 23,
//         'cacheKey': 'vulnerabilities_top_details|count_3|order_exploit_cvss',
//         'fullLabel': '',
//         'label': 'Top Vulnerabilities w/ Details',
//         'settings': {
//           'order_by': 'exploit_cvss',
//           'item_count': 3,
//           'orientation': 'horizontal',
//           'include_risk': false,
//           'include_instances': true,
//           'include_cvss': true,
//           'report_type': 'vulnerabilities',
//         },
//         'version': 2,
//       },
//       {
//         'h': 10,
//         'i': '6b17c869-3fc1-4e4d-9094-79c0917a3221',
//         'key': 'hosts_top_details',
//         'maxH': 48,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 6,
//         'x': 0,
//         'y': 52,
//         'cacheKey': 'hosts_top_details|count_3|order_num_vulnerabilities',
//         'fullLabel': '',
//         'label': 'Top Hosts w/ Details',
//         'settings': {
//           'order_by': 'num_vulnerabilities',
//           'item_count': 3,
//           'orientation': 'horizontal',
//           'include_risk': false,
//           'include_instances': true,
//           'include_cvss': true,
//           'report_type': 'hosts',
//         },
//         'version': 2,
//       },
//       {
//         'h': 5,
//         'i': '552f703d-fd65-4609-b655-eeeae8706830',
//         'key': 'vulnerabilities_total',
//         'maxH': 12,
//         'maxW': 4,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 2,
//         'y': 0,
//         'cacheKey': 'risk_breakdown',
//         'fullLabel': '',
//         'label': 'Total',
//         'settings': {
//           'report_type': 'vulnerabilities',
//         },
//         'version': 2,
//       },
//       {
//         'h': 5,
//         'i': 'c4c67a87-d89f-48f8-9436-3c58f0a8802b',
//         'key': 'hosts_total',
//         'maxH': 12,
//         'maxW': 4,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 2,
//         'y': 5,
//         'cacheKey': 'risk_breakdown',
//         'fullLabel': '',
//         'label': 'Total',
//         'settings': {
//           'report_type': 'hosts',
//         },
//         'version': 2,
//       },
//       {
//         'h': 17,
//         'i': 'f1dd6d21-9f7e-4daa-928f-ae3a6f6de5a3',
//         'key': 'vulnerabilities_priority',
//         'maxH': 48,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 4,
//         'y': 35,
//         'cacheKey': 'vulnerabilities_priority|order_num_hosts|tags_',
//         'fullLabel': 'Vulnerabilities: Highest Priority (100)',
//         'label': 'Highest Priority Vulnerabilities',
//         'settings': {
//           'version': 'list',
//           'item_count': 25,
//           'report_type': 'vulnerabilities',
//           'include_rating': true,
//           'include_description': false,
//           'order_by': 'num_hosts',
//         },
//         'version': 2,
//       },
//       {
//         'h': 17,
//         'i': 'b31d6b74-677b-4795-98f3-8dfc76692188',
//         'key': 'hosts_priority',
//         'maxH': 48,
//         'maxW': 6,
//         'minH': 4,
//         'minW': 2,
//         'resizeHandles': [
//           's',
//           'e',
//         ],
//         'w': 2,
//         'x': 4,
//         'y': 62,
//         'cacheKey': 'hosts_priority|order_num_vulnerabilities|tags_',
//         'fullLabel': 'Hosts: Highest Priority (100)',
//         'label': 'Highest Priority Hosts',
//         'settings': {
//           'version': 'list',
//           'item_count': 25,
//           'report_type': 'hosts',
//           'include_rating': true,
//           'include_user': false,
//           'include_os_type': false,
//           'include_description': false,
//           'order_by': 'num_vulnerabilities',
//         },
//         'version': 2,
//       },
//     ],
//   },
// ];
// /* eslint-enable camelcase */

// const builtInIDS = builtInDashboards.map( dash => dash.id );

// const isBuiltIn = _layout => builtInIDS.includes( _layout.id );

const Grid = Responsive;

const DashboardsV2 = ( ) => {

  const dashboardWrapperRef = React.useRef( null );
  const editorRef = React.useRef( null );

  const [ expandLeftNavigation ] = React.useContext( NavigationContext );
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ , setPrintingOrientation ] = React.useContext( PrintingContext );
  const [ , , , demoMode ] = React.useContext( CurrentUserContext );

  const [ tags ] = React.useContext( TagsContext );

  // the current dashboard that should be rendered, stored in local storage
  const [ currentLayoutID, setCurrentLayoutID ] = useLocalStorage( 'DSdashboardCurrentLayoutID', '' );
  // the options for the dashboard selector
  // { [uuid]: { h, w, x, y, etc. }, etc. }
  const [ dashboardOptions, setDashBoardOptions ] = React.useState( null );
  const [ currentDashboard, setCurrentDashboard ] = React.useState( null );

  // used to hold all the vars needed for the grid to render the box for a widget
  // { [i]: { h, w, x, y, etc. }, etc. }
  const [ layoutWidgets, setLayoutWidgets ] = React.useState( null );
  // used to hold all the vars needed for the widgets to display the content that they should
  // { [i]: { settings, label, version, etc. }, etc. }
  const [ contentWidgets, setContentWidgets ] = React.useState( null );

  const [ showWidgetEditor, setShowWidgetEditor ] = React.useState( false );
  const [ printing, setPrinting ] = React.useState( false );
  const [ showPageBreaks, setShowPageBreaks ] = useLocalStorage( 'DSdashboardShowPageBreaks', false );
  const [ pageCount, setPageCount ] = React.useState( 1 );
  const [ gridWidth, setGridWidth ] = React.useState( 1270 );
  const [ editMode, setEditMode ] = React.useState( false );
  const [ initialDashboardFetchComplete, setInitialDashboardFetchComplete ] = React.useState( false );
  const [ existingReport, setExistingReport ] = React.useState( null );
  const [ gridLoading, setGridLoading ] = React.useState( false );
  const [ updatedDashboardForm, setUpdatedDashboardForm ] = React.useState( null );
  const [ groupedComplianceData, setGroupedComplianceData ] = React.useState( null );
  const [ complianceFieldsOptions, setComplianceFieldsOptions ] = React.useState( null );

  // widget and variant related vars, need to be here so they can pass down to all children
  const [ selectedWidgetVariant, setSelectedWidgetVariant ] = React.useState( null );
  const [ selectedWidgetOptions, setSelectedWidgetOptions ] = React.useState( null );
  const [ selectedWidgetCategory, setSelectedWidgetCategory ] = React.useState( null );
  // IMPORTANT: this is the recordLabel that gets saved after selecting a record for the top N paths,
  // prevents a totally unnecessary separate lookup later for the label display
  const [ recordLabel, setRecordLabel ] = React.useState( null );
  const [ currentWidget, setCurrentWidget ] = React.useState( null );

  // only called once on page load (will trigger a data prefetch)
  // 1. uploads all the current builtin dashboards so that they are always current
  // 2. calls setupWidgetState
  // 3. sets up the report creator if needed
  const onLoad = async () => {
    // const records = builtInDashboards;

    // // for now, always going to update the backend with the latest builtin dashboards no matter what
    // await makeRequest( 'UPSERT', '/dashboard', { records } );

    setupWidgetState();

    const hash = decodeURLHash();

    if ( hash.creating_report ) {
      const project = 'default';
      const model = 'base';
      const filters = {
        // eslint-disable-next-line camelcase
        extra_columns: [
          'created',
          'email_recipients',
          'format',
          'filters',
          'id',
          'label',
          'last_finished',
          'last_started',
          'schedule',
          'expiration',
          'type',
          'owner',
          'state',
        ],
        // eslint-disable-next-line camelcase
        id_list: [ hash.report_id ],
      };

      if ( isNotEmpty( hash.report_id ) ) {
        const reportRequest = await makeRequest( 'SEARCH', '/model/base/exported_report', {
          project,
          model,
          filters,
        } );

        if ( reportRequest && reportRequest.results ) {
          setExistingReport( reportRequest.results[0] );
        } else {
          setExistingReport( null );
        }
        openReportCreator();
      } else {
        setExistingReport( null );
        openReportCreator();
      }
    }
  };

  // eslint-disable-next-line max-len
  // called anytime the data changes (on page load, or dash selector change, when a dashboard is created/updated/removed)
  // 1. fetches all existing dashboards
  // 2. separates each existing dashboard into:
  //    a. objects needed for layout
  //    b. objects needed for getting the content
  //    c. one can retrieve from the other because they will both be keyed off of the `i` value of each widget.
  const setupWidgetState = async () => {

    const _layoutWidgets = {};
    const _contentWidgets = {};
    const _dashboardOptions = {};
    const _uniqueCacheKeys = new Set();
    let _currentDashboard = null;

    let _currentID = isNotEmpty( currentLayoutID ) ? currentLayoutID : '00000000-0000-0000-0000-000000000001';
    const dashboardsResponse = await makeRequest( 'SEARCH', '/dashboard' );

    if ( isNotEmpty( dashboardsResponse && isNotEmpty( dashboardsResponse.results ) ) ) {
      // if we have dashboards (should always have at least builtin) just need to save them to state
      // before saving them, we do need to see if any of the widgets need to be updated to the latest version
      // the state will have all the dashboards as an object keyed by id (uuid) and used for the selector
      if ( isNotEmpty( dashboardsResponse.results ) ) {
        dashboardsResponse.results.map( dash => {
          const _dash = {
            ...dash,
            widgets: dash.widgets.map( w => {
              if ( w.version === 2 ) {
                return w;
              }
              const _latest = getLatestWidgetVersion( w );
              return _latest;
            } ),
          };
          // add to options for the selector
          _dashboardOptions[_dash.id] = _dash;
        } );

        // if the currentlayout id stored in localStorage has been deleted, reset to the default
        if ( isNotEmpty( _dashboardOptions ) && !Object.keys( _dashboardOptions ).includes( _currentID ) ) {
          _currentID = '00000000-0000-0000-0000-000000000001';
          setCurrentLayoutID( '00000000-0000-0000-0000-000000000001' );
        }

        if ( isNotEmpty( _dashboardOptions ) ) {
          _currentDashboard = _dashboardOptions[_currentID];

          if ( isNotEmpty( _currentDashboard ) && isNotEmpty( _currentDashboard.widgets ) ) {
            _currentDashboard.widgets.map( widget => {

              widget.cacheKey = getWidgetCacheKey( widget );

              // if this cacheKey is not already in the global dashboard cache, add it to the keys that need a fetch
              if ( isEmpty( window.dashboardCache[widget.cacheKey] ) ) {
                _uniqueCacheKeys.add( widget.cacheKey );
              }

              const deconstructedWidget = deconstructWidget( widget );

              _layoutWidgets[widget.i] = deconstructedWidget?.layoutWidget;
              _contentWidgets[widget.i] = deconstructedWidget?.contentWidget;
            } );
          }
        }
      }

      if ( isNotEmpty( _uniqueCacheKeys ) ) {
        fillDashboardCache( _uniqueCacheKeys );
      } else {
        setInitialDashboardFetchComplete( true );
      }
      setLayoutWidgets( _layoutWidgets );
      setContentWidgets( _contentWidgets );
      setDashBoardOptions( _dashboardOptions );
      setCurrentDashboard( _currentDashboard );
      updatePageCounts();
    }
  };

  // initial setup of the cache, makes sure that there is the correct data in the cache for each widget on load
  const fillDashboardCache = async ( cacheKeys ) => {
    if ( isNotEmpty( cacheKeys ) ) {

      // turn the set into an array so that we can get the index later and map over it
      const cacheKeysAsArray = [ ...cacheKeys ];

      // get all the fetches needed for each key
      const fetches = cacheKeysAsArray.map( k => fetchForCacheKey( k, tags ) );

      // if there are needed fetches, that means that they have not been fetched, resolve the promises and add the
      // data to the global cache
      if ( isNotEmpty( fetches ) ) {
        setGridLoading( true );
        const resolvedResponses = await promiseAllSequential( fetches, { chunkSize: 4 } );

        if ( isNotEmpty( resolvedResponses ) ) {
          cacheKeysAsArray.map( ( key, index ) => {
            window.dashboardCache[key] = resolvedResponses[index];
          } );
        }
      }
    }
    setGridLoading( false );

    const exampleComplianceFetch = await makeRequest( 'POST', '/fe/history/compliance', {
      columns: '*',
      created: [ null, null ],
      // eslint-disable-next-line camelcase
      order_by: [
        [ 'created', 'ASC' ],
        [ 'id', 'ASC' ],
      ],
      'rows': [ 0, 2_000 ],
    } );

    if ( isNotEmpty( exampleComplianceFetch ) ) {
      const _groupedComplianceData = {};
      const _complianceFieldsOptions = {};

      exampleComplianceFetch.map( point => {
        if ( isNotEmpty( point ) && isNotEmpty( point.regulation ) && isNotEmpty( point.created ) ) {
          // very first one
          if ( isEmpty( _groupedComplianceData[point.created] ) ) {
            _groupedComplianceData[point.created] = { [point.regulation]: { [point.control]: point } };
          // timestamp already exists, first of this regulation
          } else if (
            isNotEmpty( _groupedComplianceData[point.created] )
            && isEmpty( _groupedComplianceData[point.created][point.regulation] )
          ) {
            _groupedComplianceData[point.created][point.regulation] = { [point.control]: point };
          // timestamp and regulation already exist
          } else {
            _groupedComplianceData[point.created][point.regulation][point.control] = point;
          }
        }
      } );

      setGroupedComplianceData( _groupedComplianceData );

      if ( isNotEmpty( _groupedComplianceData ) ) {

        const [ latestTimestamp ] = Object.keys( _groupedComplianceData );

        if ( isNotEmpty( _groupedComplianceData[latestTimestamp] ) ) {
          Object.entries( _groupedComplianceData[latestTimestamp] ).map( ( [ regulationKey, controls ] ) => {

            const controlOptions = { all: 'All Controls' };

            Object.keys( controls ).map( controlKey => controlOptions[controlKey] = controlKey );
            _complianceFieldsOptions[regulationKey] = controlOptions;
          } );
        }
      }
      setComplianceFieldsOptions( _complianceFieldsOptions );
    }
    setInitialDashboardFetchComplete( true );
  };

  // called anytime a widget needs to get its data (create/edit)
  // 1. first checks the cache
  // 2. if the cache does not have the data this widget needs, it will kick off the fetch and add to the cache
  const getDataForWidget = async ( widget, tags ) => {

    let data;
    if ( isNotEmpty( widget ) ) {
      const { cacheKey } = widget;

      if ( isNotEmpty( cacheKey ) ) {

        if ( isNotEmpty( window.dashboardCache[cacheKey] ) ) {
          data = window.dashboardCache[cacheKey];
        } else {
          const fetch = fetchForCacheKey( cacheKey, tags );
          data = await fetch();
          window.dashboardCache[cacheKey] = data;
        }
      }
    }
    return data;
  };

  // called to split a saved widget into its layout and content parts
  const deconstructWidget = widget => {

    if ( isNotEmpty( widget ) ) {
      // eslint-disable-next-line max-len
      const { cacheKey, fullLabel, h, i, key, label, maxH, maxW, minH, minW, resizeHandles, settings, version, w, x, y } = widget;

      const layoutWidget = { h, i, key, maxH, maxW, minH, minW, resizeHandles, w, x, y, cacheKey };
      const contentWidget = {
        fullLabel,
        label,
        settings,
        version,
        i,
        key,
        cacheKey,
      };

      return ( { layoutWidget, contentWidget } );
    }
    return ( { } );
  };

  // 1. first thing that happens on page load
  React.useEffect( () => {
    onLoad();
  }, [ tags ] );

  // adjusts sizing when printing
  React.useEffect( ( ) => {
    if ( printing ) {
      setGridWidth( 960 );
      setPrintingOrientation( 'landscape' );
    } else {
      const leftPadding = expandLeftNavigation ? 400 : 124;
      setGridWidth( window.innerWidth - leftPadding );
      setTimeout( () => {
        setPrintingOrientation( 'portrait' );
      }, 100 );
    }
  }, [ printing ] );

  const handleBeforePrint = () => {
    setPrinting( true );
  };

  const handleAfterPrint = () => {
    setPrinting( false );
  };

  // sets up the resize listener so that the padding can correctly be applied to the top of the grid to account
  // for the size of the editor
  React.useEffect( ( ) => {
    adjustGridWidthAndPadding();
    window.addEventListener( 'resize', adjustGridWidthAndPadding );
    window.addEventListener( 'beforeprint', handleBeforePrint );
    window.addEventListener( 'afterprint', handleAfterPrint );
    return () => {
      window.removeEventListener( 'resize', adjustGridWidthAndPadding );
      window.removeEventListener( 'beforeprint', handleBeforePrint );
      window.removeEventListener( 'afterprint', handleAfterPrint );
    };
  }, [ editMode, expandLeftNavigation ] );

  const adjustGridWidthAndPadding = () => {
    if ( !printing ) {
      // full width - the sidebar

      const leftPadding = expandLeftNavigation ? 390 : 124;
      setGridWidth( window.innerWidth - leftPadding );
    }
  };

  // callback from onLayoutChange (comes with react grid layout)
  // all this needs to do is reset the layoutWidgets state with the new state from the manipulation of the grid
  const handleLayoutChange = layout => {
    if ( isNotEmpty( layout ) ) {
      const _layoutWidgets = {};

      layout.map( widget => {
        let oldLayoutWidget = {};

        if ( isNotEmpty( layoutWidgets ) && isNotEmpty( layoutWidgets[widget.i] ) ) {
          oldLayoutWidget = layoutWidgets[widget.i];
        }

        const { h, w, x, y, i, maxH, maxW, minH, minW, resizeHandles } = widget;

        _layoutWidgets[widget.i] = { ...oldLayoutWidget, h, w, x, y, i, maxH, maxW, minH, minW, resizeHandles };
      } );
      setLayoutWidgets( _layoutWidgets );
    }
  };

  // when entering editmode
  const editAndConfigure = () => {
    setEditMode( true );
    setTimeout( () => {
      adjustGridWidthAndPadding();
    }, 100 );
  };

  const handleExportButtonClick = () => {
    if ( currentDashboard.id === '00000000-0000-0000-0000-000000000000' ) {
      openReportCreator();
    } else {
      setGridWidth( 960 );
      setPrinting( true );
      setPrintingOrientation( 'landscape' );
      setTimeout( () => {
        window.print();
      }, 100 );
    }
  };

  const updatePageCounts = () => {
    if ( isNotEmpty( dashboardWrapperRef ) && isNotEmpty( dashboardWrapperRef.current ) ) {
      const { height } = getDimensionsAndOffset( dashboardWrapperRef.current );

      const _pageCount = Math.ceil( height / 720 );

      if ( isNotEmpty( _pageCount ) && _pageCount > 1 ) {
        setPageCount( _pageCount );
      } else {
        setPageCount( 1 );
      }
    }
  };

  const handleShowPageBreakClick = () => {
    updatePageCounts();
    setShowPageBreaks( !showPageBreaks );
  };

  // need to adjust the page count when the grid changes, so that the page breaks update accordingly
  React.useEffect( () => {
    updatePageCounts();
  }, [ layoutWidgets ] );

  const copyDashboard = async () => {
    if ( isNotEmpty( currentDashboard ) ) {
      const copiedDashboard = { ...currentDashboard };

      delete copiedDashboard.id;
      delete copiedDashboard.created;
      delete copiedDashboard.modified;

      copiedDashboard.label = `${copiedDashboard.label} (copy)`;

      const upsertRequest = await makeRequest( 'UPSERT', '/dashboard', { records: [ copiedDashboard ] } );

      // success
      if ( isNotEmpty( upsertRequest ) && isNotEmpty( upsertRequest.results ) ) {

        setCurrentLayoutID( upsertRequest.results[0].id );

        addFlashMessage( {
          body: 'Successfully copied dashboard',
          type: 'success',
        } );
        window.location.reload();
      } else {
        addFlashMessage( {
          body: 'There was an error copying your dashboard',
          type: 'alert',
        } );
      }

      setEditMode( false );
    }
  };

  const openWidgetEditorFor = widgetCategory => {
    setSelectedWidgetCategory( widgetCategory );
    setShowWidgetEditor( true );
  };

  const closeWidgetEditor = () => {
    setSelectedWidgetCategory( null );
    setShowWidgetEditor( false );
    setCurrentWidget( null );
    setSelectedWidgetVariant( null );
  };

  // widget CRUD functions, add, remove, edit
  const addWidget = widget => {
    if ( isNotEmpty( widget ) ) {

      const getNewWidgetY = () => {
        let _otherItems = [];
        if ( isNotEmpty( currentDashboard?.widgets ) ) {
          _otherItems = [ ...currentDashboard.widgets ];
          _otherItems = _otherItems.sort( ( a, b ) => b.y - a.y );
          return _otherItems[0].y + _otherItems[0].h;
        }
        return 0;
      };

      widget.y = getNewWidgetY();

      if ( isEmpty( widget.x ) ) {
        widget.x = 0;
      }
      widget.cacheKey = getWidgetCacheKey( widget );

      const deconstructedWidget = deconstructWidget( widget );

      const _layoutWidgets = { ...layoutWidgets };
      const _contentWidgets = { ...contentWidgets };
      _layoutWidgets[widget.i] = deconstructedWidget?.layoutWidget;
      _contentWidgets[widget.i] = deconstructedWidget?.contentWidget;

      setLayoutWidgets( _layoutWidgets );
      setContentWidgets( _contentWidgets );

      // scroll container to newly added widget for convenience
      const scrollingContainer = document.getElementById( 'reportingContainer' );
      if ( isNotEmpty( scrollingContainer ) ) {
        window.setTimeout( () => {
          scrollingContainer.scrollTop = scrollingContainer.scrollHeight;
        }, 100 );
      }
    }
  };

  // similar to adding a new widget, just with less adjustments needed
  const editWidget = widget => {
    if ( isNotEmpty( widget ) ) {

      // need to reset the cacheKey in case settings changed that would effect what it is
      widget.cacheKey = getWidgetCacheKey( widget );

      // we need to update the content version of this widget only because this is only called when editing
      // the settings, etc. we do however need to update the layout version with the new cacheKey

      const _layoutWidgets = { ...layoutWidgets };
      const _contentWidgets = { ...contentWidgets };

      _layoutWidgets[widget.i] = { ..._layoutWidgets[widget.i], cacheKey: widget.cacheKey };
      _contentWidgets[widget.i] = widget;

      setLayoutWidgets( _layoutWidgets );
      setContentWidgets( _contentWidgets );
    }
  };

  const removeWidget = widget => {
    if (
      isNotEmpty( widget )
      && isNotEmpty( widget.i )
      && isNotEmpty( layoutWidgets )
      && isNotEmpty( contentWidgets )
    ) {
      const _layoutWidgets = { ...layoutWidgets };
      const _contentWidgets = { ...contentWidgets };

      delete _layoutWidgets[widget.i];
      delete _contentWidgets[widget.i];

      setLayoutWidgets( _layoutWidgets );
      setContentWidgets( _contentWidgets );
    }
  };

  return (
    <React.Fragment>
      <ReportCreator
        existingReport={existingReport}
        setExistingReport={setExistingReport}
        advanced
      />
      <WidgetEditorModal
        showWidgetEditor={ showWidgetEditor}
        setShowWidgetEditor={ setShowWidgetEditor}
        selectedWidgetVariant={ selectedWidgetVariant }
        setSelectedWidgetVariant={ setSelectedWidgetVariant }
        selectedWidgetOptions={ selectedWidgetOptions }
        setSelectedWidgetOptions={ setSelectedWidgetOptions }
        selectedWidgetCategory={ selectedWidgetCategory }
        recordLabel={ recordLabel }
        setRecordLabel={ setRecordLabel }
        closeWidgetEditor={ closeWidgetEditor }
        addWidget={ addWidget }
        editWidget={editWidget}
        setSelectedWidgetCategory={ setSelectedWidgetCategory }
        currentWidget={ currentWidget }
        setCurrentWidget={ setCurrentWidget }
        groupedComplianceData={groupedComplianceData}
        complianceFieldsOptions={ complianceFieldsOptions }
      />
      <PageHeader elementClass="reportingDashboardPageHeader">
        {
          editMode
            ? <React.Fragment>
              <DashboardEditorV2
                setEditMode={setEditMode}
                currentLayoutID={currentLayoutID}
                setCurrentLayoutID={setCurrentLayoutID}
                currentDashboard={currentDashboard}
                setCurrentDashboard={setCurrentDashboard}
                updatedDashboardForm={updatedDashboardForm}
                setUpdatedDashboardForm={setUpdatedDashboardForm}
                editorRef={editorRef}
                layoutWidgets={layoutWidgets}
                contentWidgets={contentWidgets}
                openWidgetEditorFor={openWidgetEditorFor}
                setupWidgetState={setupWidgetState}
              />
            </React.Fragment>
            : <React.Fragment>
              {
                isNotEmpty( currentDashboard ) &&
                <React.Fragment>
                  <h2>
                    {
                      currentDashboard.builtin
                      && <InlineSVG type="primaryLogoBug" version="bug" size="logoBug" elementClass="dsLogo" />
                    }
                    {
                      isNotEmpty( currentDashboard?.label )
                        ? currentDashboard.label
                        : 'Default Dashboard'
                    }
                    {
                      !currentDashboard.builtin &&
                      <button
                        onClick={ editAndConfigure }
                        className="editModeButton"
                        disabled={demoMode}
                      >
                        <InlineSVG type="setup" />
                        Configure
                      </button>
                    }
                    {
                      <button
                        className={ `copyDashboardButton ${demoMode ? 'disabled' : '' }` }
                        disabled={demoMode}
                        onClick={ copyDashboard }
                        title="Duplicate Current Report?"
                      >
                        <InlineSVG type="copy"/>
                      </button>
                    }

                  </h2>
                  <div className="exportActions">
                    <div className="checkboxWrapper" onClick={ handleShowPageBreakClick }>
                      {
                        showPageBreaks
                          ? <InlineSVG type="checkboxChecked" />
                          : <InlineSVG type="checkbox" />
                      }
                      <label>Show page breaks?</label>
                    </div>
                    <button
                      disabled={ demoMode }
                      className="exportMenuTrigger"
                      onClick={ handleExportButtonClick }
                    >
                      {
                        (
                          currentDashboard.builtin
                          && currentDashboard.id === '00000000-0000-0000-0000-000000000000'
                        )
                          ? <InlineSVG type="exportFile" />
                          : <InlineSVG type="print" />
                      }
                      {
                        (
                          currentDashboard.builtin
                          && currentDashboard.id === '00000000-0000-0000-0000-000000000000'
                        )
                          ? <span>Export</span>
                          : <span>Print/Save PDF</span>
                      }
                    </button>
                  </div>

                </React.Fragment>
              }
            </React.Fragment>
        }
      </PageHeader>
      <PageCreateButton>
        {
          isNotEmpty( dashboardOptions ) &&
          <DashboardSelector
            setEditMode={setEditMode}
            options={dashboardOptions}
            setDashBoardOptions={setDashBoardOptions}
            currentLayoutID={currentLayoutID}
            setCurrentLayoutID={setCurrentLayoutID}
            currentDashboard={currentDashboard}
            setCurrentDashboard={setCurrentDashboard}
            setLayoutWidgets={setLayoutWidgets}
            setContentWidgets={setContentWidgets}
          />
        }
      </PageCreateButton>
      <div ref={dashboardWrapperRef} className={ `dashboardsGridWrapper ${printing ? 'printing' : ''}` } >
        { gridLoading && <Loading text="Loading data..." /> }
        {
          ( isNotEmpty( pageCount ) && showPageBreaks ) &&
          <div className="pagesContainer">
            {
              Array( pageCount ).fill().map( ( p, i ) => <div key={i} className="pageWrapper" /> )
            }
          </div>
        }
        {
          (
            isNotEmpty( layoutWidgets )
            && isNotEmpty( contentWidgets )
            && isNotEmpty( Grid )
            && isNotEmpty( gridWidth )
            && initialDashboardFetchComplete
          ) &&
          <Grid
            className="layout"
            width={ gridWidth }
            layouts={ { default: Object.values( layoutWidgets ) } }
            breakpoints={{ default: 0 }}
            cols={ { default: 6 } }
            rowHeight={ 62 }
            margin={ [ 0, 0 ] }
            isDraggable={ editMode }
            isResizable={ editMode }
            isDroppable={ editMode }
            isBounded
            onLayoutChange={ handleLayoutChange }
          >
            {
              Object.values( layoutWidgets ).map( widget => {
                return <div key={widget.i} >
                  <WidgetWrapperV2
                    widget={contentWidgets[widget.i]}
                    layoutWidgets={layoutWidgets}
                    addWidget={addWidget}
                    editWidget={editWidget}
                    removeWidget={removeWidget}
                    editMode={editMode}
                    printing={printing}
                    getDataForWidget={getDataForWidget}
                    setCurrentWidget={setCurrentWidget}
                    setShowWidgetEditor={setShowWidgetEditor}
                    setSelectedWidgetVariant={setSelectedWidgetVariant}
                  />
                </div>;
              } )
            }
          </Grid>
        }
      </div>
    </React.Fragment>
  );
};

export default DashboardsV2;