/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import Dropdown from '../../../shared/Dropdown';
import InlineSVG from '../../../shared/InlineSVG';
import { exportReport } from './Shared';

import './RemediationExportMenu.scss';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import { decodeURLHash, isNotEmpty } from '../../../shared/Utilities';
import Dialog from '../../../shared/Dialog';
import ExportModal from './ExportModal';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';

const RemediationExportMenu = ( { plan, fullVersion=false, activeIntegrations } ) => {

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ , , , demoMode ] = React.useContext( CurrentUserContext );

  const [ showIntegrationDialog, setShowIntegrationDialog ] = React.useState( false );

  const [ showExportModal, setShowExportModal ] = React.useState( false );

  // on init, if the hash has any specific params (because they were redirected here from somewhere), we can initialize
  // the export process so that they can "seemlessly" pick up where they left off
  React.useEffect( ( ) => {
    const hash = decodeURLHash();
    // the user was redirected here after configuring an integration, pop that open and let them continue
    if (
      isNotEmpty( hash )
      && isNotEmpty( plan )
      && isNotEmpty( hash.export_plan_id )
      && plan.id === hash.export_plan_id
    ) {
      setShowExportModal( true );
    }
  }, [ plan, activeIntegrations ] );

  // callback for remediation report export button
  const exportCallback = response => {
    addFlashMessage( {
      type: 'success',
      // eslint-disable-next-line max-len
      body: <p>Successfully created export. To see progress, edit, or download, visit <a href={`#.=reporting&page=exports&report_id=${response.results.id}`}>The reports page</a></p>,
    } );
  };

  // if the user has not setup any integrations of this type, we will instead send them over to the integration section
  // with a callback url in the hash so that they can setup the integration, and then be redirected back to this state
  // in order to complete the export process
  const beginIntegrationConfiguration = ( plan ) => {
    const redirectURL = encodeURIComponent( `#.=remediation&page=remediation_plans&export_plan_id=${plan.id}` );
    window.location.href = `#.=remediation&page=remediation_ticketing&new=start&redirect_url=${redirectURL}`;
  };

  // when clicking to export to an integration (ie: Jira ), we need to check to see if there is a configured integration
  // of that type, if there is, they may select the integration they would like and proceed with the export, if not, we
  // can direct them to create an integration of that type and then bring them back integration
  const exportToIntegration = async ( ) => {

    // there is at least one integration configured, allow to procede
    if (
      isNotEmpty( activeIntegrations )
      && (
        isNotEmpty( activeIntegrations.email )
        || isNotEmpty( activeIntegrations.jira )
        || isNotEmpty( activeIntegrations.tanium )
      )
    ) {
      setShowIntegrationDialog( false );
      setShowExportModal( true );
    // need to setup an integration first
    } else {
      setShowExportModal( false );
      setShowIntegrationDialog( true );
    }
  };

  // export menu for remediation plans, with fake coming soon options
  const exportMenuItems = [
    <div
      onClick={ () => exportReport( plan, exportCallback ) }
      title="Export Remediation Plan"
    >
      <InlineSVG type="xlsx" size="xlarge" version="special" />
      <span>Export .xlsx</span>
    </div>,
    <div
      onClick={ () => exportToIntegration( ) }
      title="Export Tasks"
    >
      {/* <InlineSVG type="integration" /> */}
      <span>Export Tasks</span>
    </div>,
  ];

  return (
    <React.Fragment>
      <Dialog
        type="confirm"
        visible={showIntegrationDialog}
        setVisible={setShowIntegrationDialog}
        content="You have not yet configured any integrations for remediation export. Would you like to do that now?"
        action={ () => beginIntegrationConfiguration( plan ) }
      />
      <ExportModal
        show={showExportModal}
        setShow={setShowExportModal}
        plan={plan}
        activeIntegrations={activeIntegrations}
        exportType="ticketing"
      />
      <Dropdown
        trigger={
          <button
            title="Export Remediation Plan"
            disabled={demoMode}
            // eslint-disable-next-line max-len
            className={ `${ demoMode ? 'disabled' : '' } ${fullVersion ? 'fullVersion' : '' } exportMenuTrigger roundGlyphButton light` }
          >
            <InlineSVG type="exportFile" />
            {
              fullVersion &&
              <span>Export</span>
            }
          </button>
        }
        disabled={demoMode}
        menuItems={ exportMenuItems }
        elementClass="remediationExportMenuWrapper"
      />
    </React.Fragment>

  );
};

export default RemediationExportMenu;