/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import Modal from '../../../../shared/Modal';
import Notification from '../../../../shared/Notification';

import {
  isNotEmpty,
  formatUnixTime,
} from '../../../../shared/Utilities';

import InlineSVG from '../../../../shared/InlineSVG';
import { makeRequest } from '../../../../../legacy/io';
import { CurrentUserContext } from '../../../../Contexts/CurrentUser';

const ModalBody = ( {
  record,
  certificate,
  status,
  notificationOptions,
} ) => {

  return (
    <React.Fragment>
      {
        isNotEmpty( notificationOptions ) &&
        <Notification options={notificationOptions} />
      }
      {
        isNotEmpty( status ) &&
        <div className="statusViewer">
          <div className="statusColumn">
            <span>
              <strong>Type: </strong>
              { record.type }
            </span>
            <span>
              <strong>Purpose: </strong>
              { record.purpose }
            </span>
            <span>
              <strong>Sub Purpose: </strong>
              { record.subpurpose }
            </span>
            <span>
              <strong>Identitiy: </strong>
              { record.identity }
            </span>
            <span>
              <strong>Added On: </strong>
              { formatUnixTime( record.created ) }
            </span>
          </div>
          <div className="statusColumn">
            {
              Object.keys( status ).map( ( label, index ) => {
                return  <span
                  key={index}
                >
                  <strong>{ `${label }: `}</strong>
                  { status[label] }
                </span>;
              } )
            }
          </div>
        </div>
      }
      <div className="certifcateViewer">
        { certificate}
      </div>
    </React.Fragment>
  );
};

const ViewCertificate = ( {
  certificate,
} ) => {

  const [ showModal, setShowModal ] = React.useState( false );
  const [ status, setStatus ] = React.useState( {} );
  const [ notificationOptions, setNotificationOptions ] = React.useState( '' );

  const [ , , , demoMode ] = React.useContext( CurrentUserContext );

  React.useEffect( () => {
    makeRequest( 'STATUS', '/certificate', { 'certificate': certificate.key } ).then( response => {
      if ( response && response['results'] ) {
        setStatus( {
          issuer: response.results.issuer,
          subject: response.results.subject,
          expiration: formatUnixTime( response.results.expiration, false ),
          fingerprint: `${response.results.hash_algorithm.toUpperCase()} Fingerprint: ${response.results.fingerprint}`,
        } );
      } else {
        setNotificationOptions( {
          type: 'alert',
          message: 'Failed to fetch certificate status!',
        } );
        setStatus( {
          issuer: 'N/A',
          subject: 'N/A',
          expiration: 'N/A',
          fingerprint: 'N/A',
        } );
      }
    } );
  }, [] );

  return (
    <React.Fragment>
      <Modal
        visible={showModal}
        setVisible={setShowModal}
        header={`${certificate.type} Certificate`}
        elementClass="viewCertificateModal"
        body={<ModalBody
          record={certificate}
          certificate={certificate.key}
          status={status}
          notificationOptions={notificationOptions}
        />}
      />
      <button
        title={'View Certificate'}
        className={ `recordItemAction ${demoMode ? 'disabled' : ''}` }
        disabled={ demoMode }
        onClick={ () => setShowModal( true ) }
      >
        <InlineSVG type="visible" />
      </button>
    </React.Fragment>
  );
};

export default ViewCertificate;
